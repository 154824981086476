import React from "react";
import { FieldProps } from "formik";
import { DictPropertyCategory } from "@api/client";

import { ReactComponent as IconOfficesActive } from "@svg/icon-offices-active.svg";
import { ReactComponent as IconOfficesNotActive } from "@svg/icon-offices-notactive.svg";
import { ReactComponent as IconShopsActive } from "@svg/icon-shops-active.svg";
import { ReactComponent as IconShopsNotActive } from "@svg/icon-shops-notactive.svg";
import { ReactComponent as IconStudentsActive } from "@svg/icon-students-active.svg";
import { ReactComponent as IconStudentsNotActive } from "@svg/icon-students-notactive.svg";
import { ReactComponent as IconWarehousesActive } from "@svg/icon-warehouses-active.svg";
import { ReactComponent as IconWarehousesNotActive } from "@svg/icon-warehouses-notactive.svg";

import * as S from "./radiobox-icon.styled";

const icons = {
  unchecked: {
    OFF: <IconOfficesNotActive />,
    WRH: <IconWarehousesNotActive />,
    STH: <IconStudentsNotActive />,
    COM: <IconShopsNotActive />,
  },
  checked: {
    OFF: <IconOfficesActive />,
    WRH: <IconWarehousesActive />,
    STH: <IconStudentsActive />,
    COM: <IconShopsActive />,
  },
};

type Props = FieldProps & {
  options: DictPropertyCategory[];
};

type Values = "OFF" | "WRH" | "STH" | "COM";

export const RadioboxIcon: React.FC<Props> = ({ field, options }) => {
  const getIcon = (type: "checked" | "unchecked") => icons[type];

  return (
    <S.Wrapper>
      {options.map((item) => (
        <S.StyledRadioboxIconWrapper key={(Math.random() + 1).toString(36).substring(7)}>
          <S.StyledInput
            {...field}
            type="radio"
            id={`${field.name}_${item.code}`}
            checked={field.value === item.code}
            value={item.code}
          />
          <S.Option id={`${field.name}_${item.code}`} selected={field.value === item.code}>
            <S.StyledLabel htmlFor={`${field.name}_${item.code}`}>
              <S.StyledRadioboxIcon key={`${item.code}_checked`}>
                {getIcon("checked")[item.code as Values]}
              </S.StyledRadioboxIcon>
              <S.StyledRadioboxIcon key={`${item.code}_unchecked`}>
                {getIcon("unchecked")[item.code as Values]}
              </S.StyledRadioboxIcon>
              <S.LabelText>{item.namePlural}</S.LabelText>
            </S.StyledLabel>
          </S.Option>
        </S.StyledRadioboxIconWrapper>
      ))}
    </S.Wrapper>
  );
};
