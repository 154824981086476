/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomAttributeDistinctUnits
 */
export interface CustomAttributeDistinctUnits {
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeDistinctUnits
     */
    code: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CustomAttributeDistinctUnits
     */
    units?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeDistinctUnits
     */
    baseUnitOfMeasure?: string;
}

export function CustomAttributeDistinctUnitsFromJSON(json: any): CustomAttributeDistinctUnits {
    return CustomAttributeDistinctUnitsFromJSONTyped(json, false);
}

export function CustomAttributeDistinctUnitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAttributeDistinctUnits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'units': !exists(json, 'units') ? undefined : json['units'],
        'baseUnitOfMeasure': !exists(json, 'baseUnitOfMeasure') ? undefined : json['baseUnitOfMeasure'],
    };
}

export function CustomAttributeDistinctUnitsToJSON(value?: CustomAttributeDistinctUnits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'units': value.units,
        'baseUnitOfMeasure': value.baseUnitOfMeasure,
    };
}


