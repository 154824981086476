import React from "react";
import { useParams } from "react-router";
import { useQuery, useMutation } from "react-query";

import { Tag } from "@components/tag";

import { usePermissions } from "@hooks/use-permission";

import { offerApi } from "@api/core";

import { NewTag } from "./new-tag";
import * as S from "./offer-tags.styled";

export const OfferTags = () => {
  const { hasPermission } = usePermissions();
  const params = useParams<{ id: string }>();
  const offerId = Number(params.id);
  const { data: tags, refetch } = useQuery(`tags-offer-${params.id}`, () => {
    if (hasPermission("GET_OFFER_TAGS")) return offerApi.getOfferTagsApiV1OffersOfferIdTagsGet({ offerId });
  });
  const deleteTag = useMutation(
    (tagId: number) => offerApi.deleteOfferTagApiV1OffersOfferIdTagsTagIdDelete({ offerId, tagId }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  return (
    <S.TagsWrapper>
      <NewTag
        offerId={offerId}
        onSuccess={refetch}
        hasPermission={hasPermission("POST_OFFER_TAG")}
        canLoadTags={hasPermission("GET_RECOMENDED_OFFER_TAGS")}
      />
      <S.TagsContainer>
        {tags?.map(({ tagId, offerTag }) => (
          <Tag
            color="orange"
            key={tagId}
            closable={hasPermission("DELETE_OFFER_TAG")}
            onClose={() => deleteTag.mutate(tagId)}
          >
            {offerTag}
          </Tag>
        ))}
      </S.TagsContainer>
    </S.TagsWrapper>
  );
};
