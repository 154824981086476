import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { poiApi } from "@api/core";

import { setPois, setMorePois } from "@state/poi/actions";
import { Loader } from "@components/loader";
import { Empty } from "@components/empty";
import { Table as TableCore, TableHead, THeadCell } from "@components/table";

import { TableRow } from "./table-row";
import { TableHeading } from "./table-heading";

const options = {
  root: null,
  threshold: 1,
};

export const Table: React.FC = () => {
  const poi = useSelector((state) => state.poi);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState<HTMLElement | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(setPois.request({}));
    // this is component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIntersecting && poi.canLoadMore) dispatch(setMorePois.request());
  }, [isIntersecting, dispatch, poi]);

  useEffect(() => {
    if (loader) {
      const observer = new IntersectionObserver((entry: IntersectionObserverEntry[]) => {
        setIsIntersecting(entry[0].isIntersecting);
      }, options);
      observer.observe(loader);

      return () => {
        if (loader) return observer.unobserve(loader);
        return undefined;
      };
    }
    return undefined;
  }, [loader]);

  const handleToggleModal = useCallback(() => setIsOpen((open) => !open), [setIsOpen]);
  const handleDelete = useCallback(
    (poiId: number) => {
      poiApi.deletePoiApiV1PoisPoiIdDelete({ poiId });
      dispatch(setPois.request({}));
    },
    [dispatch]
  );

  if (poi.loading && !poi.pois.length) return <Loader />;

  if (!poi.loading && !poi.pois.length) {
    return (
      <>
        <TableHeading isOpen={isOpen} handleToggleModal={handleToggleModal} />
        <Empty />
      </>
    );
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    dispatch(setPois.request({ nameOrAddress: search }));
  };

  return (
    <>
      <TableHeading
        isOpen={isOpen}
        search={search}
        handleChange={handleChange}
        handleToggleModal={handleToggleModal}
      />
      <TableCore>
        <TableHead>
          <THeadCell>Name</THeadCell>
          <THeadCell>Category Code</THeadCell>
          <THeadCell>Address</THeadCell>
          <THeadCell>Geography Name</THeadCell>
          <THeadCell>Delete</THeadCell>
        </TableHead>
        {poi.pois.map((poiItem) => (
          <TableRow key={poiItem.id} {...poiItem} onDelete={handleDelete} />
        ))}
        <div ref={setLoader}>{poi.loading && <Loader />}</div>
      </TableCore>
    </>
  );
};
