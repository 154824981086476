/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseAttribute,
    BaseAttributeFromJSON,
    BaseAttributeFromJSONTyped,
    BaseAttributeToJSON,
    CustomAttributeOut,
    CustomAttributeOutFromJSON,
    CustomAttributeOutFromJSONTyped,
    CustomAttributeOutToJSON,
    GeoBase,
    GeoBaseFromJSON,
    GeoBaseFromJSONTyped,
    GeoBaseToJSON,
    PoiProperty,
    PoiPropertyFromJSON,
    PoiPropertyFromJSONTyped,
    PoiPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface OfferDetails
 */
export interface OfferDetails {
    /**
     * 
     * @type {number}
     * @memberof OfferDetails
     */
    offerId: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    propertyName: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    propertyStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerName: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerStatus: string;
    /**
     * 
     * @type {GeoBase}
     * @memberof OfferDetails
     */
    propertyGeography?: GeoBase;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    propertyAddress?: string;
    /**
     * 
     * @type {Date}
     * @memberof OfferDetails
     */
    offerLastModificationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerBaseRent?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerServiceCharge?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferDetails
     */
    propertyId: number;
    /**
     * 
     * @type {number}
     * @memberof OfferDetails
     */
    propertyGeoLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferDetails
     */
    propertyGeoLongitude?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    imageFileLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerDescriptionLong?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerDescriptionLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerDescriptionENG?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerDescriptionLongENG?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerDescriptionLocationENG?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    offerNote?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    propertyCategoryCode: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    propertyCategoryName: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    propertyIconCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDetails
     */
    hasREDD?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDetails
     */
    allowReddDownload?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OfferDetails
     */
    zoom?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDetails
     */
    module?: string;
    /**
     * 
     * @type {Array<PoiProperty>}
     * @memberof OfferDetails
     */
    pois: Array<PoiProperty>;
    /**
     * 
     * @type {{ [key: string]: Array<CustomAttributeOut>; }}
     * @memberof OfferDetails
     */
    customAttributes: { [key: string]: Array<CustomAttributeOut>; };
    /**
     * 
     * @type {Array<BaseAttribute>}
     * @memberof OfferDetails
     */
    suggestedAttributes: Array<BaseAttribute>;
    quickformAttributes: QuickformAttributes
}

export interface QuickformAttributes {
    askingRent: QuickformAttribute;
    availableOfficeSpace: QuickformAttribute;
    availability: QuickformAttribute;
    comments: QuickformAttribute;
    name: QuickformAttribute;
    type: QuickformAttribute;
    floors: Array<Floors>;
    buildingName: QuickformAttribute;
    availableSpace: QuickformAttribute;
    clearHeight: QuickformAttribute;
    columnGrid: QuickformAttribute;
    floorLoadingCapacity: QuickformAttribute;
    documentComments: QuickformAttribute;
    internalComments: QuickformAttribute;
    unit: QuickformAttribute;
    brand: QuickformAttribute;
    category1: QuickformAttribute;
    category2: QuickformAttribute;
    gLA: QuickformAttribute;
    salesArea: QuickformAttribute;
    rent: QuickformAttribute;
    date: QuickformAttribute;
    level: QuickformAttribute;
    notes: QuickformAttribute;
    totalNetArea: QuickformAttribute;
    leasedArea: QuickformAttribute;
    tenant: QuickformAttribute;
    transactionDate: QuickformAttribute;
}

export interface QuickformAttribute {
    id: number;
    label: string;
    valueOriginal: string;
    groupKey: string;
}

export interface Floors {
    floorsOriginal: string;
    availability: QuickformAttribute;
    availableOfficeSpace: QuickformAttribute;
    askingRent: QuickformAttribute;
    comments: QuickformAttribute;
    name: QuickformAttribute;
    type: QuickformAttribute;
    modules: Array<Module>;
    buildingName: QuickformAttribute;
    availableSpace: QuickformAttribute;
    clearHeight: QuickformAttribute;
    columnGrid: QuickformAttribute;
    floorLoadingCapacity: QuickformAttribute;
    documentComments: QuickformAttribute;
    internalComments: QuickformAttribute;
    totalNetArea: QuickformAttribute,
    leasedArea: QuickformAttribute,
    tenant: QuickformAttribute,
    transactionDate: QuickformAttribute,
    rent: QuickformAttribute,
    date: QuickformAttribute;
    level: QuickformAttribute;
    notes: QuickformAttribute;
    salesArea: QuickformAttribute;
    unit: QuickformAttribute;
    brand: QuickformAttribute;
    category1: QuickformAttribute;
    category2: QuickformAttribute;
    gLA: QuickformAttribute;
}

export interface Module {
    module: number;
    availableOfficeSpace: QuickformAttribute;
    availability: QuickformAttribute;
    askingRent: QuickformAttribute;
    comments: QuickformAttribute;
    name: QuickformAttribute;
    type: QuickformAttribute;
    buildingName: QuickformAttribute;
    availableSpace: QuickformAttribute;
    clearHeight: QuickformAttribute;
    columnGrid: QuickformAttribute;
    floorLoadingCapacity: QuickformAttribute;
    documentComments: QuickformAttribute;
    internalComments: QuickformAttribute;
    totalNetArea: QuickformAttribute,
    leasedArea: QuickformAttribute,
    tenant: QuickformAttribute,
    transactionDate: QuickformAttribute,
    rent: QuickformAttribute,
    unit: QuickformAttribute;
    brand: QuickformAttribute;
    category1: QuickformAttribute;
    category2: QuickformAttribute;
    gLA: QuickformAttribute;
    salesArea: QuickformAttribute;
    date: QuickformAttribute;
    level: QuickformAttribute;
    notes: QuickformAttribute;
}

export function OfferDetailsFromJSON(json: any): OfferDetails {
    return OfferDetailsFromJSONTyped(json, false);
}

export function OfferDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offerId': json['offerId'],
        'propertyName': json['propertyName'],
        'propertyStatus': json['propertyStatus'],
        'offerName': json['offerName'],
        'offerStatus': json['offerStatus'],
        'propertyGeography': !exists(json, 'propertyGeography') ? undefined : GeoBaseFromJSON(json['propertyGeography']),
        'propertyAddress': !exists(json, 'propertyAddress') ? undefined : json['propertyAddress'],
        'offerLastModificationDate': (new Date(json['offerLastModificationDate'])),
        'offerBaseRent': !exists(json, 'offerBaseRent') ? undefined : json['offerBaseRent'],
        'offerServiceCharge': !exists(json, 'offerServiceCharge') ? undefined : json['offerServiceCharge'],
        'propertyId': json['propertyId'],
        'propertyGeoLatitude': !exists(json, 'propertyGeoLatitude') ? undefined : json['propertyGeoLatitude'],
        'propertyGeoLongitude': !exists(json, 'propertyGeoLongitude') ? undefined : json['propertyGeoLongitude'],
        'imageFileLocation': !exists(json, 'imageFileLocation') ? undefined : json['imageFileLocation'],
        'offerDescription': !exists(json, 'offerDescription') ? undefined : json['offerDescription'],
        'offerDescriptionLong': !exists(json, 'offerDescriptionLong') ? undefined : json['offerDescriptionLong'],
        'offerDescriptionLocation': !exists(json, 'offerDescriptionLocation') ? undefined : json['offerDescriptionLocation'],
        'offerDescriptionENG': !exists(json, 'offerDescriptionENG') ? undefined : json['offerDescriptionENG'],
        'offerDescriptionLongENG': !exists(json, 'offerDescriptionLongENG') ? undefined : json['offerDescriptionLongENG'],
        'offerDescriptionLocationENG': !exists(json, 'offerDescriptionLocationENG') ? undefined : json['offerDescriptionLocationENG'],
        'offerNote': !exists(json, 'offerNote') ? undefined : json['offerNote'],
        'propertyCategoryCode': json['propertyCategoryCode'],
        'propertyCategoryName': json['propertyCategoryName'],
        'propertyIconCode': json['propertyIconCode'],
        'hasREDD': !exists(json, 'hasREDD') ? undefined : json['hasREDD'],
        'allowReddDownload': !exists(json, 'allowReddDownload') ? undefined : json['allowReddDownload'],
        'zoom': !exists(json, 'zoom') ? undefined : json['zoom'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'pois': ((json['pois'] as Array<any>).map(PoiPropertyFromJSON)),
        'customAttributes': json['customAttributes'],
        'suggestedAttributes': ((json['suggestedAttributes'] as Array<any>).map(BaseAttributeFromJSON)),
        'quickformAttributes': json['quickformAttributes']
    };
}

export function OfferDetailsToJSON(value?: OfferDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'offerId': value.offerId,
        'propertyName': value.propertyName,
        'propertyStatus': value.propertyStatus,
        'offerName': value.offerName,
        'offerStatus': value.offerStatus,
        'propertyGeography': GeoBaseToJSON(value.propertyGeography),
        'propertyAddress': value.propertyAddress,
        'offerLastModificationDate': (value.offerLastModificationDate.toISOString()),
        'offerBaseRent': value.offerBaseRent,
        'offerServiceCharge': value.offerServiceCharge,
        'propertyId': value.propertyId,
        'propertyGeoLatitude': value.propertyGeoLatitude,
        'propertyGeoLongitude': value.propertyGeoLongitude,
        'imageFileLocation': value.imageFileLocation,
        'offerDescription': value.offerDescription,
        'offerDescriptionLong': value.offerDescriptionLong,
        'offerDescriptionLocation': value.offerDescriptionLocation,
        'offerDescriptionENG': value.offerDescriptionENG,
        'offerDescriptionLongENG': value.offerDescriptionLongENG,
        'offerDescriptionLocationENG': value.offerDescriptionLocationENG,
        'offerNote': value.offerNote,
        'propertyCategoryCode': value.propertyCategoryCode,
        'propertyCategoryName': value.propertyCategoryName,
        'propertyIconCode': value.propertyIconCode,
        'hasREDD': value.hasREDD,
        'allowReddDownload': value.allowReddDownload,
        'zoom': value.zoom,
        'module': value.module,
        'pois': ((value.pois as Array<any>).map(PoiPropertyToJSON)),
        'customAttributes': value.customAttributes,
        'suggestedAttributes': ((value.suggestedAttributes as Array<any>).map(BaseAttributeToJSON)),
        'quickformAttributes': value.quickformAttributes,
    };
}


