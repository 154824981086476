import React from "react";
import { useMutation } from "react-query";
import { Popconfirm } from "antd";

import { DeleteButton } from "@components/delete-button";
import { tenantApi } from "@api/core";
import { usePermissions } from "@hooks/use-permission";
import * as S from "./tenant-element-header.styled";

interface TenantElementHeaderProp {
  tenantId: number;
  propertyId: number;
  name: string;
  onDelete: () => void;
}

export const TenantElementHeader: React.FC<TenantElementHeaderProp> = ({
  name,
  onDelete,
  tenantId,
  propertyId,
}) => {
  const { hasPermission } = usePermissions();
  const { mutate: handleDeleteTenant, isLoading } = useMutation(
    (tenantID: number) =>
      tenantApi.deletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDelete({
        tenantId: tenantID,
        propertyId,
      }),
    {
      onSuccess: () => {
        onDelete();
      },
    }
  );

  const handleDeleteConfirm = () => {
    handleDeleteTenant(tenantId);
  };

  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <S.HeaderContainer>
      <span>{name}</span>
      {hasPermission("DELETE_LETTING_TENNANT") && (
        <Popconfirm
          placement="left"
          title="Are you sure to delete this tenant?"
          onConfirm={handleDeleteConfirm}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <DeleteButton onClick={onDeleteClick} loading={isLoading} />
        </Popconfirm>
      )}
    </S.HeaderContainer>
  );
};
