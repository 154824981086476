import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field, FieldProps, useFormikContext } from "formik";
import { Divider, Radio } from "antd";
import AutoSizer from "react-virtualized-auto-sizer";

import { MapLazy as Map } from "@components/map";
import { Input } from "@components/form/input";
import { Empty } from "@components/empty";
import { Loader } from "@components/loader";
import { RadioButton } from "@components/radio-button";
import { ErrorMessage } from "@components/form/error-message";

import { actions } from "@state/properties/actions";

import { formKeys } from "./formik";

import * as S from "./new-offer-modal.styled";

type RefState = Record<number, HTMLDivElement | null>;

const options = {
  root: null,
  threshold: 1,
};

export const NewOfferSubform: React.FC = () => {
  const { properties, loading, canLoadMore } = useSelector((state) => state.properties);
  const dispatch = useDispatch();
  const formik = useFormikContext<typeof formKeys>();
  const [isInterSecting, setIsIntersecting] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState<HTMLElement | null>(null);
  const [refs, setRefs] = useState<RefState>({});

  useEffect(() => {
    if (isInterSecting && canLoadMore)
      dispatch(actions.loadMoreProperties.request(formik.values.findProperty));
  }, [isInterSecting, dispatch, formik.values.findProperty, properties, canLoadMore]);

  useEffect(() => {
    if (loadingIndicator) {
      const observer = new IntersectionObserver((entry: IntersectionObserverEntry[]) => {
        setIsIntersecting(entry[0].isIntersecting);
      }, options);
      observer.observe(loadingIndicator);

      return () => {
        if (loadingIndicator) return observer.unobserve(loadingIndicator);
        return undefined;
      };
    }
    return undefined;
  }, [loadingIndicator]);

  const handleClick = useCallback(
    (value: { id: string | number; longitude: number; latitude: number }) => {
      formik.setFieldValue(formKeys.selectedProperty, value.id);
      refs[Number(value.id)]?.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    [formik, refs]
  );

  const radios = useMemo(() => {
    const refsToSet: Record<number, HTMLDivElement | null> = {};

    const radioButtons = properties.map((property) => (
      <div
        ref={(element) => {
          refsToSet[property.id] = element;
        }}
      >
        <RadioButton value={property.id}>
          {property.name} - {property.address}
        </RadioButton>
      </div>
    ));

    setRefs(refsToSet);

    return radioButtons;
  }, [properties]);

  return (
    <>
      <Divider />
      <S.InputContainer>
        <Field name={formKeys.findProperty}>
          {(fieldProps: FieldProps) => <Input {...fieldProps} placeholder="Search for properties" />}
        </Field>
      </S.InputContainer>
      <S.InputContainer>
        <ErrorMessage>{formik.errors?.selectedProperty}</ErrorMessage>
        <S.PropertiesAndMapWrapper>
          <S.PropertiesListWrapper>
            {loading && <Loader />}
            {!loading && !properties.length && <Empty />}
            {!!properties.length && (
              <>
                <Field name={formKeys.selectedProperty}>
                  {({ field }: FieldProps) => <Radio.Group {...field}>{radios}</Radio.Group>}
                </Field>
                <div ref={setLoadingIndicator}>{loading && <Loader />}</div>
              </>
            )}
          </S.PropertiesListWrapper>
          <S.MapWrapper>
            {properties[0] && (
              <AutoSizer>
                {({ height, width }) => (
                  <Map
                    latitude={properties[0].geoLatitude as number}
                    longitude={properties[0].geoLongitude as number}
                    pois={properties.map(({ geoLatitude, geoLongitude, id }) => ({
                      latitude: geoLatitude as number,
                      longitude: geoLongitude as number,
                      type: "pin",
                      id,
                    }))}
                    zoom={12}
                    height={height}
                    width={width}
                    onMarkerClick={handleClick}
                    hideMain
                    calculateViewport
                    selected={formik.values.selectedProperty && Number(formik.values.selectedProperty)}
                  />
                )}
              </AutoSizer>
            )}
          </S.MapWrapper>
        </S.PropertiesAndMapWrapper>
      </S.InputContainer>
    </>
  );
};
