import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "antd";
import { Histogram } from "@components/histogram";
import { Heading } from "@components/heading";
import { setCustomFilterUnit } from "@state/attribures/actions";
import * as S from "./numeric.styled";
import { UnitSelection } from "./unit-selection";
import { getPrecisionNumber } from "./sliders/utils/get-precision-number";
import { getUnits } from "./custom-filter-modal/utils/get-units";

interface Props {
  onChange: (param: string) => void;
  currentGroup: string;
  value: string;
  unitValue?: string;
}

export const Numeric: React.FC<Props> = ({ onChange, currentGroup, value, unitValue }) => {
  const distribution = useSelector((state) => state.attributes.distribution);
  const minVal = distribution[currentGroup]?.numeric?.valueMin;
  const maxVal = distribution[currentGroup]?.numeric?.valueMax;
  const [min, max] = value ? value.split("~") : [undefined, undefined];
  const unitValues = useSelector((state) => state.attributes?.distinctUnits[currentGroup])?.length !== 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sliderValueState = [min || minVal?.toString() || "", max || maxVal?.toString() || ""];
  const dispatch = useDispatch();
  const [inputsState, setInputsState] = useState<string[]>([
    sliderValueState[0] || "0",
    sliderValueState[1] || "0",
  ]);

  useCallback(() => {
    onChange(`${sliderValueState[0]}~${sliderValueState[1]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);

  useEffect(() => {
    if (!unitValue) {
      getUnits(dispatch, currentGroup);
    }
  }, [currentGroup, dispatch, unitValue]);

  useEffect(() => {
    onChange(`${sliderValueState[0]}~${sliderValueState[1]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, minVal, max, maxVal]);

  const onNumbericChange = ([minChange, maxChange]: number[]) => {
    setInputsState([minChange.toString(), maxChange.toString()]);
    onChange(`${minChange}~${maxChange}`);
  };

  const histogramData = distribution[currentGroup]?.numeric;

  const onUnitValueChange = (unitValueArg: string) => {
    dispatch(setCustomFilterUnit(unitValueArg));
  };

  const onBlurLowNumber = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let lowValue = +event.target.value;
      const highValue = +sliderValueState[1];
      if (lowValue > highValue) lowValue = highValue;
      setInputsState([lowValue.toString(), inputsState[1]]);
      onChange(`${lowValue}~${highValue}`);
    },
    [sliderValueState, onChange, inputsState]
  );

  const changeLowNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lowValue = event.target.value;
    setInputsState([lowValue, inputsState[1]]);
  };

  const changeHighNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const highValue = event.target.value;
    setInputsState([inputsState[0], highValue]);
  };

  const onBlurHighNumber = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let highValue = +event.target.value;
      const lowValue = +sliderValueState[0];
      if (highValue < lowValue) highValue = lowValue;
      setInputsState([inputsState[0], highValue.toString()]);
      onChange(`${lowValue}~${highValue}`);
    },
    [onChange, sliderValueState, inputsState]
  );

  const numberToCalculatePrecisionForSliderStep = distribution[currentGroup]?.numeric?.valueMax ?? 0;
  return (
    <>
      {unitValues && (
        <S.ChooseUnitWrapper>
          <Heading level="5">Choose Units:</Heading>
          <UnitSelection onChangeUnit={onUnitValueChange} currentGroup={currentGroup} />
        </S.ChooseUnitWrapper>
      )}
      {histogramData && (
        <Histogram
          data={histogramData}
          width={430}
          height={200}
          showAxis
          marginLeft={10}
          marginRight={25}
          marginBottom={40}
          onBinClick={(values) => {
            onNumbericChange([values.binLow, values.binHigh]);
          }}
        />
      )}
      <S.SliderContainer>
        <Slider
          range
          onChange={onNumbericChange}
          value={[Number.parseFloat(sliderValueState[0]), Number.parseFloat(sliderValueState[1])]}
          min={distribution[currentGroup]?.numeric?.valueMin}
          max={distribution[currentGroup]?.numeric?.valueMax}
          step={getPrecisionNumber(numberToCalculatePrecisionForSliderStep)}
        />
      </S.SliderContainer>
      <S.InputsWrapper>
        <S.SliderInput
          value={inputsState[0]}
          type="number"
          onChange={changeLowNumber}
          onBlur={onBlurLowNumber}
          min={0}
        />
        <S.SliderInput
          value={inputsState[1]}
          type="number"
          max={distribution[currentGroup]?.numeric?.valueMax}
          onChange={changeHighNumber}
          onBlur={onBlurHighNumber}
        />
      </S.InputsWrapper>
    </>
  );
};
