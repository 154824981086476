import React from "react";
import { Divider } from "antd";
import { Field, FieldProps, useFormikContext } from "formik";

import { Input } from "@components/form/input";
import { ErrorMessage } from "@components/form/error-message";

import { formKeys } from "./formik";

import * as S from "./new-offer-modal.styled";

export const NewPropertySubform: React.FC = () => {
  const values = useFormikContext<typeof formKeys>();

  return (
    <>
      <Divider />
      <ErrorMessage>{values.errors?.newPropertyName}</ErrorMessage>
      <S.InputContainer>
        <Field name={formKeys.newPropertyName}>
          {(fieldProps: FieldProps) => <Input {...fieldProps} placeholder="New property name" />}
        </Field>
      </S.InputContainer>
    </>
  );
};
