/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditTrailAttributeModule
 */
export interface AuditTrailAttributeModule {
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeModule
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeModule
     */
    valueOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeModule
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuditTrailAttributeModule
     */
    timeInterval: Date;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeModule
     */
    floorsOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeModule
     */
    module?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditTrailAttributeModule
     */
    id?: number;
}

export function AuditTrailAttributeModuleFromJSON(json: any): AuditTrailAttributeModule {
    return AuditTrailAttributeModuleFromJSONTyped(json, false);
}

export function AuditTrailAttributeModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditTrailAttributeModule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'valueOriginal': !exists(json, 'valueOriginal') ? undefined : json['valueOriginal'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'timeInterval': (new Date(json['timeInterval'])),
        'floorsOriginal': !exists(json, 'floorsOriginal') ? undefined : json['floorsOriginal'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function AuditTrailAttributeModuleToJSON(value?: AuditTrailAttributeModule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'valueOriginal': value.valueOriginal,
        'createdBy': value.createdBy,
        'timeInterval': (value.timeInterval.toISOString()),
        'floorsOriginal': value.floorsOriginal,
        'module': value.module,
        'id': value.id,
    };
}


