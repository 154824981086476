import styled from "styled-components";
import { theme } from "@components/variables";

type TextAlign = "left" | "right" | "center";

interface Cell {
  textAlign?: TextAlign;
}

const textPosition = (textAlign = "left") => `text-align: ${textAlign};`;

export const Table = styled.table`
  table-layout: fixed;
  background-color: ${theme.white};
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid ${theme.grey};
  padding: 0 40px;
  overflow: hidden;
  width: 100%;

  /* @media screen and (max-width: 1440px) {
    padding: 0;
  } */
`;

export const TableHead = styled.thead`
  width: 100%;
  border-bottom: 1px solid ${theme.tableBorder};
  display: flex;
  padding: 25px 34px 10px;

  /* @media screen and (max-width: 1440px) {
    padding: 40px 17px 20px;
  } */
`;

export const THeadCell = styled.div<Cell>`
  flex: 1;
  text-transform: uppercase;
  ${({ textAlign }) => textAlign && textPosition(textAlign)}
`;

export const TableRow = styled.tr`
  display: flex;
  padding: 20px 34px;
  font-size: 13px;
  font-weight: 400;
  align-items: baseline;

  /* @media screen and (max-width: 1440px) {
    padding: 0 17px;
  } */

  &:hover {
    background-color: ${theme.lightTeal};
    cursor: pointer;
  }

  &:first-of-type {
    border-top: 1px solid #d3d3d3;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.tableBorder};
  }
`;

export const TableCell = styled.td<Cell>`
  flex: 1;
  ${({ textAlign }) => textAlign && textPosition(textAlign)}
`;
