import React from "react";
import styled from "styled-components";
import { Plus } from "@styled-icons/fa-solid/Plus";
import { Button } from "@components/button";

import * as S from "./generic-add-buttonstyled";

interface Props {
  isLoading?: boolean;
  onClick: any;
}

const LeftPadding = styled.div`
  display: inline;
  padding-left: 10px;
`;

export const GenericAddButton: React.FC<Props> = ({ isLoading, onClick, children }) => {
  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <S.AddButtonWrapper>
      <Button loading={isLoading} onClick={handleOnClick} type="primary" icon={<Plus size={15} />}>
        <LeftPadding>{children}</LeftPadding>
      </Button>
    </S.AddButtonWrapper>
  );
};
