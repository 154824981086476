/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoiOutCategory
 */
export interface PoiOutCategory {
    /**
     * 
     * @type {number}
     * @memberof PoiOutCategory
     */
    categoryId: number;
    /**
     * 
     * @type {number}
     * @memberof PoiOutCategory
     */
    poiId: number;
    /**
     * 
     * @type {Date}
     * @memberof PoiOutCategory
     */
    modificationDate: Date;
}

export function PoiOutCategoryFromJSON(json: any): PoiOutCategory {
    return PoiOutCategoryFromJSONTyped(json, false);
}

export function PoiOutCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiOutCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': json['categoryId'],
        'poiId': json['poiId'],
        'modificationDate': (new Date(json['modificationDate'])),
    };
}

export function PoiOutCategoryToJSON(value?: PoiOutCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'poiId': value.poiId,
        'modificationDate': (value.modificationDate.toISOString()),
    };
}


