import styled from "styled-components";
import { ButtonGroup as ButtonGroupCore, Button as ButtonCore } from "@components/button";

export const ButtonGroup = styled(ButtonGroupCore)`
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
`;

export const Button = styled(ButtonCore)`
  margin-bottom: 10px;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;
