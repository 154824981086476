import React from "react";
import { QuickformAttributes } from "@api/client";

import * as S from "../quick-form.styled";
import { AttributeValue as AttributeValueInput } from "../attribute-value";

import { HandleAddAttributeValue, HandleAttributeValueUpdate } from "../types";

type AttributeDataTypes = {
  string: string;
  numeric: string;
};

type Props = {
  quickformAttributes: QuickformAttributes;
  handleUpdateAttribute: HandleAttributeValueUpdate;
  handleAddAttribute?: HandleAddAttributeValue;
  attributeNames: any;
  attributeDataTypes: AttributeDataTypes;
};

export const OfficeInputsItaly: React.FC<Props> = ({
  quickformAttributes,
  handleUpdateAttribute,
  handleAddAttribute,
  attributeNames,
  attributeDataTypes,
}) => {
  return (
    <>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.totalNetArea?.id}
          attributeName={attributeNames.totalNetArea}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.totalNetArea?.valueOriginal}
          parentAttributeId={quickformAttributes.totalNetArea?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.totalNetArea?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.availableOfficeSpace?.id}
          attributeName={attributeNames.availableOfficeSpace}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.availableOfficeSpace?.valueOriginal}
          parentAttributeId={quickformAttributes.availableOfficeSpace?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.availableOfficeSpace?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.availability?.id}
          attributeName={attributeNames.availability}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.availability?.valueOriginal}
          parentAttributeId={quickformAttributes.availability?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.availability?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.type?.id}
          attributeName={attributeNames.type}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.type?.valueOriginal}
          parentAttributeId={quickformAttributes.type?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.type?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.leasedArea?.id}
          attributeName={attributeNames.leasedArea}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.leasedArea?.valueOriginal}
          parentAttributeId={quickformAttributes.leasedArea?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.leasedArea?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.tenant?.id}
          attributeName={attributeNames.tenant}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.tenant?.valueOriginal}
          parentAttributeId={quickformAttributes.tenant?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.tenant?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.transactionDate?.id}
          attributeName={attributeNames.transactionDate}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.transactionDate?.valueOriginal}
          parentAttributeId={quickformAttributes.transactionDate?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.transactionDate?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.rent?.id}
          attributeName={attributeNames.rent}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.rent?.valueOriginal}
          parentAttributeId={quickformAttributes.rent?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.rent?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.internalComments?.id}
          attributeName={attributeNames.internalComments}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.internalComments?.valueOriginal}
          parentAttributeId={quickformAttributes.internalComments?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.internalComments?.groupKey}
        />
      </S.HeadTableCell>
    </>
  );
};
