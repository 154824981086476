import {
  CustomAttributeOut,
  OfferDetails,
  OfferOutDescription,
  OfferOutName,
  OfferOutNote,
  OfferOutStatus,
  OffersBase,
  PoiProperty,
  PropertyOutName,
  PropertyOutStatus,
  PropertyOutAddressAndCoordinates,
  PropertyOutGeography,
  PutOfferAttributesOut,
  CustomAttributeNoteOut,
  BaseCustomAttributeOut,
  BaseAttribute,
  PropertyOutPoi,
  CustomAttributeVisibilityOut,
  BaseAttributeIn,
  QuickformAttributes,
} from "@api/client";
import { createAction, createAsyncAction } from "typesafe-actions";
import { Module } from "@state/types";

export const OFFERS_REQUEST = "OFFERS/OFFERS_REQUEST";
export const OFFERS_SUCCESS = "OFFERS/OFFERS_SUCCESS";
export const OFFERS_FAILURE = "OFFERS/OFFERS_FAILURE";

export const SET_PAGE_NUMBER = "OFFERS/SET_PAGE_NUMBER";
export const SET_OFFERS_LIST = "OFFERS/SET_OFFERS_LIST";

export const TOTAL_OFFERS_REQUEST = "OFFERS/TOTAL_OFFERS_REQUEST";
export const TOTAL_OFFERS_SUCCESS = "OFFERS/TOTAL_OFFERS_SUCCESS";
export const TOTAL_OFFERS_FAILURE = "OFFERS/TOTAL_OFFERS_FAILURE";

export const RESET_OFFERS = "OFFERS/RESET_OFFERS";
export const REFRESH_DATA = "OFFERS/REFRESH_DATA";

export const OFFER_DETAIL_TRIGGER = "OFFERS/OFFER_DETAIL_TRIGGER";

export const OFFER_DETAIL_REQUEST = "OFFERS/OFFER_DETAIL_REQUEST";
export const OFFER_DETAIL_SUCCESS = "OFFERS/OFFER_DETAIL_SUCCESS";
export const OFFER_DETAIL_FAILURE = "OFFERS/OFFER_DETAIL_FAILURE";

export const CLEAR_DETAILS = "CLEAR_DETAILS";

export const UPDATE_TITLE_REQUEST = "OFFERS/UPDATE_TITLE_REQUEST";
export const UPDATE_TITLE_SUCCESS = "OFFERS/UPDATE_TITLE_SUCCESS";
export const UPDATE_TITLE_FAILURE = "OFFERS/UPDATE_TITLE_FAILURE";

export const UPDATE_NOTE_REQUEST = "OFFERS/UPDATE_NOTE_REQUEST";
export const UPDATE_NOTE_SUCCESS = "OFFERS/UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILURE = "OFFERS/UPDATE_NOTE_FAILURE";

export const UPDATE_DESCRIPTION_REQUEST = "OFFERS/UPDATE_DESCRIPTION_REQUEST";
export const UPDATE_DESCRIPTION_SUCCESS = "OFFERS/UPDATE_DESCRIPTION_SUCCESS";
export const UPDATE_DESCRIPTION_FAILURE = "OFFERS/UPDATE_DESCRIPTION_FAILURE";

export const UPDATE_STATUS_REQUEST = "OFFERS/UPDATE_STATUS_REQUEST";
export const UPDATE_STATUS_SUCCESS = "OFFERS/UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAILURE = "OFFERS/UPDATE_STATUS_FAILURE";

export const UPDATE_ATTRIBUTE_REQUEST = "OFFERS/UPDATE_ATTRIBUTE_REQUEST";
export const UPDATE_ATTRIBUTE_SUCCESS = "OFFERS/UPDATE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE_FAILURE = "OFFERS/UPDATE_ATTRIBUTE_FAILURE";

export const DELETE_ATTRIBUTE_REQUEST = "OFFERS/DELETE_ATTRIBUTE_REQUEST";
export const DELETE_ATTRIBUTE_SUCCESS = "OFFERS/DELETE_ATTRIBUTE_SUCCESS";
export const DELETE_ATTRIBUTE_FAILURE = "OFFERS/DELETE_ATTRIBUTE_FAILURE";

export const ADD_QUICKFORM_ATTRIBUTE_REQUEST = "OFFERS/ADD_QUICKFORM_ATTRIBUTE_REQUEST";
export const ADD_QUICKFORM_ATTRIBUTE_SUCCESS = "OFFERS/ADD_QUICKFORM_ATTRIBUTE_SUCCESS";
export const ADD_QUICKFORM_ATTRIBUTE_FAILURE = "OFFERS/ADD_QUICKFORM_ATTRIBUTE_FAILURE";

export const DELETE_QUICKFORM_FLOOR_REQUEST = "OFFERS/DELETE_QUICKFORM_FLOOR_REQUEST";
export const DELETE_QUICKFORM_FLOOR_SUCCESS = "OFFERS/DELETE_QUICKFORM_FLOOR_SUCCESS";
export const DELETE_QUICKFORM_FLOOR_FAILURE = "OFFERS/DELETE_QUICKFORM_FLOOR_FAILURE";

export const DELETE_QUICKFORM_MODULE_REQUEST = "OFFERS/DELETE_QUICKFORM_MODULE_REQUEST";
export const DELETE_QUICKFORM_MODULE_SUCCESS = "OFFERS/DELETE_QUICKFORM_MODULE_SUCCESS";
export const DELETE_QUICKFORM_MODULE_FAILURE = "OFFERS/DELETE_QUICKFORM_MODULE_FAILURE";

export const SET_ATTRIBUTE_NOTE_REQUEST = "OFFERS/SET_ATTRIBUTE_NOTE_REQUEST";
export const SET_ATTRIBUTE_NOTE_SUCCESS = "OFFERS/SET_ATTRIBUTE_NOTE_SUCCESS";
export const SET_ATTRIBUTE_NOTE_FAILURE = "OFFERS/SET_ATTRIBUTE_NOTE_FAILURE";

export const SET_ATTRIBUTE_VISIBILITY_REQUEST = "OFFERS/SET_ATTRIBUTE_VISIBILITY_REQUEST";
export const SET_ATTRIBUTE_VISIBILITY_SUCCESS = "OFFERS/SET_ATTRIBUTE_VISIBILITY_SUCCESS";
export const SET_ATTRIBUTE_VISIBILITY_FAILURE = "OFFERS/SET_ATTRIBUTE_VISIBILITY_FAILURE";

export const UPDATE_PROPERTY_STATUS_REQUEST = "OFFERS/UPDATE_PROPERTY_STATUS_REQUEST";
export const UPDATE_PROPERTY_STATUS_SUCCESS = "OFFERS/UPDATE_PROPERTY_STATUS_SUCCESS";
export const UPDATE_PROPERTY_STATUS_FAILURE = "OFFERS/UPDATE_PROPERTY_STATUS_FAILURE";

export const UPDATE_PROPERTY_NAME_REQUEST = "OFFERS/UPDATE_PROPERTY_NAME_REQUEST";
export const UPDATE_PROPERTY_NAME_SUCCESS = "OFFERS/UPDATE_PROPERTY_NAME_SUCCESS";
export const UPDATE_PROPERTY_NAME_FAILURE = "OFFERS/UPDATE_PROPERTY_NAME_FAILURE";

export const UPDATE_PROPERTY_ADDRESS_REQUEST = "OFFERS/UPDATE_PROPERTY_ADDRESS_REQUEST";
export const UPDATE_PROPERTY_ADDRESS_SUCCESS = "OFFERS/UPDATE_PROPERTY_ADDRESS_SUCCESS";
export const UPDATE_PROPERTY_ADDRESS_FAILURE = "OFFERS/UPDATE_PROPERTY_ADDRESS_FAILURE";

export const UPDATE_PROPERTY_GEOGRAPHY_REQUEST = "OFFERS/UPDATE_PROPERTY_GEOGRAPHY_REQUEST";
export const UPDATE_PROPERTY_GEOGRAPHY_SUCCESS = "OFFERS/UPDATE_PROPERTY_GEOGRAPHY_SUCCESS";
export const UPDATE_PROPERTY_GEOGRAPHY_FAILURE = "OFFERS/UPDATE_PROPERTY_GEOGRAPHY_FAILURE";

export const ADD_ATTRIBUTE_REQUEST = "ADD_ATTRIBUTE_REQUEST";
export const ADD_ATTRIBUTE_SUCCESS = "ADD_ATTRIBUTE_SUCCESS";
export const ADD_ATTRIBUTE_FAILURE = "ADD_ATTRIBUTE_FAILURE";

export const SET_ATTRIBUTES_REQUEST = "SET_ATTRIBUTES_REQUEST";
export const SET_ATTRIBUTES_SUCCESS = "SET_ATTRIBUTES_SUCCESS";
export const SET_ATTRIBUTES_FAILURE = "SET_ATTRIBUTES_FAILURE";

export const ASSIGN_PROPERTY_POI_REQUEST = "OFFERS/ASSIGN_PROPERTY_POI_REQUEST";
export const ASSIGN_PROPERTY_POI_SUCCESS = "OFFERS/ASSIGN_PROPERTY_POI_SUCCESS";
export const ASSIGN_PROPERTY_POI_FAILURE = "OFFERS/ASSIGN_PROPERTY_POI_FAILURE";

export const UNASSIGN_PROPERTY_POI_REQUEST = "OFFERS/UNASSIGN_PROPERTY_POI_REQUEST";
export const UNASSIGN_PROPERTY_POI_SUCCESS = "OFFERS/UNASSIGN_PROPERTY_POI_SUCCESS";
export const UNASSIGN_PROPERTY_POI_FAILURE = "OFFERS/UNASSIGN_PROPERTY_POI_FAILURE";

export const GET_SUGGESTED_POI_REQUEST = "OFFERS/GET_SUGGESTED_POI_REQUEST";
export const GET_SUGGESTED_POI_SUCCESS = "OFFERS/GET_SUGGESTED_POI_SUCCESS";
export const GET_SUGGESTED_POI_FAILURE = "OFFERS/GET_SUGGESTED_POI_FAILURE";

export const UPDATE_COORDINATES_REQUEST = "OFFERS/UPDATE_COORDINATES_REQUEST";
export const UPDATE_COORDINATES_SUCCESS = "OFFERS/UPDATE_COORDINATES_SUCCESS";
export const UPDATE_COORDINATES_FAILURE = "OFFERS/UPDATE_COORDINATES_FAILURE";

export const SET_PREDEFINED_ATTRIBUTES_REQUEST = "OFFERS/SET_PREDEFINED_ATTRIBUTES_REQUEST";
export const SET_PREDEFINED_ATTRIBUTES_SUCCESS = "OFFERS/SET_PREDEFINED_ATTRIBUTES_SUCCESS";
export const SET_PREDEFINED_ATTRIBUTES_FAILURE = "OFFERS/SET_PREDEFINED_ATTRIBUTES_FAILURE";

export const FETCH_ALL_DATA_FOR_OFFER_DETAILS = "OFFERS/FETCH_ALL_DATA_FOR_OFFER_DETAILS";

export const UPDATE_ATTRIBUTE_AFTER_FLOOR_UPDATE = "OFFERS/UPDATE_ATTRIBUTE_AFTER_FLOOR_UPDATE";

export const SET_ALLOW_REDD_DOWNLOAD = "OFFERS/SET_ALLOW_REDD_DOWNLOAD";

export const SET_CURRENT_MODULE = "OFFERS/SET_CURRENT_MODULE";

export const fetchOffersAction = createAsyncAction(OFFERS_REQUEST, OFFERS_SUCCESS, OFFERS_FAILURE)<
  void,
  { offerBase: OffersBase[]; resetData?: boolean; emptyData?: boolean },
  Error
>();

export const setTotalOffers = createAsyncAction(
  TOTAL_OFFERS_REQUEST,
  TOTAL_OFFERS_SUCCESS,
  TOTAL_OFFERS_FAILURE
)<void, number, Error>();

export const setOffersList = createAction(SET_OFFERS_LIST)<OffersBase[]>();

export const setPageNumber = createAction(SET_PAGE_NUMBER)<number>();

export const resetData = createAction(RESET_OFFERS)();

export const refreshData = createAction(REFRESH_DATA)();

export const triggerFetchDetails = createAction(OFFER_DETAIL_TRIGGER)<{ id: string }>();

export const fetchOfferDetails = createAsyncAction(
  OFFER_DETAIL_REQUEST,
  OFFER_DETAIL_SUCCESS,
  OFFER_DETAIL_FAILURE
)<void, OfferDetails, Error>();

export const clearDetails = createAction(CLEAR_DETAILS)();

export const updateTitle = createAsyncAction(
  UPDATE_TITLE_REQUEST,
  UPDATE_TITLE_SUCCESS,
  UPDATE_TITLE_FAILURE
)<void, OfferOutName, Error>();

export const updateNote = createAsyncAction(UPDATE_NOTE_REQUEST, UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAILURE)<
  void,
  OfferOutNote,
  Error
>();

export const updateDescription = createAsyncAction(
  UPDATE_DESCRIPTION_REQUEST,
  UPDATE_DESCRIPTION_SUCCESS,
  UPDATE_DESCRIPTION_FAILURE
)<void, OfferOutDescription, Error>();

export const updateStatus = createAsyncAction(
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE
)<void, OfferOutStatus, Error>();

export const updateAttribute = createAsyncAction(
  UPDATE_ATTRIBUTE_REQUEST,
  UPDATE_ATTRIBUTE_SUCCESS,
  UPDATE_ATTRIBUTE_FAILURE
)<{ attributeId: number; groupKey: string }, PutOfferAttributesOut & { groupKey: string }, number>();

export const deleteAttribute = createAsyncAction(
  DELETE_ATTRIBUTE_REQUEST,
  DELETE_ATTRIBUTE_SUCCESS,
  DELETE_ATTRIBUTE_FAILURE
)<{ attributeId: number; groupKey: string }, { attributeId: number; groupKey: string }, Error>();

export const addQuickformAttribute = createAsyncAction(
  ADD_QUICKFORM_ATTRIBUTE_REQUEST,
  ADD_QUICKFORM_ATTRIBUTE_SUCCESS,
  ADD_QUICKFORM_ATTRIBUTE_FAILURE
)<
  { dataTypeCode: string; name: string },
  { attributeId: number; dataTypeCode: string; name: string },
  Error
>();

export const deleteQuickformFloor = createAsyncAction(
  DELETE_QUICKFORM_FLOOR_REQUEST,
  DELETE_QUICKFORM_FLOOR_SUCCESS,
  DELETE_QUICKFORM_FLOOR_FAILURE
)<void, void, Error>();

export const deleteQuickformModule = createAsyncAction(
  DELETE_QUICKFORM_MODULE_REQUEST,
  DELETE_QUICKFORM_MODULE_SUCCESS,
  DELETE_QUICKFORM_MODULE_FAILURE
)<void, void, Error>();

export const addAttribute = createAsyncAction(
  ADD_ATTRIBUTE_REQUEST,
  ADD_ATTRIBUTE_SUCCESS,
  ADD_ATTRIBUTE_FAILURE
)<BaseAttributeIn, BaseCustomAttributeOut, Error>();

export const setAttributeNote = createAsyncAction(
  SET_ATTRIBUTE_NOTE_REQUEST,
  SET_ATTRIBUTE_NOTE_SUCCESS,
  SET_ATTRIBUTE_NOTE_FAILURE
)<string, CustomAttributeNoteOut, Error>();

export const updatePropertyStatus = createAsyncAction(
  UPDATE_PROPERTY_STATUS_REQUEST,
  UPDATE_PROPERTY_STATUS_SUCCESS,
  UPDATE_PROPERTY_STATUS_FAILURE
)<string, PropertyOutStatus, Error>();

export const updatePropertyName = createAsyncAction(
  UPDATE_PROPERTY_NAME_REQUEST,
  UPDATE_PROPERTY_NAME_SUCCESS,
  UPDATE_PROPERTY_NAME_FAILURE
)<string, PropertyOutName, Error>();

export const updatePropertyAddress = createAsyncAction(
  UPDATE_PROPERTY_ADDRESS_REQUEST,
  UPDATE_PROPERTY_ADDRESS_SUCCESS,
  UPDATE_PROPERTY_ADDRESS_FAILURE
)<string, PropertyOutAddressAndCoordinates, Error>();

export const updatePropertyGeography = createAsyncAction(
  UPDATE_PROPERTY_GEOGRAPHY_REQUEST,
  UPDATE_PROPERTY_GEOGRAPHY_SUCCESS,
  UPDATE_PROPERTY_GEOGRAPHY_FAILURE
)<string, PropertyOutGeography, Error>();

export const setAttributes = createAsyncAction(
  SET_ATTRIBUTES_REQUEST,
  SET_ATTRIBUTES_SUCCESS,
  SET_ATTRIBUTES_FAILURE
)<
  void,
  { quickformAttributes: QuickformAttributes; customAttributes: { [key: string]: CustomAttributeOut[] } },
  Error
>();

export const assignPropertyPoi = createAsyncAction(
  ASSIGN_PROPERTY_POI_REQUEST,
  ASSIGN_PROPERTY_POI_SUCCESS,
  ASSIGN_PROPERTY_POI_FAILURE
)<string, PropertyOutPoi, Error>();

export const unassignPropertyPoi = createAsyncAction(
  UNASSIGN_PROPERTY_POI_REQUEST,
  UNASSIGN_PROPERTY_POI_SUCCESS,
  UNASSIGN_PROPERTY_POI_FAILURE
)<string, void, Error>();

export const getSuggestedPoi = createAsyncAction(
  GET_SUGGESTED_POI_REQUEST,
  GET_SUGGESTED_POI_SUCCESS,
  GET_SUGGESTED_POI_FAILURE
)<string, PoiProperty[], Error>();

export const updateCoordinates = createAsyncAction(
  UPDATE_COORDINATES_REQUEST,
  UPDATE_COORDINATES_SUCCESS,
  UPDATE_COORDINATES_FAILURE
)<string, PropertyOutAddressAndCoordinates, Error>();

export const setPredefinedAttributes = createAsyncAction(
  SET_PREDEFINED_ATTRIBUTES_REQUEST,
  SET_PREDEFINED_ATTRIBUTES_SUCCESS,
  SET_PREDEFINED_ATTRIBUTES_FAILURE
)<string, BaseAttribute[], Error>();

export const setAttributeVisibility = createAsyncAction(
  SET_ATTRIBUTE_VISIBILITY_REQUEST,
  SET_ATTRIBUTE_VISIBILITY_SUCCESS,
  SET_ATTRIBUTE_VISIBILITY_FAILURE
)<void, CustomAttributeVisibilityOut, Error>();

export const updateAttributeAfterFloorUpdate = createAction(UPDATE_ATTRIBUTE_AFTER_FLOOR_UPDATE)();

export const setAllowReddDownload = createAction(SET_ALLOW_REDD_DOWNLOAD)<boolean>();

export const setCurrentModule = createAction(SET_CURRENT_MODULE)<Module>();

