/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomAttributeDistributionNumeric,
    CustomAttributeDistributionNumericFromJSON,
    CustomAttributeDistributionNumericFromJSONTyped,
    CustomAttributeDistributionNumericToJSON,
    CustomAttributeDistributionText,
    CustomAttributeDistributionTextFromJSON,
    CustomAttributeDistributionTextFromJSONTyped,
    CustomAttributeDistributionTextToJSON,
    CustomAttributeFloorsDistributionNumeric,
    CustomAttributeFloorsDistributionNumericFromJSON,
    CustomAttributeFloorsDistributionNumericFromJSONTyped,
    CustomAttributeFloorsDistributionNumericToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomAttributeDistribution
 */
export interface CustomAttributeDistribution {
    /**
     * 
     * @type {CustomAttributeDistributionNumeric}
     * @memberof CustomAttributeDistribution
     */
    numeric?: CustomAttributeDistributionNumeric;
    /**
     * 
     * @type {CustomAttributeDistributionText}
     * @memberof CustomAttributeDistribution
     */
    text?: CustomAttributeDistributionText;
    /**
     * 
     * @type {CustomAttributeFloorsDistributionNumeric}
     * @memberof CustomAttributeDistribution
     */
    floors?: CustomAttributeFloorsDistributionNumeric;
    /**
     * 
     * @type {CustomAttributeFloorsDistributionNumeric}
     * @memberof CustomAttributeDistribution
     */
    modules?: CustomAttributeFloorsDistributionNumeric;
}

export function CustomAttributeDistributionFromJSON(json: any): CustomAttributeDistribution {
    return CustomAttributeDistributionFromJSONTyped(json, false);
}

export function CustomAttributeDistributionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAttributeDistribution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numeric': !exists(json, 'numeric') ? undefined : CustomAttributeDistributionNumericFromJSON(json['numeric']),
        'text': !exists(json, 'text') ? undefined : CustomAttributeDistributionTextFromJSON(json['text']),
        'floors': !exists(json, 'floors') ? undefined : CustomAttributeFloorsDistributionNumericFromJSON(json['floors']),
        'modules': !exists(json, 'modules') ? undefined : CustomAttributeFloorsDistributionNumericFromJSON(json['modules']),
    };
}

export function CustomAttributeDistributionToJSON(value?: CustomAttributeDistribution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numeric': CustomAttributeDistributionNumericToJSON(value.numeric),
        'text': CustomAttributeDistributionTextToJSON(value.text),
        'floors': CustomAttributeFloorsDistributionNumericToJSON(value.floors),
        'modules': CustomAttributeFloorsDistributionNumericToJSON(value.modules),
    };
}


