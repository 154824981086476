import styled from "styled-components";
import { Link as LinkCore } from "react-router-dom";

export const Link = styled(LinkCore)`
  color: ${({ theme }) => theme.greyBlue};
  text-decoration: none;
  font-size: inherit;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor};
  }
`;
