import React from "react";
import { Tooltip } from "antd";
import { theme } from "@components/variables";

import { StyledButton } from "@components/button";
import { Icon } from "@components/icon";
import { ReactComponent as IconNote } from "@svg/icon-note.svg";

export const NoteButton: React.FC<React.ButtonHTMLAttributes<any>> = ({ onClick, disabled }) => (
  <Tooltip title="add note" color={theme.primaryColor}>
    <StyledButton type="ghost" onClick={onClick} disabled={disabled}>
      <Icon icon={IconNote} />
    </StyledButton>
  </Tooltip>
);
