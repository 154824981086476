/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseAttributeIn
 */
export interface BaseAttributeIn {
    /**
     * 
     * @type {string}
     * @memberof BaseAttributeIn
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAttributeIn
     */
    dataTypeCode: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAttributeIn
     */
    baseUnitOfMeasureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAttributeIn
     */
    namePL?: string;
}

export function BaseAttributeInFromJSON(json: any): BaseAttributeIn {
    return BaseAttributeInFromJSONTyped(json, false);
}

export function BaseAttributeInFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAttributeIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'dataTypeCode': json['dataTypeCode'],
        'baseUnitOfMeasureCode': !exists(json, 'baseUnitOfMeasureCode') ? undefined : json['baseUnitOfMeasureCode'],
        'namePL': !exists(json, 'namePL') ? undefined : json['namePL'],
    };
}

export function BaseAttributeInToJSON(value?: BaseAttributeIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dataTypeCode': value.dataTypeCode,
        'baseUnitOfMeasureCode': value.baseUnitOfMeasureCode,
        'namePL': value.namePL,
    };
}


