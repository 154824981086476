import { createApiAction } from "@utils/createApiAction";
import * as action from "@state/attribures/actions";
import { attributeApi } from "@api/core";
import { Dispatch } from "redux";
import { GetAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRequest } from "@api/client";

export const fetchAttributes = createApiAction(
  action.fetchAttributes,
  attributeApi.getAttributesApiV1AttributesGet.bind(attributeApi)
);

export const fetchDistinctUnits = createApiAction(
  action.fetchDistinctUnits,
  attributeApi.getDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGet.bind(attributeApi)
);

export const fetchDistribution =
  (dispatch: Dispatch) =>
  async (params: GetAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRequest) => {
    dispatch(action.fetchDistribution.request());
    try {
      const response = await attributeApi.getAttributeDistributionApiV1AttributesAttributeCodeDistributionGet(
        params
      );
      return dispatch(
        action.fetchDistribution.success({
          response,
          params,
        })
      );
    } catch (e) {
      console.error(e);
      return dispatch(action.fetchDistribution.failure(e));
    }
  };

export const translateAttribute = createApiAction(
  action.translateAttribute,
  attributeApi.putAttributeTranslationApiV1AttributesAttributeCodeTranslationPut.bind(attributeApi)
);
