import React from "react";

import { ReactComponent as iconEye } from "@svg/icon-show.svg";
import { Icon } from "@components/icon";
import * as S from "./attributes.styled";

export const IconEye: React.FC<S.IconEyeStyle> = ({ show }) => (
  <S.IconEyeWrapper show={show}>
    <Icon icon={iconEye} />
  </S.IconEyeWrapper>
);
