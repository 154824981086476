/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditTrailAttributeFloor,
    AuditTrailAttributeFloorFromJSON,
    AuditTrailAttributeFloorFromJSONTyped,
    AuditTrailAttributeFloorToJSON,
    AuditTrailAttributeModule,
    AuditTrailAttributeModuleFromJSON,
    AuditTrailAttributeModuleFromJSONTyped,
    AuditTrailAttributeModuleToJSON,
    AuditTrailBase,
    AuditTrailBaseFromJSON,
    AuditTrailBaseFromJSONTyped,
    AuditTrailBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuditTrailOut
 */
export interface AuditTrailOut {
    /**
     * 
     * @type {Array<AuditTrailBase>}
     * @memberof AuditTrailOut
     */
    attributesAuditTrail: Array<AuditTrailBase>;
    /**
     * 
     * @type {Array<AuditTrailAttributeFloor>}
     * @memberof AuditTrailOut
     */
    floorsAttributesAuditTrail: Array<AuditTrailAttributeFloor>;
    /**
     * 
     * @type {Array<AuditTrailAttributeModule>}
     * @memberof AuditTrailOut
     */
    modulesAttributesAuditTrail: Array<AuditTrailAttributeModule>;
}

export function AuditTrailOutFromJSON(json: any): AuditTrailOut {
    return AuditTrailOutFromJSONTyped(json, false);
}

export function AuditTrailOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditTrailOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributesAuditTrail': ((json['AttributesAuditTrail'] as Array<any>).map(AuditTrailBaseFromJSON)),
        'floorsAttributesAuditTrail': ((json['FloorsAttributesAuditTrail'] as Array<any>).map(AuditTrailAttributeFloorFromJSON)),
        'modulesAttributesAuditTrail': ((json['ModulesAttributesAuditTrail'] as Array<any>).map(AuditTrailAttributeModuleFromJSON)),
    };
}

export function AuditTrailOutToJSON(value?: AuditTrailOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AttributesAuditTrail': ((value.attributesAuditTrail as Array<any>).map(AuditTrailBaseToJSON)),
        'FloorsAttributesAuditTrail': ((value.floorsAttributesAuditTrail as Array<any>).map(AuditTrailAttributeFloorToJSON)),
        'ModulesAttributesAuditTrail': ((value.modulesAttributesAuditTrail as Array<any>).map(AuditTrailAttributeModuleToJSON)),
    };
}


