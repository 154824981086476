import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { en, cz, pl, czOffice, enOffice} from "./resources";

export const languages = {
  en: "EN",
  pl: "PL",
  cz: "CZ",
  czOffice: "CZ_OFFICE",
  enOffice: "EN_OFFICE"
};

const DEFAULT_LANGUAGE = languages.en;

i18n.use(initReactI18next).init({
  resources: {
    EN: {
      translation: en,
    },
    PL: {
      translation: pl,
    },
    CZ: {
      translation: cz,
    },
    CZ_OFFICE: {
      translation: czOffice,
    },
    EN_OFFICE: {
      translation: enOffice,
    }
  },
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
