import styled, { css } from "styled-components";
import { ClipboardNotes } from "@styled-icons/foundation/ClipboardNotes";
import { AddButton as AddButtonCore, Button } from "@components/button";

export interface ContactWrapperStyles {
  single?: boolean;
  isChildren?: boolean;
}

const notesIconSize = "30px";

export const NotesIcon = styled(ClipboardNotes)`
  height: ${notesIconSize};
  width: ${notesIconSize};

  pointer-events: none;
`;

export const NoteButton = styled(Button)`
  &.ant-btn {
    border: none;
    box-shadow: none;
  }

  display: inline-block;
  margin-left: 10px;
  height: 40px;
`;

export const AddButton = styled(AddButtonCore)`
  margin-top: 20px;
`;

export const StyledLabel = styled.label`
  display: block;

  &:not(:first-of-type) {
    margin-top: 20px;
  }
`;

const singleContactStyles = css`
  padding: 12px 16px 12px 40px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
`;

const childrenContactStyles = css`
  padding-left: 25px;
`;

export const ContactWrapper = styled.div<ContactWrapperStyles>`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${(props) => props.single && singleContactStyles}
  ${(props) => props.isChildren && childrenContactStyles}

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  & > div {
    flex: 1;
    line-height: 38px;
  }
`;

export const ContactGroupWrapper = styled.div`
  margin-top: 30px;
  padding-bottom: 10px;
`;
