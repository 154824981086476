import React, { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { FieldProps } from "formik";
import { throttle } from "throttle-debounce";

import * as S from "./editable-content-input.styled";

type Props = FieldProps & {
  multiline?: boolean;
  large?: boolean;
  onBlur?: (value: string) => void;
  edibable?: boolean;
};

const heightOffset = 10;

export const EditableContentInput: React.FC<Props> = React.memo(
  ({ onBlur, large, multiline, field, form, edibable = true, meta }) => {
    const [active, setActive] = useState(false);
    const [inputHeight, setInputHeight] = useState(0);

    const inputRef = createRef<HTMLInputElement>();
    const textareaRef = createRef<HTMLTextAreaElement>();
    const divRef = createRef<HTMLDivElement>();

    const { error } = meta;

    useEffect(() => {
      if (!divRef.current?.clientHeight) return;
      setInputHeight(divRef.current?.clientHeight);
    }, [divRef]);

    const blurHandler = useCallback(
      (event: React.FocusEvent) => {
        field.onBlur(event);
        setActive(false);
        onBlur?.(field.value);
      },
      [field, onBlur]
    );

    const focus = useCallback((input?: HTMLInputElement | HTMLTextAreaElement | null) => {
      if (!input) {
        return;
      }
      input.focus();
      // eslint-disable-next-line no-param-reassign
      input.selectionStart = input.value.length;
      // eslint-disable-next-line no-param-reassign
      input.selectionEnd = input.value.length;
    }, []);

    const onClick = useCallback(() => {
      if (!edibable) {
        return false;
      }
      setActive(true);
      focus(textareaRef.current);
      focus(inputRef.current);
      return undefined;
    }, [textareaRef, focus, inputRef, edibable]);

    const keyPressHandler = useCallback(
      (enter: boolean) => (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const intitialValue = form.initialValues[field.name];
        if (e.key === "Escape") {
          setActive(false);
          onBlur?.(intitialValue);
        }

        if (e.key === "Enter" && enter) {
          setActive(false);
          onBlur?.(field.value);
        }
      },
      [form.initialValues, field.name, field.value, onBlur]
    );

    const onChange = useMemo(
      () =>
        throttle(300, (e: React.ChangeEvent) => {
          field.onChange(e);
        }),
      [field]
    );

    const inputProps = useMemo(
      () => ({
        ...field,
        large,
        onChange,
        onBlur: blurHandler,
      }),
      [blurHandler, onChange, large, field]
    );

    return (
      <S.Container>
        <S.Inputs show={active}>
          <S.Input
            show={!multiline}
            ref={inputRef}
            error={!!error}
            {...inputProps}
            onKeyDown={keyPressHandler(true)}
          />
          <S.Textarea
            minHeight={inputHeight + heightOffset}
            show={multiline}
            ref={textareaRef}
            onKeyDown={keyPressHandler(false)}
            error={!!error}
            {...inputProps}
          />
        </S.Inputs>
        {!active && (
          <S.TextContent editable={edibable} large={large} onClick={onClick} ref={divRef} error={!!error}>
            {field.value || <S.Placeholder>Edit...</S.Placeholder>}
          </S.TextContent>
        )}
        {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
      </S.Container>
    );
  }
);

