/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoBase,
    GeoBaseFromJSON,
    GeoBaseFromJSONTyped,
    GeoBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface OffersBase
 */
export interface OffersBase {
    /**
     * 
     * @type {number}
     * @memberof OffersBase
     */
    offerId: number;
    /**
     * 
     * @type {string}
     * @memberof OffersBase
     */
    propertyName: string;
    /**
     * 
     * @type {string}
     * @memberof OffersBase
     */
    propertyStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OffersBase
     */
    offerName: string;
    /**
     * 
     * @type {string}
     * @memberof OffersBase
     */
    offerStatus: string;
    /**
     * 
     * @type {GeoBase}
     * @memberof OffersBase
     */
    propertyGeography?: GeoBase;
    /**
     * 
     * @type {string}
     * @memberof OffersBase
     */
    propertyAddress?: string;
    /**
     * 
     * @type {Date}
     * @memberof OffersBase
     */
    offerLastModificationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof OffersBase
     */
    offerBaseRent?: string;
    /**
     * 
     * @type {string}
     * @memberof OffersBase
     */
    offerServiceCharge?: string;
}

export function OffersBaseFromJSON(json: any): OffersBase {
    return OffersBaseFromJSONTyped(json, false);
}

export function OffersBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffersBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offerId': json['offerId'],
        'propertyName': json['propertyName'],
        'propertyStatus': json['propertyStatus'],
        'offerName': json['offerName'],
        'offerStatus': json['offerStatus'],
        'propertyGeography': !exists(json, 'propertyGeography') ? undefined : GeoBaseFromJSON(json['propertyGeography']),
        'propertyAddress': !exists(json, 'propertyAddress') ? undefined : json['propertyAddress'],
        'offerLastModificationDate': (new Date(json['offerLastModificationDate'])),
        'offerBaseRent': !exists(json, 'offerBaseRent') ? undefined : json['offerBaseRent'],
        'offerServiceCharge': !exists(json, 'offerServiceCharge') ? undefined : json['offerServiceCharge'],
    };
}

export function OffersBaseToJSON(value?: OffersBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offerId': value.offerId,
        'propertyName': value.propertyName,
        'propertyStatus': value.propertyStatus,
        'offerName': value.offerName,
        'offerStatus': value.offerStatus,
        'propertyGeography': GeoBaseToJSON(value.propertyGeography),
        'propertyAddress': value.propertyAddress,
        'offerLastModificationDate': (value.offerLastModificationDate.toISOString()),
        'offerBaseRent': value.offerBaseRent,
        'offerServiceCharge': value.offerServiceCharge,
    };
}


