/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomLettingAttributeOut,
    CustomLettingAttributeOutFromJSON,
    CustomLettingAttributeOutFromJSONTyped,
    CustomLettingAttributeOutToJSON,
} from './';

/**
 * 
 * @export
 * @interface LettingOut
 */
export interface LettingOut {
    /**
     * 
     * @type {number}
     * @memberof LettingOut
     */
    id: number;
    /**
     * 
     * @type {Array<CustomLettingAttributeOut>}
     * @memberof LettingOut
     */
    attributes: Array<CustomLettingAttributeOut>;
}

export function LettingOutFromJSON(json: any): LettingOut {
    return LettingOutFromJSONTyped(json, false);
}

export function LettingOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): LettingOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attributes': ((json['attributes'] as Array<any>).map(CustomLettingAttributeOutFromJSON)),
    };
}

export function LettingOutToJSON(value?: LettingOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attributes': ((value.attributes as Array<any>).map(CustomLettingAttributeOutToJSON)),
    };
}


