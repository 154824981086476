import { createReducer, ActionType } from "typesafe-actions";
import { PoiBase } from "@api/client";
import * as actions from "./actions";

const initialState = {
  loading: false,
  pois: [] as PoiBase[],
  error: undefined as Error | undefined,
  page: 1,
  canLoadMore: true,
};

type State = typeof initialState;
type Actions = ActionType<typeof actions>;

export const poiReducer = createReducer<State, Actions>(initialState)
  .handleAction(actions.setPois.request || actions.setMorePois.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(actions.setPois.success, (state, action) => ({
    ...state,
    pois: action.payload,
    loading: false,
  }))
  .handleAction(actions.setPois.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(actions.setPageNumber, (state, action) => ({ ...state, page: action.payload }))
  .handleAction(actions.noMorePages, (state) => ({ ...state, canLoadMore: false }))
  .handleAction(actions.thereAreMorePages, (state) => ({ ...state, canLoadMore: true }))
  .handleAction(actions.setMorePois.success, (state, action) => ({
    ...state,
    pois: [...state.pois, ...action.payload],
    loading: false,
  }));
