/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentMain
 */
export interface AttachmentMain {
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentMain
     */
    isMainFile: boolean;
}

export function AttachmentMainFromJSON(json: any): AttachmentMain {
    return AttachmentMainFromJSONTyped(json, false);
}

export function AttachmentMainFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentMain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isMainFile': json['isMainFile'],
    };
}

export function AttachmentMainToJSON(value?: AttachmentMain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isMainFile': value.isMainFile,
    };
}


