import styled, { css } from "styled-components";
import { GearFill } from "@styled-icons/bootstrap/GearFill";
import { TableRow as TableRowCore, TableCell as TableCellCore } from "@components/table";
import { Heading } from "../offer-detail.styled";

export interface IconEyeStyle {
  show: boolean;
}

export interface EditableContentWrapperProps {
  loading?: boolean;
}

export const HeadingGroup = styled(Heading)`
  margin: 20px 0 10px 0;
`;

export const TableRow = styled(TableRowCore)`
  display: block;
  padding: 10px;
`;

export const CellsWrapper = styled.div`
  display: flex;
  align-items: baseline;

  & > div:last-child {
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    cursor: default;

    & > div:last-child {
      opacity: 1;
    }
  }
`;

export const TableCell = styled(TableCellCore)`
  &:first-of-type {
    flex-grow: 15;
  }

  &:nth-of-type(2) {
    flex-grow: 5;
    text-align: right;
  }

  &:nth-of-type(n + 3) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const IconEyeWrapper = styled.div<IconEyeStyle>`
  opacity: ${({ show }) => (show ? "1" : "0.5")};
`;

export const EditableContentWrapper = styled.div<EditableContentWrapperProps>`
  display: flex;
  width: 50%;
  align-items: center;
  background-color: rgba(211, 211, 211, 0.5);
  border: 2px solid rgba(211, 211, 211, 0.7);
  border-radius: 3px;
  justify-content: ${({ loading }) => (loading ? "center" : "flex-start")};
`;

const editableContentSides = css`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  ${editableContentSides}
`;

export const InputWrapper = styled.div`
  flex: 8;
`;

export const DeleteButtonWrapper = styled.div`
  ${editableContentSides}
`;

const gearSize = "20px";

export const StyledGear = styled(GearFill)`
  height: ${gearSize};
  width: ${gearSize};
`;

export const Label = styled.label`
  &:not(:first-of-type) {
    margin-top: 20px;
  }
`;
