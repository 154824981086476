/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppSchemasLettingsFloorOut
 */
export interface AppSchemasLettingsFloorOut {
    /**
     * 
     * @type {number}
     * @memberof AppSchemasLettingsFloorOut
     */
    attributeId: number;
}

export function AppSchemasLettingsFloorOutFromJSON(json: any): AppSchemasLettingsFloorOut {
    return AppSchemasLettingsFloorOutFromJSONTyped(json, false);
}

export function AppSchemasLettingsFloorOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSchemasLettingsFloorOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeId': json['attributeId'],
    };
}

export function AppSchemasLettingsFloorOutToJSON(value?: AppSchemasLettingsFloorOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeId': value.attributeId,
    };
}


