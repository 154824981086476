import React from "react";

import * as S from "./main.styled";
import { Attributes } from "../attributes";
import { AddAttributeButton } from "../add-attributes";
import { POIList } from "../poi-list";
import { DescriptionsSection } from "../descriptions-section";
import { QuickForm } from "../quick-form/quick-form";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <DescriptionsSection />
      <Attributes />
      <AddAttributeButton />
      <POIList />
    </S.Main>
  );
};

