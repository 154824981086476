import React from "react";
import { DictBase } from "@api/client";
import * as S from "./button.styled";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  DictBase & {
    allValues?: string[];
  };

export const Button: React.FC<Props & S.StylingProps> = ({ code, name, onClick, children }) => {
  return (
    <S.StyledButton type="button" id={`${name}_${code}`} onClick={onClick}>
      {children || name}
    </S.StyledButton>
  );
};
