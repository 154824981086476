/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppSchemasLettingsFloorIn
 */
export interface AppSchemasLettingsFloorIn {
    /**
     * 
     * @type {string}
     * @memberof AppSchemasLettingsFloorIn
     */
    floors: string;
}

export function AppSchemasLettingsFloorInFromJSON(json: any): AppSchemasLettingsFloorIn {
    return AppSchemasLettingsFloorInFromJSONTyped(json, false);
}

export function AppSchemasLettingsFloorInFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSchemasLettingsFloorIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'floors': json['floors'],
    };
}

export function AppSchemasLettingsFloorInToJSON(value?: AppSchemasLettingsFloorIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'floors': value.floors,
    };
}


