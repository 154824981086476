import React, { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { Button } from "@components/button";
import { usePermissions } from "@hooks/use-permission";
import {
  AppSchemasOfferFloorIn,
  PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest,
} from "@api/client";
import { offerApi } from "@api/core";
import { updateAttributeAfterFloorUpdate } from "@state/offers/actions";

import { HandleAttributeValueUpdate } from "./types";
import * as S from "./attribute-value.styled";

export interface AttributeValueProps {
  attributeId: number;
  currentValue: string;
  parentAttributeId?: number;
  onUpdate: HandleAttributeValueUpdate;
  floorsOriginal: string;
  groupKey: string;
}

export const AttributeFloorValue: React.FC<AttributeValueProps> = ({
  attributeId,
  currentValue,
  parentAttributeId = null,
  onUpdate,
  floorsOriginal,
  groupKey,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const handleBlur = useCallback(
    (valueOriginal: string) => onUpdate(attributeId, { valueOriginal }, groupKey),
    [onUpdate, attributeId, groupKey]
  );

  const { mutate } = useMutation(
    () =>
      offerApi.postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPost({
        attributeId: parentAttributeId,
        appSchemasOfferFloorIn: { floors: floorsOriginal } as AppSchemasOfferFloorIn,
      } as PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("Success");
      },
      onError: () => {
        message.error("Operation failed");
      },
    }
  );

  const { hasPermission } = usePermissions();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const keyPressHandler = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  return parentAttributeId && parentAttributeId !== null && attributeId ? (
    hasPermission("PUT_OFFER_ATTRIBUTE_VALUE") ? (
      <S.Input
        name={attributeId ? attributeId.toString() : ""}
        value={value}
        type="text"
        onKeyDown={(e) => keyPressHandler(e)}
        onChange={(e) => onChange(e)}
        onBlur={() => {
          if (value) {
            handleBlur(value);
          }

          if (value === "") {
            handleBlur("");
          }
        }}
        placeholder="Edit..."
      />
    ) : (
      <span>{currentValue}</span>
    )
  ) : hasPermission("PUT_OFFER_ATTRIBUTE_VALUE") && parentAttributeId !== null ? (
    <Button onClick={() => mutate()}>Add</Button>
  ) : null;
};
