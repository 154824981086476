import React from "react";
import { ReactComponent as IconDeleteCircle } from "@svg/icon-delete-circle.svg";
import { ButtonProps } from "antd/lib/button";

import { Icon } from "@components/icon";

import * as S from "./delete-button.styled";

export const DeleteButton: React.FC<ButtonProps> = ({ onClick, disabled, value, loading }) => (
  <S.DeleteButton loading={loading} type="ghost" onClick={onClick} disabled={disabled} value={value}>
    <Icon icon={IconDeleteCircle} />
  </S.DeleteButton>
);
