import React, { ReactElement } from "react";
import { ReactComponent as IconEditNotActive } from "@svg/icon-edit-notactive.svg";
import { ReactComponent as IconCloseNotActive } from "@svg/icon-close-notactive.svg";
import { CustomFilter } from "@state/filters/types";
import { useDispatch, useSelector } from "react-redux";
import { setCustomFilter } from "@state/attribures/actions";
import * as filterAction from "@state/filters/actions";
import { CustomParser } from "@pages/shared-module/extra-filters/sliders/utils/custom-parser";
import * as S from "./filterbox.styled";

export const Filterbox = ({
  code,
  name,
  value: valueOfFilter,
  unitValue = "",
  floors,
  modules,
}: CustomFilter): ReactElement => {
  const dispatch = useDispatch();
  const customFilter = useSelector((state) =>
    state.filters[state.filters.currentModule].usedCustomFilters.find((x) => x.code === code)
  );

  const onEdit = () => {
    const filterFloors = CustomParser.parseFromStringToArray(floors);
    const filterModules = CustomParser.parseFromStringToArray(modules);

    dispatch(
      setCustomFilter({
        modalActive: true,
        key: code,
        value: customFilter?.value,
        valueUnit: customFilter?.unitValue,
        floors: CustomParser.parseFromStringToArray(floors),
        modules: CustomParser.parseFromStringToArray(modules),
        searchType: customFilter?.searchType,
        isValueFilterActivie: !!customFilter?.value,
        isFloorFilterActivie: !!filterFloors?.length,
        isModuleFilterActivie: !!filterModules?.length,
      })
    );
  };

  const onDelete = () => {
    dispatch(filterAction.removeCustomFilter(code));
  };

  return (
    <S.StyledFilterbox>
      <S.StyledHeading>
        {name.toLocaleUpperCase()}
        <div>
          <S.Edit onClick={onEdit}>
            <IconEditNotActive />
          </S.Edit>
          <S.Delete onClick={onDelete}>
            <IconCloseNotActive />
          </S.Delete>
        </div>
      </S.StyledHeading>
      <S.StyledCheckboxesContainer>
        <div>
          {" "}
          {`${Array.isArray(valueOfFilter) ? valueOfFilter.join(", ") : valueOfFilter} ${unitValue}`}{" "}
        </div>
      </S.StyledCheckboxesContainer>
      <S.FloorsAndModulesWrapper>
        {floors && (
          <S.Width50Percent>
            Floors:
            <span>{floors}</span>
          </S.Width50Percent>
        )}
        {modules && (
          <S.Width50Percent>
            Modules:
            <span>{modules}</span>
          </S.Width50Percent>
        )}
      </S.FloorsAndModulesWrapper>
    </S.StyledFilterbox>
  );
};
