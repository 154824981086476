import { OfferDetail } from "@pages/offer-detail";
import { Breadcrumb } from "@pages/offer-detail/breadcrumb";
import { auditTrailId, offerContactsId, offersId, offerTransactionsId } from "@router/paths";
import { clearDetails, triggerFetchDetails } from "@state/offers/actions";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteChildrenProps, useHistory, useLocation } from "react-router-dom";
import { TabPane, Tabs } from "@components/tabs";
import { ContactsContainer } from "@pages/contacts";
import { Transactions } from "@pages/transactions";
import { AuditTrail } from "@pages/audit-trail/audit-trail";
import * as S from "./entry-detail-transaction-contact.styled";
import "./entry-detail-transaction-contact.less";

const routerConfig = {
  offerDetails: {
    label: "Property details",
    component: OfferDetail,
    path: "",
    pathFun: offersId,
  },
  transactions: {
    label: "Tenants",
    component: Transactions,
    path: "transactions",
    pathFun: offerTransactionsId,
  },
  contacts: {
    label: "Contacts",
    component: ContactsContainer,
    path: "contacts",
    pathFun: offerContactsId,
  },
  auditTrail: {
    label: "Audit trail",
    component: AuditTrail,
    path: "audit-trail",
    pathFun: auditTrailId,
  },
};

export const EntryDetailTransactionContact: React.FC<RouteChildrenProps<{ id: string }>> = ({ match }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.offers.offerDetails);

  useEffect(() => {
    if (match?.params.id)
      dispatch(
        triggerFetchDetails({
          id: match?.params.id,
        })
      );
    return () => {
      dispatch(clearDetails());
    };
  }, [match, dispatch]);

  const initialValues = data || {};

  const onChange = (path: string) => {
    history.push(path);
  };

  const onSubmit = () => {
    // send values to the backend
  };

  return (
    <S.Layout>
      <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues} validateOnChange={false}>
        <div>
          <Breadcrumb />
          <Tabs
            className="TabPanelOfferDetailClass"
            defaultActiveKey={pathname}
            onChange={onChange}
            type="card"
          >
            {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
            {Object.entries(routerConfig).map(([_, { label, component: Component, pathFun }]) => (
              <TabPane tab={<p>{label}</p>} key={pathFun(match?.params.id)} destroyInactiveTabPane>
                {/* destroyInactiveTabPanel not works. Thats why I introduce below condition */}
                {pathname === pathFun(match?.params.id) && <Component />}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Formik>
    </S.Layout>
  );
};
