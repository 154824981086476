import { Dispatch } from "redux";
import * as attributeActions from "@state/attribures/actions";
import { attributeApi } from "@api/core";

export const getUnits = async (dispatch: Dispatch<any>, attributeCode: string) => {
  if (attributeCode === "") return;
  try {
    if (attributeCode) {
      dispatch(attributeActions.fetchDistinctUnits.request());
      const units =
        await attributeApi.getDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGet.bind(
          attributeApi
        )({
          attributeCode,
        });
      dispatch(
        attributeActions.fetchDistinctUnits.success({
          ...units,
        })
      );
    }
  } catch (e) {
    dispatch(attributeActions.fetchDistribution.failure(e));
  }
};
