import styled from "styled-components";

export interface HeadingWithIconStyleProps {
  iconLeft?: boolean;
}

export const Wrapper = styled.div<HeadingWithIconStyleProps>`
  display: flex;
  flex-direction: ${({ iconLeft }) => (iconLeft ? "row" : "row-reverse")};
`;

export const IconWrapper = styled.div<HeadingWithIconStyleProps>`
  margin-${({ iconLeft }) => (iconLeft ? "right" : "right")}: 10px;
`;
