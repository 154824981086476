export const retailAttributes = [
  {
    code: "",
    cellWidth: 50,
    key: "emptyFirst",
  },
  {
    code: "Unit",
    cellWidth: 100,
    key: "unit",
  },
  {
    code: "Brand",
    cellWidth: 580,
    key: "brand",
  },
  {
    code: "Category 1",
    cellWidth: 580,
    key: "category1",
  },
  {
    code: "Category 2",
    cellWidth: 170,
    key: "category2",
  },
  {
    code: "GLA",
    cellWidth: 100,
    key: "gla",
  },
  {
    code: "Sales Area",
    cellWidth: 100,
    key: "sales",
  },
  {
    code: "Rent",
    cellWidth: 100,
    key: "rent",
  },
  {
    code: "Date",
    cellWidth: 180,
    key: "date",
  },
  {
    code: "Level",
    cellWidth: 100,
    key: "level",
  },
  {
    code: "Notes",
    cellWidth: 170,
    key: "notes",
  },
];
