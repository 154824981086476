import React from "react";
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, useFormikContext } from "formik";
import { RadioboxIcon } from "@components/form/radiobox-icon";
import { ButtonCheckbox } from "@components/form/button-checkbox";
import { Input } from "@components/form/input";
import { Icon } from "@components/icon";

import { DictBase, DictPropertyCategory } from "@api/client";
import { ReactComponent as SearchIcon } from "@svg/icon-search.svg";
import { Flex } from "@components/flex/flex";
import { CustomFilter } from "@state/filters/types";
import * as S from "./filters.styled";

import { SelectGeography } from "../select-geography";

export type FormValues = {
  propertyCategoryCode: any;
  propertyStatusCode: any;
  offerStatusCode: any;
  customAttributeFilter: CustomFilter[];
};

type Props = {
  propertyCategoryCode: DictPropertyCategory[];
  propertyStatusCode: DictBase[];
  offerStatusCode: DictBase[];
};

export const Form: React.FC<Props> = ({ offerStatusCode, propertyCategoryCode, propertyStatusCode }) => {
  const { values } = useFormikContext<FormValues>();

  return (
    <>
      {/* propertyCategoryCode */}
      <S.FieldGroup>
        <Field name="propertyCategoryCode">
          {(fieldProps: FieldProps) => <RadioboxIcon options={propertyCategoryCode} {...fieldProps} />}
        </Field>
      </S.FieldGroup>

      <S.FieldGroup>
        <Field name="propertyGeographyIds">
          {(fieldProps: FieldProps) => (
            <SelectGeography
              onChange={(v: number[] | number) => {
                if (typeof v === "number") {
                  return;
                }
                fieldProps.form.setFieldValue(fieldProps.field.name, v);
              }}
              value={fieldProps.field.value}
            />
          )}
        </Field>
      </S.FieldGroup>
      <S.FieldGroup>
        <Field name="propertyNameOrAddress">
          {(fieldProps: FieldProps) => (
            <Input suffix={<Icon icon={SearchIcon} />} placeholder="Search" {...fieldProps} />
          )}
        </Field>
      </S.FieldGroup>
      {/* offerStatusCode */}
      <S.FieldGroup>
        <Flex>
          <FieldArray name="offerStatusCode">
            {(arrayHelpers: FieldArrayRenderProps) =>
              offerStatusCode.map((option) => (
                <ButtonCheckbox
                  key={option.code}
                  allValues={values.offerStatusCode}
                  arrayHelpers={arrayHelpers}
                  grow
                  {...option}
                />
              ))
            }
          </FieldArray>
        </Flex>
      </S.FieldGroup>
      {/* propertyStatusCode */}
      <S.FieldGroup>
        <Flex>
          <FieldArray name="propertyStatusCode">
            {(arrayHelpers: FieldArrayRenderProps) =>
              propertyStatusCode.map((option) => (
                <ButtonCheckbox
                  key={option.code}
                  allValues={values.propertyStatusCode}
                  arrayHelpers={arrayHelpers}
                  grow
                  {...option}
                />
              ))
            }
          </FieldArray>
        </Flex>
      </S.FieldGroup>
    </>
  );
};
