import styled from "styled-components";
import backgroundImage from "../../img/background.png";

export const Background = styled.div`
  min-width: 1000px;
  background-image: url(${backgroundImage});
  background-size: cover;
  padding: 0 40px 40px;
  min-height: 100vh;
`;

export const Wrapper = styled.main`
  max-width: 1870px;
  height: auto;
  margin: auto;
`;
