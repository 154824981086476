export class CustomParser {
  public static parseFromStringToArray = (rangeAsString?: string) => {
    if (!rangeAsString) {
      return undefined;
    }
    const splitValues = rangeAsString.split("~");
    if (!(splitValues?.length === 2)) {
      return undefined;
    }
    const minValue = Number(splitValues[0]);
    const maxValue = Number(splitValues[1]);
    if (!minValue || !maxValue) {
      return undefined;
    }
    return [minValue, maxValue];
  };

  public static stringifyFormArrayToString = (range?: number[]) => {
    if (range?.length !== 2) {
      return undefined;
    }
    return `${range[0]}~${range[1]}`;
  };
}
