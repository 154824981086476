import styled, { css, DefaultTheme, ThemeProps } from "styled-components";

type TextProps = { error?: boolean };

const textStyle = (props: ThemeProps<DefaultTheme> & TextProps) => css`
  border-radius: 5px;
  padding: 3px 7px;
  width: 100%;
  transition: 0.2s ease-in-out;
  border: 1px solid transparent;
  white-space: pre-line;
  cursor: pointer;
  background: none;
  text-align: center;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.grey};
  }

  &:focus {
    padding: 3px 7px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    outline-color: ${({ theme }) => theme.primaryColor};
    background: none;
    cursor: text;
  }

  ${props.error &&
  css`
    border-color: ${({ theme }) => theme.red};
  `}
`;

export const Input = styled.input<TextProps>`
  ${textStyle}
`;

type ButtonProps = {
  isSelected: boolean;
};

export const AttributeButton = styled.button.attrs((props: { isSelected: boolean }) => props)`
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  :hover {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
  }
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${({ theme }) => theme.primaryColor};
      color: white;
    `}

  :focus {
    outline: none;
  }
  :last-of-type {
    margin-right: none;
    margin-bottom: none;
  }
`;

export const AttributeButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
