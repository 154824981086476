import styled, { css, DefaultTheme, ThemeProps } from "styled-components";

type TextProps = { show?: boolean; large?: boolean; error?: boolean };

export const TextContent = styled.div<{ large?: boolean; editable?: boolean; error?: boolean }>`
  border-radius: 5px;
  padding: 3px 7px;
  width: 100%;
  transition: 0.2s ease-in-out;
  border: 1px solid transparent;
  white-space: pre-line;

  ${(props) =>
    props.editable &&
    css`
      cursor: pointer;
      &:hover {
        background: ${({ theme }) => theme.grey};
      }
    `};

  ${(props) =>
    props.large &&
    css`
      font-size: 16px;
      font-weight: 500;
    `};

  ${(props) =>
    props.error &&
    css`
      outline: 2px solid ${({ theme }) => theme.red};
    `};

  input,
  textarea {
    width: 100%;
  }
`;

export const Inputs = styled.div<{ show: boolean }>`
  display: block;
  width: 100%;
  ${(props) =>
    !props.show &&
    css`
      overflow: hidden;
      width: 0;
      opacity: 0;
      left: 0;
      position: absolute;
      pointer-events: none;
    `}
`;

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const textStyle = (props: ThemeProps<DefaultTheme> & TextProps) => css`
  padding: 3px 7px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  outline-color: ${({ theme }) => theme.primaryColor};

  ${props.large &&
  css`
    font-size: 16px;
    font-weight: 500;
  `}

  ${!props.show &&
  css`
    display: none;
  `}

  ${props.error &&
  css`
    border-color: ${({ theme }) => theme.red};
  `}
`;

export const Textarea = styled.textarea<TextProps & { minHeight?: number }>`
  ${textStyle};
  min-height: ${(props) => props.minHeight && `${props.minHeight}px`};
`;

export const Input = styled.input<TextProps>`
  ${textStyle}
`;

export const Placeholder = styled.span`
  color: ${(props) => props.theme.grey};
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.red};
  padding: 2px 0;
`;
