/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PoiProperty,
    PoiPropertyFromJSON,
    PoiPropertyToJSON,
    Property,
    PropertyFromJSON,
    PropertyToJSON,
    PropertyAddress,
    PropertyAddressFromJSON,
    PropertyAddressToJSON,
    PropertyBase,
    PropertyBaseFromJSON,
    PropertyBaseToJSON,
    PropertyCoordinates,
    PropertyCoordinatesFromJSON,
    PropertyCoordinatesToJSON,
    PropertyGeography,
    PropertyGeographyFromJSON,
    PropertyGeographyToJSON,
    PropertyName,
    PropertyNameFromJSON,
    PropertyNameToJSON,
    PropertyOut,
    PropertyOutFromJSON,
    PropertyOutToJSON,
    PropertyOutAddressAndCoordinates,
    PropertyOutAddressAndCoordinatesFromJSON,
    PropertyOutAddressAndCoordinatesToJSON,
    PropertyOutGeography,
    PropertyOutGeographyFromJSON,
    PropertyOutGeographyToJSON,
    PropertyOutName,
    PropertyOutNameFromJSON,
    PropertyOutNameToJSON,
    PropertyOutPhoto,
    PropertyOutPhotoFromJSON,
    PropertyOutPhotoToJSON,
    PropertyOutPoi,
    PropertyOutPoiFromJSON,
    PropertyOutPoiToJSON,
    PropertyOutStatus,
    PropertyOutStatusFromJSON,
    PropertyOutStatusToJSON,
    PropertyPoi,
    PropertyPoiFromJSON,
    PropertyPoiToJSON,
    PropertyStatus,
    PropertyStatusFromJSON,
    PropertyStatusToJSON,
    PropertyToPoiDistance,
    PropertyToPoiDistanceFromJSON,
    PropertyToPoiDistanceToJSON,
    ReddIdIn,
    ReddIdInFromJSON,
    ReddIdInToJSON,
    ReddIdOut,
    ReddIdOutFromJSON,
    ReddIdOutToJSON,
    ReddState,
    ReddStateFromJSON,
    ReddStateToJSON,
} from '../models';

export interface DeletePropertyPhotoApiV1PropertiesPropertyIdPhotoDeleteRequest {
    propertyId: number;
}

export interface DeletePropertyPhotoApiV1PropertiesPropertyIdPoisDeleteRequest {
    propertyId: number;
}

export interface DeletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDeleteRequest {
    propertyId: number;
    poiId: number;
}

export interface GetPropertiesApiV1PropertiesGetRequest {
    propertyCategoryCode: string;
    pageNumber?: number;
    pageSize?: number;
    propertyNameOrAddress?: string;
}

export interface GetPropertyPhotoApiV1PropertiesPropertyIdPhotoGetRequest {
    propertyId: number;
}

export interface GetPropertyPoisApiV1PropertiesPropertyIdPoisGetRequest {
    propertyId: number;
    categoryId?: Array<any>;
}

export interface GetSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGetRequest {
    propertyId: number;
}

export interface PostPropertyApiV1PropertiesPostRequest {
    property: Property;
}

export interface PutDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPutRequest {
    propertyId: number;
    poiId: number;
}

export interface PutPropertyAddressApiV1PropertiesPropertyIdAddressPutRequest {
    propertyId: number;
    propertyAddress: PropertyAddress;
}

export interface PutPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPutRequest {
    propertyId: number;
    propertyCoordinates: PropertyCoordinates;
}

export interface PutPropertyGeographyApiV1PropertiesPropertyIdGeographyPutRequest {
    propertyId: number;
    propertyGeography: PropertyGeography;
}

export interface PutPropertyNameApiV1PropertiesPropertyIdNamePutRequest {
    propertyId: number;
    propertyName: PropertyName;
}

export interface PutPropertyPhotoApiV1PropertiesPropertyIdPhotoPutRequest {
    propertyId: number;
    image: Blob;
}

export interface PutPropertyPoiApiV1PropertiesPropertyIdPoisPutRequest {
    propertyId: number;
    propertyPoi: PropertyPoi;
}

export interface PutPropertyStatusApiV1PropertiesPropertyIdStatusPutRequest {
    propertyId: number;
    propertyStatus: PropertyStatus;
}

export interface PutReddIdApiV1PropertiesPropertyIdReddPutRequest {
    propertyId: number;
    reddIdIn: ReddIdIn;
}

export interface PutReddStateApiV1PropertiesPropertyIdReddstatePutRequest {
    propertyId: number;
    reddState: ReddState;
}

/**
 * 
 */
export class PropertyApi extends runtime.BaseAPI {

    /**
     * Delete Property Photo
     */
    async deletePropertyPhotoApiV1PropertiesPropertyIdPhotoDeleteRaw(requestParameters: DeletePropertyPhotoApiV1PropertiesPropertyIdPhotoDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling deletePropertyPhotoApiV1PropertiesPropertyIdPhotoDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/photo`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Property Photo
     */
    async deletePropertyPhotoApiV1PropertiesPropertyIdPhotoDelete(requestParameters: DeletePropertyPhotoApiV1PropertiesPropertyIdPhotoDeleteRequest): Promise<void> {
        await this.deletePropertyPhotoApiV1PropertiesPropertyIdPhotoDeleteRaw(requestParameters);
    }

    /**
     * Delete Property Photo
     */
    async deletePropertyPhotoApiV1PropertiesPropertyIdPoisDeleteRaw(requestParameters: DeletePropertyPhotoApiV1PropertiesPropertyIdPoisDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling deletePropertyPhotoApiV1PropertiesPropertyIdPoisDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/pois`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Property Photo
     */
    async deletePropertyPhotoApiV1PropertiesPropertyIdPoisDelete(requestParameters: DeletePropertyPhotoApiV1PropertiesPropertyIdPoisDeleteRequest): Promise<void> {
        await this.deletePropertyPhotoApiV1PropertiesPropertyIdPoisDeleteRaw(requestParameters);
    }

    /**
     * Unassign POI From Property
     */
    async deletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDeleteRaw(requestParameters: DeletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDeleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling deletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDelete.');
        }

        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling deletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/pois/{poiId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Unassign POI From Property
     */
    async deletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDelete(requestParameters: DeletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDeleteRequest): Promise<any> {
        const response = await this.deletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get List of Properties
     */
    async getPropertiesApiV1PropertiesGetRaw(requestParameters: GetPropertiesApiV1PropertiesGetRequest): Promise<runtime.ApiResponse<Array<PropertyBase>>> {
        if (requestParameters.propertyCategoryCode === null || requestParameters.propertyCategoryCode === undefined) {
            throw new runtime.RequiredError('propertyCategoryCode','Required parameter requestParameters.propertyCategoryCode was null or undefined when calling getPropertiesApiV1PropertiesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.propertyCategoryCode !== undefined) {
            queryParameters['propertyCategoryCode'] = requestParameters.propertyCategoryCode;
        }

        if (requestParameters.propertyNameOrAddress !== undefined) {
            queryParameters['propertyNameOrAddress'] = requestParameters.propertyNameOrAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PropertyBaseFromJSON));
    }

    /**
     * Get List of Properties
     */
    async getPropertiesApiV1PropertiesGet(requestParameters: GetPropertiesApiV1PropertiesGetRequest): Promise<Array<PropertyBase>> {
        const response = await this.getPropertiesApiV1PropertiesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Property Photo
     */
    async getPropertyPhotoApiV1PropertiesPropertyIdPhotoGetRaw(requestParameters: GetPropertyPhotoApiV1PropertiesPropertyIdPhotoGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getPropertyPhotoApiV1PropertiesPropertyIdPhotoGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/photo`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Property Photo
     */
    async getPropertyPhotoApiV1PropertiesPropertyIdPhotoGet(requestParameters: GetPropertyPhotoApiV1PropertiesPropertyIdPhotoGetRequest): Promise<void> {
        await this.getPropertyPhotoApiV1PropertiesPropertyIdPhotoGetRaw(requestParameters);
    }

    /**
     * Get assigned POIs
     */
    async getPropertyPoisApiV1PropertiesPropertyIdPoisGetRaw(requestParameters: GetPropertyPoisApiV1PropertiesPropertyIdPoisGetRequest): Promise<runtime.ApiResponse<Array<PoiProperty>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getPropertyPoisApiV1PropertiesPropertyIdPoisGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.categoryId) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/pois`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PoiPropertyFromJSON));
    }

    /**
     * Get assigned POIs
     */
    async getPropertyPoisApiV1PropertiesPropertyIdPoisGet(requestParameters: GetPropertyPoisApiV1PropertiesPropertyIdPoisGetRequest): Promise<Array<PoiProperty>> {
        const response = await this.getPropertyPoisApiV1PropertiesPropertyIdPoisGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Property Xml
     */
    async getPropertyXmlApiV1PropertiesXmlGetRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/xml`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get Property Xml
     */
    async getPropertyXmlApiV1PropertiesXmlGet(): Promise<any> {
        const response = await this.getPropertyXmlApiV1PropertiesXmlGetRaw();
        return await response.value();
    }

    /**
     * Get Property Xml Industrial
     */
    async getPropertyXmlIndustrialApiV1PropertiesXmlIndustrialGetRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/xmlIndustrial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get Property Xml Industrial
     */
    async getPropertyXmlIndustrialApiV1PropertiesXmlIndustrialGet(): Promise<any> {
        const response = await this.getPropertyXmlIndustrialApiV1PropertiesXmlIndustrialGetRaw();
        return await response.value();
    }

    /**
     * Get suggested POIs
     */
    async getSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGetRaw(requestParameters: GetSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGetRequest): Promise<runtime.ApiResponse<Array<PoiProperty>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/suggestedPois/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PoiPropertyFromJSON));
    }

    /**
     * Get suggested POIs
     */
    async getSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGet(requestParameters: GetSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGetRequest): Promise<Array<PoiProperty>> {
        const response = await this.getSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add New Property
     */
    async postPropertyApiV1PropertiesPostRaw(requestParameters: PostPropertyApiV1PropertiesPostRequest): Promise<runtime.ApiResponse<PropertyOut>> {
        if (requestParameters.property === null || requestParameters.property === undefined) {
            throw new runtime.RequiredError('property','Required parameter requestParameters.property was null or undefined when calling postPropertyApiV1PropertiesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyToJSON(requestParameters.property),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutFromJSON(jsonValue));
    }

    /**
     * Add New Property
     */
    async postPropertyApiV1PropertiesPost(requestParameters: PostPropertyApiV1PropertiesPostRequest): Promise<PropertyOut> {
        const response = await this.postPropertyApiV1PropertiesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Calculate and update travel times
     */
    async putDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPutRaw(requestParameters: PutDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPutRequest): Promise<runtime.ApiResponse<PropertyToPoiDistance>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPut.');
        }

        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling putDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/distanceToPoi/{poiId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyToPoiDistanceFromJSON(jsonValue));
    }

    /**
     * Calculate and update travel times
     */
    async putDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPut(requestParameters: PutDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPutRequest): Promise<PropertyToPoiDistance> {
        const response = await this.putDistanceToPoiApiV1PropertiesPropertyIdDistanceToPoiPoiIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Property Address
     */
    async putPropertyAddressApiV1PropertiesPropertyIdAddressPutRaw(requestParameters: PutPropertyAddressApiV1PropertiesPropertyIdAddressPutRequest): Promise<runtime.ApiResponse<PropertyOutAddressAndCoordinates>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putPropertyAddressApiV1PropertiesPropertyIdAddressPut.');
        }

        if (requestParameters.propertyAddress === null || requestParameters.propertyAddress === undefined) {
            throw new runtime.RequiredError('propertyAddress','Required parameter requestParameters.propertyAddress was null or undefined when calling putPropertyAddressApiV1PropertiesPropertyIdAddressPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/address`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyAddressToJSON(requestParameters.propertyAddress),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutAddressAndCoordinatesFromJSON(jsonValue));
    }

    /**
     * Put Property Address
     */
    async putPropertyAddressApiV1PropertiesPropertyIdAddressPut(requestParameters: PutPropertyAddressApiV1PropertiesPropertyIdAddressPutRequest): Promise<PropertyOutAddressAndCoordinates> {
        const response = await this.putPropertyAddressApiV1PropertiesPropertyIdAddressPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Property Coordinates
     */
    async putPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPutRaw(requestParameters: PutPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPutRequest): Promise<runtime.ApiResponse<PropertyOutAddressAndCoordinates>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPut.');
        }

        if (requestParameters.propertyCoordinates === null || requestParameters.propertyCoordinates === undefined) {
            throw new runtime.RequiredError('propertyCoordinates','Required parameter requestParameters.propertyCoordinates was null or undefined when calling putPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/coordinates`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyCoordinatesToJSON(requestParameters.propertyCoordinates),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutAddressAndCoordinatesFromJSON(jsonValue));
    }

    /**
     * Put Property Coordinates
     */
    async putPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPut(requestParameters: PutPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPutRequest): Promise<PropertyOutAddressAndCoordinates> {
        const response = await this.putPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Property Geography
     */
    async putPropertyGeographyApiV1PropertiesPropertyIdGeographyPutRaw(requestParameters: PutPropertyGeographyApiV1PropertiesPropertyIdGeographyPutRequest): Promise<runtime.ApiResponse<PropertyOutGeography>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putPropertyGeographyApiV1PropertiesPropertyIdGeographyPut.');
        }

        if (requestParameters.propertyGeography === null || requestParameters.propertyGeography === undefined) {
            throw new runtime.RequiredError('propertyGeography','Required parameter requestParameters.propertyGeography was null or undefined when calling putPropertyGeographyApiV1PropertiesPropertyIdGeographyPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/geography`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyGeographyToJSON(requestParameters.propertyGeography),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutGeographyFromJSON(jsonValue));
    }

    /**
     * Put Property Geography
     */
    async putPropertyGeographyApiV1PropertiesPropertyIdGeographyPut(requestParameters: PutPropertyGeographyApiV1PropertiesPropertyIdGeographyPutRequest): Promise<PropertyOutGeography> {
        const response = await this.putPropertyGeographyApiV1PropertiesPropertyIdGeographyPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Property Name
     */
    async putPropertyNameApiV1PropertiesPropertyIdNamePutRaw(requestParameters: PutPropertyNameApiV1PropertiesPropertyIdNamePutRequest): Promise<runtime.ApiResponse<PropertyOutName>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putPropertyNameApiV1PropertiesPropertyIdNamePut.');
        }

        if (requestParameters.propertyName === null || requestParameters.propertyName === undefined) {
            throw new runtime.RequiredError('propertyName','Required parameter requestParameters.propertyName was null or undefined when calling putPropertyNameApiV1PropertiesPropertyIdNamePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/name`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyNameToJSON(requestParameters.propertyName),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutNameFromJSON(jsonValue));
    }

    /**
     * Put Property Name
     */
    async putPropertyNameApiV1PropertiesPropertyIdNamePut(requestParameters: PutPropertyNameApiV1PropertiesPropertyIdNamePutRequest): Promise<PropertyOutName> {
        const response = await this.putPropertyNameApiV1PropertiesPropertyIdNamePutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Property Photo
     */
    async putPropertyPhotoApiV1PropertiesPropertyIdPhotoPutRaw(requestParameters: PutPropertyPhotoApiV1PropertiesPropertyIdPhotoPutRequest): Promise<runtime.ApiResponse<PropertyOutPhoto>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putPropertyPhotoApiV1PropertiesPropertyIdPhotoPut.');
        }

        if (requestParameters.image === null || requestParameters.image === undefined) {
            throw new runtime.RequiredError('image','Required parameter requestParameters.image was null or undefined when calling putPropertyPhotoApiV1PropertiesPropertyIdPhotoPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/photo`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutPhotoFromJSON(jsonValue));
    }

    /**
     * Put Property Photo
     */
    async putPropertyPhotoApiV1PropertiesPropertyIdPhotoPut(requestParameters: PutPropertyPhotoApiV1PropertiesPropertyIdPhotoPutRequest): Promise<PropertyOutPhoto> {
        const response = await this.putPropertyPhotoApiV1PropertiesPropertyIdPhotoPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Assign POI To Property
     */
    async putPropertyPoiApiV1PropertiesPropertyIdPoisPutRaw(requestParameters: PutPropertyPoiApiV1PropertiesPropertyIdPoisPutRequest): Promise<runtime.ApiResponse<PropertyOutPoi>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putPropertyPoiApiV1PropertiesPropertyIdPoisPut.');
        }

        if (requestParameters.propertyPoi === null || requestParameters.propertyPoi === undefined) {
            throw new runtime.RequiredError('propertyPoi','Required parameter requestParameters.propertyPoi was null or undefined when calling putPropertyPoiApiV1PropertiesPropertyIdPoisPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/pois/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyPoiToJSON(requestParameters.propertyPoi),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutPoiFromJSON(jsonValue));
    }

    /**
     * Assign POI To Property
     */
    async putPropertyPoiApiV1PropertiesPropertyIdPoisPut(requestParameters: PutPropertyPoiApiV1PropertiesPropertyIdPoisPutRequest): Promise<PropertyOutPoi> {
        const response = await this.putPropertyPoiApiV1PropertiesPropertyIdPoisPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Property Status
     */
    async putPropertyStatusApiV1PropertiesPropertyIdStatusPutRaw(requestParameters: PutPropertyStatusApiV1PropertiesPropertyIdStatusPutRequest): Promise<runtime.ApiResponse<PropertyOutStatus>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putPropertyStatusApiV1PropertiesPropertyIdStatusPut.');
        }

        if (requestParameters.propertyStatus === null || requestParameters.propertyStatus === undefined) {
            throw new runtime.RequiredError('propertyStatus','Required parameter requestParameters.propertyStatus was null or undefined when calling putPropertyStatusApiV1PropertiesPropertyIdStatusPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/status`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyStatusToJSON(requestParameters.propertyStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyOutStatusFromJSON(jsonValue));
    }

    /**
     * Put Property Status
     */
    async putPropertyStatusApiV1PropertiesPropertyIdStatusPut(requestParameters: PutPropertyStatusApiV1PropertiesPropertyIdStatusPutRequest): Promise<PropertyOutStatus> {
        const response = await this.putPropertyStatusApiV1PropertiesPropertyIdStatusPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put property reddId value
     */
    async putReddIdApiV1PropertiesPropertyIdReddPutRaw(requestParameters: PutReddIdApiV1PropertiesPropertyIdReddPutRequest): Promise<runtime.ApiResponse<ReddIdOut>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putReddIdApiV1PropertiesPropertyIdReddPut.');
        }

        if (requestParameters.reddIdIn === null || requestParameters.reddIdIn === undefined) {
            throw new runtime.RequiredError('reddIdIn','Required parameter requestParameters.reddIdIn was null or undefined when calling putReddIdApiV1PropertiesPropertyIdReddPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/redd`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReddIdInToJSON(requestParameters.reddIdIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReddIdOutFromJSON(jsonValue));
    }

    /**
     * Put property reddId value
     */
    async putReddIdApiV1PropertiesPropertyIdReddPut(requestParameters: PutReddIdApiV1PropertiesPropertyIdReddPutRequest): Promise<ReddIdOut> {
        const response = await this.putReddIdApiV1PropertiesPropertyIdReddPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put property REDD state
     */
    async putReddStateApiV1PropertiesPropertyIdReddstatePutRaw(requestParameters: PutReddStateApiV1PropertiesPropertyIdReddstatePutRequest): Promise<runtime.ApiResponse<ReddState>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putReddStateApiV1PropertiesPropertyIdReddstatePut.');
        }

        if (requestParameters.reddState === null || requestParameters.reddState === undefined) {
            throw new runtime.RequiredError('reddState','Required parameter requestParameters.reddState was null or undefined when calling putReddStateApiV1PropertiesPropertyIdReddstatePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/properties/{propertyId}/reddstate`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReddStateToJSON(requestParameters.reddState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReddStateFromJSON(jsonValue));
    }

    /**
     * Put property REDD state
     */
    async putReddStateApiV1PropertiesPropertyIdReddstatePut(requestParameters: PutReddStateApiV1PropertiesPropertyIdReddstatePutRequest): Promise<ReddState> {
        const response = await this.putReddStateApiV1PropertiesPropertyIdReddstatePutRaw(requestParameters);
        return await response.value();
    }

}
