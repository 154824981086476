import {
  fetchPropertyCategoriesAction,
  fetchOfferStatuesAction,
  fetchPoiCategoriesAction,
  fetchPropertyStatuesAction,
} from "@state/dics/actions";
import { dictApi } from "@api/core";
import { createApiAction } from "@utils/createApiAction";

export const loadPropertyCategories = createApiAction(
  fetchPropertyCategoriesAction,
  dictApi.getPropertyCategoriesApiV1DictsPropertyCategoriesGet.bind(dictApi)
);

export const loadPoiCategories = createApiAction(
  fetchPoiCategoriesAction,
  dictApi.getPoiCategoriesApiV1DictsPoiCategoriesGet.bind(dictApi)
);

export const loadOfferStatues = createApiAction(
  fetchOfferStatuesAction,
  dictApi.getOfferStatusesApiV1DictsOfferStatusesGet.bind(dictApi)
);

export const loadPropertyStatues = createApiAction(
  fetchPropertyStatuesAction,
  dictApi.getPropertyStatusesApiV1DictsPropertyStatusesGet.bind(dictApi)
);
