import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { Field, FieldProps } from "formik";
import { Select } from "@components/form/select";
import { updateStatus } from "@utils/offer";
import { usePermissions } from "@hooks/use-permission";

import * as S from "./last-update.styled";

export const LastUpdate: React.FC = () => {
  const { hasPermission } = usePermissions();
  const data = useSelector((state) => state.offers.offerDetails.data);
  const statuses = useSelector((state) => state.dicts.offer.data);
  const dispatch = useDispatch();

  const onStatusChange = (value: string) => {
    if (!data) {
      return undefined;
    }
    return updateStatus(dispatch)({
      offerId: data.offerId,
      offerInStatus: {
        statusCode: value,
      },
    });
  };

  const statusOptions = statuses.map((item) => ({
    value: item.code,
    label: item.name,
  }));

  return (
    <S.Wrapper>
      <S.SelectWrapper>
        <Field name="offerStatus">
          {(fieldProps: FieldProps) => (
            <Select
              {...fieldProps}
              field={{
                ...fieldProps.field,
                onChange: (value: any) => {
                  onStatusChange(value);
                },
              }}
              options={statusOptions}
              style={{ width: 200 }}
              size="large"
              disabled={!hasPermission("PUT_OFFER_STATUS")}
            />
          )}
        </Field>
      </S.SelectWrapper>
      <div>
        <S.Heading level="4"># {data?.offerId}</S.Heading>
        <S.Heading level="5">
          Updated:{" "}
          {DateTime.fromJSDate(data?.offerLastModificationDate as Date).toFormat("yyyy-MM-dd HH:mm:ss")}
        </S.Heading>
      </div>
    </S.Wrapper>
  );
};
