import React, { memo, useState } from "react";
import { Popconfirm } from "antd";

import { PutOfferAttributesOut, CustomAttributeOut } from "@api/client";

import { Button } from "@components/button";
import { DeleteButton } from "@components/delete-button/delete-button";
import { usePermissions } from "@hooks/use-permission";
import { useToggle } from "@hooks/use-toggle";

import { NoteButton } from "./note-button";
import { IconEye } from "./icon-eye";
import { InfoIcon } from "./info-icon";
import { AttributeValue } from "./attribute-value";
import { HandleAttributeUpdate, HandleAttributeDelete, HandleNoteAttributeUpdate } from "./types";
import { EditNote } from "./edit-note";
import { AttributeFloorManageModal } from "./attribute-floor-manage-modal";

import * as S from "./attributes.styled";

interface TableRowProps extends CustomAttributeOut {
  index: number;
  groupKey: string;
  onAttributeUpdate: HandleAttributeUpdate;
  currentlyLoading?: number;
  onDelete: HandleAttributeDelete;
  deleting?: number;
  onNoteUpdate: HandleNoteAttributeUpdate;
  noteUpdated?: string;
  onFloorManageClick: (code: string) => void;
  selectedFloorAtributeManager: string;
  onFloorManageModalClose: () => void;
  onVisibilityClick: (shouldBeVisible: boolean) => void;
}

export const TableRow: React.FC<TableRowProps & Omit<PutOfferAttributesOut, "modificationDate">> = memo(
  ({
    attributeId,
    code,
    name,
    index,
    groupKey,
    onAttributeUpdate,
    currentlyLoading,
    onDelete,
    deleting,
    noteUpdated,
    onNoteUpdate,
    dataTypeCode,
    note,
    valueInterpretationStatusCode,
    valueInterpretationMessage,
    onFloorManageClick,
    selectedFloorAtributeManager,
    onFloorManageModalClose,
    isVisible,
    onVisibilityClick,
  }) => {
    const { state: showPopconfirm, toggle: togglePopconfirm } = useToggle();
    const [showAddNoteInput, setShowAddNoteInput] = useState(!!note);
    const { hasPermission } = usePermissions();

    const toggleInput = () => setShowAddNoteInput(!showAddNoteInput);

    const sharedAttributeValueProps = {
      code,
      index,
      onUpdate: onAttributeUpdate,
      currentlyLoading,
    };

    return (
      <S.TableRow>
        <S.CellsWrapper>
          <S.TableCell>{name}</S.TableCell>
          <S.TableCell>
            <AttributeValue
              attributeId={attributeId}
              code={code}
              index={index}
              onUpdate={onAttributeUpdate}
              currentlyLoading={currentlyLoading}
              groupKey={groupKey}
            />
          </S.TableCell>
          <S.TableCell>
            <InfoIcon
              info={dataTypeCode}
              statusCode={valueInterpretationStatusCode}
              responseMessage={valueInterpretationMessage}
            />
          </S.TableCell>
          <S.TableCell>
            <NoteButton onClick={toggleInput} disabled={showAddNoteInput} />
          </S.TableCell>
          <S.TableCell>
            <Button
              type="link"
              onClick={() => {
                onVisibilityClick(!isVisible);
              }}
              disabled={!hasPermission("PUT_ATTRIBUTE_VISIBILITY")}
            >
              <IconEye show={!!isVisible} />
            </Button>
          </S.TableCell>
          <S.TableCell>
            <Popconfirm
              title={`Are you sure that you want to delete ${name}`}
              visible={showPopconfirm}
              onConfirm={() => onDelete(attributeId)}
              onCancel={togglePopconfirm}
            >
              <DeleteButton
                onClick={togglePopconfirm}
                disabled={attributeId === deleting || !hasPermission("DELETE_ATTRIBUTE")}
              />
            </Popconfirm>
          </S.TableCell>
          <S.TableCell>
            <Button type="link" onClick={() => onFloorManageClick(code)}>
              <S.StyledGear />
            </Button>
            <AttributeFloorManageModal
              attributeId={attributeId}
              attributeName={name}
              visible={selectedFloorAtributeManager === code}
              onCancel={onFloorManageModalClose}
              attributeCode={code}
              groupKey={groupKey}
              {...sharedAttributeValueProps}
            />
          </S.TableCell>
        </S.CellsWrapper>
        {showAddNoteInput && (
          <EditNote
            index={index}
            onClose={() => {
              toggleInput()
              onNoteUpdate(attributeId)
            }}
            loading={code === noteUpdated}
            onUpdate={onNoteUpdate(attributeId)}
            groupKey={groupKey}
          />
        )}
      </S.TableRow>
    );
  }
);
