export const industrialAttributesItaly = [
  {
    code: "",
    cellWidth: 60,
    key: "emptyFirst",
  },
  {
    code: "Building Name",
    cellWidth: 450,
    key: "buildingName",
  },
  {
    code: "Total Net Area",
    cellWidth: 200,
    key: "totalNetArea",
  },
  {
    code: "Available Space",
    cellWidth: 200,
    key: "availableSpace",
  },
  {
    code: "Availability",
    cellWidth: 200,
    key: "availability",
  },
  {
    code: "Leased Area",
    cellWidth: 150,
    key: "leasedArea",
  },
  {
    code: "Tenant",
    cellWidth: 200,
    key: "tenant",
  },
  {
    code: "Transaction Date",
    cellWidth: 200,
    key: "transactionDate",
  },
  {
    code: "Rent",
    cellWidth: 170,
    key: "rent",
  },
  {
    code: "Internal Comments",
    cellWidth: 170,
    key: "internalComments",
  },
  {
    code: "",
    cellWidth: 60,
    key: "emptyLast",
  },
];
