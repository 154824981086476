import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .ant-select {
    align-self: center;
  }
`;

export const Link = styled.div`
  transition: 0.2s ease-in-out;
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.grey};
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 18px;
  }
`;

export const AddressContainer = styled.div`
  margin-bottom: 15px;
`;

export const AreaContainer = styled.div`
  margin: 10px 0;
`;
