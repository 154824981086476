/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientAttachments,
    ClientAttachmentsFromJSON,
    ClientAttachmentsFromJSONTyped,
    ClientAttachmentsToJSON,
    ClientAttributesMain,
    ClientAttributesMainFromJSON,
    ClientAttributesMainFromJSONTyped,
    ClientAttributesMainToJSON,
    ClientAttributesRest,
    ClientAttributesRestFromJSON,
    ClientAttributesRestFromJSONTyped,
    ClientAttributesRestToJSON,
    ClientOfferInfo,
    ClientOfferInfoFromJSON,
    ClientOfferInfoFromJSONTyped,
    ClientOfferInfoToJSON,
    Contacts,
    ContactsFromJSON,
    ContactsFromJSONTyped,
    ContactsToJSON,
    FloorsDetails,
    FloorsDetailsFromJSON,
    FloorsDetailsFromJSONTyped,
    FloorsDetailsToJSON,
    PoiProperty,
    PoiPropertyFromJSON,
    PoiPropertyFromJSONTyped,
    PoiPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface OfferDetailClient
 */
export interface OfferDetailClient {
    /**
     * 
     * @type {ClientOfferInfo}
     * @memberof OfferDetailClient
     */
    offerInfo: ClientOfferInfo;
    /**
     * 
     * @type {Array<ClientAttributesRest>}
     * @memberof OfferDetailClient
     */
    visibleAttributes: Array<ClientAttributesRest>;
    /**
     * 
     * @type {Array<ClientAttributesMain>}
     * @memberof OfferDetailClient
     */
    mainAttributes: Array<ClientAttributesMain>;
    /**
     * 
     * @type {Array<Contacts>}
     * @memberof OfferDetailClient
     */
    contacts: Array<Contacts>;
    /**
     * 
     * @type {Array<ClientAttachments>}
     * @memberof OfferDetailClient
     */
    attachments: Array<ClientAttachments>;
    /**
     * 
     * @type {Array<FloorsDetails>}
     * @memberof OfferDetailClient
     */
    floors: Array<FloorsDetails>;
    /**
     * 
     * @type {Array<PoiProperty>}
     * @memberof OfferDetailClient
     */
    pois: Array<PoiProperty>;
    /**
     * 
     * @type {number}
     * @memberof OfferDetailClient
     */
    propertyId: number;
}

export function OfferDetailClientFromJSON(json: any): OfferDetailClient {
    return OfferDetailClientFromJSONTyped(json, false);
}

export function OfferDetailClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferDetailClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offerInfo': ClientOfferInfoFromJSON(json['offerInfo']),
        'visibleAttributes': ((json['visibleAttributes'] as Array<any>).map(ClientAttributesRestFromJSON)),
        'mainAttributes': ((json['mainAttributes'] as Array<any>).map(ClientAttributesMainFromJSON)),
        'contacts': ((json['contacts'] as Array<any>).map(ContactsFromJSON)),
        'attachments': ((json['attachments'] as Array<any>).map(ClientAttachmentsFromJSON)),
        'floors': ((json['floors'] as Array<any>).map(FloorsDetailsFromJSON)),
        'pois': ((json['pois'] as Array<any>).map(PoiPropertyFromJSON)),
        'propertyId': json['propertyId'],
    };
}

export function OfferDetailClientToJSON(value?: OfferDetailClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offerInfo': ClientOfferInfoToJSON(value.offerInfo),
        'visibleAttributes': ((value.visibleAttributes as Array<any>).map(ClientAttributesRestToJSON)),
        'mainAttributes': ((value.mainAttributes as Array<any>).map(ClientAttributesMainToJSON)),
        'contacts': ((value.contacts as Array<any>).map(ContactsToJSON)),
        'attachments': ((value.attachments as Array<any>).map(ClientAttachmentsToJSON)),
        'floors': ((value.floors as Array<any>).map(FloorsDetailsToJSON)),
        'pois': ((value.pois as Array<any>).map(PoiPropertyToJSON)),
        'propertyId': value.propertyId,
    };
}


