import React from "react";
import { Formik, Field, FieldProps } from "formik";
import { message } from "antd";
import { contactsApi } from "@api/core";

import { EditableContentInput } from "@components/form/editable-content-input";
import { DeleteButton } from "@components/delete-button";
import * as T from "@components/table";

import { ContactTableProps } from "./types";

const onSubmitPlaceholder = () => {};

export const NotesTable: React.FC<ContactTableProps> = ({ contactId, data, handleDelete, refetch }) => {
  const initialValues = data.reduce(
    (prev, { comment, commentId }) => ({ ...prev, [commentId]: comment }),
    {}
  );

  const handleCommentChange = (commentId: number) => async (comment: string) => {
    try {
      await contactsApi.putCommentApiV1ContactsContactIdCommentsCommentIdPut({
        contactId,
        commentId,
        comment: { comment },
      });
      refetch();
    } catch (err) {
      message.error("Something went wrong!");
      refetch();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitPlaceholder} enableReinitialize>
      <T.Table>
        <T.TableHead>
          <T.THeadCell>Comment</T.THeadCell>
          <T.THeadCell>Author</T.THeadCell>
          <T.THeadCell>Created at</T.THeadCell>
          <T.THeadCell>Delete</T.THeadCell>
        </T.TableHead>
        <tbody>
          {data.map((note) => (
            <T.TableRow key={note.commentId}>
              <T.TableCell>
                <Field name={note.commentId}>
                  {(props: FieldProps) => (
                    <EditableContentInput onBlur={handleCommentChange(note.commentId)} {...props} />
                  )}
                </Field>
              </T.TableCell>
              <T.TableCell>{note.user}</T.TableCell>
              <T.TableCell>
                {note.createDate ? new Date(note.createDate).toLocaleString() : "unknown"}
              </T.TableCell>
              <T.THeadCell>
                <DeleteButton value={note.commentId} onClick={handleDelete} />
              </T.THeadCell>
            </T.TableRow>
          ))}
        </tbody>
      </T.Table>
    </Formik>
  );
};
