import React, { useEffect } from "react";
import { useIsSignedIn } from "@components/authentication/is-authenticate";
import { useAutomateLogout } from "@components/logout/logout";
import { CustomeLogin } from "@pages/login/login";
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import FullPageLoader from "@components/full-page-loader/full-page-loader";
import { msalConfiguration } from "../../config/msal-config";

export const withAuthentication =
  (Component: React.ComponentType<any>): React.FC =>
  (props) => {
    useEffect(() => {
      Providers.globalProvider = new Msal2Provider(msalConfiguration);
    }, []);

    const [isSignIn] = useIsSignedIn();
    useAutomateLogout();

    if (isSignIn === 1) {
      return <CustomeLogin />;
    }

    if (isSignIn === 0) {
      return <FullPageLoader />;
    }

    return <Component {...props} />;
  };
