import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { notification } from "antd";
import { propertyApi } from "@api/core";
import { Search } from "@components/input";
import * as S from "./add-reddid.styled";

export const AddREDDID = () => {
  const [value, setValue] = useState("");

  const propertyId = useSelector((state) => state.offers.offerDetails.data?.propertyId) || 0;

  const { mutate, isLoading } = useMutation(
    ["put-redd-state-add-redd-id", propertyId],
    () =>
      propertyApi.putReddIdApiV1PropertiesPropertyIdReddPut({
        propertyId,
        reddIdIn: {
          reddId: value,
        },
      }),
    {
      onError: (error: Response) => {
        if (error.status === 404) {
          notification.error({
            message: "Please verify reddid",
          });
        }
      },
    }
  );

  const handleSubmit = () => {
    mutate();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <S.Container>
      <Search
        loading={isLoading}
        value={value}
        onChange={handleChange}
        placeholder="Add reddId"
        onSearch={handleSubmit}
        style={{ width: 200 }}
        enterButton="Add"
      />
    </S.Container>
  );
};
