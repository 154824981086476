export const officeAttributesItaly = [
  {
    code: "",
    cellWidth: 60,
    key: "emptyFirst",
  },
  {
    code: "Total Net Area",
    cellWidth: 200,
    key: "totalNetArea",
  },
  {
    code: "Av. Off. Space",
    cellWidth: 200,
    key: "availableOfficeSpace",
  },
  {
    code: "Availability",
    cellWidth: 200,
    key: "availability",
  },
  {
    code: "Type",
    cellWidth: 200,
    key: "type",
  },
  {
    code: "Leased Area",
    cellWidth: 200,
    key: "leasedArea",
  },
  {
    code: "Tenant",
    cellWidth: 150,
    key: "tenant",
  },
  {
    code: "Transaction Date",
    cellWidth: 250,
    key: "transactionDate",
  },
  {
    code: "Rent",
    cellWidth: 250,
    key: "rent",
  },
  {
    code: "Internal Comments",
    cellWidth: 250,
    key: "internalComments",
  },
  {
    code: "",
    cellWidth: 60,
    key: "emptyLast",
  },
];
