import React, { useEffect } from "react";
import { setCustomFilterKey, setShowModalToAddCustomFilter } from "@state/attribures/actions";
import { fetchAttributes } from "@utils/attributes";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@components/form/button";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import { Form } from "./form";
import { AddOtherFilter } from "./add-other-filter.styled";

const customFiltersVisibleNumber = 10;

export const ExtraFilters = React.memo(() => {
  const dispatch = useDispatch();
  const categoryCode = useSelector(
    (state) => state.filters[state.filters.currentModule].data.propertyCategoryCode
  );
  const attributes = useSelector((state) => state.attributes.data);
  const setupFilters = useSelector((state) => state.filters[state.filters.currentModule].usedCustomFilters);
  const modalActive = useSelector((state) => state.attributes.customFilter.modalActive);

  useEffect(() => {
    fetchAttributes(dispatch)({
      categoryCode,
    });
  }, [categoryCode, dispatch]);

  const onClick = () => {
    dispatch(setShowModalToAddCustomFilter(true));
  };
  const openModalToSetupValues = (code: string) => async () => {
    dispatch(setCustomFilterKey(code));
    dispatch(setShowModalToAddCustomFilter(true));
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {attributes &&
        attributes
          .filter((attribute) => !setupFilters.find((item) => item.code === attribute.code))
          .slice(0, customFiltersVisibleNumber)
          .map((attribute) => (
            <Button
              key={`custome-filter-${attribute.code}`}
              code={attribute.code}
              name={attribute.name}
              onClick={openModalToSetupValues(attribute.code)}
            />
          ))}
      <Button code="Other filter" name="Other filter" onClick={onClick}>
        <AddOtherFilter>
          <PlusCircleOutlined style={{ marginRight: "5px" }} />
          Other filter
        </AddOtherFilter>
      </Button>
      <Form visible={modalActive} />
    </div>
  );
});
