import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Loader } from "@components/loader";

import { clientApi } from "@api/core";

import { useToggle } from "@hooks/use-toggle";

import { portalPath } from "@router/paths";

import { GenerateLink, FormValues } from "./generate-link";
import { AddNewClient } from "./add-new-client";
import * as S from "./get-link-form.styled";

interface GetLinkFormProps {
  ids: number[];
}

export const GetLinkForm = ({ ids }: GetLinkFormProps) => {
  const [newClientName, setNewClientName] = useState("");
  const [link, setLink] = useState<string | undefined>(undefined);
  const { state: isTooltipVisible, toggle } = useToggle();
  const currentModule = useSelector((appState) => appState.offers.currentModule);

  const contacts = useQuery("contacts", () => clientApi.getAllClientsApiV1ClientsGet());
  const newClient = useMutation(
    () => clientApi.postClientApiV1ClientsPost({ clientName: { name: newClientName } }),
    {
      onSuccess: () => {
        contacts.refetch();
      },
    }
  );
  const getLink = useMutation(
    ({ clientId, mainLocation, secondaryLocation }: FormValues) =>
      clientApi.createClientUrlApiV1ClientsUrlsPostRaw({
        clientOfferIn: {
          clientId,
          offerIds: ids.map((id) => Number(id)),
          mainLocation,
          secondaryLocation,
          module: currentModule,
        },
      }),
    {
      onSuccess: async (data) => {
        try {
          const { url } = await data.value();
          const { origin } = window.location;
          const hash = url.split("/").pop();
          setLink(`${origin + portalPath}/${hash}`);
        } catch (error) {
          console.error(error.message);
        }
      },
    }
  );

  const handleInputChange = (fn: (value: string) => void) => (e: ChangeEvent<HTMLInputElement>) =>
    fn(e.target.value);

  const handleNewClientNameChange = handleInputChange(setNewClientName);

  const handleAddNewClient = (e: SyntheticEvent) => {
    e.preventDefault();
    newClient.mutate();
  };
  const handleGetLink = (values: FormValues) => getLink.mutate(values);

  const handleTooltipVisibilityChange = () => {
    if (isTooltipVisible) {
      setTimeout(() => {
        toggle();
      }, 1000);
    }
  };

  return (
    <Loader spinning={contacts.isLoading || newClient.isLoading || getLink.isLoading}>
      <GenerateLink
        onSubmit={handleGetLink}
        contacts={contacts.data || []}
        isLoading={contacts.isLoading || newClient.isLoading}
      />
      {link && (
        <Tooltip title="Copied!" visible={isTooltipVisible} onVisibleChange={handleTooltipVisibilityChange}>
          <CopyToClipboard text={link} onCopy={() => toggle()}>
            <S.CopyButton>Copy link</S.CopyButton>
          </CopyToClipboard>
        </Tooltip>
      )}
      <AddNewClient
        onSubmit={handleAddNewClient}
        isLoading={newClient.isLoading}
        newClientName={newClientName}
        onNewClientNameChange={handleNewClientNameChange}
      />
    </Loader>
  );
};
