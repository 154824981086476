import styled, { css } from "styled-components";
import { Button as BaseButton } from "@components/button";

export const Button = styled(BaseButton)<{ grey?: boolean }>`
  margin-right: 15px;
  ${(props) =>
    props.grey &&
    css`
      background: ${props.theme.grey};
    `}
`;
