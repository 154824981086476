import { createReducer, ActionType } from "typesafe-actions";

import * as actions from "./actions";

import { State } from "./types";

type Actions = ActionType<typeof actions>;

const initialState: State = {
  isPickingFromMainTable: false,
  offers: [],
};

export const newsletterGenerateBreakdownReportReducer = createReducer<State, Actions>(initialState)
  .handleAction(actions.togglePickingNewsletter, (state, { payload }) => ({
    ...state,
    isPickingFromMainTable: true,
    typeOfPicking: "newsletter",
    group: payload,
  }))
  .handleAction(actions.togglePickingGenerateBreakdownReport, (state, { payload }) => ({
    ...state,
    isPickingFromMainTable: true,
    typeOfPicking: "generateBreakdownReport",
    group: payload,
  }))
  .handleAction(actions.turnOffPicking, (state) => ({
    ...state,
    isPickingFromMainTable: false,
    typeOfPicking: undefined,
    group: undefined,
    offers: [],
  }))
  .handleAction(actions.addToGroup, (state, { payload }) => ({
    ...state,
    offers: [...state.offers, payload],
  }))
  .handleAction(actions.turnOnModal, (state) => ({
    ...state,
    isPickingFromMainTable: false,
  }));
