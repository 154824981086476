import React from "react";
import { Loader } from "@components/loader";
import * as S from "./full-page-loader.styled";

const FullPageLoader = () => {
  return (
    <S.Wrapper>
      <Loader />
    </S.Wrapper>
  );
};

export default FullPageLoader;
