import React from "react";
import { Collapse } from "antd";

import { ContactOut, ContactType } from "@api/client/models";

import { Heading } from "@components/heading";

import { Contact } from "./contact";
import { AddContactForm } from "./add-contact-form";

import * as S from "./contact-group.styled";

type PassedContact = Omit<ContactOut, "type">;

interface ContactGroupProps {
  contacts: PassedContact[];
  type: string;
  onDelete: any;
  onNotesClick: any;
  selectedContactNotes: number;
  onCloseModal: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onAdd: () => void;
  propertyId: number;
  contactType: ContactType;
  onEmailSubmit: (value: { contactId: number; inputValue: string }) => void;
  onPhoneSubmit: (value: { contactId: number; inputValue: string }) => void;
  isEmailLoading: boolean;
  isPhoneLoading: boolean;
}

interface ContactObject extends PassedContact {
  children: PassedContact[];
}

const { Panel } = Collapse;

export const ContactGroup: React.FC<ContactGroupProps> = ({
  contacts,
  type,
  onDelete,
  onNotesClick,
  selectedContactNotes,
  onCloseModal,
  onAdd,
  propertyId,
  contactType,
  onEmailSubmit,
  onPhoneSubmit,
  isEmailLoading,
  isPhoneLoading,
}) => {
  const grouppedParentIds: Record<string, ContactObject> = {};
  contacts.forEach((contact) => {
    if (!contact.parentId) grouppedParentIds[contact.id] = { ...contact, children: [] };
    else grouppedParentIds[contact.parentId].children.push(contact);
  });

  return (
    <S.ContactGroupWrapper>
      <Heading level="3">{type.toUpperCase()}</Heading>
      <Collapse>
        {Object.values(grouppedParentIds).map(({ children, ...contactProps }) => (
          <Panel key={contactProps.id} header={contactProps.name}>
            <Contact
              isChildren
              onCloseModal={onCloseModal}
              selectedContactNotes={selectedContactNotes}
              onNotesClick={onNotesClick}
              onDelete={onDelete}
              onEmailSubmit={onEmailSubmit}
              onPhoneSubmit={onPhoneSubmit}
              isEmailLoading={isEmailLoading}
              isPhoneLoading={isPhoneLoading}
              {...contactProps}
            />
            {children.map((childrenContact) => (
              <Contact
                isChildren
                onCloseModal={onCloseModal}
                selectedContactNotes={selectedContactNotes}
                onNotesClick={onNotesClick}
                onDelete={onDelete}
                onEmailSubmit={onEmailSubmit}
                onPhoneSubmit={onPhoneSubmit}
                isEmailLoading={isEmailLoading}
                isPhoneLoading={isPhoneLoading}
                {...childrenContact}
              />
            ))}
            <AddContactForm
              parentId={contactProps.id}
              onAdd={onAdd}
              propertyId={propertyId}
              contactGroupName={contactProps.name}
              contactTypeId={contactType.id}
            />
          </Panel>
        ))}
      </Collapse>
    </S.ContactGroupWrapper>
  );
};
