import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { Select, Popover } from "antd";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";

import { ContactType } from "@api/client/models";
import { contactsApi } from "@api/core";

import { Input, Label } from "@components/input";
import { AddButton, Button } from "@components/button";
import { Empty } from "@components/empty";

import { useToggle } from "@hooks/use-toggle";

import { emailPattern, telPattern } from "@utils/regexp-and-input-patterns";

interface NewContactTypeProps {
  contactTypes: ContactType[];
  onSuccessfullContactUpload: () => void;
}

const { Option } = Select;

const nameLabelId = "Name";
const emailLabelId = "Email";
const phoneLabelId = "Phone";
const contactTypeLabelId = "Contact type";

const typeHelper =
  (handler: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.setCustomValidity("");
    handler(event.target.value);
  };

export const NewContactType: React.FC<NewContactTypeProps> = ({
  contactTypes,
  onSuccessfullContactUpload,
}) => {
  const propertyId = useSelector((state) => state.offers.offerDetails.data?.propertyId);
  const [contactTypeId, setContactTypeId] = useState<number>(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { state: showForm, toggle } = useToggle();

  const { mutate, isLoading, isError } = useMutation(
    () =>
      contactsApi.postContactApiV1ContactsPropertyPropertyIdPost({
        propertyId: propertyId as number,
        contactIn: { contactTypeId, name, phone, email },
      }),
    {
      onSuccess: () => {
        onSuccessfullContactUpload();
      },
    }
  );

  const handleTypeChange = (value: number) => setContactTypeId(value);
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (contactTypeId === 0) {
      setErrorMessage("Please select a contact type!");
      return;
    }
    setErrorMessage("");
    setContactTypeId(0);
    setName("");
    setEmail("");
    setPhone("");
    mutate();
  };

  if (isError) return <Empty />;

  return (
    <div style={{ marginTop: 20 }}>
      <Popover
        placement="bottomRight"
        trigger="click"
        visible={showForm}
        content={
          <form onSubmit={handleSubmit} style={{ width: 500 }}>
            <Label htmlFor={contactTypeLabelId}>{contactTypeLabelId}</Label>
            <Select id={contactTypeLabelId} placeholder="select contact type" onChange={handleTypeChange}>
              {contactTypes
                .filter(({ type }) => type !== "tenant")
                .map(({ id, type }) => (
                  <Option value={id} key={id}>
                    {type}
                  </Option>
                ))}
            </Select>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            <Label htmlFor={nameLabelId}>{nameLabelId}</Label>
            <Input
              value={name}
              placeholder={nameLabelId}
              onChange={typeHelper(setName)}
              required
              minLength={3}
            />
            <Label htmlFor={emailLabelId}>{emailLabelId}</Label>
            <Input
              value={email}
              placeholder={emailLabelId}
              onChange={typeHelper(setEmail)}
              type="email"
              pattern={emailPattern}
              onInvalid={(e) =>
                e.currentTarget.setCustomValidity("Enter an email in specified format: example@example.com")
              }
            />
            <Label htmlFor={phoneLabelId}>{phoneLabelId}</Label>
            <Input
              value={phone}
              placeholder={phoneLabelId}
              onChange={typeHelper(setPhone)}
              style={{ marginBottom: 20 }}
              type="tel"
              pattern={telPattern}
              onInvalid={(e) => e.currentTarget.setCustomValidity("Invalid format of the number.")}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button onClick={toggle} type="primary" style={{ marginRight: "4px" }}>
                Close
              </Button>
              <AddButton htmlType="submit" loading={isLoading} type="primary">
                Add
              </AddButton>
            </div>
          </form>
        }
      >
        <AddButton onClick={toggle} type="primary">
          Add new contact
        </AddButton>
      </Popover>
    </div>
  );
};
