import React from "react";

import { Popover } from "@components/popover";
import { StyledButton } from "@components/button";
import { Icon } from "@components/icon";
import { ReactComponent as Info } from "@svg/icon-info-value.svg";
import { ReactComponent as Warning } from "@svg/icon-warning.svg";

import "./info-icon.less";

interface InfoIconProps {
  info?: string;
  responseMessage?: string;
  statusCode?: string;
}

const title = "Interpreted as:";

export const InfoIcon: React.FC<InfoIconProps> = ({ info, responseMessage, statusCode }) => {
  let icon = Warning;
  if (statusCode === "OK" || !statusCode) icon = Info;

  if (info || responseMessage)
    return (
      <Popover
        title={!responseMessage && title}
        trigger="hover"
        content={responseMessage || info}
        error={statusCode === "ERR" || statusCode === "WARN"}
        className="icon-popover"
      >
        <StyledButton type="ghost">
          <Icon icon={icon} />
        </StyledButton>
      </Popover>
    );
  return null;
};
