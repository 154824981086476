import React from "react";
import AntLayout, { LayoutProps } from "antd/lib/layout";
import "antd/lib/layout/style/index.less";
import "./layout.less";

export const Layout: React.FC<LayoutProps> = (props) => <AntLayout {...props} />;
export const { Content } = AntLayout;
export const { Footer } = AntLayout;
export const { Header } = AntLayout;
export const { Sider } = AntLayout;
