import React from "react";
import { Upload, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";

import { offerApi, dictApi } from "@api/core";

import { Button } from "@components/button";
import { Loader } from "@components/loader";
import { usePermissions } from "@hooks/use-permission";

import { UploadItem, File } from "./upload-item";
import * as S from "./attachements.styled";

export const Attachements = () => {
  const { hasPermission } = usePermissions();
  const params = useParams<{ id: string }>();
  const offerId = Number(params.id);
  const fileList = useQuery(
    `file-list-offer-${params.id}`,
    () => {
      if (hasPermission("GET_OFFER_ATTACHEMENTS")) {
        return offerApi.getOfferAttachmentsApiV1OffersOfferIdAttachmentsGet({ offerId });
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const fileTypes = useQuery("file-types", () => dictApi.getAllFileTypesApiV1DictsFileTypesGet());
  const deleteFile = useMutation(
    (attachmentId: number) =>
      offerApi.deleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDelete({
        offerId,
        attachmentId,
      }),
    {
      onSuccess: () => {
        fileList.refetch();
      },
    }
  );
  const updateVisibility = useMutation(
    ({
      attachmentId,
      isVisibleInCp,
      isPublic,
    }: {
      attachmentId: number;
      isVisibleInCp: boolean;
      isPublic: boolean;
    }) =>
      offerApi.setOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPut({
        offerId,
        attachmentId,
        attachmentVisibility: { isVisibleInCp, isPublic },
      }),
    {
      onSuccess: () => {
        fileList.refetch();
      },
    }
  );
  const fileUpload = useMutation(
    (file: RcFile) =>
      offerApi.postOfferAttachmentApiV1OffersOfferIdAttachmentsPost({
        offerId,
        attachment: file,
      }),
    {
      onSuccess: () => {
        fileList.refetch();
      },
    }
  );
  const updateFileType = useMutation(
    ({ attachmentId, fileTypeId }: { attachmentId: number; fileTypeId: number }) =>
      offerApi.putOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePut({
        offerId,
        attachmentId,
        attachmentFileTypeId: { fileTypeId },
      }),
    {
      onSuccess: () => {
        fileList.refetch();
      },
    }
  );
  const updateMain = useMutation(
    ({ attachmentId, isMainFile }: { attachmentId: number; isMainFile: boolean }) =>
      offerApi.setOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPut({
        offerId,
        attachmentId,
        attachmentMain: { isMainFile },
      }),
    {
      onSuccess: () => {
        fileList.refetch();
      },
    }
  );
  const getAllFileTags = useQuery("all-file-tags", () =>
    offerApi.getOfferTagsApiV1OffersOfferIdTagsGet({ offerId: Number(params.id) })
  );
  const deleteTag = useMutation(
    (props: { attachmentId: number; fileTagId: number }) =>
      offerApi.deleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDelete(props),
    {
      onSuccess: () => {
        fileList.refetch();
      },
    }
  );

  const uploadedFileList = fileList.data?.map((file) => {
    const name = file.urlPath.split("/").pop()?.split("?")[0];

    return {
      uid: file.attachmentId,
      name: name || "unknown",
      ...file,
    };
  });

  const selectOptions = fileTypes.data?.map(({ fileTypeId, fileType }) => ({
    label: fileType,
    key: fileTypeId,
    value: fileTypeId,
  }));

  const handleDelete = (e: any) => deleteFile.mutate(Number(e.target.value));
  const handleMainChange = (e: CheckboxChangeEvent) =>
    updateMain.mutate({
      attachmentId: e.target.value,
      isMainFile: e.target.checked,
    });
  const handleUpdateVisibility = (e: CheckboxChangeEvent, publicValue: boolean, cpValue: boolean) => {
    const attachmentId = e.target.value.split("-");
    const isCp = attachmentId[0] === "cp";
    const updateBody: { isPublic: boolean; isVisibleInCp: boolean } = {
      isPublic: false,
      isVisibleInCp: false,
    };
    if (isCp) {
      updateBody.isPublic = publicValue;
      updateBody.isVisibleInCp = e.target.checked;
    } else {
      updateBody.isPublic = e.target.checked;
      updateBody.isVisibleInCp = cpValue;
    }
    updateVisibility.mutate({
      ...updateBody,
      attachmentId: attachmentId[1],
    });
  };
  const handleFileTypeChange = (attachmentId: number) => (fileTypeId: number) => {
    updateFileType.mutate({ attachmentId, fileTypeId });
  };

  if (fileTypes.isLoading) return <Loader />;

  const uploadProps: UploadProps = {
    customRequest: ({ file }) => {
      fileUpload.mutate(file as RcFile);
    },
    fileList: (uploadedFileList as any) || [],
    itemRender: (_, file) => (
      <UploadItem
        file={file as unknown as File}
        fileTypes={selectOptions}
        onDelete={handleDelete}
        onMainChange={handleMainChange}
        onFileTypeSelect={handleFileTypeChange}
        onTagMutate={fileList.refetch}
        onUpdateVisibilty={handleUpdateVisibility}
        fileTags={getAllFileTags.data}
        onDeleteTag={deleteTag.mutate}
        isEditable={!hasPermission("POST_FILE_TAG")}
        isDownloadable={hasPermission("GET_OFFER_ATTACHEMENT")}
        isVisibilityChangeable={!hasPermission("PUT_OFFER_ATTACHEMENT_VISIBILITY")}
        isMainEditable={!hasPermission("PUT_OFFER_ATTACHEMENT_MAIN")}
        isFileTypeEditable={!hasPermission("PUT_OFFER_ATTACHEMENT_FILE_TYPE")}
        isDeleteAllowed={!hasPermission("DELETE_OFFER_ATTACHEMENT")}
        isDeleteTagAllowed={hasPermission("DELETE_FILE_TAG")}
      />
    ),
  };

  return (
    <S.Wrapper>
      <S.Heading level="2">Attachements</S.Heading>
      <Upload {...uploadProps}>
        <Button disabled={!hasPermission("POST_OFFER_ATTACHEMENTS")} type="primary">
          Load file
        </Button>
      </Upload>
      <Loader spinning={fileUpload.isLoading} />
    </S.Wrapper>
  );
};
