import React, { useState, ChangeEvent } from "react";
import { Popover } from "antd";
import { useQuery, useMutation } from "react-query";

import { AddButton } from "@components/button";
import { Loader } from "@components/loader";
import { Empty } from "@components/empty";
import { contactsApi } from "@api/core";

import { useToggle } from "@hooks/use-toggle";

import { NotesModalProps } from "./types";
import { AddNoteForm } from "./add-note-form";
import { NotesTable } from "./notes-table";

export const ModalContent: React.FC<Omit<NotesModalProps, "name">> = ({ contactId }) => {
  const [note, setNote] = useState("");
  const { state: showForm, toggle } = useToggle();
  const { data, isLoading, error, refetch } = useQuery(
    ["notes", contactId],
    () => contactsApi.getCommentsApiV1ContactsContactIdCommentsGet({ contactId }),
    {
      refetchOnMount: true,
    }
  );
  const deleteComment = useMutation(
    (commentId: string) =>
      contactsApi.deleteCommentApiV1ContactsContactIdCommentsCommentIdDelete({
        commentId: Number(commentId),
        contactId,
      }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );
  const addComment = useMutation(
    () =>
      contactsApi.postCommentApiV1ContactsContactIdCommentsPost({ contactId, comment: { comment: note } }),
    {
      onSuccess: () => {
        refetch();
        setNote("");
        toggle();
      },
    }
  );

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value);

  if (error) return <>error</>;

  const handleDelete = (event: any) => {
    deleteComment.mutate(event.target.value);
  };

  return (
    <>
      <Loader spinning={isLoading || addComment.isLoading || deleteComment.isLoading} noMargin>
        {!data || !data.length ? (
          <Empty />
        ) : (
          <NotesTable contactId={contactId} data={data} handleDelete={handleDelete} refetch={refetch} />
        )}
      </Loader>
      <div style={{ marginTop: 20 }}>
        <Popover
          visible={showForm}
          title="Add note"
          trigger="click"
          onVisibleChange={toggle}
          content={
            <AddNoteForm
              contactId={contactId}
              toggle={toggle}
              refetch={refetch}
              addComment={addComment.mutate}
              value={note}
              onChange={handleChange}
              isLoading={addComment.isLoading}
            />
          }
          destroyTooltipOnHide
          placement="rightBottom"
        >
          <AddButton onClick={toggle}>Add note</AddButton>
        </Popover>
      </div>
    </>
  );
};
