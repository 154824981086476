import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";

import * as S from "@components/table";

import { TableHeading } from "@components/table-heading";
import { mainTableMixin, Checkbox } from "./styled";

const StyledHCell = styled(S.THeadCell)`
  ${mainTableMixin};
`;

export interface TableHeadProps {
  selectAllChecked: boolean;
  onSelectAll: () => void;
}

export const TableHead = ({ selectAllChecked, onSelectAll }: TableHeadProps) => (
  <S.TableHead>
    <StyledHCell>
      <Tooltip title="Select all">
        <Checkbox checked={selectAllChecked} onClick={onSelectAll} />
      </Tooltip>
    </StyledHCell>
    <StyledHCell>
      <TableHeading level="4">Name</TableHeading>
    </StyledHCell>
    <StyledHCell>
      <TableHeading level="4">Status</TableHeading>
    </StyledHCell>
    <StyledHCell>
      <TableHeading level="4">Geography Area</TableHeading>
    </StyledHCell>
    <StyledHCell>
      <TableHeading level="4">Address</TableHeading>
    </StyledHCell>
    <StyledHCell textAlign="right">
      <TableHeading level="4">Rent for m2</TableHeading>
    </StyledHCell>
    <StyledHCell textAlign="right">
      <TableHeading level="4">Service charge</TableHeading>
    </StyledHCell>
    <StyledHCell textAlign="right">
      <TableHeading level="4">Last update</TableHeading>
    </StyledHCell>
  </S.TableHead>
);
