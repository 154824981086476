import React, { createRef, useCallback, useEffect, useState } from "react";
import { ModalWithForm } from "./modal-with-form/modal-with-form";

import * as S from "./new-tenant.styled";
import { GenericAddButton } from "../generic-add/generic-add-button";

interface Props {
  propertyId: number;
  mutateListOfTenants: any;
}

export const NewTenant: React.FC<Props> = ({ propertyId, mutateListOfTenants }) => {
  const [show, setShow] = useState(false);
  const submitButton = createRef<HTMLButtonElement>();

  const handleNewTenantClick = useCallback(() => {
    setShow(!show);
  }, [show]);

  const handleCancel = useCallback(() => {
    setShow(!show);
  }, [show, setShow]);

  const onOk = useCallback(() => {
    submitButton.current?.click();
    mutateListOfTenants();
    setShow(!show);
  }, [show, submitButton, mutateListOfTenants]);

  const pressEnterHandler = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        setShow(!show);
      }
      event.stopPropagation();
    },
    [show]
  );

  useEffect(() => {
    if (show) {
      window.addEventListener("keypress", pressEnterHandler);
    } else {
      window.removeEventListener("keypress", pressEnterHandler);
    }
  }, [pressEnterHandler, show]);

  return (
    <S.TopPadding>
      <GenericAddButton onClick={handleNewTenantClick}>New Tenant</GenericAddButton>
      <ModalWithForm
        propertyId={propertyId}
        show={show}
        onCancel={handleCancel}
        onOk={onOk}
        submitButtonRef={submitButton}
        callUpdateTenantList={mutateListOfTenants}
      />
    </S.TopPadding>
  );
};
