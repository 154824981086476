import styled from "styled-components";
import { Input } from "@components/input";

export const GeneralSliderWrapper = styled.div`
  padding-top: 10px;
`;

export const ChooseUnitWrapper = styled.div`
  padding-bottom: 20px;
`;

export const SliderContainer = styled.div`
  width: 100%;
`;

export const InputsWrapper = styled(SliderContainer)`
  display: flex;
  justify-content: space-between;
`;

export const SliderInput = styled(Input)`
  max-width: 100px;

  &:last-of-type {
    text-align: right;
  }
`;
