import { all, put, AllEffect, ForkEffect, takeLatest } from "redux-saga/effects";

import * as actions from "./actions";

const effects = [
  actions.changeCustomFilter,
  actions.addCustomFilter,
  actions.removeCustomFilter,
  actions.setAllFilters,
];

function* setProperObjectForRequestWithCustomFilters() {
  yield put(actions.setCustomFilterRequest());
}

export default function* (): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([takeLatest([...effects], setProperObjectForRequestWithCustomFilters)]);
}
