import styled from "styled-components";
import { theme } from "@components/variables";
import { Button } from "./button";

export const StyledButton = styled(Button)`
  &.ant-btn-ghost {
    border: none;
    height: 32px;
    width: 32px;
    padding: 0;
    box-shadow: none;

    &:hover {
      background-color: #e5e6e8;
      border-radius: 2px;
      /* svg path {
        fill: ${theme.primaryColor};
      } */
    }

    &:active {
      background-color: #cecfd2;
    }
  }
  &.ant-btn-link {
    color: ${theme.darkGrey};
    display: flex;
    align-content: center;
    transition: none;
    svg {
      height: 16px;
      width: 16px;
      align-self: center;
    }
    :hover {
      color: ${theme.primaryColor};
      animation-name: none;
      svg {
        height: 16px;
        width: 16px;
      }
      svg path {
        fill: ${theme.primaryColor};
      }
    }
  }
`;
