/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeoBase
 */
export interface GeoBase {
    /**
     * 
     * @type {number}
     * @memberof GeoBase
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GeoBase
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GeoBase
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof GeoBase
     */
    fullyQualifiedName: string;
}

export function GeoBaseFromJSON(json: any): GeoBase {
    return GeoBaseFromJSONTyped(json, false);
}

export function GeoBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'fullyQualifiedName': json['fullyQualifiedName'],
    };
}

export function GeoBaseToJSON(value?: GeoBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'parentId': value.parentId,
        'fullyQualifiedName': value.fullyQualifiedName,
    };
}


