/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoiIn
 */
export interface PoiIn {
    /**
     * 
     * @type {string}
     * @memberof PoiIn
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PoiIn
     */
    categoryId: number;
    /**
     * 
     * @type {number}
     * @memberof PoiIn
     */
    geographyId?: number;
    /**
     * 
     * @type {string}
     * @memberof PoiIn
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof PoiIn
     */
    geoLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PoiIn
     */
    geoLongitude?: number;
}

export function PoiInFromJSON(json: any): PoiIn {
    return PoiInFromJSONTyped(json, false);
}

export function PoiInFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'categoryId': json['categoryId'],
        'geographyId': !exists(json, 'geographyId') ? undefined : json['geographyId'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'geoLatitude': !exists(json, 'geoLatitude') ? undefined : json['geoLatitude'],
        'geoLongitude': !exists(json, 'geoLongitude') ? undefined : json['geoLongitude'],
    };
}

export function PoiInToJSON(value?: PoiIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'categoryId': value.categoryId,
        'geographyId': value.geographyId,
        'address': value.address,
        'geoLatitude': value.geoLatitude,
        'geoLongitude': value.geoLongitude,
    };
}


