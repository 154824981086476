import React, { useState, SyntheticEvent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { message, Modal, ModalProps, Tooltip } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";

import { Heading } from "@components/heading";
import { Input, Label } from "@components/input";
import { Button } from "@components/button";
import { usePermissions } from "@hooks/use-permission";
import { turnOffPicking } from "@state/newsletter-generate-breakdown-report/actions";
import { getFileNameFormResponseHeader } from "@utils/getFileNameFromResponseHeader";
import { clientApi, offerApi } from "@api/core";
import { ApiResponse } from "@api/client";
import { portalPath } from "@router/paths";
import { useToggle } from "@hooks/use-toggle";
import { Loader } from "@components/loader";

import { Group as GroupWrapper } from "./group";
import { Group, Property } from "./types";
import { TemplateButton } from "./template-button";
import { LoadTemplates } from "./load-templates";

import { Progress } from "../progress";

import * as S from "./newsletter-property-breakdown-report-shared.styled";

interface NewsletterPropertyBreakdownReportSharedProps extends ModalProps {
  heading: string;
  method:
    | "getNewsletterApiV1OffersNewsletterPut"
    | "getSummaryApiV1OffersSummaryPut"
    | "getSummaryApiV1OffersSummaryLNGPut"
    | "getIndustrialListEngApiV1OffersIndustrialListPut"
    | "getIndustrialListApiV1OffersIndustrialListENGPut"
  isNewsletter?: boolean;
  properties: Property[];
}

const groupGenerator = (id: string, properties: Property[] = []): Group => ({
  id,
  properties,
});

const newGroupLabelId = "Add new group";
const maxGroupsLength = 9;

export const NewsletterPropertyBreakdownReportShared = ({
  heading,
  method,
  isNewsletter,
  properties,
  ...modalProps
}: NewsletterPropertyBreakdownReportSharedProps) => {
  const { hasPermission } = usePermissions();
  const dispatch = useDispatch();
  const [newGroup, setNewGroup] = useState("");
  const [schemaId, setSelectedTemplate] = useState(0);
  const [link, setLink] = useState<string | undefined>(undefined);
  const { state: isTooltipVisible, toggle } = useToggle();
  const [groups, setGroups] = useState<Group[]>([]);
  const {
    isPickingFromMainTable,
    group: selectedGroup,
    typeOfPicking,
  } = useSelector((state) => state.newsletterGenerateBreakdownReportReducer);
  const currentModule = useSelector((appState) => appState.offers.currentModule);

  const isPickingNewsletter = typeOfPicking === "newsletter";
  const isNewsletterModal =
    method === "getNewsletterApiV1OffersNewsletterPut" ||
    method === "getIndustrialListApiV1OffersIndustrialListENGPut";

  const isIndustrial =
    method === "getIndustrialListEngApiV1OffersIndustrialListPut" ||
    method === "getIndustrialListApiV1OffersIndustrialListENGPut";

  useQuery(
    `schema-${schemaId}`,
    () => {
      if (hasPermission("GET_SCHEMA_BY_ID"))
        return offerApi.getNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGet({ schemaId });
    },
    {
      enabled: !!schemaId,
      onSuccess: (data = { locations: [], name: "" }) => {
        setGroups(
          data.locations.map(({ name, offers }) =>
            groupGenerator(
              name,
              // TODO: fix swagger
              offers.map(({ id, name: value }: any) => ({ text: id, value }))
            )
          )
        );
      },
    }
  );

  const handleAddNewOfferToGroup = (id: string, offer: Property) => {
    if (groups.length <= maxGroupsLength) {
      setGroups((prevState) =>
        prevState.map((group) => {
          if (id === group.id && group.properties.filter((x) => x.value === offer.value).length === 0)
            return groupGenerator(id, [...group.properties, offer]);
          return group;
        })
      );
    }
  };

  const reoderProperties = (id: string, groupProperties: Property[]) => {
    if (groups.length <= maxGroupsLength) {
      setGroups((prevState) =>
        prevState.map((group) => {
          if (id === group.id) return groupGenerator(id, [...groupProperties]);
          return group;
        })
      );
    }
  };

  useEffect(() => {
    if (!isPickingFromMainTable) {
      setGroups((prevState) =>
        prevState.map((group) => {
          if (selectedGroup === group.id && isPickingNewsletter === isNewsletterModal) {
            const groupSet = new Set();
            group.properties.forEach(({ text }) => groupSet.add(text));
            const filteredNewProperties = properties.filter(({ text }) => !groupSet.has(text));
            return groupGenerator(selectedGroup, [...group.properties, ...filteredNewProperties]);
          }
          return group;
        })
      );
      dispatch(turnOffPicking());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPickingFromMainTable]);

  const putNewsletter = useMutation(
    () => {
      const newsletterLocation = groups.map((group) => ({
        name: group.id,
        offers: group.properties.map(({ text }) => text),
      }));
      return offerApi[method]({ newsletterLocation });
    },
    {
      onSuccess: async (data: ApiResponse<any>) => {
        const defaultFileName = isNewsletter ? "newsletter" : "summary.pptx";
        const fileName = getFileNameFormResponseHeader(data) ?? defaultFileName;
        const blobFile = await data.raw.blob();

        const url = window.URL.createObjectURL(
          new Blob([blobFile], {
            type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          })
        );
        const newsLetterLink = document.createElement("a");
        newsLetterLink.href = url;
        newsLetterLink.setAttribute("download", fileName);
        document.body.appendChild(newsLetterLink);
        newsLetterLink.click();
      },
    }
  );

  const ConvertGroupsToOfferIdsArray = (selectedGroups: Group[]) => {
    const offerIdsArray: Array<number> = [];

    selectedGroups.forEach((group) => {
      group.properties.map((p) => (!offerIdsArray.includes(p.text) ? offerIdsArray.push(p.text) : null));
    });

    return offerIdsArray;
  };

  const getLink = useMutation(
    () =>
      clientApi.createClientUrlApiV1ClientsUrlsPostRaw({
        clientOfferIn: {
          clientId: null,
          offerIds: groups && ConvertGroupsToOfferIdsArray(groups),
          mainLocation: "",
          secondaryLocation: "",
          module: currentModule,
        },
      }),
    {
      onSuccess: async (data) => {
        const { url } = await data.value();
        const { origin } = window.location;
        const hash = url.split("/").pop();
        setLink(`${origin + portalPath}/${hash}`);
      },
      onError: () => {
        message.error("Failed client portal could not be created");
      },
    }
  );
  const handleGetLink = () => getLink.mutate();

  const handleTooltipVisibilityChange = () => {
    if (isTooltipVisible) {
      setTimeout(() => {
        toggle();
      }, 1000);
    }
  };

  const handleAddNewGroup = (event: SyntheticEvent) => {
    event.preventDefault();
    if (newGroup.length < 3) return;
    setGroups((prevState) => [...prevState, groupGenerator(newGroup)]);
    setNewGroup("");
  };

  const handleDeleteGroup = (id: string) => {
    setGroups((prevGroups) => prevGroups.filter((group) => id !== group.id));
  };
  const handleDeleteProperty = (property: number, id: string) =>
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === id
          ? { ...group, properties: group.properties.filter(({ text }) => text !== property) }
          : group
      )
    );

  const progressEstimatedTime = groups.reduce((prevVal, group) => prevVal + group.properties.length, 0);

  return (
    <Modal
      {...modalProps}
      onOk={() => putNewsletter.mutate()}
      okText={heading}
      okButtonProps={{ loading: putNewsletter.isLoading }}
      destroyOnClose
      width={isIndustrial ? 650 : 520}
    >
      <Heading level="2">{heading}</Heading>
      {groups.map((group) => (
        <GroupWrapper
          key={group.id}
          onSelect={handleAddNewOfferToGroup}
          onDeleteGroup={handleDeleteGroup}
          onOfferDelete={handleDeleteProperty}
          name={group.id}
          properties={group.properties}
          isNewsletter={isNewsletter}
          reoderProperties={reoderProperties}
        />
      ))}
      <form onSubmit={handleAddNewGroup}>
        <Label htmlFor={newGroupLabelId}>{newGroupLabelId}</Label>
        <Input
          id={newGroupLabelId}
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
          placeholder="New group"
        />
        <Button htmlType="submit" type="primary" style={{ marginTop: 10 }}>
          {newGroupLabelId}
        </Button>
      </form>
      {putNewsletter.isLoading && <Progress max={progressEstimatedTime} />}
      <S.AutoCompleteWrapper>
        <LoadTemplates
          setSelectedTemplate={setSelectedTemplate}
          canLoadTemplates={hasPermission("GET_NEWSLETTER_SCHEMAS")}
          canDeleteTemplate={hasPermission("DELETE_SCHEMA_BY_ID")}
        />
      </S.AutoCompleteWrapper>
      <S.ButtonPositioner>
        <TemplateButton
          locations={groups.map(({ id, properties: offers }) => ({
            name: id,
            offers: offers.map(({ text }) => text),
          }))}
          canAddTemplate={hasPermission("POST_NEWSLETTER_SCHEMA")}
        />
        <div>
          <Button onClick={handleGetLink} loading={getLink.isLoading} type="primary">
            Generate portal link
          </Button>

          {link && (
            <Tooltip
              title="Copied!"
              visible={isTooltipVisible}
              onVisibleChange={handleTooltipVisibilityChange}
            >
              <CopyToClipboard text={link} onCopy={() => toggle()}>
                <Button style={{ marginLeft: 10 }}>Copy link</Button>
              </CopyToClipboard>
            </Tooltip>
          )}
        </div>
      </S.ButtonPositioner>
    </Modal>
  );
};
