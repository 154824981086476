import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { addCustomFilter, changeCustomFilter } from "@state/filters/actions";

import { resetCustomFilterValue, setCustomFilter } from "@state/attribures/actions";
import { CustomFilter } from "@state/filters/types";
import * as S from "./Form.styled";
import { CustomParser } from "./sliders/utils/custom-parser";
import { SecondStepCustomFilterModal } from "./custom-filter-modal/second-step-custom-filter-modal";
import { FirstStepCustomFilterModal } from "./custom-filter-modal/first-step-custom-filter-modal";

interface Props {
  visible: boolean;
}

export const Form: React.FC<Props> = React.memo(({ visible }) => {
  const dispatch = useDispatch();
  const attributes = useSelector((state) => state.attributes.data);
  const { usedCustomFilters: filtersAddapted } = useSelector(
    (state) => state.filters[state.filters.currentModule]
  );
  const allAttributes = useSelector((state) => state.attributes.data);
  const {
    key: currentGroup,
    value: groupValue,
    valueUnit: unitGroupValue,
    searchType,
    floors,
    modules,
    isValueFilterActivie,
    isFloorFilterActivie,
    isModuleFilterActivie,
  } = useSelector((state) => state.attributes.customFilter);
  const filterAlreadyExisted = filtersAddapted.find((x) => x.code === currentGroup);
  const currentAttribute = attributes.find((item) => item.code === currentGroup);

  const closeModal = () => {
    dispatch(
      setCustomFilter({
        modalActive: false,
        value: "",
        key: "",
        floors: undefined,
        modules: undefined,
        valueUnit: undefined,
      })
    );
  };

  const submit = () => {
    closeModal();
    const attribute = allAttributes.find((x) => x.code === currentGroup);
    if (attribute) {
      const newFilterToSet: CustomFilter = {
        ...attribute,
        value: isValueFilterActivie ? groupValue : undefined,
        unitValue: unitGroupValue,
        searchType,
        floors: isFloorFilterActivie ? CustomParser.stringifyFormArrayToString(floors) : undefined,
        modules: isModuleFilterActivie ? CustomParser.stringifyFormArrayToString(modules) : undefined,
      };
      dispatch(resetCustomFilterValue(true));
      if (filterAlreadyExisted) {
        dispatch(changeCustomFilter({ ...newFilterToSet }));
      } else {
        dispatch(addCustomFilter({ ...newFilterToSet }));
      }
    }
  };

  return (
    <Modal visible={visible} onCancel={closeModal} onOk={submit}>
      <S.Container>
        {!currentAttribute && <FirstStepCustomFilterModal />}
        {currentAttribute && <SecondStepCustomFilterModal currentAttribute={currentAttribute} />}
      </S.Container>
    </Modal>
  );
});
