import styled from "styled-components";
import { Button } from "antd";
import { theme } from "@components/variables";

export const AttributeButton = styled.button`
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  :hover {
    background-color: ${theme.primaryColor};
    color: white;
  }
  :focus {
    outline: none;
  }
  :last-of-type {
    margin-right: none;
    margin-bottom: none;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

export const AddAttributeButtonWrapper = styled.div`
  margin: 20px 0;
`;

export const AttributeButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: "row";
  max-height: 330px;
  width: 1000px;
`;

export const PopupContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid ${theme.borderColor};
  width: auto;
  height: auto;
  flex-grow: 1;
  min-width: 30%;
  :nth-child(2) {
    min-width: 40%;
  }
  :last-of-type {
    border: none;
  }
`;

export const ElementOnPredefinedAttributeList = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  :hover {
    color: ${theme.primaryColor};
  }
`;

export const PredefinedAttributeInputWrapper = styled.div`
  padding-bottom: 10px;
`;
