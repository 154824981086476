import React, { useState, useEffect, useCallback } from "react";
import { Modal, Divider } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { ModalProps } from "antd/lib/modal";
import { useDispatch, useSelector } from "react-redux";
import { poiApi } from "@api/core";
import { setPois } from "@state/poi/actions";

import { Heading } from "@components/heading";
import { RadioButton } from "@components/radio-button";
import { Icon } from "@components/icon";
import { icons, IconTypes, centerOfPoland, zoomForPoland } from "@components/variables";
import { MapLazy as Map } from "@components/map";
import {
  putPoiName,
  putPoiAddress,
  putPoiCoordinates,
  fetchPoiDicts,
  putPoiGeographyId,
  putPoiCategoryId,
} from "@utils/poi";
import { SelectGeography } from "@pages/shared-module/select-geography";
import type { DragEvent } from "react-map-gl/index";

import * as S from "./table.styled";

interface Props {
  address?: string;
  geographyName?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  poiCategory?: string;
  poiId?: number;
  put?: boolean;
  zoom?: number;
}

type Coordinates = number[];

export const AddNewPoi: React.FC<ModalProps & Props> = ({
  address,
  geographyName,
  latitude,
  longitude,
  name,
  onCancel,
  poiCategory: poiC,
  poiId,
  put,
  visible,
  zoom,
}) => {
  const dispatch = useDispatch();
  const geographyList = useSelector((state) => state.geography.data);
  const pois = useSelector((state) => state.dicts.poi);
  const [poiName, setPoiName] = useState(name || "");
  const [poiCategoryId, setPoiId] = useState<number | undefined>();
  const [selectedGeographyState, setSelectedGeographyState] = useState<number>();
  useEffect(() => {
    (async () => {
      if (visible) await fetchPoiDicts(dispatch)();
    })();
    setPoiId(pois.data.find((item) => item.name === poiC)?.id);
    setSelectedGeographyState(geographyList.find((item) => item.name === geographyName)?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, dispatch]);

  useEffect(() => {
    setPoiId(pois.data.find((item) => item.name === poiC)?.id);
    setSelectedGeographyState(geographyList.find((item) => item.name === geographyName)?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pois, geographyList]);

  // const initialSelected = pois.data.find((poi) => poi.name === poiC);
  const [loading, setLoading] = useState(false);
  const [addr, setAddr] = useState(address || "");
  const [geography, setGeography] = useState<Coordinates>([
    longitude || centerOfPoland.longitude,
    latitude || centerOfPoland.latitude,
  ]);

  const handleCoordinatesPut = useCallback(
    // eslint-disable-next-line consistent-return
    (coordinatesToChange: Coordinates, poiPostId?: number) => {
      if (poiId || poiPostId) {
        return putPoiCoordinates(dispatch)({
          poiId: poiPostId ? (poiPostId as number) : (poiId as number),
          poiInCoordinates: { geoLongitude: coordinatesToChange[0], geoLatitude: coordinatesToChange[1] },
        });
      }
    },
    [dispatch, poiId]
  );
  const handleDragEnd = useCallback(
    (event: DragEvent) => {
      setGeography(event.lngLat);
      handleCoordinatesPut(event.lngLat)?.then((response: any) => setAddr(response.payload.address));
    },
    [handleCoordinatesPut]
  );
  const handleNameChangePut = useCallback(
    (nameToChange: string) => poiId && putPoiName(dispatch)({ poiId, poiInName: { name: nameToChange } }),
    [dispatch, poiId]
  );
  const handleAddressChangePut = useCallback(
    (addressToChange: string) =>
      poiId && putPoiAddress(dispatch)({ poiId, poiInAddress: { address: addressToChange } }),
    [dispatch, poiId]
  );
  const handleGeographyChangePut = useCallback(
    (geographyIdToChange: number) =>
      poiId && putPoiGeographyId(dispatch)({ poiId, poiInGeo: { geographyId: geographyIdToChange } }),
    [dispatch, poiId]
  );
  const handlePoiCategoryChangePut = useCallback(
    (poiCategoryIdToChange: number) =>
      poiId && putPoiCategoryId(dispatch)({ poiId, poiInCategory: { categoryId: poiCategoryIdToChange } }),
    [dispatch, poiId]
  );
  const handleCategorySelect = useCallback((event: RadioChangeEvent) => {
    setPoiId(event.target.value);
  }, []);
  const handlePoiNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPoiName(event.target.value);
  }, []);
  const handleAddressChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAddr(event.target.value);
  }, []);
  const handleOkClick = useCallback(() => {
    if (poiName && poiCategoryId) {
      setLoading((l) => !l);
      // const addressInput = Object.values(centerOfPoland).every((coord, i) => coord === geography[i])
      //   ? { address: addr }
      //   : { geoLongitude: geography[0], geoLatitude: geography[1] };
      poiApi
        .postPoisApiV1PoisPost({
          poiIn: {
            name: poiName,
            categoryId: poiCategoryId,
            address: addr,
            geoLongitude: geography[0],
            geoLatitude: geography[1],
            geographyId: selectedGeographyState,
          },
        })
        .then(() => {
          if (onCancel) onCancel(undefined as any);
          setLoading((l) => !l);
          setPoiName("");
          setPoiId(undefined);
          setAddr("");
          dispatch(setPois.request({}));
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [setLoading, poiCategoryId, poiName, onCancel, dispatch, addr, geography, selectedGeographyState]);

  const handlePut = useCallback(async () => {
    setLoading((l) => !l);
    try {
      await Promise.all([
        handleNameChangePut(poiName) as Promise<any>,
        handleAddressChangePut(addr) as Promise<any>,
        selectedGeographyState && (handleGeographyChangePut(selectedGeographyState) as Promise<any>),
        handleCoordinatesPut(geography),
        handlePoiCategoryChangePut(poiCategoryId || 0),
      ]);
      if (onCancel) onCancel(undefined as any);
      setLoading((l) => !l);
      dispatch(setPois.request({}));
    } catch (error) {
      console.log(error);
    }
  }, [
    handleNameChangePut,
    handleAddressChangePut,
    addr,
    poiName,
    onCancel,
    selectedGeographyState,
    geography,
    handleGeographyChangePut,
    handleCoordinatesPut,
    dispatch,
    handlePoiCategoryChangePut,
    poiCategoryId,
  ]);

  const onGeographyChange = (geographyId: number | number[]) => {
    if (typeof geographyId === "number" || typeof geographyId === "undefined") {
      setSelectedGeographyState(geographyId);
    }
  };

  if (pois.loading) return null;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{ loading }}
      onOk={put ? handlePut : handleOkClick}
      destroyOnClose
      width={1100}
    >
      <Heading>Add new POI</Heading>
      <Divider />
      <S.NewOfferWrapper>
        <S.WrapperHalf>
          <S.InputName placeholder="Name" value={poiName} onChange={handlePoiNameChange} />
          <Heading level="4">Select POI category</Heading>
          <div hidden>{JSON.stringify(pois.data)}</div>
          <S.RadioGroup onChange={handleCategorySelect} defaultValue={26} value={poiCategoryId}>
            {pois.data.map((poi) => (
              <RadioButton key={poi.code} value={poi.id}>
                <Icon icon={icons[poi.name as IconTypes] || icons.pin} /> {poi.name}
              </RadioButton>
            ))}
          </S.RadioGroup>
        </S.WrapperHalf>
        <S.WrapperHalf>
          <S.GeographySelection>
            <SelectGeography
              multiple={false}
              treeCheckable={false}
              onChange={onGeographyChange}
              value={selectedGeographyState ? [selectedGeographyState] : []}
              treeNodeLabelProp="fullyQualifiedName"
            />
          </S.GeographySelection>
          <S.Input placeholder="Address" onChange={handleAddressChange} value={addr} />
          <Map
            longitude={geography[0]}
            latitude={geography[1]}
            zoom={zoom || zoomForPoland}
            height={500}
            width={500}
            draggable
            onDragEnd={handleDragEnd}
          />
        </S.WrapperHalf>
      </S.NewOfferWrapper>
    </Modal>
  );
};
