import React from "react";
import { FloorsValueAttribute } from "@api/client";
import { FloorPanel } from "./floors-and-modules/floors-panel";

interface FloorListProp {
  floors?: FloorsValueAttribute[];
}

export const FloorListForAttributeManager: React.FC<FloorListProp> = ({ floors }) => {
  if (!floors) {
    return null;
  }

  return (
    <>
      {floors
        .sort((floorA, floorB) => (floorA.floorsFrom ?? -1) - (floorB.floorsFrom ?? -1))
        .map((floor) => (
          <FloorPanel key={floor.attributeId} floor={floor} />
        ))}
    </>
  );
};
