/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FloorsDetails
 */
export interface FloorsDetails {
    /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
     Availability?: Floor;
    /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
     AvailableSpace?: Floor;
    /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
     ClearHeight?: Floor;
      /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
       ConstructionStatus?: Floor;
        /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
        TotalSize?: Floor;
     /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
    BuildingName?: Floor;
     /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
     floorsOriginal?: Floor;
      /**
     * 
     * @type {string}
     * @memberof FloorsDetails
     */
      AvailableOfficeSpace?: Floor;
}

interface Floor {
  unitName: string;
  value: string;
}


export function FloorsDetailsFromJSON(json: any): FloorsDetails {
    return FloorsDetailsFromJSONTyped(json, false);
}

export function FloorsDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloorsDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Availability': !exists(json, 'Availability') ? undefined : json['Availability'],
        'AvailableSpace': !exists(json, 'AvailableSpace') ? undefined : json['AvailableSpace'],
        'ClearHeight': !exists(json, 'ClearHeight') ? undefined : json['ClearHeight'],
        'ConstructionStatus': !exists(json, 'ConstructionStatus') ? undefined : json['ConstructionStatus'],
        'TotalSize': !exists(json, 'TotalSize') ? undefined : json['TotalSize'],
        'BuildingName': !exists(json, 'BuildingName') ? undefined : json['BuildingName'],
        'floorsOriginal': !exists(json, 'floorsOriginal') ? undefined : json['floorsOriginal'],
        'AvailableOfficeSpace': !exists(json, 'AvailableOfficeSpace') ? undefined : json['AvailableOfficeSpace'],
        
    };
}

export function FloorsDetailsToJSON(value?: FloorsDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Availability': value.Availability,
        'AvailableSpace': value.AvailableSpace,
        'ClearHeight': value.ClearHeight,
        'ConstructionStatus': value.ConstructionStatus,
        'TotalSize': value.TotalSize,
        'BuildingName': value.BuildingName,
        'floorsOriginal': value.floorsOriginal,
        'AvailableOfficeSpace': value.AvailableOfficeSpace,
    };
}


