import { Heading } from "@components/heading";
import React from "react";
import * as S from "./add-attributes.styled";

type IPopupContentColumn = {
  title: string;
};

export const PopupContentColumn: React.FC<IPopupContentColumn> = ({ title, children }) => (
  <S.PopupContentColumn>
    <Heading level="2"> {title} </Heading>
    {children}
  </S.PopupContentColumn>
);
