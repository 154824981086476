import message from "antd/lib/message";
import { AnyAction } from "redux";
import * as offersActions from "@state/offers/actions";
import * as attributesActions from "@state/attribures/actions";
import {
  all,
  put,
  select,
  takeLatest,
  call,
  AllEffect,
  ForkEffect,
  cancelled,
  debounce,
  StrictEffect,
} from "redux-saga/effects";
import { AppState } from "@state/store";
import { FiltersQuery } from "@state/filters/types";
import {
  setAllFilters,
  setPropertyCategoryCode,
  setPropertyStatusCode,
  setOfferStatusCode,
  setPropertyNameOrAddress,
  setPropertyGeographyIds,
  addCustomFilter,
  removeCustomFilter,
  setCustomFilterRequest,
  setRefreshFilters,
} from "@state/filters/actions";

import { getOfferApi } from "@api/core";
import { filters } from "@utils/filters";
import { CustomAttributeOut, QuickformAttributes } from "@api/client";

const effectsWithFiltersChanged = [
  setAllFilters,
  setPropertyCategoryCode,
  setPropertyStatusCode,
  setOfferStatusCode,
  setPropertyNameOrAddress,
  setPropertyGeographyIds,
  addCustomFilter,
  removeCustomFilter,
  setCustomFilterRequest,
];

const effectsWithoutFiltersChanged = [offersActions.refreshData, offersActions.fetchOffersAction.request];

const effectBeforeFetchOffers = [setRefreshFilters];

function* fetchPartOfferDetailsAction(action: AnyAction): Generator<any, any, any> {
  const { offerApi, abortController } = getOfferApi();
  const id = yield select((state: AppState) => state.offers.offerDetails.data?.offerId);
  const currentModule = yield select((state: AppState) => state.offers.currentModule);
  try {
    yield put(offersActions.fetchOfferDetails.request());
    const offerDetails = yield call(offerApi.getOfferApiV1OffersOfferIdGet.bind(offerApi), {
      offerId: id || +action.payload.id,
    });
    yield put(offersActions.fetchOfferDetails.success(offerDetails));
    if (currentModule !== offerDetails.module) {
      yield put(offersActions.setCurrentModule(offerDetails.module));
    }
  } catch (e) {
    console.error(e);
    yield put(offersActions.fetchOfferDetails.failure(e));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* fetchOfferAttributs(): Generator<
  StrictEffect,
  any,
  number & {
    quickformAttributes: QuickformAttributes;
    customAttributes: { [key: string]: CustomAttributeOut[] };
  }
> {
  const { offerApi, abortController } = getOfferApi();
  try {
    const offerId = yield select((state: AppState) => state.offers.offerDetails.data?.offerId);
    if (offerId) {
      const offerAttributes = yield call(
        offerApi.getOfferAttributesApiV1OffersOfferIdAttributesGet.bind(offerApi),
        {
          offerId: +offerId,
        }
      );
      yield put(offersActions.setAttributes.success(offerAttributes));
    }
  } catch (e) {
    console.error(e);
    yield put(offersActions.setAttributes.failure(e));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* changeRefreshingState(action: AnyAction) {
  if (effectsWithFiltersChanged.some((effect) => effect.toString() === action.type)) {
    yield put(setRefreshFilters(true));
  } else {
    yield put(setRefreshFilters(false));
  }
}

function* fetchOffers(): any {
  const { offerApi, abortController } = getOfferApi();
  const filtersQuery: FiltersQuery = yield select(
    (state: AppState) => state.filters[state.filters.currentModule].data
  );
  const refreshFilters = yield select(
    (state: AppState) => state.filters[state.filters.currentModule].data.refreshFilters
  );
  // eslint-disable-next-line prefer-const
  let { pageNumber, pageSize } = yield select(
    (state: AppState) => state.offers[state.offers.currentModule].pageSettings
  );

  pageNumber = refreshFilters ? 1 : pageNumber;

  const currentModule = yield select((state: AppState) => state.offers.currentModule);

  try {
    const [offers, totalOffers] = yield all([
      call(offerApi.getOffersApiV1OffersGet.bind(offerApi), {
        pageNumber,
        pageSize,
        module: currentModule,
        ...filters(filtersQuery),
      }),
      call(offerApi.getOffersCountApiV1OffersCountGet.bind(offerApi), {
        ...filters(filtersQuery),
        module: currentModule,
      }),
    ]);

    if (offers.length > pageSize) offers.pop();

    yield put(offersActions.setTotalOffers.success(totalOffers.offerCount));
    yield put(offersActions.setPageNumber(offers.length ? pageNumber + 1 : 1));
    yield put(
      offersActions.fetchOffersAction.success({
        offerBase: offers,
        emptyData: !offers.length,
        resetData: refreshFilters,
      })
    );
  } catch (error) {
    yield put(offersActions.setTotalOffers.failure(error));
    yield put(offersActions.fetchOffersAction.failure(error.toString()));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function showSuccess() {
  message.success("Offer has been updated");
}

export default function* (): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    debounce(300, [...effectsWithFiltersChanged, ...effectsWithoutFiltersChanged], changeRefreshingState),
    takeLatest([...effectBeforeFetchOffers], fetchOffers),
    takeLatest(
      [
        offersActions.triggerFetchDetails,
        offersActions.unassignPropertyPoi.success,
        offersActions.assignPropertyPoi.success,
      ],
      fetchPartOfferDetailsAction
    ),
    takeLatest(
      [
        offersActions.updateTitle.success,
        offersActions.updateDescription.success,
        offersActions.updateNote.success,
        offersActions.updateStatus.success,
        offersActions.updatePropertyGeography.success,
        offersActions.updatePropertyAddress.success,
        offersActions.updatePropertyName.success,
        offersActions.updatePropertyStatus.success,
      ],
      showSuccess
    ),
    takeLatest(
      [
        offersActions.addAttribute.success,
        offersActions.deleteAttribute.success,
        offersActions.deleteQuickformFloor.success,
        offersActions.deleteQuickformModule.success,
        offersActions.addAttribute.failure,
        offersActions.addQuickformAttribute.success,
        offersActions.updateAttribute.success,
        offersActions.setAttributeVisibility.success,
        offersActions.updateAttributeAfterFloorUpdate,
        attributesActions.translateAttribute.success,
      ],
      fetchOfferAttributs
    ),
  ]);
}

