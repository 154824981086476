/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommentWithUser
 */
export interface CommentWithUser {
    /**
     * 
     * @type {Date}
     * @memberof CommentWithUser
     */
    createDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CommentWithUser
     */
    commentId: number;
    /**
     * 
     * @type {string}
     * @memberof CommentWithUser
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof CommentWithUser
     */
    user: string;
}

export function CommentWithUserFromJSON(json: any): CommentWithUser {
    return CommentWithUserFromJSONTyped(json, false);
}

export function CommentWithUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentWithUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createDate': !exists(json, 'createDate') ? undefined : (new Date(json['createDate'])),
        'commentId': json['commentId'],
        'comment': json['comment'],
        'user': json['user'],
    };
}

export function CommentWithUserToJSON(value?: CommentWithUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createDate': value.createDate === undefined ? undefined : (value.createDate.toISOString()),
        'commentId': value.commentId,
        'comment': value.comment,
        'user': value.user,
    };
}


