import styled from "styled-components";
import { theme } from "@components/variables";

export const HeaderStyle = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;
  max-width: 1870px;
  margin: 0 auto;
`;

export const ImageWrapper = styled.div`
  height: fit-content;
  display: flex;
`;

export const DesignedFor = styled.span`
  font-size: 12px;
  color: ${theme.lightGrey};
  margin: 5px 10px 0 0;
  align-self: flex-start;
  text-align: right;
`;
