import React from "react";
import * as S from "./icon.styled";

type Props = {
  size?: number;
  icon: React.ComponentType;
  className?: string;
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const Icon: React.FC<Props> = ({ size, icon: Icon, className }) => {
  return (
    <S.Wrapper size={size} className={className}>
      <Icon />
    </S.Wrapper>
  );
};

Icon.defaultProps = {
  size: 20,
};
