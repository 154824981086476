import React from "react";
import { QuickformAttributes } from "@api/client";

import * as S from "../quick-form.styled";
import { AttributeValue as AttributeValueInput } from "../attribute-value";

import { HandleAddAttributeValue, HandleAttributeValueUpdate } from "../types";

type AttributeDataTypes = {
  string: string;
  numeric: string;
  date: string;
};

type Props = {
  quickformAttributes: QuickformAttributes;
  handleUpdateAttribute: HandleAttributeValueUpdate;
  handleAddAttribute?: HandleAddAttributeValue;
  attributeNames: any;
  attributeDataTypes: AttributeDataTypes;
};

export const RetailInputs: React.FC<Props> = ({
  quickformAttributes,
  handleUpdateAttribute,
  handleAddAttribute,
  attributeNames,
  attributeDataTypes,
}) => {
  return (
    <>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.unit?.id}
          attributeName={attributeNames.unit}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.unit?.valueOriginal}
          parentAttributeId={quickformAttributes.unit?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.unit?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.brand?.id}
          attributeName={attributeNames.brand}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.brand?.valueOriginal}
          parentAttributeId={quickformAttributes.brand?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.brand?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.category1?.id}
          attributeName={attributeNames.category1}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.category1?.valueOriginal}
          parentAttributeId={quickformAttributes.category1?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.category1?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.category2?.id}
          attributeName={attributeNames?.category2}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.category2?.valueOriginal}
          parentAttributeId={quickformAttributes.category2?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.category2?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.gLA?.id}
          attributeName={attributeNames.gla}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.gLA?.valueOriginal}
          parentAttributeId={quickformAttributes.gLA?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.gLA?.groupKey}
        />
      </S.HeadTableCell>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.salesArea?.id}
          attributeName={attributeNames.salesArea}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.salesArea?.valueOriginal}
          parentAttributeId={quickformAttributes.salesArea?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.salesArea?.groupKey}
        />
      </S.HeadTableCell>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.rent?.id}
          attributeName={attributeNames.rent}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.rent?.valueOriginal}
          parentAttributeId={quickformAttributes.rent?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.rent?.groupKey}
        />
      </S.HeadTableCell>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.date?.id}
          attributeName={attributeNames.date}
          attributeDataTypeCode={attributeDataTypes.date}
          currentValue={quickformAttributes.date?.valueOriginal}
          parentAttributeId={quickformAttributes.date?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.date?.groupKey}
        />
      </S.HeadTableCell>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.level?.id}
          attributeName={attributeNames.level}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.level?.valueOriginal}
          parentAttributeId={quickformAttributes.level?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.level?.groupKey}
        />
      </S.HeadTableCell>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.notes?.id}
          attributeName={attributeNames.notes}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.notes?.valueOriginal}
          parentAttributeId={quickformAttributes.notes?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.notes?.groupKey}
        />
      </S.HeadTableCell>
    </>
  );
};
