import React from "react";
import { FieldProps } from "formik";
import { Input as BaseInput } from "../../input";
import "./input.less";

type Props = FieldProps & {
  placeholder: string;
  suffix?: React.ReactNode;
};

export const Input: React.FC<Props> = ({ field, meta, placeholder, suffix }) => (
  <div>
    <BaseInput {...field} suffix={suffix} placeholder={placeholder} />
    {meta.error && meta.touched && <div className="ant-input-error">{meta.error}</div>}
  </div>
);
