import React, { useMemo } from "react";
import { scaleLinear } from "d3-scale";
import { max } from "d3-array";
import { useChartDimensions, Dimensions } from "@utils/hooks/use-chart-dimensions";
import { Axis } from "@components/axis";
import { Bin, Value } from "./bin";
import "./histogram.less";

interface Props {
  data: {
    binEdges: number[];
    binHeights: number[];
    valueMin: number;
    valueMax: number;
  };
  showAxis?: boolean;
  onBinClick?: (value: Value) => void;
}

const barPadding = 1;

export const Histogram: React.FC<Dimensions & Props> = ({
  data,
  width,
  height,
  showAxis,
  onBinClick,
  ...dimensionsProps
}) => {
  const { setElement, dimensions } = useChartDimensions({ width, height, ...dimensionsProps });

  const xScale = useMemo(
    () => scaleLinear().range([0, dimensions.boundedWidth]).domain([data.valueMin, data.valueMax]),
    [data, dimensions]
  );

  const bins = useMemo(
    () =>
      data.binHeights.map((binHeight, i) => ({
        height: binHeight,
        x0: data.binEdges[i],
        x1: data.binEdges[i + 1],
      })),
    [data]
  );

  const yScale = useMemo(
    () =>
      scaleLinear()
        .domain([0, max(data.binHeights) as number])
        .range([dimensions.boundedHeight, 0]),
    [data, dimensions]
  );

  return (
    <div ref={setElement} style={{ height, width }}>
      <svg width={dimensions.width} height={dimensions.height}>
        <g
          style={{
            transform: `translate(${dimensions.marginLeft}px, ${dimensions.marginTop}px)`,
            maxWidth: dimensions.boundedWidth,
            maxHeight: dimensions.boundedHeight,
          }}
        >
          {bins.map((bin) => {
            const scaledWidth = dimensions.boundedWidth / data.binHeights.length - barPadding;
            const passedWidth = scaledWidth > dimensions.boundedWidth ? dimensions.boundedWidth : scaledWidth;
            return (
              <Bin
                x={xScale(bin.x0) + barPadding / 2}
                y={yScale(bin.height)}
                width={max([1, passedWidth]) as number}
                height={dimensions.boundedHeight - yScale(bin.height)}
                yStart={dimensions.boundedHeight}
                onClick={onBinClick}
                value={{ binLow: bin.x0, binHigh: bin.x1 }}
                quantity={bin.height}
                key={bin.x0}
              />
            );
          })}
        </g>
        {showAxis && (
          <Axis
            xScale={xScale}
            width={dimensions.boundedWidth}
            position={{ left: dimensions.marginLeft, top: dimensions.boundedHeight + dimensions.marginTop }}
            data={data.binEdges}
          />
        )}
      </svg>
    </div>
  );
};
