import styled from "styled-components";
import { theme } from "@components/variables";
import { Button } from "@components/button";
import { RadioButton as RadioButtonCore } from "@components/radio-button";

export const RadioWrapper = styled.div`
  overflow-y: scroll;
  max-height: 300px;
  margin-top: 20px;
  border: 1px solid ${theme.grey};
  border-radius: 2px;
  padding-left: 10px;
`;

export const CopyButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

export const RadioButton = styled(RadioButtonCore)`
  padding: 5px 0;
`;

export const InputWrapper = styled.div`
  margin: 10px 0;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.red};
`;
