import { lettingsApi } from "@api/core";
import { useMutation } from "react-query";
import React from "react";
import { useSelector } from "react-redux";
import { AddLettingApiV1LettingsPropertyIdTenantIdPostRequest } from "@api/client";
import { GenericAddButton } from "./generic-add-button";

interface Props {
  tenantId: number;
  onAdd: () => void;
}

export const AddLetting: React.FC<Props> = ({ tenantId, onAdd }) => {
  const propertyId = useSelector((state) => state.offers.offerDetails.data?.propertyId) || 0;
  const postMethod = lettingsApi.addLettingApiV1LettingsPropertyIdTenantIdPost.bind(lettingsApi);
  const postParams: AddLettingApiV1LettingsPropertyIdTenantIdPostRequest = {
    tenantId,
    propertyId,
  };

  const { mutate, isLoading } = useMutation(() => postMethod({ ...postParams }), {
    onSuccess: () => {
      onAdd();
    },
  });

  return (
    <GenericAddButton onClick={mutate} isLoading={isLoading}>
      Add Letting
    </GenericAddButton>
  );
};
