import React, { ChangeEvent } from "react";
import { ArrayHelpers } from "formik";
import { DictBase } from "@api/client";
import * as S from "./button-checkbox.styled";

type Props = DictBase & {
  allValues: string[];
  arrayHelpers?: ArrayHelpers;
  onChange?: () => void;
};

export const ButtonCheckbox: React.FC<Props & S.StylingProps> = ({
  code,
  allValues,
  arrayHelpers,
  name,
  grow,
  onChange,
}) => {
  const onChangeHandler =
    onChange ||
    (arrayHelpers &&
      ((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) arrayHelpers.push(code);
        else {
          const idx = allValues.indexOf(code);
          arrayHelpers.remove(idx);
        }
      }));

  return (
    <S.StyledContainer grow={grow}>
      <S.StyledInput
        id={`${name}_${code}`}
        type="checkbox"
        value={code}
        checked={allValues.includes(code)}
        onChange={onChangeHandler}
      />
      <S.Label title={name} htmlFor={`${name}_${code}`}>
        {name}
      </S.Label>
    </S.StyledContainer>
  );
};
