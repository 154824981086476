export const czOffice = {
  available_space: "Volné prostory",
  park_details: "Informace o budově",
  park_desc: "Popis budovy",
  park_location_desc: "Lokalita",
  available_units: "Volné jednotky",
  other_attributes: "Vybavenost",
  map: "Mapa",
  gallery: "Fotogalerie",
  contact: "Kontakt",
  /* aut - Available units table */
  aut: {
    building: "Hala",
    total_size: "Celková rozloha",
    available_size: "Volná plocha",
    available_from: "Dostupné od",
    clear_height: "Čistá výška",
    construction_status: "Stav výstavby",
  },
  your_building_list: "Váš výběr",
  more_info: "VÍCE",
  attachments: "Přílohy",
  floors: {
    available_areas: "Volné prostory",
    floor: "Patro",
    net_area: "Čistá plocha",
    availability: "Dostupnost",
  },
};
