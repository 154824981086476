import { createReducer } from "typesafe-actions";
import { AttributesState } from "./types";
import {
  fetchAttributes,
  fetchDistinctUnits,
  fetchDistribution,
  setCustomFilter,
  setCustomFilterValue,
  changeSearchType,
  setCustomFilterUnit,
  setShowModalToAddCustomFilter,
  setCustomFilterFloors,
  setCustomFilterModules,
  setCustomFilterKey,
  setCustomFilterSearchType,
  setVisibleFloorSlider,
  setVisibleModuleSlider,
  setVisibleValueSlider,
  resetCustomFilterValue,
} from "./actions";

const initialState: AttributesState = {
  data: [],
  loading: false,
  loadingDistribution: false,
  distinctUnits: {},
  distribution: {},
  customFilter: {
    key: "",
    value: "",
    modalActive: false,
    isValueFilterActivie: true,
    isFloorFilterActivie: false,
    isModuleFilterActivie: false,
  },
};

type AttributesAction = {
  type: string;
};

export const attributesReducer = createReducer<AttributesState, AttributesAction>(initialState)
  .handleAction(fetchAttributes.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchAttributes.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchAttributes.success, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload,
  }))
  .handleAction(fetchDistinctUnits.success, (state, action) => ({
    ...state,
    distinctUnits: {
      [action.payload.code]: action.payload.units || [],
    },
    customFilter: { ...state.customFilter, valueUnit: action.payload.baseUnitOfMeasure },
  }))
  .handleAction(fetchDistribution.success, (state, action) => ({
    ...state,
    loadingDistribution: false,
    distribution: {
      [action.payload.params.attributeCode]: {
        ...action.payload.response,
      },
    },
  }))
  .handleAction(fetchDistribution.request, (state) => ({
    ...state,
    loadingDistribution: true,
  }))
  .handleAction(fetchDistribution.failure, (state) => ({
    ...state,
    loadingDistribution: false,
  }))
  .handleAction(setCustomFilter, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      ...action.payload,
    },
  }))
  .handleAction(setCustomFilterValue, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      ...action.payload,
    },
  }))
  .handleAction(setCustomFilterUnit, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      valueUnit: action.payload,
    },
  }))
  .handleAction(changeSearchType, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      searchType: action.payload,
    },
  }))
  .handleAction(setShowModalToAddCustomFilter, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      modalActive: action.payload,
    },
  }))
  .handleAction(setCustomFilterFloors, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      floors: action.payload,
    },
  }))
  .handleAction(setCustomFilterModules, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      modules: action.payload,
    },
  }))
  .handleAction(setCustomFilterKey, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      key: action.payload,
    },
  }))
  .handleAction(setCustomFilterSearchType, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      searchType: action.payload,
    },
  }))
  .handleAction(setVisibleFloorSlider, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      isFloorFilterActivie: action.payload,
    },
  }))
  .handleAction(setVisibleModuleSlider, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      isModuleFilterActivie: action.payload,
    },
  }))
  .handleAction(setVisibleValueSlider, (state, action) => ({
    ...state,
    customFilter: {
      ...state.customFilter,
      isValueFilterActivie: action.payload,
    },
  }))
  .handleAction(resetCustomFilterValue, (state) => {
    console.log("resetCustomFilterValue");
    return {
      ...state,
      customFilter: { ...initialState.customFilter },
    };
  });
