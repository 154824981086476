import { setCustomFilterValue } from "@state/attribures/actions";
import { Dispatch } from "redux";

interface HandlersGeneratorProps {
  dispatch: Dispatch<any>;
  groupValue: string | string[];
  floors?: number[];
  modules?: number[];
}

const handlersFloorGenerator = ({ dispatch, groupValue, floors, modules }: HandlersGeneratorProps) => {
  if (!floors) {
    return null;
  }
  const onFloorsSliderValueChange = (value: number[]) => {
    dispatch(
      setCustomFilterValue({
        value: groupValue,
        floors: value,
        modules,
      })
    );
  };

  const onFloorsLowerInputChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setCustomFilterValue({
        value: groupValue,
        floors: [Number(value.target.value), floors?.[1] || Number(value.target.value)],
        modules,
      })
    );
  };

  const onFloorsUpperInputChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setCustomFilterValue({
        value: groupValue,
        floors: [floors?.[0] || Number(value.target.value), Number(value.target.value)],
        modules,
      })
    );
  };
  return {
    onFloorsSliderValueChange,
    onFloorsLowerInputChange,
    onFloorsUpperInputChange,
  };
};

const handlersModulesGenerator = ({ dispatch, groupValue, floors, modules }: HandlersGeneratorProps) => {
  if (!modules) {
    return null;
  }
  const onModulesSliderValueChange = (value: number[]) => {
    dispatch(
      setCustomFilterValue({
        value: groupValue,
        floors,
        modules: value,
      })
    );
  };

  const onModulesLowerInputChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setCustomFilterValue({
        value: groupValue,
        floors,
        modules: [Number(value.target.value), modules?.[1] || Number(value.target.value)],
      })
    );
  };

  const onModulesUpperInputChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setCustomFilterValue({
        value: groupValue,
        floors,
        modules: [modules?.[0] || Number(value.target.value), Number(value.target.value)],
      })
    );
  };
  return {
    onModulesSliderValueChange,
    onModulesLowerInputChange,
    onModulesUpperInputChange,
  };
};

const handlersFloorsAndModulesGenerator = (
  params: Parameters<typeof handlersFloorGenerator>[0] & Parameters<typeof handlersModulesGenerator>[0]
) => {
  return { ...handlersFloorGenerator(params), ...handlersModulesGenerator(params) };
};

export default handlersFloorsAndModulesGenerator;
