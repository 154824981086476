import { LoginWrapper } from "@components/login-wrapper/login-wrapper.styles";
import { Login } from "@microsoft/mgt-react";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Header } from "../../layout/header/header";

import * as S from "../../layout/root/root.styles";

export const CustomeLogin: React.FC = () => (
  <Router>
    <S.Background>
      <Header />
      <LoginWrapper>
        <Login />
      </LoginWrapper>
    </S.Background>
  </Router>
);
