/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyBase
 */
export interface PropertyBase {
    /**
     * 
     * @type {number}
     * @memberof PropertyBase
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyBase
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyBase
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyBase
     */
    geoLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBase
     */
    geoLongitude?: number;
}

export function PropertyBaseFromJSON(json: any): PropertyBase {
    return PropertyBaseFromJSONTyped(json, false);
}

export function PropertyBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'geoLatitude': !exists(json, 'geoLatitude') ? undefined : json['geoLatitude'],
        'geoLongitude': !exists(json, 'geoLongitude') ? undefined : json['geoLongitude'],
    };
}

export function PropertyBaseToJSON(value?: PropertyBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'geoLatitude': value.geoLatitude,
        'geoLongitude': value.geoLongitude,
    };
}


