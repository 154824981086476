import React, { useMemo } from "react";
import { ScaleLinear } from "d3-scale";
import { max } from "d3-array";

export interface Props {
  xScale: ScaleLinear<number, number, never>;
  width: number;
  position: {
    left: number;
    top: number;
  };
  data: number[];
}

const maxLength = 10;
const maxValueLength = 1000;

export const Axis: React.FC<Props> = ({ xScale, width, position, data }) => {
  const range = xScale.range();

  const ticks = useMemo(() => {
    const pixelsPerTick = 30;

    if (data?.length && data.length < 15) {
      return data.map((value, i) => ({
        value,
        xOffset: xScale(data[i]),
      }));
    }

    const numberOfTicksTarget = Math.max(1, Math.floor(width / pixelsPerTick));

    return xScale
      .nice()
      .ticks(numberOfTicksTarget)
      .map((value) => ({
        value,
        xOffset: xScale(value),
      }));
  }, [xScale, width, data]);

  const rotateTicks =
    (data && data?.length > maxLength) || (data && String(max(data)).length > maxValueLength);

  const transorm = () => {
    if (rotateTicks) return "translateY(20px) rotate(-45deg)";
    return "translateY(20px)";
  };

  return (
    <g transform={`translate(${position.left}, ${position.top})`}>
      <path
        d={["M", range[0], 6, "v", -6, "H", range[1], "v", 6].join(" ")}
        fill="none"
        stroke="currentColor"
      />
      {ticks.map(({ value, xOffset }) => (
        <g key={value} transform={`translate(${xOffset}, 0)`}>
          <line y2="6" stroke="currentColor" />
          <text
            key={value}
            style={{
              fontSize: "10px",
              textAnchor: "middle",
              transform: transorm(),
            }}
          >
            {/* eslint-disable-next-line no-restricted-globals */}
            {!isNaN(value) && Math.round(value * 100) / 100}
          </text>
        </g>
      ))}
    </g>
  );
};
