import React from "react";
import { Train } from "@styled-icons/boxicons-solid/Train";
import { Bus } from "@styled-icons/fa-solid/Bus";
import { PlaneAlt } from "@styled-icons/boxicons-solid/PlaneAlt";
import { Subway } from "@styled-icons/material-outlined/Subway";
import { ShoppingBags } from "@styled-icons/boxicons-solid/ShoppingBags";
import { Tree } from "@styled-icons/boxicons-solid/Tree";
import { Bed } from "@styled-icons/ionicons-outline/Bed";
import { Museum } from "@styled-icons/material-outlined/Museum";
import { Building } from "@styled-icons/bootstrap/Building";
import { SpoonKnife } from "@styled-icons/icomoon/SpoonKnife";
import { Cup } from "@styled-icons/remix-fill/Cup";
import { PlusMedical } from "@styled-icons/boxicons-regular/PlusMedical";
import { University } from "@styled-icons/fa-solid/University";
import { Hospital } from "@styled-icons/fa-regular/Hospital";
import { Road } from "@styled-icons/fa-solid/Road";
import { MoneyEuroCircle } from "@styled-icons/remix-fill/MoneyEuroCircle";
import { GasPump } from "@styled-icons/boxicons-regular/GasPump";
import { SportsFootball } from "@styled-icons/material/SportsFootball";
import { QuestionMark } from "@styled-icons/boxicons-regular/QuestionMark";
import { ReactComponent as Pin } from "@svg/icon-pin-red-google.svg";
import { ReactComponent as SelectedPin } from "@svg/icon-pin-yellow-poi.svg";

export const theme = {
  primaryColor: "#e95f20",
  shadowBlue: "#ebf9ff", // this is not a correct shadow
  white: "#fff",
  tableBorder: "#bfbfbf",
  grey: "#d3d3d3",
  grey2: "#f0f1f5",
  lightTeal: "#f7f8fa",
  darkBlue: "#001744",
  darkGrey: "#47526c",
  greyBlue: "#272937",
  lightGrey: "#5f6368",
  lightGrey2: "#eff0f4",
  orange2: "#e26127",
  blue: "#5390F5",
  lightBlue: "#68769c",
  warning: "#f1950a",
  fontLight: 300,
  fontMedium: 400,
  fontBold: 600,
  borderColor: "#9ba2aa",
  borderColorNotActive: "#9ba2aa",
  red: "#ff4d4f",
  red2: "#cd171e",
  transparenPrimaryColor: "#e95f2020",
  darkBlue2: "#25283B",
  yellow: "#ffdf00",
};

export type IconTypes =
  | "pin"
  | "SelectedPin"
  | "Railway station"
  | "Airport"
  | "Bus station"
  | "Subway"
  | "Shopping mall"
  | "Park"
  | "Hotel"
  | "Museum"
  | "Office"
  | "Restaurant"
  | "Coffee shop"
  | "Pharmacy"
  | "University"
  | "Hospital"
  | "Highway"
  | "Bank/ATM"
  | "Gas Station"
  | "Sport venue"
  | "Other";

export const icons: Record<IconTypes, React.ComponentType> = {
  pin: Pin,
  SelectedPin,
  "Railway station": Train,
  Airport: PlaneAlt,
  "Bus station": Bus,
  Subway,
  "Shopping mall": ShoppingBags,
  Park: Tree,
  Hotel: Bed,
  Museum,
  Office: Building,
  Restaurant: SpoonKnife,
  "Coffee shop": Cup,
  Pharmacy: PlusMedical,
  University,
  Hospital,
  Highway: Road,
  "Bank/ATM": MoneyEuroCircle,
  "Gas Station": GasPump,
  "Sport venue": SportsFootball,
  Other: QuestionMark,
};

export const centerOfPoland = {
  longitude: 19.1344,
  latitude: 51.9189,
};

export const zoomForPoland = 5;
