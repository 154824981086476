/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditTrailAttributeFloor
 */
export interface AuditTrailAttributeFloor {
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeFloor
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeFloor
     */
    valueOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeFloor
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuditTrailAttributeFloor
     */
    timeInterval: Date;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailAttributeFloor
     */
    floorsOriginal?: string;
}

export function AuditTrailAttributeFloorFromJSON(json: any): AuditTrailAttributeFloor {
    return AuditTrailAttributeFloorFromJSONTyped(json, false);
}

export function AuditTrailAttributeFloorFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditTrailAttributeFloor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'valueOriginal': !exists(json, 'valueOriginal') ? undefined : json['valueOriginal'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'timeInterval': (new Date(json['timeInterval'])),
        'floorsOriginal': !exists(json, 'floorsOriginal') ? undefined : json['floorsOriginal'],
    };
}

export function AuditTrailAttributeFloorToJSON(value?: AuditTrailAttributeFloor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'valueOriginal': value.valueOriginal,
        'createdBy': value.createdBy,
        'timeInterval': (value.timeInterval.toISOString()),
        'floorsOriginal': value.floorsOriginal,
    };
}


