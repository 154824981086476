/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OfferDetailClient,
    OfferDetailClientFromJSON,
    OfferDetailClientFromJSONTyped,
    OfferDetailClientToJSON,
    PoiProperty,
    PoiPropertyFromJSON,
    PoiPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface OfferDetailsClient
 */
export interface OfferDetailsClient {
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsClient
     */
    mainLocation: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsClient
     */
    secondaryLocation: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsClient
     */
    clientsName: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsClient
     */
    module?: string;
    /**
     * 
     * @type {Array<OfferDetailClient>}
     * @memberof OfferDetailsClient
     */
    offerDetailClient: Array<OfferDetailClient>;
    
     /**
     * 
     * @type {Array<PoiProperty>}
     * @memberof OfferDetailsClient
     */
     pois: Array<PoiProperty>;
}

export function OfferDetailsClientFromJSON(json: any): OfferDetailsClient {
    return OfferDetailsClientFromJSONTyped(json, false);
}

export function OfferDetailsClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferDetailsClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mainLocation': json['mainLocation'],
        'secondaryLocation': json['secondaryLocation'],
        'clientsName': json['clientsName'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'offerDetailClient': ((json['OfferDetailClient'] as Array<any>).map(OfferDetailClientFromJSON)),
        'pois': ((json['pois'] as Array<any>).map(PoiPropertyFromJSON)),
    };
}

export function OfferDetailsClientToJSON(value?: OfferDetailsClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mainLocation': value.mainLocation,
        'secondaryLocation': value.secondaryLocation,
        'clientsName': value.clientsName,
        'module': value.module,
        'OfferDetailClient': ((value.offerDetailClient as Array<any>).map(OfferDetailClientToJSON)),
        'pois': ((value.pois as Array<any>).map(PoiPropertyToJSON)),
    };
}


