import React from "react";
import { Popconfirm } from "antd";

import { Floors, Module } from "@api/client";
import { useToggle } from "@hooks/use-toggle";
import { usePermissions } from "@hooks/use-permission";
import { DeleteButton } from "@components/delete-button";

import { HandleAttributeValueUpdate, HandleDeleteModule } from "../types";
import * as S from "../quick-form.styled";
import { AttributeModuleValue } from "../attribute-module-value";

interface CollapsibleTableRowProps {
  module: Module;
  floor: Floors;
  onAttributeUpdate: HandleAttributeValueUpdate;
  onDeleteModule: HandleDeleteModule;
}

export const CollapsibleTableRowIndustrial = ({
  module,
  floor,
  onAttributeUpdate,
  onDeleteModule,
}: CollapsibleTableRowProps) => {
  const { state: showModulePopconfirm, toggle: toggleModulePopconfirm } = useToggle();
  const { hasPermission } = usePermissions();

  return (
    <>
      <S.CollapseTableRow key={module?.module}>
        <S.TableCell style={{ fontSize: "13px", textAlign: "right" }}>{module?.module}</S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.buildingName?.id}
            currentValue={module.buildingName?.valueOriginal}
            parentAttributeId={floor.buildingName?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.buildingName?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.availableSpace?.id}
            currentValue={module.availableSpace?.valueOriginal}
            parentAttributeId={floor.availableSpace?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.availableSpace?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.availability?.id}
            currentValue={module.availability?.valueOriginal}
            parentAttributeId={floor.availability?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.availability?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.clearHeight?.id}
            currentValue={module.clearHeight?.valueOriginal}
            parentAttributeId={floor.clearHeight?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.clearHeight?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.columnGrid?.id}
            currentValue={module.columnGrid?.valueOriginal}
            parentAttributeId={floor.columnGrid?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.columnGrid?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.floorLoadingCapacity?.id}
            currentValue={module.floorLoadingCapacity?.valueOriginal}
            parentAttributeId={floor.floorLoadingCapacity?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.floorLoadingCapacity?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.documentComments?.id}
            currentValue={module.documentComments?.valueOriginal}
            parentAttributeId={floor.documentComments?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.documentComments?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.internalComments?.id}
            currentValue={module.internalComments?.valueOriginal}
            parentAttributeId={floor.internalComments?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.internalComments?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <Popconfirm
            title="Are you sure that you want to delete this module?"
            visible={showModulePopconfirm}
            onConfirm={() => {
              onDeleteModule(floor.floorsOriginal, module.module.toString());
              toggleModulePopconfirm();
            }}
            onCancel={toggleModulePopconfirm}
          >
            <DeleteButton onClick={toggleModulePopconfirm} disabled={!hasPermission("DELETE_ATTRIBUTE")} />
          </Popconfirm>
        </S.TableCell>
      </S.CollapseTableRow>
    </>
  );
};
