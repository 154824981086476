import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { fetchOffersAction, resetData, setPageNumber } from "@state/offers/actions";

import { Loader } from "@components/loader";
import { Empty } from "@components/empty";
import * as S from "@components/table";

import { VariousThingsGeneratorOverTable } from "./variuos-things-generator-over-table";
import { TableHead } from "./table-head";
import { TableRow } from "./table-row";
import { Property } from "./types";

const options = {
  root: null,
  threshold: 1,
};

export const MainTable: React.FC = () => {
  const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);
  const [loader, setLoader] = useState<HTMLDivElement | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(true);
  const { offers, pageSettings } = useSelector((state) => state.offers[state.offers.currentModule]);
  const currentModule = useSelector((state) => state.offers.currentModule);
  const { isPickingFromMainTable } = useSelector((state) => state.newsletterGenerateBreakdownReportReducer);
  const dispatch = useDispatch();
  const totalPages = Math.ceil(offers.totalOffers / pageSettings.pageSize);
  const hasMorePages = totalPages >= pageSettings.pageNumber;
  const canLoad = !offers.loading && hasMorePages;

  const fetchOffers = useCallback(() => dispatch(fetchOffersAction.request()), [dispatch]);
  const handleSelectOffer = useCallback(
    (event: CheckboxChangeEvent) => {
      const { name, checked, value } = event.target;

      if (checked && name)
        setSelectedProperties((prevProperties) => [...prevProperties, { name, id: value }]);
      else setSelectedProperties((prevIds) => prevIds.filter(({ id }) => id !== value));
    },
    [setSelectedProperties]
  );

  const handleDeleteOffer = (e: any) => {
    setSelectedProperties((proeprties) => proeprties.filter(({ id }) => id !== Number(e.target.value)));
  };

  useEffect(() => {
    setSelectedProperties([]);
  }, [isPickingFromMainTable]);

  useEffect(() => {
    fetchOffers();
    return () => {
      dispatch(resetData());
      dispatch(setPageNumber(1));
    };
    // this is componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIntersecting && canLoad) fetchOffers();
    // this has to trigger only if is intersecting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    if (loader) {
      const observer = new IntersectionObserver((entry: IntersectionObserverEntry[]) => {
        setIsIntersecting(entry[0].isIntersecting);
      }, options);
      observer.observe(loader);

      return () => {
        if (loader) return observer.unobserve(loader);
        return undefined;
      };
    }
    return undefined;
  }, [loader]);

  const selectedPropertiesIds = selectedProperties.map(({ id }) => id);
  const isSelectAllChecked =
    !!selectedProperties.length &&
    offers.data.length === selectedProperties.length &&
    selectedProperties.every((property) => offers.data.some(({ offerId }) => property.id === offerId));

  const selectAllProperties = () => {
    let properties: Property[] = [];
    if (!isSelectAllChecked)
      properties = offers.data.map((offer) => ({ id: offer.offerId, name: offer.offerName }));

    setSelectedProperties(properties);
  };

  return (
    <>
      {currentModule !== "retail" && (
        <VariousThingsGeneratorOverTable
          properties={selectedProperties}
          onDeleteProperty={handleDeleteOffer}
        />
      )}

      {!offers.data.length && !offers.loading ? (
        <Empty />
      ) : (
        <S.Table>
          <TableHead selectAllChecked={isSelectAllChecked} onSelectAll={selectAllProperties} />
          {offers.data.map((offer) => (
            <TableRow
              ids={selectedPropertiesIds}
              {...offer}
              key={offer.offerId}
              onSelect={handleSelectOffer}
            />
          ))}
          <div ref={setLoader}>{offers.loading && <Loader />}</div>
        </S.Table>
      )}
    </>
  );
};
