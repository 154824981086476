/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoiOutAddressAndCoordinates
 */
export interface PoiOutAddressAndCoordinates {
    /**
     * 
     * @type {string}
     * @memberof PoiOutAddressAndCoordinates
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof PoiOutAddressAndCoordinates
     */
    geoLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PoiOutAddressAndCoordinates
     */
    geoLongitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PoiOutAddressAndCoordinates
     */
    poiId: number;
    /**
     * 
     * @type {Date}
     * @memberof PoiOutAddressAndCoordinates
     */
    modificationDate: Date;
}

export function PoiOutAddressAndCoordinatesFromJSON(json: any): PoiOutAddressAndCoordinates {
    return PoiOutAddressAndCoordinatesFromJSONTyped(json, false);
}

export function PoiOutAddressAndCoordinatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiOutAddressAndCoordinates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'geoLatitude': !exists(json, 'geoLatitude') ? undefined : json['geoLatitude'],
        'geoLongitude': !exists(json, 'geoLongitude') ? undefined : json['geoLongitude'],
        'poiId': json['poiId'],
        'modificationDate': (new Date(json['modificationDate'])),
    };
}

export function PoiOutAddressAndCoordinatesToJSON(value?: PoiOutAddressAndCoordinates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'geoLatitude': value.geoLatitude,
        'geoLongitude': value.geoLongitude,
        'poiId': value.poiId,
        'modificationDate': (value.modificationDate.toISOString()),
    };
}


