import React, { SyntheticEvent, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { Popover, message } from "antd";

import { Button } from "@components/button";
import { Input } from "@components/input";
import { useToggle } from "@hooks/use-toggle";

import { offerApi } from "@api/core";
import { NewsletterLocation } from "@api/client/models";

interface TemplateFormProps {
  inputValue: string;
  setInputValue: (e: string) => void;
  submitForm: () => void;
  isLoading: boolean;
}

interface TemplateButtonProps {
  locations: NewsletterLocation[];
  canAddTemplate: boolean;
}

const TemplateForm = ({ inputValue, setInputValue, submitForm, isLoading }: TemplateFormProps) => {
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    submitForm();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <Button loading={isLoading} type="primary" size="small" htmlType="submit">
        Send template
      </Button>
    </form>
  );
};

export const TemplateButton = ({ locations, canAddTemplate }: TemplateButtonProps) => {
  const { state: isTemplateOpen, toggle: setIsTemplateOpen } = useToggle(false);
  const currentModule = useSelector((appState) => appState.offers.currentModule);
  const [template, setTemplate] = useState("");
  const templateMutation = useMutation(
    () =>
      offerApi.postNewsletterSchemaApiV1OffersNewsletterSchemaPost({
        newsletterSchemaIn: { name: template, locations, module: currentModule },
      }),
    {
      onSuccess: () => {
        message.success(`Successfully added ${template} template!`);
        setTemplate("");
        setIsTemplateOpen();
      },
      onError: () => {
        message.error("Something went wrong!");
      },
    }
  );
  return (
    <Popover
      visible={isTemplateOpen}
      title="Enter template name"
      content={
        <TemplateForm
          inputValue={template}
          setInputValue={setTemplate}
          submitForm={templateMutation.mutate}
          isLoading={templateMutation.isLoading}
        />
      }
    >
      <Button type="primary" onClick={setIsTemplateOpen} disabled={!canAddTemplate}>
        Save template
      </Button>
    </Popover>
  );
};
