import { Dispatch } from "redux";
import { PayloadActionCreator } from "typesafe-actions";

export const createApiAction = <T extends unknown, P extends unknown>(
  action: {
    request: PayloadActionCreator<string, any>;
    success: PayloadActionCreator<string, P>;
    failure: PayloadActionCreator<string, Error>;
  },
  apiAction: (...params: T[]) => Promise<P>
) => (dispatch: Dispatch) => async (...params: T[]) => {
  dispatch(action.request(params[0]));
  try {
    const response = await apiAction(...params);
    return dispatch(action.success(response));
  } catch (e) {
    console.error(e);
    return dispatch(action.failure(e));
  }
};
