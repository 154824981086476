import React, { useState } from "react";
import { useSelector } from "react-redux";

import { AutoComplete, message } from "antd";
import { useQuery, useMutation } from "react-query";

import { offerApi } from "@api/core";

import { Loader } from "@components/loader";
import { DeleteButton } from "@components/delete-button";

const { Option } = AutoComplete;

interface LoadTemplatesProps {
  setSelectedTemplate: (value: number) => void;
  canLoadTemplates: boolean;
  canDeleteTemplate: boolean;
}

export const LoadTemplates = ({
  setSelectedTemplate,
  canLoadTemplates,
  canDeleteTemplate,
}: LoadTemplatesProps) => {
  const [inputValue, setInputValue] = useState("");
  const currentModule = useSelector((appState) => appState.offers.currentModule);
  const getTemplates = useQuery(`newsletter-or-property-particular-templates-${currentModule}`, () => {
    if (canLoadTemplates)
      return offerApi.getNewsletterSchemasApiV1OffersNewsletterSchemaGet({ module: currentModule });
  });
  const deleteTemplate = useMutation(
    (schemaId: number) =>
      offerApi.deleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDelete({ schemaId }),
    {
      onSuccess: () => {
        message.success("Successfully deleted template!");
        getTemplates.refetch();
      },
      onError: () => {
        message.error("Something went wrong!");
      },
    }
  );

  if ((!getTemplates.data && !getTemplates.isLoading) || !getTemplates.data?.length) return null;
  if (getTemplates.isLoading) return <Loader />;

  const options = inputValue
    ? getTemplates.data
        .filter((template) => template.name.includes(inputValue))
        .map(({ id, name }) => ({ key: id, text: id, value: name }))
    : getTemplates.data.map(({ id, name }) => ({ key: id, text: id, value: name }));
  return (
    <AutoComplete
      value={inputValue}
      onChange={setInputValue}
      placeholder="Search for template"
      style={{ width: "100%" }}
      onSelect={(_, { name, value }) => {
        setInputValue(name);
        setSelectedTemplate(value);
      }}
    >
      {options.map(({ key, text, value }) => {
        const handleClick = () => deleteTemplate.mutate(text);
        return (
          <Option key={key} value={text} name={value}>
            {value}
            <DeleteButton onClick={handleClick} disabled={!canDeleteTemplate} />
          </Option>
        );
      })}
    </AutoComplete>
  );
};
