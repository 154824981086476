/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppSchemasLettingsFloorIn,
    AppSchemasLettingsFloorInFromJSON,
    AppSchemasLettingsFloorInToJSON,
    AppSchemasLettingsFloorOut,
    AppSchemasLettingsFloorOutFromJSON,
    AppSchemasLettingsFloorOutToJSON,
    AttributeValue,
    AttributeValueFromJSON,
    AttributeValueToJSON,
    BaseAttributeIn,
    BaseAttributeInFromJSON,
    BaseAttributeInToJSON,
    CustomLettingAttributeOut,
    CustomLettingAttributeOutFromJSON,
    CustomLettingAttributeOutToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    Letting,
    LettingFromJSON,
    LettingToJSON,
    LettingOut,
    LettingOutFromJSON,
    LettingOutToJSON,
    PutLettingAttributesOut,
    PutLettingAttributesOutFromJSON,
    PutLettingAttributesOutToJSON,
} from '../models';

export interface AddLettingApiV1LettingsPropertyIdTenantIdPostRequest {
    propertyId: number;
    tenantId: number;
}

export interface DeleteLettingApiV1LettingsLettingIdDeleteRequest {
    lettingId: number;
}

export interface DeleteLettingAttributeApiV1LettingsAttributesAttributeIdDeleteRequest {
    attributeId: number;
}

export interface GetLettingAttributesApiV1LettingsLettingIdAttributesGetRequest {
    lettingId: number;
}

export interface GetLettingsApiV1LettingsPropertyIdTenantIdGetRequest {
    propertyId: number;
    tenantId: number;
}

export interface PostLettingAttributeApiV1LettingsLettingIdAttributesPostRequest {
    lettingId: number;
    baseAttributeIn: BaseAttributeIn;
}

export interface PostLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPostRequest {
    attributeId: number;
    appSchemasLettingsFloorIn: AppSchemasLettingsFloorIn;
}

export interface PutLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePutRequest {
    attributeId: number;
    attributeValue: AttributeValue;
}

/**
 * 
 */
export class LettingsApi extends runtime.BaseAPI {

    /**
     * Add letting of given tenant to given property and given tenant.
     */
    async addLettingApiV1LettingsPropertyIdTenantIdPostRaw(requestParameters: AddLettingApiV1LettingsPropertyIdTenantIdPostRequest): Promise<runtime.ApiResponse<LettingOut>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling addLettingApiV1LettingsPropertyIdTenantIdPost.');
        }

        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling addLettingApiV1LettingsPropertyIdTenantIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/{propertyId}/{tenantId}/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LettingOutFromJSON(jsonValue));
    }

    /**
     * Add letting of given tenant to given property and given tenant.
     */
    async addLettingApiV1LettingsPropertyIdTenantIdPost(requestParameters: AddLettingApiV1LettingsPropertyIdTenantIdPostRequest): Promise<LettingOut> {
        const response = await this.addLettingApiV1LettingsPropertyIdTenantIdPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete letting
     */
    async deleteLettingApiV1LettingsLettingIdDeleteRaw(requestParameters: DeleteLettingApiV1LettingsLettingIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.lettingId === null || requestParameters.lettingId === undefined) {
            throw new runtime.RequiredError('lettingId','Required parameter requestParameters.lettingId was null or undefined when calling deleteLettingApiV1LettingsLettingIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/{lettingId}/`.replace(`{${"lettingId"}}`, encodeURIComponent(String(requestParameters.lettingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete letting
     */
    async deleteLettingApiV1LettingsLettingIdDelete(requestParameters: DeleteLettingApiV1LettingsLettingIdDeleteRequest): Promise<void> {
        await this.deleteLettingApiV1LettingsLettingIdDeleteRaw(requestParameters);
    }

    /**
     * Delete Letting Attribute
     */
    async deleteLettingAttributeApiV1LettingsAttributesAttributeIdDeleteRaw(requestParameters: DeleteLettingAttributeApiV1LettingsAttributesAttributeIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId','Required parameter requestParameters.attributeId was null or undefined when calling deleteLettingAttributeApiV1LettingsAttributesAttributeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/attributes/{attributeId}`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Letting Attribute
     */
    async deleteLettingAttributeApiV1LettingsAttributesAttributeIdDelete(requestParameters: DeleteLettingAttributeApiV1LettingsAttributesAttributeIdDeleteRequest): Promise<void> {
        await this.deleteLettingAttributeApiV1LettingsAttributesAttributeIdDeleteRaw(requestParameters);
    }

    /**
     * Get letting attributes
     */
    async getLettingAttributesApiV1LettingsLettingIdAttributesGetRaw(requestParameters: GetLettingAttributesApiV1LettingsLettingIdAttributesGetRequest): Promise<runtime.ApiResponse<Array<CustomLettingAttributeOut>>> {
        if (requestParameters.lettingId === null || requestParameters.lettingId === undefined) {
            throw new runtime.RequiredError('lettingId','Required parameter requestParameters.lettingId was null or undefined when calling getLettingAttributesApiV1LettingsLettingIdAttributesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/{lettingId}/attributes`.replace(`{${"lettingId"}}`, encodeURIComponent(String(requestParameters.lettingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomLettingAttributeOutFromJSON));
    }

    /**
     * Get letting attributes
     */
    async getLettingAttributesApiV1LettingsLettingIdAttributesGet(requestParameters: GetLettingAttributesApiV1LettingsLettingIdAttributesGetRequest): Promise<Array<CustomLettingAttributeOut>> {
        const response = await this.getLettingAttributesApiV1LettingsLettingIdAttributesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all lettings for given property and given tenant
     */
    async getLettingsApiV1LettingsPropertyIdTenantIdGetRaw(requestParameters: GetLettingsApiV1LettingsPropertyIdTenantIdGetRequest): Promise<runtime.ApiResponse<Array<Letting>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getLettingsApiV1LettingsPropertyIdTenantIdGet.');
        }

        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getLettingsApiV1LettingsPropertyIdTenantIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/{propertyId}/{tenantId}/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LettingFromJSON));
    }

    /**
     * Get all lettings for given property and given tenant
     */
    async getLettingsApiV1LettingsPropertyIdTenantIdGet(requestParameters: GetLettingsApiV1LettingsPropertyIdTenantIdGetRequest): Promise<Array<Letting>> {
        const response = await this.getLettingsApiV1LettingsPropertyIdTenantIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Letting Attribute
     */
    async postLettingAttributeApiV1LettingsLettingIdAttributesPostRaw(requestParameters: PostLettingAttributeApiV1LettingsLettingIdAttributesPostRequest): Promise<runtime.ApiResponse<CustomLettingAttributeOut>> {
        if (requestParameters.lettingId === null || requestParameters.lettingId === undefined) {
            throw new runtime.RequiredError('lettingId','Required parameter requestParameters.lettingId was null or undefined when calling postLettingAttributeApiV1LettingsLettingIdAttributesPost.');
        }

        if (requestParameters.baseAttributeIn === null || requestParameters.baseAttributeIn === undefined) {
            throw new runtime.RequiredError('baseAttributeIn','Required parameter requestParameters.baseAttributeIn was null or undefined when calling postLettingAttributeApiV1LettingsLettingIdAttributesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/{lettingId}/attributes`.replace(`{${"lettingId"}}`, encodeURIComponent(String(requestParameters.lettingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseAttributeInToJSON(requestParameters.baseAttributeIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomLettingAttributeOutFromJSON(jsonValue));
    }

    /**
     * Post Letting Attribute
     */
    async postLettingAttributeApiV1LettingsLettingIdAttributesPost(requestParameters: PostLettingAttributeApiV1LettingsLettingIdAttributesPostRequest): Promise<CustomLettingAttributeOut> {
        const response = await this.postLettingAttributeApiV1LettingsLettingIdAttributesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Letting Attribute Floor
     */
    async postLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPostRaw(requestParameters: PostLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPostRequest): Promise<runtime.ApiResponse<Array<AppSchemasLettingsFloorOut>>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId','Required parameter requestParameters.attributeId was null or undefined when calling postLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPost.');
        }

        if (requestParameters.appSchemasLettingsFloorIn === null || requestParameters.appSchemasLettingsFloorIn === undefined) {
            throw new runtime.RequiredError('appSchemasLettingsFloorIn','Required parameter requestParameters.appSchemasLettingsFloorIn was null or undefined when calling postLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/attributes/{attributeId}/floors`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppSchemasLettingsFloorInToJSON(requestParameters.appSchemasLettingsFloorIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppSchemasLettingsFloorOutFromJSON));
    }

    /**
     * Post Letting Attribute Floor
     */
    async postLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPost(requestParameters: PostLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPostRequest): Promise<Array<AppSchemasLettingsFloorOut>> {
        const response = await this.postLettingAttributeFloorApiV1LettingsAttributesAttributeIdFloorsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Letting Attribute Value
     */
    async putLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePutRaw(requestParameters: PutLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePutRequest): Promise<runtime.ApiResponse<PutLettingAttributesOut>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId','Required parameter requestParameters.attributeId was null or undefined when calling putLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePut.');
        }

        if (requestParameters.attributeValue === null || requestParameters.attributeValue === undefined) {
            throw new runtime.RequiredError('attributeValue','Required parameter requestParameters.attributeValue was null or undefined when calling putLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/lettings/attributes/{attributeId}/value`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeValueToJSON(requestParameters.attributeValue),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PutLettingAttributesOutFromJSON(jsonValue));
    }

    /**
     * Put Letting Attribute Value
     */
    async putLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePut(requestParameters: PutLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePutRequest): Promise<PutLettingAttributesOut> {
        const response = await this.putLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePutRaw(requestParameters);
        return await response.value();
    }

}
