import React from "react";
import { Popover } from "antd";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { usePermissions } from "@hooks/use-permission";
import { AddButton } from "@components/button";
import { PopupContent } from "./popup-content-wrapper";
import { LettingIdContext } from "./local-context/letting-context";

import * as S from "./add-attributes.styled";

interface Props {
  lettingId?: number;
  onAdd?: any;
}

const AddAttributeButtonMain: React.FC<Props> = ({ lettingId, onAdd, children }) => {
  return (
    <LettingIdContext.Provider value={{ lettingId, onAdd }}>
      <S.AddAttributeButtonWrapper>
        <Popover placement="topLeft" content={<PopupContent />} trigger="click">
          <div>{children}</div>
        </Popover>
      </S.AddAttributeButtonWrapper>
    </LettingIdContext.Provider>
  );
};

const AddAttributeButtonOfferDetailsType: React.FC = () => {
  const { hasPermission } = usePermissions();

  return (
    <S.StyledButton
      type="ghost"
      size="large"
      icon={<PlusOutlined />}
      disabled={!hasPermission("POST_OFFER_ATTRIBUTE")}
    >
      ADD NEW ATTRIBUTE
    </S.StyledButton>
  );
};

const AddAttributeButtonTenantsType: React.FC = () => {
  const { hasPermission } = usePermissions();
  return <AddButton disabled={!hasPermission("POST_OFFER_ATTRIBUTE")}>Add attribute</AddButton>;
};

export const AddAttributeButtonTenantsComponent: React.FC<Props> = ({ lettingId, onAdd }) => (
  <AddAttributeButtonMain lettingId={lettingId} onAdd={onAdd}>
    <AddAttributeButtonTenantsType />
  </AddAttributeButtonMain>
);

export const AddAttributeButton: React.FC<Props> = ({ lettingId, onAdd }) => (
  <AddAttributeButtonMain lettingId={lettingId} onAdd={onAdd}>
    <AddAttributeButtonOfferDetailsType />
  </AddAttributeButtonMain>
);
