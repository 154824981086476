import React from "react";
import { Link } from "react-router-dom";
import Image from "@components/image";
import { Heading } from "@components/heading";
import leftLogo from "@svg/logo_pims.svg";
import rightLogo from "@svg/logo_savills.svg";
import * as S from "./header.styled";

export const Header: React.FC = () => {
  return (
    <S.HeaderStyle>
      <S.ImageWrapper>
        <Link to="/">
          <Image src={leftLogo} alt="left-logo" />
        </Link>
      </S.ImageWrapper>
      <S.ImageWrapper>
        <S.DesignedFor>
          <Heading level="6">designed for</Heading>
        </S.DesignedFor>
        <Image src={rightLogo} alt="right-logo" />
      </S.ImageWrapper>
    </S.HeaderStyle>
  );
};
