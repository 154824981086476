import React, { useState, useCallback } from "react";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";

import { contactsApi } from "@api/core";
import { ContactOut } from "@api/client/models";

import { Loader } from "@components/loader";
import { Empty } from "@components/empty";

import { Title } from "@pages/offer-detail/title";

import { NewContactType } from "./new-contact-type";
import { ContactGroup } from "./contacts-group";

import * as S from "../offer-detail/offer-detail.styled";

export const ContactsContainer = () => {
  const [selectedContactNotes, setSelectedContactNotes] = useState(NaN);
  const propertyId = useSelector((state) => state.offers.offerDetails.data?.propertyId);
  const queryId = ["contacts", propertyId];
  const {
    data: contacts,
    isLoading: areContactsLoading,
    isError: isContactsError,
    refetch: refetchContacts,
  } = useQuery(
    queryId,
    () => (propertyId ? contactsApi.getContactsApiV1ContactsPropertyPropertyIdGet({ propertyId }) : null),
    {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: contactTypes,
    isLoading: isContactTypesLoading,
    isError: isContactTypesError,
  } = useQuery("contactGroups", () => contactsApi.getContactTypesApiV1ContactsTypesGet(), {
    refetchOnWindowFocus: false,
  });

  const deleteMutation = useMutation(
    (event: any) =>
      contactsApi.deleteContactApiV1ContactsPropertyPropertyIdContactIdDelete({
        propertyId: Number(propertyId),
        contactId: Number(event.target.value),
      }),
    {
      onSuccess: () => {
        refetchContacts();
      },
    }
  );

  const emailMutation = useMutation(
    (event: any) =>
      contactsApi.putContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPut({
        propertyId: Number(propertyId),
        contactId: event.contactId,
        contactEmail: { email: event.inputValue },
      }),
    {
      onSuccess: () => {
        refetchContacts();
      },
    }
  );

  const phoneMuatation = useMutation(
    (event: any) =>
      contactsApi.putContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePut({
        propertyId: Number(propertyId),
        contactId: event.contactId,
        contactPhone: { phone: event.inputValue },
      }),
    {
      onSuccess: () => {
        refetchContacts();
      },
    }
  );

  const handleNotesClick = useCallback(
    (event: any) => {
      event.stopPropagation();

      setSelectedContactNotes(Number(event.target.value));
    },
    [setSelectedContactNotes]
  );

  const handleCloseModal = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      setSelectedContactNotes(NaN);
    },
    [setSelectedContactNotes]
  );

  if (areContactsLoading || isContactTypesLoading) return <Loader />;
  if (!contacts || !contactTypes || isContactTypesError || isContactsError) return <Empty />;

  const grouppedContacts: Record<string, Omit<ContactOut, "type">[]> = {};
  contacts.forEach(({ type, ...contact }) => {
    if (!grouppedContacts[type]) grouppedContacts[type] = [contact];
    else grouppedContacts[type].push(contact);
  });
  const sortedGrouppedContacts = Object.entries(grouppedContacts).sort(([typeA], [typeB]) => {
    if (typeA > typeB) return 1;
    if (typeB > typeA) return -1;
    return 0;
  });

  return (
    <S.Container>
      <S.TopContent>
        <S.TopContentTitle>
          <Title />
        </S.TopContentTitle>
      </S.TopContent>
      <NewContactType contactTypes={contactTypes} onSuccessfullContactUpload={refetchContacts} />
      {sortedGrouppedContacts.map(([type, entriedContacts]) => (
        <ContactGroup
          key={type}
          type={type}
          contacts={entriedContacts}
          onDelete={deleteMutation.mutate}
          onNotesClick={handleNotesClick}
          selectedContactNotes={selectedContactNotes}
          onCloseModal={handleCloseModal}
          propertyId={Number(propertyId)}
          onAdd={refetchContacts}
          onEmailSubmit={emailMutation.mutate}
          onPhoneSubmit={phoneMuatation.mutate}
          isEmailLoading={emailMutation.isLoading}
          isPhoneLoading={phoneMuatation.isLoading}
          contactType={
            contactTypes.find(({ type: contactType }) => contactType === type) || { id: 0, type: "" }
          }
        />
      ))}
    </S.Container>
  );
};
