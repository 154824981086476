import React from "react";
import { AttributesList } from "@pages/transactions/letting-attributes/attributes-list/attributes-list";
import { useQuery } from "react-query";
import { lettingsApi } from "@api/core";
import { AddAttributeButtonTenantsComponent } from "@pages/offer-detail/add-attributes";
import { GetLettingAttributesApiV1LettingsLettingIdAttributesGetRequest } from "@api/client";

interface Props {
  lettingId: number;
}

export const LettingAttributes: React.FC<Props> = ({ lettingId }) => {
  const { data, refetch } = useQuery([`letting-attributes_${lettingId}`, lettingId], () =>
    lettingsApi.getLettingAttributesApiV1LettingsLettingIdAttributesGet({
      lettingId,
    } as GetLettingAttributesApiV1LettingsLettingIdAttributesGetRequest)
  );

  return (
    <div>
      {data && (
        <AttributesList lettingId={lettingId} attributeList={data} refetchListOfAttributes={refetch} />
      )}
      <AddAttributeButtonTenantsComponent lettingId={lettingId} onAdd={refetch} />
    </div>
  );
};
