import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyledIcon } from "@styled-icons/styled-icon";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { BusAlt } from "@styled-icons/fa-solid/BusAlt";
import { Tooltip } from "antd";
import PlusOutlined from "@ant-design/icons/PlusOutlined";

import { HeadingSmall } from "@components/heading";
import { PoiProperty } from "@api/client";
import { AntTable } from "@components/ant-table";
import { icons, IconTypes } from "@components/variables";

import { unassignPoi, assignPoi, getSuggestedPoi } from "@utils/property";
import * as S from "./poi-list.styled";
import { DeleteButton } from "../../../components/delete-button/delete-button";

const getKey = (value: keyof PoiProperty) => value;

const handleDeletePoi =
  (dispatch: Dispatch) =>
  ({ poiId, propertyId }: { poiId: number; propertyId: number }) => {
    unassignPoi(dispatch)({
      poiId,
      propertyId,
    });
  };

const getColumns = ({
  propertyId,
  dispatch,
}: {
  propertyId: number;
  dispatch: Dispatch;
}): ColumnsType<PoiProperty> => [
  {
    title: "",
    dataIndex: getKey("categoryName"),
    key: getKey("categoryName"),
    render: (value: string) => {
      const Component = (icons?.[value as IconTypes] as StyledIcon) || BusAlt;
      return <Component width={25} height={25} />;
    },
  },
  {
    title: "Name",
    dataIndex: getKey("name"),
    key: getKey("name"),
  },
  {
    title: "Geography",
    dataIndex: getKey("geographyName"),
    key: getKey("geographyName"),
  },
  {
    title: "Distance",
    dataIndex: getKey("distance"),
    key: getKey("distance"),
  },
  {
    title: "Walking",
    dataIndex: getKey("durationFoot"),
    key: getKey("durationFoot"),
  },
  {
    title: "Bus/Tram",
    dataIndex: getKey("durationPublicTransport"),
    key: getKey("durationPublicTransport"),
  },
  {
    title: "Car",
    dataIndex: getKey("durationCar"),
    key: getKey("durationCar"),
  },
  {
    title: "",
    dataIndex: "id",
    key: "id",
    render: (poiId: number) => {
      return (
        <Tooltip title="Unassign POI">
          <S.Delete>
            <DeleteButton onClick={() => handleDeletePoi(dispatch)({ poiId, propertyId })} />
          </S.Delete>
        </Tooltip>
      );
    },
  },
];

export const POIList = () => {
  const [search, setSearch] = useState("");
  const data = useSelector((state) => state.offers.offerDetails.data);
  const suggestedPoi = useSelector((state) => state.offers.suggestedPoi);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) getSuggestedPoi(dispatch)({ propertyId: data.propertyId });
    // mimic component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return null;
  }

  const columns = getColumns({
    propertyId: data.propertyId,
    dispatch,
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClickSuggestedRow = (poiId: number) => {
    assignPoi(dispatch)({
      propertyId: data.propertyId,
      propertyPoi: {
        poiId,
      },
    });
    setSearch("");
  };

  const filtredSuggestedPois = () =>
    suggestedPoi.data.filter(
      (value) => value.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    );

  return (
    <>
      <S.Container>
        <HeadingSmall>Points of interest</HeadingSmall>
        <AntTable pagination={false} dataSource={data.pois} columns={columns} />
        {search && (
          <S.AddPoiResult>
            <AntTable
              onRow={(record) => ({
                onClick: () => {
                  onClickSuggestedRow(record.id);
                },
              })}
              loading={suggestedPoi.loading}
              showHeader={false}
              pagination={false}
              dataSource={filtredSuggestedPois()}
              columns={columns}
            />
          </S.AddPoiResult>
        )}
      </S.Container>
      <S.AddPoiButton>
        <PlusOutlined size={20} />
        <S.SearchPoi value={search} onChange={handleSearchChange} />
      </S.AddPoiButton>
    </>
  );
};
