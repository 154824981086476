import { combineReducers, createStore, Store, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { filtersReducer as filters } from "./filters/reducer";
import { dictsReducer as dicts } from "./dics/reducer";
import { offersReducer as offers } from "./offers/reducers";
import { geographyReducer as geography } from "./geography/reducer";
import { propertiesReducer as properties } from "./properties/reducer";
import { poiReducer as poi } from "./poi/reducer";
import { attributesReducer as attributes } from "./attribures/reducer";
import { rootSaga } from "./rootSaga";
import { newsletterGenerateBreakdownReportReducer } from "./newsletter-generate-breakdown-report/reducer";

const sagaMiddleware = createSagaMiddleware();
export const reducers = combineReducers({
  filters,
  dicts,
  offers,
  geography,
  properties,
  poi,
  attributes,
  newsletterGenerateBreakdownReportReducer,
});

export type AppState = ReturnType<typeof reducers>;

export const createAppStore = (): Store => {
  const store = createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return store;
};
