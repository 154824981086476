import React from "react";
import { Field, FieldProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import { usePermissions } from "@hooks/use-permission";
import { Select } from "@components/form/select";
import { EditableContentInput } from "@components/form/editable-content-input";
import { DictPropertyCategory } from "@api/client";
import { ReactComponent as IconOfficesNotActive } from "@svg/icon-offices-notactive.svg";
import {
  updatePropertyAddress,
  updatePropertyGeography,
  updatePropertyName,
  updatePropertyStatus,
} from "@utils/property";
import * as S from "./offer-status.styled";
import { SelectGeography } from "../../shared-module/select-geography";
import "./offer-status.less";

const createMenu = (categories: DictPropertyCategory[], onSelect: (value: string) => void) => (
  <Menu>
    {categories.map((item) => (
      <Menu.Item key={item.code}>
        <a
          href="##"
          onClick={(e) => {
            e.preventDefault();
            onSelect(item.code);
          }}
        >
          {item.name}
        </a>
      </Menu.Item>
    ))}
  </Menu>
);

export const OfferStatus = () => {
  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();
  const data = useSelector((state) => state.offers.offerDetails.data);
  const statuses = useSelector((state) => state.dicts.property.data);
  const categories = useSelector((state) => state.dicts.categories.data);

  if (!data) {
    return null;
  }

  const options = statuses.map((item) => ({
    label: item.name,
    value: item.code,
  }));

  const onSelect = () => {};

  const onPropertyStatusChange = (statusCode: string) => {
    updatePropertyStatus(dispatch)({
      propertyId: data.propertyId,
      propertyStatus: {
        statusCode,
      },
    });
  };

  const onTitleChange = (name: string) => {
    updatePropertyName(dispatch)({
      propertyId: data.propertyId,
      propertyName: {
        name,
      },
    });
  };

  const onAddressChange = (address: string) => {
    updatePropertyAddress(dispatch)({
      propertyId: data.propertyId,
      propertyAddress: {
        address,
      },
    });
  };

  const onGeographyChange = (geographyId: number[] | number) => {
    if (typeof geographyId !== "number") {
      return;
    }

    updatePropertyGeography(dispatch)({
      propertyId: data.propertyId,
      propertyGeography: {
        geographyId,
      },
    });
  };

  return (
    <>
      <S.Container>
        <S.Flex>
          {/* <Field name="propertyCategory"> probably it should be propertyuCategory instead of PropertyStatus */}
          <Field name="propertyStatus">
            {() => (
              <Dropdown
                overlay={createMenu(categories, onSelect)}
                trigger={["click"]}
                disabled={!hasPermission("PUT_PROPERTY_STATUS")}
              >
                <S.Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  <IconOfficesNotActive />
                </S.Link>
              </Dropdown>
            )}
          </Field>
          <Field name="propertyName">
            {(fieldProps: FieldProps) => (
              <EditableContentInput
                edibable={hasPermission("PUT_PROPERTY_NAME")}
                onBlur={onTitleChange}
                large
                {...fieldProps}
              />
            )}
          </Field>
        </S.Flex>
        <Field name="propertyStatus">
          {(fieldProps: FieldProps) => (
            <Select
              {...fieldProps}
              disabled={!hasPermission("PUT_PROPERTY_STATUS")}
              field={{
                ...fieldProps.field,
                onChange: (value: any) => {
                  onPropertyStatusChange(value);
                },
              }}
              options={options}
            />
          )}
        </Field>
      </S.Container>
      <S.AreaContainer>
        <Field name="propertyGeography.id">
          {(fieldProps: FieldProps) => (
            <SelectGeography
              disabled={!hasPermission("PUT_PROPERTY_GEOGRAPHY")}
              multiple={false}
              treeCheckable={false}
              onChange={onGeographyChange}
              value={fieldProps.field.value}
              treeNodeLabelProp="fullyQualifiedName"
            />
          )}
        </Field>
      </S.AreaContainer>
      <S.AddressContainer>
        <Field name="propertyAddress">
          {(fieldProps: FieldProps) => (
            <EditableContentInput
              edibable={hasPermission("PUT_PROPERTY_ADDRESS")}
              onBlur={onAddressChange}
              {...fieldProps}
            />
          )}
        </Field>
      </S.AddressContainer>
    </>
  );
};
