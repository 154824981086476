export const industrialAttributes = [
  {
    code: "",
    cellWidth: 60,
    key: "emptyFirst",
  },
  {
    code: "Building Name",
    cellWidth: 450,
    key: "buildingName",
  },
  {
    code: "Available Space",
    cellWidth: 200,
    key: "availableSpace",
  },
  {
    code: "Availability",
    cellWidth: 200,
    key: "availability",
  },
  {
    code: "Clear Height",
    cellWidth: 150,
    key: "clearHeight",
  },
  {
    code: "Column Grid",
    cellWidth: 200,
    key: "columnGrid",
  },
  {
    code: "Floor Loading Capacity",
    cellWidth: 200,
    key: "floorLoadingCapacity",
  },
  {
    code: "Document Comments",
    cellWidth: 170,
    key: "documentComments",
  },
  {
    code: "Internal Comments",
    cellWidth: 170,
    key: "internalComments",
  },
  {
    code: "",
    cellWidth: 60,
    key: "emptyLast",
  },
];
