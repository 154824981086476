import { Checkbox } from "@components/checkbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";
import * as S from "./styles";

interface Props {
  title: string;
  isSelected: boolean;
  onChange: (event: CheckboxChangeEvent) => void;
}

export const SliderWithCheckbox: React.FC<Props> = ({ isSelected, onChange, title, children }) => {
  return (
    <S.SliderWithCheckbox>
      <Checkbox defaultChecked={isSelected} onChange={onChange}>
        {title}
      </Checkbox>
      {isSelected && children}
    </S.SliderWithCheckbox>
  );
};
