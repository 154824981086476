import React, { useEffect, useState } from "react";
import { Input } from "@components/input";
import xss from "xss";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Checkbox } from "antd";

import { changeSearchType } from "@state/attribures/actions";
import { Heading } from "@components/heading";

import * as S from "./Form.styled";
import { ButtonToChangeSearchType, SearchTypesButtonsContainer } from "./string.styled";

interface Props {
  onChange: (param: string | string[]) => void;
  currentGroup: string;
  value: string[] | string;
}

export enum StringModes {
  CAT = "CAT",
  FT = "FT",
}

export const String: React.FC<Props> = ({ onChange, value }) => {
  const [phrase, setPhrase] = useState("");
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhrase(event.target.value);
    onChange(event.target.value);
  };
  const dispatch = useDispatch();
  const currentAttributeCode = useSelector((state) => state.attributes.customFilter.key);
  const distributionProp = useSelector((state) => state.attributes.distribution[currentAttributeCode]?.text);
  const searchTypeInit = useSelector(
    (state) => state.attributes.distribution[currentAttributeCode]?.text?.searchType
  );
  const distributionPropKeys = Object.keys(distributionProp?.counts || {});
  const searchTypeState = useSelector((state) => state.attributes.customFilter.searchType) || searchTypeInit;
  const buttonSearchHandle = (x: string) => {
    dispatch(changeSearchType(x));
  };

  const onGroupChange = (newValue: Array<CheckboxValueType>) => {
    onChange(newValue as string[]);
  };

  const showValue = (valueToEmphesize: string) => {
    try {
      return xss(valueToEmphesize.replace(new RegExp(phrase, "i"), (v) => `<b>${v}</b>`));
    } catch (e) {
      return valueToEmphesize;
    }
  };

  useEffect(() => {
    dispatch(changeSearchType(searchTypeState || ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <SearchTypesButtonsContainer>
        <Heading level="6">Search mode</Heading>
        <ButtonToChangeSearchType
          isActive={searchTypeState === StringModes.CAT}
          value={StringModes.CAT}
          onClick={() => buttonSearchHandle(StringModes.CAT)}
        >
          Category
        </ButtonToChangeSearchType>
        <ButtonToChangeSearchType
          isActive={searchTypeState === StringModes.FT}
          value={StringModes.FT}
          onClick={() => buttonSearchHandle(StringModes.FT)}
        >
          Full Text
        </ButtonToChangeSearchType>
      </SearchTypesButtonsContainer>
      {searchTypeState === StringModes.CAT && (
        <Checkbox.Group
          name="ValueAsCategory"
          onChange={onGroupChange}
          value={typeof value === "string" ? [] : value}
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            maxHeight: "350px",
            width: "100%",
          }}
          options={distributionPropKeys.map((item) => ({
            label: (
              <span>
                {item}
                <S.CheckouxAmount>({distributionProp?.counts[item]})</S.CheckouxAmount>
              </span>
            ),
            value: item,
            style: {
              display: "flex",
              margin: "5px 0",
            },
          }))}
        />
      )}

      {searchTypeState === StringModes.FT && (
        <>
          <S.SearchContainer>
            <Input id="textAttributeSearch" value={phrase} onChange={onSearchChange} />
          </S.SearchContainer>
          <Heading level="6">Search of values</Heading>
          <S.ContainerChild>
            <Heading level="5">Values:</Heading>
            <S.ContainerChild
              style={{
                overflowX: "hidden",
                overflowY: "auto",
                maxHeight: "350px",
                width: "100%",
              }}
            >
              {distributionPropKeys
                .filter((item) => item.match(new RegExp(phrase, "i")))
                .map((item) => (
                  <div>
                    <S.Value
                      dangerouslySetInnerHTML={{
                        __html: showValue(item),
                      }}
                    />
                  </div>
                ))}
            </S.ContainerChild>
          </S.ContainerChild>
        </>
      )}
    </>
  );
};
