import React from "react";
import { useSelector } from "react-redux";
import { GenericSlider } from "./generic-slider";
import { CommponSliderProps } from "./interfaces";

export const ModuleSlider: React.FC<CommponSliderProps> = ({
  currentGroup,
  onSliderChange,
  onLowerInputChange,
  onUpperInputChange,
}) => {
  const attributes = useSelector((state) => state.attributes);
  const { distribution } = attributes;
  const minVal = distribution[currentGroup]?.modules?.valueMin;
  const maxVal = distribution[currentGroup]?.modules?.valueMax;
  if (minVal === undefined || maxVal === undefined) {
    return null;
  }
  const lowerModuleValue = attributes.customFilter?.modules?.[0] || minVal;
  const upperModuleValue = attributes.customFilter?.modules?.[1] || maxVal;
  return (
    <GenericSlider
      title="Modules"
      minVal={minVal}
      maxVal={maxVal}
      selectedLowerValue={lowerModuleValue}
      selectedUpperValue={upperModuleValue}
      onSliderChange={onSliderChange}
      onLowerInputChange={onLowerInputChange}
      onUpperInputChange={onUpperInputChange}
    />
  );
};
