import React from "react";
import { useSelector } from "react-redux";

import { Loader } from "@components/loader";

import { useLanguage } from "@hooks/use-language";
import { GeneratePropertyParticular } from "@pages/shared-module/main-table/variuos-things-generator-over-table/generate-property-particular";
import { QuickForm } from "@pages/offer-detail/quick-form/quick-form";

import { Main } from "./main";
import { LastUpdate } from "./last-update";
import * as S from "./offer-detail.styled";
import { Title } from "./title";
import { RightSideBar } from "./right-site-bar";
import { Attachements } from "./attachements";
import { OfferTags } from "./offer-tags";
import { OfferREDDPanel } from "./offer-redd-panel/offer-redd-panel";

export const OfferDetail = () => {
  const { data } = useSelector((state) => state.offers.offerDetails);
  const { language } = useLanguage();
  const currentModule = useSelector((state) => state.offers.currentModule);
  return (
    <>
      {!data && <Loader />}
      {data && (
        <S.Container>
          {currentModule !== "retail" && (
            <S.PropertyParticular>
              {language === "PL" && <OfferREDDPanel />}
              <GeneratePropertyParticular
                properties={[{ id: data.offerId, name: data.offerName }]}
                isOfferDetails
              />
            </S.PropertyParticular>
          )}
          <S.TopContent>
            <S.TopContentTitle>
              <Title />
              <OfferTags />
            </S.TopContentTitle>
            <LastUpdate />
          </S.TopContent>
          <QuickForm />
          <S.Content>
            <Main />
            <S.Sider theme="light" width="100%">
              <RightSideBar />
            </S.Sider>
          </S.Content>
          <Attachements />
        </S.Container>
      )}
    </>
  );
};
