/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactOut
 */
export interface ContactOut {
    /**
     * 
     * @type {string}
     * @memberof ContactOut
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactOut
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactOut
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ContactOut
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ContactOut
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof ContactOut
     */
    parentId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactOut
     */
    isRemovable: boolean;
}

export function ContactOutFromJSON(json: any): ContactOut {
    return ContactOutFromJSONTyped(json, false);
}

export function ContactOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': json['name'],
        'id': json['id'],
        'type': json['type'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'isRemovable': json['isRemovable'],
    };
}

export function ContactOutToJSON(value?: ContactOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'email': value.email,
        'name': value.name,
        'id': value.id,
        'type': value.type,
        'parentId': value.parentId,
        'isRemovable': value.isRemovable,
    };
}


