/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Letting
 */
export interface Letting {
    /**
     * 
     * @type {number}
     * @memberof Letting
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Letting
     */
    tenantId: number;
    /**
     * 
     * @type {number}
     * @memberof Letting
     */
    propertyId: number;
    /**
     * 
     * @type {string}
     * @memberof Letting
     */
    tenantName: string;
}

export function LettingFromJSON(json: any): Letting {
    return LettingFromJSONTyped(json, false);
}

export function LettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Letting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'propertyId': json['propertyId'],
        'tenantName': json['tenantName'],
    };
}

export function LettingToJSON(value?: Letting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'propertyId': value.propertyId,
        'tenantName': value.tenantName,
    };
}


