/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { Popover, Tooltip } from "antd";

import { ContactOut } from "@api/client/models";

import { DeleteButton } from "@components/delete-button";
import { ExternalLink } from "@components/external-link";
import { Input, Label } from "@components/input";
import { Button } from "@components/button";

import { useToggle } from "@hooks/use-toggle";

import { Edit } from "@styled-icons/boxicons-regular/Edit";

import { emailPattern, telPattern } from "@utils/regexp-and-input-patterns";

import { NotesModal } from "./notes-modal";

import * as S from "./contact-group.styled";

interface PopoverFormProps {
  label: string;
  inputValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  contactId: number;
  onSubmit: (value: { contactId: number; inputValue: string }) => void;
  loading: boolean;
  pattern: string;
  type: "email" | "tel";
  onClose: () => void;
  invalidMessage: string;
}

interface ContactProps extends S.ContactWrapperStyles {
  onDelete: any;
  onNotesClick: any;
  selectedContactNotes: number;
  onCloseModal: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onEmailSubmit: (value: { contactId: number; inputValue: string }) => void;
  onPhoneSubmit: (value: { contactId: number; inputValue: string }) => void;
  isEmailLoading: boolean;
  isPhoneLoading: boolean;
}

const oneSecondDelay = 1;

const PopoverForm: React.FC<PopoverFormProps> = ({
  label,
  contactId,
  inputValue,
  onChange,
  onSubmit,
  loading,
  pattern,
  type,
  onClose,
  invalidMessage,
}) => {
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    onSubmit({ contactId, inputValue });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlFor={label}>{label}</Label>
      <Input
        size="small"
        id={label}
        value={inputValue}
        onChange={onChange}
        pattern={pattern}
        type={type}
        required
        onInvalid={(e) => e.currentTarget.setCustomValidity(invalidMessage)}
      />
      <div style={{ marginTop: 20 }}>
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button size="small" htmlType="submit" type="primary" loading={loading}>
          Change
        </Button>
      </div>
    </form>
  );
};

export const Contact: React.FC<Omit<ContactOut, "type"> & ContactProps> = ({
  name,
  phone,
  email,
  onDelete,
  id,
  onNotesClick,
  selectedContactNotes,
  onCloseModal,
  isRemovable,
  single,
  onEmailSubmit,
  onPhoneSubmit,
  isEmailLoading,
  isPhoneLoading,
  isChildren,
}) => {
  const [inputEmail, setInputEmail] = useState(email || "");
  const [inputPhone, setInputPhone] = useState(phone || "");

  const { state: showEmailEdit, toggle: toggleEmailEdit } = useToggle();
  const { state: showPhoneEdit, toggle: togglePhoneEdit } = useToggle();

  const handeEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputEmail(event.target.value);
    event.currentTarget.setCustomValidity("");
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputPhone(event.target.value);
    event.currentTarget.setCustomValidity("");
  };

  return (
    <S.ContactWrapper isChildren={isChildren} single={single}>
      <div>{name}</div>
      <div>
        <Popover
          title="Edit email"
          onVisibleChange={toggleEmailEdit}
          visible={showEmailEdit}
          trigger="click"
          content={
            <PopoverForm
              label="email"
              inputValue={inputEmail}
              onChange={handeEmailChange}
              contactId={id}
              onSubmit={onEmailSubmit}
              loading={isEmailLoading}
              pattern={emailPattern}
              type="email"
              onClose={toggleEmailEdit}
              invalidMessage="Enter an email in specified format: example@example.com"
            />
          }
        >
          <ExternalLink href={`mailto:${email}`}>{email}</ExternalLink>
        </Popover>
        <Tooltip title="Edit email">
          <Button type="link" onClick={toggleEmailEdit}>
            <Edit size={18} />
          </Button>
        </Tooltip>
      </div>
      <div>
        <Popover
          title="Edit phone"
          mouseEnterDelay={oneSecondDelay}
          onVisibleChange={togglePhoneEdit}
          trigger="click"
          visible={showPhoneEdit}
          content={
            <PopoverForm
              label="phone"
              inputValue={inputPhone}
              onChange={handlePhoneChange}
              contactId={id}
              onSubmit={onPhoneSubmit}
              loading={isPhoneLoading}
              type="tel"
              pattern={telPattern}
              onClose={togglePhoneEdit}
              invalidMessage="Invalid format of the phone number."
            />
          }
        >
          <ExternalLink href={`tel:${phone?.replace(/\s/g, "")}`}>{phone}</ExternalLink>
        </Popover>
        <Tooltip title="Edit phone number">
          <Button type="link" onClick={togglePhoneEdit}>
            <Edit size={20} />
          </Button>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Delete contact">
          <DeleteButton disabled={!isRemovable} value={id} onClick={onDelete} />
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Show notes">
          <S.NoteButton type="ghost" onClick={onNotesClick} value={id}>
            <S.NotesIcon />
          </S.NoteButton>
        </Tooltip>
        <NotesModal
          contactId={id}
          visible={selectedContactNotes === id}
          onCancel={onCloseModal}
          destroyOnClose
          name={name}
        />
      </div>
    </S.ContactWrapper>
  );
};
