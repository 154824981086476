import React, { useState, useCallback } from "react";

import { PoiBase } from "@api/client";

import { TableCell } from "@components/table";
import { icons, IconTypes } from "@components/variables";
import { Icon } from "@components/icon";
import { StyledButton } from "@components/button";

import { DeleteConfirmationModal } from "@components/delete-confirmation-modal/delete-confirmation-modal";
import { DeleteButton } from "../../../components/delete-button/delete-button";
import { AddNewPoi } from "./add-new-poi";

import * as S from "./table.styled";

interface Props {
  onDelete: (poiId: number) => void;
}

export const TableRow: React.FC<PoiBase & Props> = ({
  name,
  address,
  categoryName,
  geographyName,
  geoLatitude,
  geoLongitude,
  id: poiId,
  onDelete,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const toggleModal = useCallback(() => setOpenModal((o) => !o), []);
  return (
    <S.TableRow>
      <DeleteConfirmationModal
        show={deleteConfirmationState}
        onCancel={() => {
          setDeleteConfirmationState(false);
        }}
        onOk={() => onDelete(poiId)}
      />
      <TableCell>
        <StyledButton type="ghost" onClick={toggleModal} style={{ width: "fit-content" }}>
          <TableCell>{name}</TableCell>
        </StyledButton>
        <AddNewPoi
          address={address}
          geographyName={geographyName}
          latitude={geoLatitude}
          longitude={geoLongitude}
          name={name}
          onCancel={toggleModal}
          poiCategory={categoryName}
          poiId={poiId}
          put
          visible={openModal}
          zoom={14}
        />
      </TableCell>
      <TableCell>
        <Icon icon={icons[categoryName as IconTypes] || icons.pin} /> {categoryName}
      </TableCell>
      <TableCell>{address}</TableCell>
      <TableCell>{geographyName}</TableCell>
      <TableCell>
        <DeleteButton
          value={poiId}
          onClick={() => {
            setDeleteConfirmationState(true);
          }}
        />
      </TableCell>
    </S.TableRow>
  );
};
