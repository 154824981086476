import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
`;

export const SearchContainer = styled.div`
  padding-bottom: 10px;
  padding-right: 20px;
`;

export const Value = styled.span`
  b {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const SecondStep = styled.div`
  padding: 20px 0;
  margin-top: 25px;
  border-top: 1px solid ${(props) => props.theme.grey};
`;

export const ContainerChild = styled.div`
  padding: 10px;
`;

export const SelectedGroup = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckouxAmount = styled.span`
  font-size: 14px;
  display: inline-block;
  margin-left: 8px;
`;
