import styled from "styled-components";

export const Container = styled.div`
  margin: 20px 0;
  position: relative;
`;

export const Delete = styled.div`
  .ant-table-row:not(:hover) & {
    visibility: hidden;
  }
`;

export const AddPoiButton = styled.div`
  background: ${(props) => props.theme.lightGrey2};
  display: inline-flex;
  align-items: center;
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;

  .anticon {
    margin: 0 5px;
  }
`;

export const AddPoiResult = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2;
  width: 100%;
  > div {
    padding: 10px;
    background: #fff;
    border: 1px solid ${(props) => props.theme.grey};
    border-radius: 3px;
  }

  .ant-table-row {
    cursor: pointer;
  }

  ${Delete} {
    visibility: hidden;
  }
`;

export const SearchPoi = styled.input`
  background: transparent;
  border: 0;
  padding-left: 5px;
  &:focus {
    outline: #fff7f0;
  }
`;
