import React from "react";

import { useQuery } from "react-query";

import { offerApi } from "@api/core";

import { Heading } from "@components/heading";
import { Button } from "@components/button";

import { getFileNameFormResponseHeader } from "@utils/getFileNameFromResponseHeader";
import { ApiResponse } from "@api/client";

import { Progress } from "../progress";
import { PropertiesList, PropertiesListProps } from "../shared";

const powerpoint = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
const zip = "application/x-zip-compressed";

interface Props extends PropertiesListProps {
  isOfferDetails?: boolean;
}

export const GeneratePropertyParticular = ({
  properties,
  onDeleteProperty,
  isOfferDetails = false,
}: Props) => {
  const getPropertyParticular = useQuery(
    "get-property-particular",
    () =>
      offerApi.getPropertyParticularApiV1OffersPropertyParticularGet({
        offerIds: properties.map(({ id }) => id),
      }),
    {
      enabled: false,

      onSuccess: async (data: ApiResponse<any>) => {
        const fileName = getFileNameFormResponseHeader(data) || "property-particular";
        const url = window.URL.createObjectURL(
          new Blob([await data.raw.blob()], {
            type: properties.length > 1 ? zip : powerpoint,
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      },
    }
  );

  const handleClick = () => getPropertyParticular.refetch();

  if (isOfferDetails) {
    return (
      <Button loading={getPropertyParticular.isFetching} onClick={handleClick}>
        Generate property particular
      </Button>
    );
  }

  return (
    <>
      <Heading level="2">Generate property particular</Heading>
      <PropertiesList properties={properties} onDeleteProperty={onDeleteProperty} />
      {getPropertyParticular.isFetching && <Progress max={properties.length} />}
      <Button loading={getPropertyParticular.isFetching} onClick={handleClick}>
        Generate property particular
      </Button>
    </>
  );
};
