import styled from "styled-components";

import { theme } from "../variables";

export const ExternalLink = styled.a`
  color: ${theme.primaryColor};
  text-decoration: none;
`;

export const DisabledLink = styled.span`
  color: ${theme.primaryColor};
`;
