import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Filterbox } from "@components/filterbox";
import { StyledButton } from "@components/button/button.styled";
import * as actions from "@state/filters/actions";
import { ReactComponent as IconCloseNotActive } from "@svg/icon-close-notactive.svg";

export const CustomFilters: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const customFilters = useSelector((state) => state.filters[state.filters.currentModule].usedCustomFilters);
  const clearAllCustomFilters = () => {
    dispatch(actions.removeCustomFilter("removeAll"));
  };

  return (
    <>
      {customFilters.length > 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignContent: "center" }}>
          <StyledButton type="link" onClick={clearAllCustomFilters}>
            Clear All
            <IconCloseNotActive style={{ marginLeft: "5px" }} />
          </StyledButton>
        </div>
      )}
      {customFilters?.map((customFilter) => (
        <Filterbox
          code={customFilter.code}
          name={customFilter.name}
          dataTypeCode={customFilter.dataTypeCode}
          value={customFilter.value}
          unitValue={customFilter.unitValue}
          floors={customFilter.floors}
          modules={customFilter.modules}
        />
      ))}
    </>
  );
});
