import styled from "styled-components";
import { theme } from "@components/variables";

export const Wrapper = styled.div<{
  size?: number;
}>`
  display: inline-block;
  pointer-events: none;
  svg {
    fill: currentColor;
    height: ${(props) => props.size || 20}px;
    width: ${(props) => props.size || 20}px;
    vertical-align: middle;
    pointer-events: none;
  }
  :hover {
    svg path {
      fill: ${theme.primaryColor};
    }
  }
`;
