import React from "react";
import { Popover } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { turnOnModal } from "@state/newsletter-generate-breakdown-report/actions";

import { usePermissions } from "@hooks/use-permission";
import { useToggle } from "@hooks/use-toggle";
import { useLanguage } from "@hooks/use-language";

import * as S from "./various-things-generator-over-table.styled";
import { GetLinkForm } from "./get-link-form";
import { GenerateNewsletter } from "./generate-newsletter";

import { GenerateBreakdownReport } from "./generate-breakdown-report";
import { GeneratePropertyParticular } from "./generate-property-particular";
import { PropertiesListProps } from "./shared";
import { Redd } from "./redd";
import { GenerateBreakdownReportLng } from "./generate-breakdown-report-lng";

export const VariousThingsGeneratorOverTable = ({ properties, onDeleteProperty }: PropertiesListProps) => {
  const dispatch = useDispatch();
  const newsletterGenerateBreakdownReportReducer = useSelector(
    (appState) => appState.newsletterGenerateBreakdownReportReducer
  );
  const currentModule = useSelector((appState) => appState.offers.currentModule);

  const { state: getLinkVisible, toggle } = useToggle();
  const { state: showNewsletter, toggle: toggleGenerateNewsletter } = useToggle();
  const { state: showPropertyParticualar, toggle: togglePropertyParticualr } = useToggle();
  const { state: showGenerateBreakdownReport, toggle: toggleGenerateBreakdownReport } = useToggle();
  const { state: showGenerateBreakdownLngReport, toggle: toggleGenerateBreakdownLngReport } = useToggle();
  const { state: showReddPopover, toggle: toggleReddPopover } = useToggle();

  const { hasPermission } = usePermissions();
  const { language } = useLanguage();
  const areOffersSelected = !!properties.length;

  const isPickingNewsletter = newsletterGenerateBreakdownReportReducer.typeOfPicking === "newsletter";
  const areOffersSelectedAndNotPickingFromMainTable =
    areOffersSelected && !newsletterGenerateBreakdownReportReducer.isPickingFromMainTable;
  const notIsPickingNewsletterAndIsPickingFromMainTable =
    !isPickingNewsletter && newsletterGenerateBreakdownReportReducer.isPickingFromMainTable;

  const isNewsletterDisabled =
    areOffersSelectedAndNotPickingFromMainTable || notIsPickingNewsletterAndIsPickingFromMainTable;
  const isNewsletterModalVisible =
    showNewsletter && !newsletterGenerateBreakdownReportReducer.isPickingFromMainTable;

  const isGenerateBreakdownDisabled = areOffersSelectedAndNotPickingFromMainTable || isPickingNewsletter;
  const isGenerateBreakdownModalVisible =
    showGenerateBreakdownReport &&
    !isPickingNewsletter &&
    !newsletterGenerateBreakdownReportReducer.isPickingFromMainTable;
  const isGenerateBreakdownLngModalVisible =
    showGenerateBreakdownLngReport &&
    !isPickingNewsletter &&
    !newsletterGenerateBreakdownReportReducer.isPickingFromMainTable;

  const selectedPropertiesForModals = properties.map(({ name, id }) => ({ value: name, text: id }));

  const showRedActionButton = language === "PL" && currentModule === "office";

  const newsletterClick = () => {
    if (isPickingNewsletter) {
      dispatch(turnOnModal());
    } else {
      toggleGenerateNewsletter();
    }
  };
  const breakdownReportClick = () => {
    const isPicking = typeof newsletterGenerateBreakdownReportReducer.typeOfPicking === "string";
    if (isPicking) {
      dispatch(turnOnModal());
    } else {
      toggleGenerateBreakdownReport();
    }
  };
  const breakdownLngReportClick = () => {
    const isPicking = typeof newsletterGenerateBreakdownReportReducer.typeOfPicking === "string";
    if (isPicking) {
      dispatch(turnOnModal());
    } else {
      toggleGenerateBreakdownLngReport();
    }
  };

  return (
    <S.ButtonGroup>
      <Popover
        visible={areOffersSelected && showPropertyParticualar}
        onVisibleChange={togglePropertyParticualr}
        content={<GeneratePropertyParticular properties={properties} onDeleteProperty={onDeleteProperty} />}
        destroyTooltipOnHide
        trigger="click"
        placement="bottom"
      >
        <S.Button
          onClick={togglePropertyParticualr}
          disabled={newsletterGenerateBreakdownReportReducer.isPickingFromMainTable || !areOffersSelected}
        >
          Generate Property Particular
        </S.Button>
      </Popover>
      <S.Button onClick={newsletterClick} disabled={isNewsletterDisabled}>
        {currentModule === "office" ? "Generate Newsletter" : "Generate Properties breakdown report English"}
      </S.Button>
      <GenerateNewsletter
        properties={selectedPropertiesForModals}
        visible={isNewsletterModalVisible}
        onCancel={toggleGenerateNewsletter}
      />
      <S.Button disabled={isGenerateBreakdownDisabled} onClick={breakdownReportClick}>
        {currentModule === "office"
          ? "Generate Properties Breakdown Report"
          : "Generate Properties breakdown report LNG"}
      </S.Button>
      {currentModule === "office" && (
        <S.Button disabled={isGenerateBreakdownDisabled} onClick={breakdownLngReportClick}>
          Generate Properties Breakdown Report LNG
        </S.Button>
      )}
      <GenerateBreakdownReport
        properties={selectedPropertiesForModals}
        visible={isGenerateBreakdownModalVisible}
        onCancel={toggleGenerateBreakdownReport}
      />
      <GenerateBreakdownReportLng
        properties={selectedPropertiesForModals}
        visible={isGenerateBreakdownLngModalVisible}
        onCancel={toggleGenerateBreakdownLngReport}
      />
      <Popover
        visible={getLinkVisible}
        content={<GetLinkForm ids={properties.map(({ id }) => id)} />}
        trigger="click"
        onVisibleChange={toggle}
        placement="bottom"
        destroyTooltipOnHide
      >
        <S.Button
          disabled={newsletterGenerateBreakdownReportReducer.isPickingFromMainTable || !areOffersSelected}
          onClick={toggle}
        >
          Generate Link For Customer Portal
        </S.Button>
      </Popover>
      {showRedActionButton && (
        <Popover
          visible={areOffersSelected && showReddPopover}
          onVisibleChange={toggleReddPopover}
          content={
            <Redd
              properties={properties}
              onDeleteProperty={onDeleteProperty}
              closePopover={toggleReddPopover}
            />
          }
          destroyTooltipOnHide
          trigger="click"
          placement="bottom"
        >
          <S.Button
            onClick={toggleReddPopover}
            disabled={
              newsletterGenerateBreakdownReportReducer.isPickingFromMainTable ||
              !areOffersSelected ||
              !hasPermission("PUT_UPDATE_REDD")
            }
          >
            REDD
          </S.Button>
        </Popover>
      )}
    </S.ButtonGroup>
  );
};

