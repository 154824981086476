/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileTypeOut
 */
export interface FileTypeOut {
    /**
     * 
     * @type {number}
     * @memberof FileTypeOut
     */
    fileTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof FileTypeOut
     */
    fileType: string;
    /**
     * 
     * @type {Date}
     * @memberof FileTypeOut
     */
    modificationDate: Date;
}

export function FileTypeOutFromJSON(json: any): FileTypeOut {
    return FileTypeOutFromJSONTyped(json, false);
}

export function FileTypeOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileTypeOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileTypeId': json['fileTypeId'],
        'fileType': json['fileType'],
        'modificationDate': (new Date(json['modificationDate'])),
    };
}

export function FileTypeOutToJSON(value?: FileTypeOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileTypeId': value.fileTypeId,
        'fileType': value.fileType,
        'modificationDate': (value.modificationDate.toISOString()),
    };
}


