import React from "react";
import { Description } from "./description";
import * as S from "./descriptions-section.styled";

export const DescriptionsSection = () => {
  return (
    <S.Container>
      <Description title="Description (Long ENG)" type="descriptionLongENG" name="offerDescriptionLongENG" />
      <Description title="Description (Long LNG)" type="descriptionLong" name="offerDescriptionLong" />
      <Description title="Description (Short ENG)" type="descriptionENG" name="offerDescriptionENG" />
      <Description title="Description (Short LNG)" type="description" name="offerDescription" />
      <Description
        title="Location description (ENG)"
        type="descriptionLocationENG"
        name="offerDescriptionLocationENG"
      />
      <Description
        title="Location description (LNG)"
        type="descriptionLocation"
        name="offerDescriptionLocation"
      />
    </S.Container>
  );
};
