import React, { useCallback } from "react";
import { FastField, FieldProps } from "formik";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { EditableContentInput } from "@components/form/editable-content-input";
import { updateTitle } from "@utils/offer";
import { usePermissions } from "@hooks/use-permission";

export const Title = () => {
  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();
  const { id } = useParams<{ id: string }>();
  const editable = hasPermission("PUT_OFFER_NAME");

  const onTitleChange = useCallback(
    (name: string) => {
      updateTitle(dispatch)({
        offerId: +id,
        offerInName: {
          name,
        },
      });
    },
    [dispatch, id]
  );

  return (
    <FastField name="offerName">
      {(fieldProps: FieldProps) => (
        <EditableContentInput edibable={editable} onBlur={onTitleChange} large {...fieldProps} />
      )}
    </FastField>
  );
};
