export const pl = {
  available_space: "Dostępna powierzchnia",
  park_details: "Szczegóły nieruchomości",
  park_desc: "Opis nieruchomości",
  park_location_desc: "Opis lokalizacji nieruchomości",
  available_units: "Dostępne jednostki",
  other_attributes: "Standard techniczny",
  map: "Mapa",
  gallery: "Galeria zdjęć",
  contact: "Kontakt",
  /* aut - Available units table */
  aut: {
    building: "Budynki",
    total_size: "Całkowita powierzchnia",
    available_size: "Dostępna powierzchnia",
    available_from: "Dostępne od",
    clear_height: "Wysokość",
    construction_status: "Status budowy",
  },
  your_building_list: "Lista nieruchomości",
  more_info: "Szczegóły",
  attachments: "Załączniki",
  floors: {
    available_areas: "Dostępne powierzchnie",
    floor: "Piętro",
    net_area: "Pow. netto",
    availability: "Dostępność",
  },
};
