import React from "react";
import { useSelector } from "react-redux";
import { GenericSlider } from "./generic-slider";
import { CommponSliderProps } from "./interfaces";

export const FloorSlider: React.FC<CommponSliderProps> = ({
  currentGroup,
  onSliderChange,
  onLowerInputChange,
  onUpperInputChange,
}) => {
  const attributes = useSelector((state) => state.attributes);
  const { distribution } = attributes;
  const minVal = distribution[currentGroup]?.floors?.valueMin;
  const maxVal = distribution[currentGroup]?.floors?.valueMax;

  if (minVal === undefined || maxVal === undefined) {
    return null;
  }

  const lowerFloorValue = attributes.customFilter?.floors?.[0] || minVal;
  const upperFloorValue = attributes.customFilter?.floors?.[1] || maxVal;

  return (
    <GenericSlider
      title="Floors"
      minVal={minVal}
      maxVal={maxVal}
      selectedLowerValue={lowerFloorValue}
      selectedUpperValue={upperFloorValue}
      onSliderChange={onSliderChange}
      onLowerInputChange={onLowerInputChange}
      onUpperInputChange={onUpperInputChange}
    />
  );
};
