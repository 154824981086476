import styled from "styled-components";

import { theme } from "@components/variables";
import { Label as LabelCore } from "@components/input";
import { Button as ButtonCore } from "@components/button";

const border = `1px solid ${theme.grey}`;

export const GroupWrapper = styled.div`
  margin-bottom: 10px;
  border-bottom: ${border};
  padding: 5px 0 10px;

  &:first-of-type {
    border-top: ${border};
  }
`;

export const Label = styled(LabelCore)`
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 10px;
`;

export const NameWithDeleteButton = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Name = styled.p`
  margin-bottom: 0;
`;

export const AddButton = styled(ButtonCore)`
  margin-top: 10px;
`;

export const ButtonPositioner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 50px;
  margin-bottom: 60px;
  justify-content: space-between;
`;

export const AutoCompleteWrapper = styled.div`
  margin-top: 20px;
`;

export const DndItem = styled.div`
  opacity: 1;
  padding: 0 10px;
  margin-left: -10px;
  cursor: move;
  margin-right: -10px;
`;

