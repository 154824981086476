import React from "react";
import * as S from "./image.styled";

export interface IImage {
  src: string;
  alt: string;
  size?: string;
}

const Image: React.FC<IImage> = ({ src, alt, size }) => {
  let height = "fit-content";

  if (size) {
    height = size;
  }

  return <S.ImageStyle height={height} src={src} alt={alt} />;
};

export default Image;
