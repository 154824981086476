export enum Roles {
  ADMIN = "ADMIN",
  AGENT = "AGENT",
  INDUSTRIAL_ADMIN = "INDUSTRIAL_ADMIN",
  INDUSTRIAL_AGENT = "INDUSTRIAL_AGENT",
  GLOBAL_ADMIN = "GLOBAL_ADMIN",
  RETAIL_AGENT = "RETAIL_AGENT",
  RETAIL_ADMINISTRATOR = "RETAIL_ADMINISTRATOR",
}

const modules = {
  ACCESS_AGENCY_MODULE: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.AGENT],
  ACCESS_INDUSTRIAL_MODULE: [Roles.GLOBAL_ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.INDUSTRIAL_AGENT],
  ACCESS_POI_MODULE: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  ACCESS_RETAIL_MODULE: [Roles.RETAIL_ADMINISTRATOR, Roles.RETAIL_AGENT, Roles.GLOBAL_ADMIN],
};

const offers = {
  GET_LIST_OF_OFFERS: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  ADD_NEW_OFFER: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  GET_NUMBER_OF_OFFERS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_OFFER_DETAILS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  CLONE_OFFER: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  DELETE_OFFER: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_OFFER_NAME: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_OFFER_STATUS: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_OFFER_DESCRIPTION: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  PUT_OFFER_NOTE: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_UPDATE_REDD: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  GET_OFFER_ATTRIBUTES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  POST_OFFER_ATTRIBUTE: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_OFFER_ATTRIBUTE_VALUE: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  PUT_OFFER_ATTRIBUTE_NOTE: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  DELETE_ATTRIBUTE: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  GET_OTHER_OFFER_ATTRIBUTES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_OFFER_ATTACHEMENTS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  POST_OFFER_ATTACHEMENTS: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  GET_OFFER_ATTACHEMENT: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  PUT_OFFER_ATTACHEMENT_DESCRIPTION: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  PUT_OFFER_ATTACHEMENT_VISIBILITY: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  PUT_OFFER_ATTACHEMENT_MAIN: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  PUT_OFFER_ATTACHEMENT_FILE_TYPE: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  DELETE_OFFER_ATTACHEMENT: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  POST_FILE_TAG: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  DELETE_FILE_TAG: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  GET_OFFER_TAGS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  POST_OFFER_TAG: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  GET_RECOMENDED_OFFER_TAGS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  DELETE_OFFER_TAG: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  GET_NEWSLETTER_SCHEMAS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  POST_NEWSLETTER_SCHEMA: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_SCHEMA_BY_ID: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  DELETE_SCHEMA_BY_ID: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  DELETE_LETTING: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  DELETE_LETTING_TENNANT: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
};

const dict = {
  GET_PROPERTY_CATEGORIES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_POI_CATEGORIES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_OFFER_STATUSES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_PROPERTY_STATUSES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
};

const geography = {
  GET_GEOGRAPHIES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
};

const property = {
  GET_ASSIGNED_POIS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_LIST_OF_PROPERTIES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  ADD_NEW_PROPERTY: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_PROPERTY_NAME: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_PROPERTY_STATUS: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_PROPERTY_ADDRESS: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  PUT_PROPERTY_GEOGRAPHY: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  PUT_PROPERTY_COORDINATES: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  GET_PROPERTY_PHOTO: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  PUT_PROPERTY_PHOTO: [Roles.GLOBAL_ADMIN, Roles.ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.RETAIL_ADMINISTRATOR],
  DELETE_PROPERTY_PHOTO: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  ASSIGN_POI_TO_PROPERTY: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  UNASSIGN_POI_FROM_PROPERTY: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_SUGGESTED_POIS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  CALCULATE_AND_UPDATE_TRAVEL_TIMES: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
};

const attributes = {
  GET_ATTRIBUTES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_DISTINCT_ATTRIBUTE_UNITS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  GET_ATTRIBUTE_DISTRIBUTION: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  POST_ATTRIBUTE_MODULE: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  PUT_ATTRIBUTE_VISIBILITY: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
  POST_OFFER_ATTRIBUTE_FLOOR: [
    Roles.GLOBAL_ADMIN,
    Roles.ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.RETAIL_ADMINISTRATOR,
  ],
};

const pois = {
  GET_POIS: [Roles.ADMIN, Roles.AGENT, Roles.GLOBAL_ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.INDUSTRIAL_AGENT],
  POST_POIS: [Roles.ADMIN, Roles.AGENT, Roles.GLOBAL_ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.INDUSTRIAL_AGENT],
  PUT_POI_NAME: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  PUT_POI_ADDRESS: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  PUT_POI_CATEGORY: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  PUT_POI_GEOGRAPHY: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  PUT_POI_COORDINATES: [
    Roles.ADMIN,
    Roles.AGENT,
    Roles.GLOBAL_ADMIN,
    Roles.INDUSTRIAL_ADMIN,
    Roles.INDUSTRIAL_AGENT,
    Roles.RETAIL_ADMINISTRATOR,
    Roles.RETAIL_AGENT,
  ],
  DELETE_POI: [Roles.ADMIN, Roles.AGENT, Roles.GLOBAL_ADMIN, Roles.INDUSTRIAL_ADMIN, Roles.INDUSTRIAL_AGENT],
};

export const permissions = {
  ...modules,
  ...attributes,
  ...dict,
  ...geography,
  ...offers,
  ...pois,
  ...property,
};
