import React from "react";

import { useMutation } from "react-query";

import { offerApi } from "@api/core";

import { Button } from "@components/button";
import { PropertiesList, PropertiesListProps } from "../shared";

interface Props extends PropertiesListProps {
  closePopover: () => void;
}

export const Redd = ({ properties, onDeleteProperty, closePopover }: Props) => {
  const reddUpdate = useMutation(
    () => offerApi.putReddApiV1OffersReddUpdatePut({ offerIds: properties.map((x) => x.id) }),
    {
      onSuccess: () => {
        closePopover();
      },
      onError: () => {},
    }
  );

  const onReddClick = () => {
    reddUpdate.mutate();
  };

  return (
    <>
      <PropertiesList properties={properties} onDeleteProperty={onDeleteProperty} />
      <Button onClick={onReddClick}>REDD</Button>
    </>
  );
};
