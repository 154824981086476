/* eslint-disable consistent-return */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import Radio from "antd/lib/radio";
import { Formik, Field, FieldProps } from "formik";
import * as yup from "yup";

import { propertyApi, offerApi } from "@api/core";

import { RadioButton } from "@components/radio-button";

import { offersId } from "@router/paths";

import { usePermissions } from "@hooks/use-permission";
import { notAuthorized } from "@utils/not-authorised-info";

import { initialForm, formKeys, radioValues } from "./formik";
import { AutoCompleteHelper } from "./auto-complete-helper";
import { NewPropertySubform } from "./new-property-subform";
import { NewOfferSubform } from "./new-offer-subform";

import * as S from "./new-offer-modal.styled";

const minimum = 3;

const newOfferSchema = yup.object().shape({
  [formKeys.selectedProperty]: yup.number().when(formKeys.newOffer, {
    is: (value: string) => value === radioValues.existingProperty,
    then: yup.number().required("Please select one property."),
  }),
  [formKeys.newPropertyName]: yup.string().when(formKeys.newOffer, {
    is: (value: string) => value === radioValues.newProperty,
    then: yup
      .string()
      .required("This field is required.")
      .min(minimum, `Name should have minimum ${minimum} digits. // TODO: This has to be specified.`),
  }),
});

export const NewOfferModal: React.FC<ModalProps> = (props) => {
  const { filters, dicts } = useSelector((state) => state);
  const history = useHistory();
  const { hasPermission } = usePermissions();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values: typeof initialForm) => {
    setLoading(true);
    if (values.newOffer === radioValues.existingProperty) {
      offerApi
        .postOfferApiV1OffersPost({
          offerIn: { propertyId: values.selectedProperty as number, module: filters.currentModule },
        })
        .then(({ offerId: id }) => {
          setLoading(false);
          if (props.onCancel) props.onCancel(undefined as any);
          history.push(offersId(id));
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
    if (values.newOffer === radioValues.newProperty) {
      if (!hasPermission("ADD_NEW_PROPERTY")) {
        notAuthorized();
        return undefined;
      }
      propertyApi
        .postPropertyApiV1PropertiesPost({
          property: {
            name: values.newPropertyName,
            categoryId: dicts.categories.data.find(
              ({ code }) => code === filters[filters.currentModule].data.propertyCategoryCode
            )?.id as number,
            statusCode: filters[filters.currentModule].data.propertyStatusCode[0],
          },
        })
        .then(({ propertyId }) =>
          offerApi
            .postOfferApiV1OffersPost({ offerIn: { propertyId, module: filters.currentModule } })
            .then(({ offerId: id }) => {
              if (props.onCancel) props.onCancel(undefined as any);
              history.push(offersId(id));
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            })
        )
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  return (
    <Formik
      initialValues={initialForm}
      onSubmit={onSubmit}
      validationSchema={newOfferSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      {(formikProps) => (
        <Modal
          title={<S.Heading>Add new offer</S.Heading>}
          {...props}
          okType="primary"
          okText="Add offer"
          cancelText="Abort"
          onOk={formikProps.handleSubmit as any}
          onCancel={(e: any) => {
            formikProps.resetForm(formikProps.initialStatus);
            if (props.onCancel) props.onCancel(e);
          }}
          width={1000}
          okButtonProps={{ loading }}
        >
          <Field name={formKeys.newOffer}>
            {({ field }: FieldProps) => (
              <Radio.Group {...field}>
                <RadioButton value={radioValues.existingProperty}>
                  Add new Offer for existing Property
                </RadioButton>
                <RadioButton value={radioValues.newProperty}>Add new Offer for a new Property</RadioButton>
              </Radio.Group>
            )}
          </Field>
          {formikProps.values[formKeys.newOffer] === radioValues.existingProperty && <NewOfferSubform />}
          {formikProps.values[formKeys.newOffer] === radioValues.newProperty && <NewPropertySubform />}
          <AutoCompleteHelper />
        </Modal>
      )}
    </Formik>
  );
};
