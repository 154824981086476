import React from "react";
import BreadcrumbCore, { BreadcrumbItemProps } from "antd/lib/breadcrumb";
import * as S from "./breadcrumb.styled";

import "./breadcrumb.less";

export const Breadcrumb: React.FC<BreadcrumbItemProps> = (props) => (
  <BreadcrumbCore separator={<S.Arrow />} {...props} />
);
export const { Item } = BreadcrumbCore;
