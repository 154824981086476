import React from "react";
import { DateTime } from "luxon";

export const ModificationDate: React.FC<{ date: Date }> = ({ date }) => {
  const getColor = (): string | undefined => {
    const now = new Date();
    const nowMinus30DaysTime = new Date(now.getTime()).setDate(now.getDate() - 30);
    const nowMinus60DaysTime = new Date(now.getTime()).setDate(now.getDate() - 60);
    if (date.getTime() < nowMinus60DaysTime) {
      return "#ff4d4f";
    }

    if (date.getTime() < nowMinus30DaysTime) {
      return "#dab812";
    }

    return "#2a8c2a";
  };

  return (
    <span style={{ color: getColor() }}>{DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm:ss")}</span>
  );
};
