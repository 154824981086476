import styled from "styled-components";
import { theme } from "@components/variables";

export const Title = styled.span`
  font-weight: ${theme.fontBold};
`;

export const PaddingTopAndButton = styled.div`
  padding: 15px 0;
  border: 1px solid ${theme.grey};
  border-bottom: none;
  :last-child {
    border-bottom: 1px solid ${theme.grey};
  }
`;
