// form keys
const newOffer = "newOffer";
const newPropertyName = "newPropertyName";
const findProperty = "findProperty";
const selectedProperty = "selectedProperty";

// radio values
const newProperty = "new_offer_new_property";
const existingProperty = "new_offer_existing_property";

export const radioValues = { newProperty, existingProperty };

export const initialForm = {
  [newOffer]: existingProperty,
  [newPropertyName]: "",
  [findProperty]: "",
  [selectedProperty]: undefined as number | undefined,
};

export const formKeys: Record<string, keyof typeof initialForm> = {
  newOffer,
  newPropertyName,
  findProperty,
  selectedProperty,
};

export type InitialFormType = typeof initialForm;
