import styled from "styled-components";
import { theme } from "@components/variables";

export interface IconEyeStyle {
  show: boolean;
}

export interface EditableContentWrapperProps {
  loading?: boolean;
}

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const HeadTableRow = styled.tr`
  font-weight: 600;
  border-bottom: 1px solid ${theme.tableBorder};
  justify-content: center;
`;

export const HeadTableCell = styled.td`
  padding: 10px 4px 10px 4px;
  text-align: center;
  vertical-align: middle;
  text-wrap: nowrap;
`;

export const TableRow = styled.tr`
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  border: 1px solid #d3d3d3;
  background-color: ${theme.lightTeal};

  /* @media screen and (max-width: 1440px) {
  padding: 0 17px;
} */

  &:hover {
    background-color: ${theme.lightGrey2};
    cursor: pointer;
  }

  &:first-of-type {
    border-top: 1px solid #d3d3d3;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.tableBorder};
  }
`;

export const CollapseTableRow = styled.tr`
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  align-items: center;

  /* @media screen and (max-width: 1440px) {
  padding: 0 17px;
} */

  &:hover {
    background-color: ${theme.lightTeal};
    cursor: pointer;
  }
`;

export const TableCell = styled.td`
  padding: 15px 7px;
  fontsize: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    flex-grow: 15;
    font-weight: 600;
  }

  &:nth-of-type(2) {
    flex-grow: 5;
  }

  &:nth-of-type(n + 3) {
    flex: 1;
  }
`;

export const Label = styled.label`
  &:not(:first-of-type) {
    margin-top: 20px;
  }
`;
