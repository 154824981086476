import React, { useCallback } from "react";
import { easeBounceInOut } from "d3-ease";
import { useSpring, animated } from "react-spring";

export type Value = { binLow: number; binHigh: number };

interface Props {
  x: number;
  y: number;
  yStart: number;
  width: number;
  height: number;
  value: Value;
  onClick?: (value: Value) => void;
  quantity?: number;
}

const duration = 300;

export const Bin: React.FC<Props> = ({ y, yStart, height, value, onClick, quantity, ...restProps }) => {
  const transitionHeight = useSpring({
    config: { duration, easing: easeBounceInOut },
    from: { y: yStart, height: 0 },
    to: async (next: any) => {
      await next({ y, height });
    },
  });
  const transitionOpacity = useSpring({
    delay: 700,
    config: { duration },
    from: { opacity: 0 },
    to: async (next: any) => {
      await next({ opacity: 1 });
    },
  });

  const handleClick = useCallback(() => onClick && onClick(value), [onClick, value]);

  return (
    <>
      {quantity && (
        <animated.text
          x={restProps.x + restProps.width / 2}
          y={y - 5}
          style={transitionOpacity}
          className="text"
          textAnchor="middle"
          onClick={handleClick}
        >
          {quantity}
        </animated.text>
      )}
      <animated.rect
        onClick={handleClick}
        style={{ ...restProps, ...transitionHeight, cursor: "pointer" }}
        className="bin"
      />
    </>
  );
};
