import React from "react";

import { DeleteButton } from "@components/delete-button";

import * as S from "./properties-list.styled";

import { Property } from "../../types";

export interface PropertiesListProps {
  properties: Property[];
  onDeleteProperty?: React.MouseEventHandler<HTMLElement>;
}

export const PropertiesList: React.FC<PropertiesListProps> = ({ properties, onDeleteProperty }) => (
  <>
    {properties.map(({ id, name }) => (
      <S.PropertiesNamesWrapper key={id}>
        <S.Name>{name}</S.Name>
        {onDeleteProperty && <DeleteButton value={String(id)} onClick={onDeleteProperty} />}
      </S.PropertiesNamesWrapper>
    ))}
  </>
);
