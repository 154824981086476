import styled, { css } from "styled-components";
import { StyledButton } from "@components/button";

const disabledStyle = css`
  background-color: transparent;
`;

export const DeleteButton = styled(StyledButton)`
  &.ant-btn {
    box-shadow: none;
  }

  svg {
    transition: 0.3s ease-in-out;
  }
  &:disabled {
    ${disabledStyle}
  }
  &:hover {
    &:disabled {
      ${disabledStyle}
    }
    svg {
      transform: rotate(90deg);
    }
    path {
      fill: ${({ theme }) => theme.primaryColor};
    }
  }
`;
