import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import { AttributeValue } from "@api/client";
import {
  addQuickformAttribute,
  deleteQuickformFloor,
  deleteQuickformModule,
  updateAttribute,
} from "@utils/offer";

import { RetailInputs } from "./module-attribute-inputs/retail-inputs";
import { OfficeInputs } from "./module-attribute-inputs/office-inputs";
import { IndustrialInputs } from "./module-attribute-inputs/industrial-inputs";
import { officeAttributes } from "./module-attributes/office-attributes";
import { officeAttributesItaly } from "./module-attributes/office-attributes-italy";
import { retailAttributes } from "./module-attributes/retail-attributes";
import { industrialAttributes } from "./module-attributes/industrial";
import * as S from "./quick-form.styled";
import { TableRowOffice } from "./table-rows/table-row-office";

import { AddFloorValueForm } from "./add-floor-value-form";
import { industrialAttributesItaly } from "./module-attributes/industrial-italy";
import { OfficeInputsItaly } from "./module-attribute-inputs/office-inputs-italy";
import { IndustrialInputsItaly } from "./module-attribute-inputs/industrial-inputs-italy";
import { TableRowOfficeItaly } from "./table-rows/table-row-office-italy";
import { TableRowIndustrial } from "./table-rows/table-row-industrial";
import { TableRowIndustrialItaly } from "./table-rows/table-row-industrial-italy";
import { TableRowRetail } from "./table-rows/table-row-retail";

export const QuickForm: React.FC = () => {
  const offers = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const isOfficeModule = offers.currentModule === "office";
  const isIndustrialModule = offers.currentModule === "industrial";
  const isRetailModule = offers.currentModule === "retail";
  const hrefHost = window.location.host;
  const isPolishOrCzech = hrefHost.includes("pl") || hrefHost.includes("cz");

  const handleUpdateAttribute = useCallback(
    (attributeId: number, attributeValue: AttributeValue, groupKey: string): void =>
      updateAttribute(dispatch)(
        {
          offerId: +id,
          attributeId: attributeId,
          attributeValue: attributeValue,
        },
        null,
        groupKey
      ),
    [dispatch, id]
  );

  const handleAddAttribute = useCallback(
    (dataTypeCode: string, name: string): void => {
      addQuickformAttribute(dispatch)({ offerId: +id, dataTypeCode: dataTypeCode, name: name });
    },
    [dispatch, id]
  );

  const handleDeleteFloor = useCallback(
    (floor: string): void => {
      deleteQuickformFloor(dispatch)({ offerId: +id, floor: floor });
    },
    [dispatch, id]
  );

  const handleDeleteModule = useCallback(
    (floor: string, module: string): void => {
      deleteQuickformModule(dispatch)({ offerId: +id, floor: floor, module: module });
    },
    [dispatch, id]
  );

  const quickformAttributes = offers.offerDetails.data?.quickformAttributes;
  const attributeDataTypes = { string: "string", numeric: "numeric", date: "datetime" };
  const attributeNames = {
    name: "Name",
    availableOfficeSpace: "Available Office Space",
    availability: "Availability",
    askingRent: "Asking Rent",
    type: "Type",
    comments: "Comments",
    buildingName: "Building Name",
    availableSpace: "Available Space",
    clearHeight: "Clear Height",
    columnGrid: "Column Grid",
    floorLoadingCapacity: "Floor Loading Capacity",
    documentComments: "Document Comments",
    internalComments: "Internal Comments",
    unit: "Unit",
    brand: "Brand",
    category1: "Category 1",
    category2: "Category 2",
    gla: "GLA",
    salesArea: "Sales Area",
    rent: "Rent",
    date: "Date",
    level: "Level",
    notes: "Notes",
    totalNetArea: "Total Net Area",
    leasedArea: "Leased Area",
    tenant: "Tenant",
    transactionDate: "Transaction Date",
  };

  return (
    <div style={{ marginTop: "30px" }}>
      {offers.offerDetails.data && quickformAttributes && (
        <S.Table>
          <S.HeadTableRow>
            {isOfficeModule &&
              (!isPolishOrCzech ? officeAttributesItaly : officeAttributes).map((a) => {
                return (
                  <S.HeadTableCell width={a.cellWidth} key={a.key}>
                    {a.code}
                  </S.HeadTableCell>
                );
              })}
            {isIndustrialModule &&
              (!isPolishOrCzech ? industrialAttributesItaly : industrialAttributes).map((a) => {
                return (
                  <S.HeadTableCell width={a.cellWidth} key={a.key}>
                    {a.code}
                  </S.HeadTableCell>
                );
              })}

            {isRetailModule &&
              retailAttributes.map((a) => {
                return (
                  <S.HeadTableCell width={a.cellWidth} key={a.key}>
                    {a.code}
                  </S.HeadTableCell>
                );
              })}
          </S.HeadTableRow>

          <S.HeadTableRow>
            <S.HeadTableCell>Total</S.HeadTableCell>

            {isOfficeModule &&
              (!isPolishOrCzech ? (
                <OfficeInputsItaly
                  quickformAttributes={quickformAttributes}
                  handleAddAttribute={handleAddAttribute}
                  handleUpdateAttribute={handleUpdateAttribute}
                  attributeNames={attributeNames}
                  attributeDataTypes={attributeDataTypes}
                />
              ) : (
                <OfficeInputs
                  quickformAttributes={quickformAttributes}
                  handleAddAttribute={handleAddAttribute}
                  handleUpdateAttribute={handleUpdateAttribute}
                  attributeNames={attributeNames}
                  attributeDataTypes={attributeDataTypes}
                />
              ))}

            {isIndustrialModule &&
              (!isPolishOrCzech ? (
                <IndustrialInputsItaly
                  quickformAttributes={quickformAttributes}
                  handleAddAttribute={handleAddAttribute}
                  handleUpdateAttribute={handleUpdateAttribute}
                  attributeNames={attributeNames}
                  attributeDataTypes={attributeDataTypes}
                />
              ) : (
                <IndustrialInputs
                  quickformAttributes={quickformAttributes}
                  handleAddAttribute={handleAddAttribute}
                  handleUpdateAttribute={handleUpdateAttribute}
                  attributeNames={attributeNames}
                  attributeDataTypes={attributeDataTypes}
                />
              ))}

            {isRetailModule && (
              <RetailInputs
                quickformAttributes={quickformAttributes}
                handleAddAttribute={handleAddAttribute}
                handleUpdateAttribute={handleUpdateAttribute}
                attributeNames={attributeNames}
                attributeDataTypes={attributeDataTypes}
              />
            )}
          </S.HeadTableRow>

          {isOfficeModule &&
            offers.offerDetails.data?.quickformAttributes.floors.map((floor) =>
              !isPolishOrCzech ? (
                <TableRowOfficeItaly
                  floor={floor}
                  parentAttributes={quickformAttributes}
                  onAttributeUpdate={handleUpdateAttribute}
                  onDeleteFloor={handleDeleteFloor}
                  onDeleteModule={handleDeleteModule}
                />
              ) : (
                <TableRowOffice
                  floor={floor}
                  parentAttributes={quickformAttributes}
                  onAttributeUpdate={handleUpdateAttribute}
                  onDeleteFloor={handleDeleteFloor}
                  onDeleteModule={handleDeleteModule}
                />
              )
            )}
          {isIndustrialModule &&
            offers.offerDetails.data?.quickformAttributes.floors.map((floor) =>
              !isPolishOrCzech ? (
                <TableRowIndustrialItaly
                  floor={floor}
                  parentAttributes={quickformAttributes}
                  onAttributeUpdate={handleUpdateAttribute}
                  onDeleteFloor={handleDeleteFloor}
                  onDeleteModule={handleDeleteModule}
                />
              ) : (
                <TableRowIndustrial
                  floor={floor}
                  parentAttributes={quickformAttributes}
                  onAttributeUpdate={handleUpdateAttribute}
                  onDeleteFloor={handleDeleteFloor}
                  onDeleteModule={handleDeleteModule}
                />
              )
            )}
          {isRetailModule &&
            offers.offerDetails.data?.quickformAttributes.floors.map((floor) => (
              <TableRowRetail
                floor={floor}
                parentAttributes={quickformAttributes}
                onAttributeUpdate={handleUpdateAttribute}
                onDeleteFloor={handleDeleteFloor}
                onDeleteModule={handleDeleteModule}
              />
            ))}
        </S.Table>
      )}
      <div style={{ margin: "20px" }} />
      <AddFloorValueForm />
    </div>
  );
};
