import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { FiltersQuery } from "@state/filters/types";
import { setAllFilters } from "@state/filters/actions";
import { Form } from "./form";
import { AutoSavingComponent } from "./autosaving-helper";
import { ExtraFilters } from "../extra-filters/extra-filters";
import { CustomFilters } from "./custom-filters/custom-filters";

export const Filters: React.FC = () => {
  const dispatch = useDispatch();

  const { offer, property, categories } = useSelector((state) => state.dicts);

  const filters = useSelector((state) => state.filters[state.filters.currentModule]);

  const initialValues = filters.data;

  const onSubmit = useCallback((values: FiltersQuery) => {
    dispatch(setAllFilters(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
        {() => (
          <>
            <Form
              offerStatusCode={offer.data}
              propertyCategoryCode={categories.data}
              propertyStatusCode={property.data}
            />
            <ExtraFilters />
            <CustomFilters />
            <AutoSavingComponent />
          </>
        )}
      </Formik>
    </div>
  );
};
