import React, { useEffect, useState } from "react";
import { Providers } from "@microsoft/mgt-element";
import { Roles } from "../config/permissions";

const ADRoleToAppRole: Record<string, Roles> = {
  IndustrialAdministrator: Roles.INDUSTRIAL_ADMIN,
  IndustrialAgent: Roles.INDUSTRIAL_AGENT,
  Administrator: Roles.ADMIN,
  Agent: Roles.AGENT,
  GlobalAdministrator: Roles.GLOBAL_ADMIN,
  RetailAgent: Roles.RETAIL_AGENT,
  RetailAdministrator: Roles.RETAIL_ADMINISTRATOR,
};

interface IUserData {
  userRole: Roles | null;
}

export const UserDataContext = React.createContext<IUserData>({ userRole: null });

export const UserDataProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<IUserData>({ userRole: null });

  useEffect(() => {
    const { roles } = (Providers.globalProvider as any).publicClientApplication.getActiveAccount()
      .idTokenClaims;

    const filteredRoles = Object.keys(ADRoleToAppRole).filter((x) => roles.includes(x)) as Roles[];
    if (filteredRoles[0]) {
      setValue({
        userRole: ADRoleToAppRole[filteredRoles[0]],
      });
    }
  }, []);

  return (
    <UserDataContext.Provider value={value}>{value.userRole && <>{children}</>}</UserDataContext.Provider>
  );
};
