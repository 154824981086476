import { createAction } from "typesafe-actions";
import { CustomFilter, FiltersQuery } from "@state/filters/types";
import { Module } from "@state/types";

export const SET_ALL_FILTERS = "FILTERS/SET_ALL_FILTERS";
export const SET_PROPERTY_CATEGORY_CODE = "FILTERS/SET_PROPERTY_CATEGORY_CODE";
export const SET_PROPERTY_STATUS_CODE = "FILTERS/SET_PROPERTY_STATUS_CODE";
export const SET_OFFER_STATUS_CODE = "FILTERS/SET_OFFER_STATUS_CODE";
export const SET_PROPERTY_NAME_OR_ADDRESS = "FILTERS/SET_PROPERTY_NAME_OR_ADDRESS";
export const SET_PROPERTY_GEOGRAPHY_IDS = "FILTERS/SET_PROPERTY_GEOGRAPHY_IDS";
export const ADD_CUSTOM_FILTER = "FILTERS/ADD_CUSTOM_FILTER";
export const REMOVE_CUSTOM_FILTER = "FILTERS/REMOVE_CUSTOM_FILTER";
export const CHANGE_CUSTOM_FILTER = "FILTERS/CHANGE_CUSTOM_FILTER";
export const SET_CUSTOM_FILTER_REQUEST = "FILTERS/CUSTOM_FILTER_REQUEST";
export const SET_REFRESH_FILTERS = "FILTERS/SET_REFRESH_FILTERS";
export const SET_CURRENT_MODULE = "FILTERS/SET_CURRENT_MODULE";

export const setAllFilters = createAction(SET_ALL_FILTERS)<FiltersQuery>();
export const setPropertyCategoryCode = createAction(SET_PROPERTY_CATEGORY_CODE)<string>();
export const setPropertyStatusCode = createAction(SET_PROPERTY_STATUS_CODE)<string[]>();
export const setOfferStatusCode = createAction(SET_OFFER_STATUS_CODE)<string[]>();
export const setPropertyNameOrAddress = createAction(SET_PROPERTY_NAME_OR_ADDRESS)<string>();
export const setPropertyGeographyIds = createAction(SET_PROPERTY_GEOGRAPHY_IDS)<number[]>();
export const changeCustomFilter = createAction(CHANGE_CUSTOM_FILTER)<CustomFilter>();
export const addCustomFilter = createAction(ADD_CUSTOM_FILTER)<CustomFilter>();
export const removeCustomFilter = createAction(REMOVE_CUSTOM_FILTER)<string>();
export const setCustomFilterRequest = createAction(SET_CUSTOM_FILTER_REQUEST)();
export const setRefreshFilters = createAction(SET_REFRESH_FILTERS)<boolean>();
export const setCurrentModule = createAction(SET_CURRENT_MODULE)<Module>();
