import React, { ReactNode } from "react";
import { useMutation } from "react-query";
import { Popconfirm } from "antd";
import { Flex } from "@components/flex/flex";
import { DeleteButton } from "@components/delete-button";
import { usePermissions } from "@hooks/use-permission";

import * as S from "./header.styled";

interface Props {
  title: ReactNode;
  onDelete: () => void;
  entityId: number | string;
  deleteApi: any;
  delteApiArg: any;
}

export const Header: React.FC<Props> = ({ title, onDelete, entityId, deleteApi, delteApiArg, children }) => {
  const { hasPermission } = usePermissions();
  const { mutate: handleDeleteApi, isLoading } = useMutation(entityId.toString(), deleteApi(), {
    onSuccess: () => {
      onDelete();
    },
    onError: (exception) => {
      console.log(exception);
    },
  });
  const handleRemove = () => {
    handleDeleteApi({ ...delteApiArg });
  };

  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <S.Title>{title}</S.Title>
      {children}
      {hasPermission("DELETE_LETTING") && (
        <Popconfirm
          placement="left"
          title="Are you sure to delete?"
          onConfirm={handleRemove}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <DeleteButton onClick={onDeleteClick} loading={isLoading} />
        </Popconfirm>
      )}
    </Flex>
  );
};
