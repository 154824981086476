import React from "react";
import { FastField, FieldProps } from "formik";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import { Loader } from "@components/loader";
import { EditableContentInput } from "@components/form/editable-content-input";
import { usePermissions } from "@hooks/use-permission";
import { DeleteButton } from "../../../components/delete-button/delete-button";

import * as S from "./attributes.styled";

interface EditNoteProps {
  index: number;
  groupKey: string;
  onClose: () => void;
  loading: boolean;
  onUpdate: (value: string | null) => void;
}

export const EditNote: React.FC<EditNoteProps> = ({ index, groupKey, onClose, loading, onUpdate }) => {
  const { hasPermission } = usePermissions();

  if (loading)
    return (
      <S.EditableContentWrapper loading={loading}>
        <Loader />
      </S.EditableContentWrapper>
    );
  return (
    <S.EditableContentWrapper>
      <S.IconWrapper>
        <InfoCircleOutlined />
      </S.IconWrapper>
      <S.InputWrapper>
        <FastField name={`customAttributes[${groupKey}][${index}].note`}>
          {(fieldProps: FieldProps) => (
            <EditableContentInput
              edibable={hasPermission("PUT_OFFER_ATTRIBUTE_NOTE")}
              onBlur={onUpdate}
              {...fieldProps}
            />
          )}
        </FastField>
      </S.InputWrapper>
      <S.DeleteButtonWrapper>
        <DeleteButton
          onClick={() => {
            onClose()
            onUpdate(null)
          }}
        />
      </S.DeleteButtonWrapper>
    </S.EditableContentWrapper>
  );
};

