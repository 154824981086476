import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { message, Popover } from "antd";
import { useMutation } from "react-query";
import { AddButton, Button } from "@components/button";
import { useToggle } from "@hooks/use-toggle";
import { usePermissions } from "@hooks/use-permission";
import { Input } from "@components/input";
import { getOfferApi } from "@api/core";
import {
  ModuleIn,
  PostOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRequest,
} from "@api/client";
import { updateAttributeAfterFloorUpdate } from "@state/offers/actions";

import { Label } from "./attributes.styled";

interface OnlyFormProps {
  onClose: () => void;
}

interface FormProps {
  attributeId: number;
}

const { offerApi } = getOfferApi();

const typeCallback =
  (callback: React.Dispatch<React.SetStateAction<number | undefined>>) =>
  (event: ChangeEvent<HTMLInputElement>) =>
    callback(Number(event.target.value));

const moduleLabelId = "Module";

const Form: React.FC<FormProps & OnlyFormProps> = ({ attributeId, onClose }) => {
  const { hasPermission } = usePermissions();
  const dispatch = useDispatch();
  const [module, setModule] = useState<number>();

  const { mutate, isLoading, error } = useMutation(
    () =>
      offerApi.postOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPost({
        attributeId,
        moduleIn: { module: module ?? -1 } as ModuleIn,
      } as PostOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("success");
      },
    }
  );

  const handleModuleChange = typeCallback(setModule);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    mutate();
  };

  if (error) return <>Something went wrong</>;

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlFor={moduleLabelId}>{moduleLabelId}:</Label>
      <Input id={moduleLabelId} type="number" value={module} onChange={handleModuleChange} min={0} />
      <div style={{ marginTop: 20 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={isLoading}
          disabled={!hasPermission("POST_ATTRIBUTE_MODULE")}
        >
          Add
        </Button>
      </div>
    </form>
  );
};

export const AddModuleValueForm: React.FC<FormProps> = (props) => {
  const { state: visible, toggle } = useToggle();
  return (
    <Popover
      visible={visible}
      title="Add number of Attrbiute"
      content={<Form {...props} onClose={toggle} />}
      destroyTooltipOnHide
      placement="rightTop"
      trigger="click"
      onVisibleChange={toggle}
    >
      <AddButton onClick={toggle}>Add module value</AddButton>
    </Popover>
  );
};

