/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FloorsLettingAttribute,
    FloorsLettingAttributeFromJSON,
    FloorsLettingAttributeFromJSONTyped,
    FloorsLettingAttributeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomLettingAttributeOut
 */
export interface CustomLettingAttributeOut {
    /**
     * 
     * @type {string}
     * @memberof CustomLettingAttributeOut
     */
    valueOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomLettingAttributeOut
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomLettingAttributeOut
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CustomLettingAttributeOut
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomLettingAttributeOut
     */
    dataTypeCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomLettingAttributeOut
     */
    isVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomLettingAttributeOut
     */
    attributeId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomLettingAttributeOut
     */
    valueInterpretationStatusCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomLettingAttributeOut
     */
    valueInterpretationMessage?: string;
    /**
     * 
     * @type {Array<FloorsLettingAttribute>}
     * @memberof CustomLettingAttributeOut
     */
    floors?: Array<FloorsLettingAttribute>;
}

export function CustomLettingAttributeOutFromJSON(json: any): CustomLettingAttributeOut {
    return CustomLettingAttributeOutFromJSONTyped(json, false);
}

export function CustomLettingAttributeOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomLettingAttributeOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valueOriginal': !exists(json, 'valueOriginal') ? undefined : json['valueOriginal'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'code': json['code'],
        'name': json['name'],
        'dataTypeCode': json['dataTypeCode'],
        'isVisible': !exists(json, 'isVisible') ? undefined : json['isVisible'],
        'attributeId': json['attributeId'],
        'valueInterpretationStatusCode': !exists(json, 'valueInterpretationStatusCode') ? undefined : json['valueInterpretationStatusCode'],
        'valueInterpretationMessage': !exists(json, 'valueInterpretationMessage') ? undefined : json['valueInterpretationMessage'],
        'floors': !exists(json, 'floors') ? undefined : ((json['floors'] as Array<any>).map(FloorsLettingAttributeFromJSON)),
    };
}

export function CustomLettingAttributeOutToJSON(value?: CustomLettingAttributeOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valueOriginal': value.valueOriginal,
        'note': value.note,
        'code': value.code,
        'name': value.name,
        'dataTypeCode': value.dataTypeCode,
        'isVisible': value.isVisible,
        'attributeId': value.attributeId,
        'valueInterpretationStatusCode': value.valueInterpretationStatusCode,
        'valueInterpretationMessage': value.valueInterpretationMessage,
        'floors': value.floors === undefined ? undefined : ((value.floors as Array<any>).map(FloorsLettingAttributeToJSON)),
    };
}


