/* eslint-disable react/destructuring-assignment */
import React, { ReactText, useState } from "react";
import Highlighter from "react-highlight-words";
import { Space } from "antd";
import { ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "@components/input";
import { Button } from "@components/button";

const getColumnSearchProps =
  ({
    handleReset,
    handleSearch,
    searchText,
    searchColumn,
  }: {
    handleReset: (cb: (() => void) | undefined) => void;
    handleSearch: (selectedKeys: ReactText[], confirm: () => void, dataIndex: string) => void;
    searchText: ReactText;
    searchColumn: string;
  }) =>
  (dataIndex: string, placeholder: string) =>
    ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search by ${placeholder}`}
            value={selectedKeys[0]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (value: string, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: (text: React.ReactNode) =>
        searchColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    } as Partial<ColumnType<any>>);

export const useSearchProps = () => {
  const [searchText, setSearchText] = useState<ReactText>("");
  const [searchColumn, setSearchColumn] = useState("");

  const handleSearch = (selectedKeys: ReactText[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters?.();
    setSearchText("");
  };

  return getColumnSearchProps({ handleSearch, handleReset, searchText, searchColumn });
};

