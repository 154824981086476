import styled from "styled-components";
import { Radio } from "antd";

import { Input as InputCore } from "@components/input";
import * as Table from "@components/table";

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin-left: auto;
  }
`;

export const TableRow = styled(Table.TableRow)`
  align-items: center;
`;

export const TableCell = styled(Table.TableCell)`
  height: 100px;
`;

export const Input = styled(InputCore)`
  margin-bottom: 24px;
  max-width: 500px;
`;

export const InputName = styled(InputCore)`
  margin-bottom: 24px;
  max-width: 300px;
`;

export const RadioGroup = styled(Radio.Group)`
  width: 100%;

  & label {
    display: inline-block;
    width: 231px;
    margin: 5px 0;
  }
`;

export const NewOfferWrapper = styled.div`
  display: flex;
`;

export const WrapperHalf = styled.div`
  flex: 1;
`;

export const GeographySelection = styled.div`
  max-width: 500px;
  padding-bottom: 24px;
`;
