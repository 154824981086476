import React from "react";
import { REDDButton } from "./redd-button/redd-button";
import { REDDAllowDownload } from "./redd-allow-download/redd-allow-download";
import { AddREDDID } from "./add-reddid/add-reddid";

export const OfferREDDPanel = () => {
  return (
    <div>
      <AddREDDID />
      <REDDAllowDownload />
      <REDDButton />
    </div>
  );
};
