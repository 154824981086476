import { useState } from "react";
import { getEnv } from "@utils/get-env";

type Language = "PL" | "CZ";

export const useLanguage = () => {
  const [language, setLanguage] = useState<Language>(getEnv("REACT_APP_LANGUAGE"));

  return { language, setLanguage };
};
