import { useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import { debounce } from "throttle-debounce";

export const AutoSavingComponent = () => {
  const formik = useFormikContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submit = useCallback(
    debounce(200, () => {
      formik.submitForm();
    }),
    []
  );

  useEffect(() => {
    if (formik.values !== formik.initialValues) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);
  return null;
};
