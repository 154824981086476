import React from "react";
import { FieldProps } from "formik";
import { Select as BaseSelect, Option, SelectProps, SelectValue } from "../../select";

type SelectOption = {
  label: React.ReactNode;
  value: React.ReactText;
};

type Props<T> = FieldProps & { options: SelectOption[] } & SelectProps<T>;

export const Select = <T extends SelectValue>({ field, form, options, ...rest }: Props<T>) => {
  return (
    <BaseSelect<T>
      {...field}
      {...rest}
      onChange={(value: T) => {
        form.setFieldValue(field.name, value);
        field.onChange(value);
      }}
    >
      {options.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))}
    </BaseSelect>
  );
};
