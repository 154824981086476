import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

export const ClientProvider: React.FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <>
      {children}
      <ReactQueryDevtools />
    </>
  </QueryClientProvider>
);
