import React from "react";
import styled from "styled-components";
import PopoverCore, { PopoverProps } from "antd/lib/popover";

export interface PopoverStyles {
  error?: boolean;
}

interface Props {
  className?: string;
}

const MyPopover: React.FC<Props & PopoverProps> = ({ className, ...props }) => (
  <PopoverCore overlayClassName={className} {...props} />
);

export const Popover = styled(MyPopover)<PopoverStyles>`
  & .ant-popover-content {
    border: 2px solid ${({ error, theme }) => (error ? theme.warning : theme.blue)};
    border-radius: 2px;
  }

  & .ant-popover-title {
    color: ${({ error, theme }) => (error ? theme.warning : theme.blue)};
  }

  & .ant-popover-arrow {
    border-right-color: ${({ error, theme }) => (error ? theme.warning : theme.blue)} !important;
    border-bottom-color: ${({ error, theme }) => (error ? theme.warning : theme.blue)} !important;
  }
`;
