/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttributeTranslation,
    AttributeTranslationFromJSON,
    AttributeTranslationToJSON,
    AttributeTranslationOut,
    AttributeTranslationOutFromJSON,
    AttributeTranslationOutToJSON,
    AuditTrailOut,
    AuditTrailOutFromJSON,
    AuditTrailOutToJSON,
    BaseAttribute,
    BaseAttributeFromJSON,
    BaseAttributeToJSON,
    CustomAttributeDistinctUnits,
    CustomAttributeDistinctUnitsFromJSON,
    CustomAttributeDistinctUnitsToJSON,
    CustomAttributeDistribution,
    CustomAttributeDistributionFromJSON,
    CustomAttributeDistributionToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRequest {
    attributeCode: string;
    propertyCategoryCode: string;
    searchPhrase?: string;
    unit?: string;
    propertyStatusCode?: Array<string>;
    offerStatusCode?: Array<string>;
    propertyNameOrAddress?: string;
    propertyGeographyIds?: Array<number>;
    module?: string;
    customAttributeFilter?: object;
}

export interface GetAttributesApiV1AttributesGetRequest {
    categoryCode: string;
}

export interface GetDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGetRequest {
    attributeCode: string;
}

export interface GetOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGetRequest {
    offerId: number;
}

export interface PutAttributeTranslationApiV1AttributesAttributeCodeTranslationPutRequest {
    attributeCode: string;
    attributeTranslation: AttributeTranslation;
}

/**
 * 
 */
export class AttributeApi extends runtime.BaseAPI {

    /**
     * Get Attribute Distribution
     */
    async getAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRaw(requestParameters: GetAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRequest): Promise<runtime.ApiResponse<CustomAttributeDistribution>> {
        if (requestParameters.attributeCode === null || requestParameters.attributeCode === undefined) {
            throw new runtime.RequiredError('attributeCode','Required parameter requestParameters.attributeCode was null or undefined when calling getAttributeDistributionApiV1AttributesAttributeCodeDistributionGet.');
        }

        if (requestParameters.propertyCategoryCode === null || requestParameters.propertyCategoryCode === undefined) {
            throw new runtime.RequiredError('propertyCategoryCode','Required parameter requestParameters.propertyCategoryCode was null or undefined when calling getAttributeDistributionApiV1AttributesAttributeCodeDistributionGet.');
        }

        let queryParameters: any = {};

        if (requestParameters.propertyCategoryCode !== undefined) {
            queryParameters['propertyCategoryCode'] = requestParameters.propertyCategoryCode;
        }

        if (requestParameters.searchPhrase !== undefined) {
            queryParameters['searchPhrase'] = requestParameters.searchPhrase;
        }

        if (requestParameters.unit !== undefined) {
            queryParameters['unit'] = requestParameters.unit;
        }

        if (requestParameters.propertyStatusCode) {
            queryParameters['propertyStatusCode'] = requestParameters.propertyStatusCode;
        }

        if (requestParameters.offerStatusCode) {
            queryParameters['offerStatusCode'] = requestParameters.offerStatusCode;
        }

        if (requestParameters.propertyNameOrAddress !== undefined) {
            queryParameters['propertyNameOrAddress'] = requestParameters.propertyNameOrAddress;
        }

        if (requestParameters.propertyGeographyIds) {
            queryParameters['propertyGeographyIds'] = requestParameters.propertyGeographyIds;
        }

        if (requestParameters.module !== undefined) {
            queryParameters['module'] = requestParameters.module;
        }

        if (requestParameters.customAttributeFilter !== undefined) {
            queryParameters = {
                ...queryParameters,
                ...requestParameters.customAttributeFilter
            };
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/attributes/{attributeCode}/distribution`.replace(`{${"attributeCode"}}`, encodeURIComponent(String(requestParameters.attributeCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAttributeDistributionFromJSON(jsonValue));
    }

    /**
     * Get Attribute Distribution
     */
    async getAttributeDistributionApiV1AttributesAttributeCodeDistributionGet(requestParameters: GetAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRequest): Promise<CustomAttributeDistribution> {
        const response = await this.getAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Attributes
     */
    async getAttributesApiV1AttributesGetRaw(requestParameters: GetAttributesApiV1AttributesGetRequest): Promise<runtime.ApiResponse<Array<BaseAttribute>>> {
        if (requestParameters.categoryCode === null || requestParameters.categoryCode === undefined) {
            throw new runtime.RequiredError('categoryCode','Required parameter requestParameters.categoryCode was null or undefined when calling getAttributesApiV1AttributesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.categoryCode !== undefined) {
            queryParameters['categoryCode'] = requestParameters.categoryCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/attributes/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BaseAttributeFromJSON));
    }

    /**
     * Get Attributes
     */
    async getAttributesApiV1AttributesGet(requestParameters: GetAttributesApiV1AttributesGetRequest): Promise<Array<BaseAttribute>> {
        const response = await this.getAttributesApiV1AttributesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Distinct Attribute Units
     */
    async getDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGetRaw(requestParameters: GetDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGetRequest): Promise<runtime.ApiResponse<CustomAttributeDistinctUnits>> {
        if (requestParameters.attributeCode === null || requestParameters.attributeCode === undefined) {
            throw new runtime.RequiredError('attributeCode','Required parameter requestParameters.attributeCode was null or undefined when calling getDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/attributes/{attributeCode}/distinctUnits`.replace(`{${"attributeCode"}}`, encodeURIComponent(String(requestParameters.attributeCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAttributeDistinctUnitsFromJSON(jsonValue));
    }

    /**
     * Get Distinct Attribute Units
     */
    async getDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGet(requestParameters: GetDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGetRequest): Promise<CustomAttributeDistinctUnits> {
        const response = await this.getDistinctAttributeUnitsApiV1AttributesAttributeCodeDistinctUnitsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Offer Custom Attributes Audit Trail
     */
    async getOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGetRaw(requestParameters: GetOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGetRequest): Promise<runtime.ApiResponse<AuditTrailOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling getOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/attributes/{offerId}/auditTrail`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditTrailOutFromJSON(jsonValue));
    }

    /**
     * Get Offer Custom Attributes Audit Trail
     */
    async getOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGet(requestParameters: GetOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGetRequest): Promise<AuditTrailOut> {
        const response = await this.getOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put attribute translation
     */
    async putAttributeTranslationApiV1AttributesAttributeCodeTranslationPutRaw(requestParameters: PutAttributeTranslationApiV1AttributesAttributeCodeTranslationPutRequest): Promise<runtime.ApiResponse<AttributeTranslationOut>> {
        if (requestParameters.attributeCode === null || requestParameters.attributeCode === undefined) {
            throw new runtime.RequiredError('attributeCode','Required parameter requestParameters.attributeCode was null or undefined when calling putAttributeTranslationApiV1AttributesAttributeCodeTranslationPut.');
        }

        if (requestParameters.attributeTranslation === null || requestParameters.attributeTranslation === undefined) {
            throw new runtime.RequiredError('attributeTranslation','Required parameter requestParameters.attributeTranslation was null or undefined when calling putAttributeTranslationApiV1AttributesAttributeCodeTranslationPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/attributes/{attributeCode}/translation`.replace(`{${"attributeCode"}}`, encodeURIComponent(String(requestParameters.attributeCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeTranslationToJSON(requestParameters.attributeTranslation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributeTranslationOutFromJSON(jsonValue));
    }

    /**
     * Put attribute translation
     */
    async putAttributeTranslationApiV1AttributesAttributeCodeTranslationPut(requestParameters: PutAttributeTranslationApiV1AttributesAttributeCodeTranslationPutRequest): Promise<AttributeTranslationOut> {
        const response = await this.putAttributeTranslationApiV1AttributesAttributeCodeTranslationPutRaw(requestParameters);
        return await response.value();
    }

}
