import { createAsyncAction, createAction } from "typesafe-actions";
import { PropertyBase } from "@api/client";

const SEARCH_PROPERTIES_REQUEST = "SEARCH_PROPERTIES_REQUEST";
const SEARCH_PROPERTIES_SUCCESS = "SEARCH_PROPERTIES_SUCCESS";
const SEARCH_PROPERTIES_FAILURE = "SEARCH_PROPERTIES_FAILURE";

const SET_LOADING = "SET_LOADING";

const LOAD_MORE_PROPERTIES_REQUEST = "LOAD_MORE_PROPERTIES_REQUEST";
const LOAD_MORE_PROPERTIES_SUCCESS = "LOAD_MORE_PROPERTIES_SUCCESS";
const LOAD_MORE_PROPERTIES_FAILURE = "LOAD_MORE_PROPERTIES_FAILURE";

const THERE_ARE_MORE_PAGES = "THERE_ARE_MORE_PAGES";
const NO_MORE_PAGES = "NO_MORE_PAGES";

const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

const setProperties = createAsyncAction(
  SEARCH_PROPERTIES_REQUEST,
  SEARCH_PROPERTIES_SUCCESS,
  SEARCH_PROPERTIES_FAILURE
)<string, PropertyBase[], string>();

const loadMoreProperties = createAsyncAction(
  LOAD_MORE_PROPERTIES_REQUEST,
  LOAD_MORE_PROPERTIES_SUCCESS,
  LOAD_MORE_PROPERTIES_FAILURE
)<string, PropertyBase[], Error>();

const setLoading = createAction(SET_LOADING)();
const noMorePages = createAction(NO_MORE_PAGES)();
const thereAreMorePages = createAction(THERE_ARE_MORE_PAGES)();
const setPageNumber = createAction(SET_PAGE_NUMBER)<number>();

export const actions = {
  setProperties,
  setLoading,
  loadMoreProperties,
  noMorePages,
  thereAreMorePages,
  setPageNumber,
};
