import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button } from "@components/button";
import { usePermissions } from "@hooks/use-permission";
import { BaseAttributeIn, BaseCustomAttributeOut } from "@api/client";

import { HandleAddAttributeValue, HandleAttributeValueUpdate } from "./types";
import * as S from "./attribute-value.styled";

export interface AttributeValueProps {
  attributeId: number;
  currentValue: string;
  parentAttributeId?: number;
  onUpdate: HandleAttributeValueUpdate;
  onAdd?: HandleAddAttributeValue;
  attributeName?: string;
  attributeDataTypeCode?: string;
  groupKey: string;
}

export const AttributeValue: React.FC<AttributeValueProps> = ({
  attributeId,
  currentValue,
  parentAttributeId = null,
  onUpdate,
  onAdd = null,
  attributeName,
  attributeDataTypeCode,
  groupKey,
}) => {
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const handleBlur = useCallback(
    (valueOriginal: string) => onUpdate(attributeId, { valueOriginal }, groupKey),
    [onUpdate, attributeId, groupKey]
  );

  const handleAddAttribute = async () => {
    if (attributeName && attributeDataTypeCode && onAdd !== null) {
      onAdd(attributeDataTypeCode, attributeName);
    }
  };

  const { hasPermission } = usePermissions();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const keyPressHandler = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  return parentAttributeId && parentAttributeId !== null && attributeId ? (
    hasPermission("PUT_OFFER_ATTRIBUTE_VALUE") ? (
      <S.Input
        name={attributeId ? attributeId.toString() : ""}
        value={value}
        type="text"
        onKeyDown={(e) => keyPressHandler(e)}
        onChange={(e) => onChange(e)}
        onBlur={() => {
          if (value) {
            handleBlur(value);
          }

          if (value === "") {
            handleBlur("");
          }
        }}
        style={{ fontWeight: 550 }}
        placeholder="Edit..."
      />
    ) : (
      <span>{currentValue}</span>
    )
  ) : hasPermission("PUT_OFFER_ATTRIBUTE_VALUE") && onAdd != null ? (
    <Button onClick={() => handleAddAttribute()}>Add</Button>
  ) : null;
};
