import React from "react";
import styled from "styled-components";
import { AttributeValue, AttributeValueProps } from "../attribute-value";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% auto;
  width: 100%;
  padding: 20px 0;
`;

const Label = styled.div`
  margin: auto;
`;

type AttributeValueChangeInModalProps = AttributeValueProps;

export const AttributeValueChangeInModal: React.FC<AttributeValueChangeInModalProps> = ({
  attributeId,
  code,
  index,
  onUpdate,
  currentlyLoading,
  groupKey,
}) => {
  return (
    <Wrapper>
      <Label>Main value:</Label>
      <AttributeValue
        code={code}
        onUpdate={onUpdate}
        attributeId={attributeId}
        index={index}
        currentlyLoading={currentlyLoading}
        groupKey={groupKey}
      />
    </Wrapper>
  );
};
