import { GetTenantsByPropertyApiV1TenantsPropertyIdGetRequest } from "@api/client";
import { tenantApi } from "@api/core";
import { useEffect } from "react";
import { useMutation } from "react-query";

export const useCallTenantsList = (propertyId: number) => {
  const requestParametersForTeneats: GetTenantsByPropertyApiV1TenantsPropertyIdGetRequest = {
    propertyId,
  };

  // Get tenants react-query hook
  const { data: tenants, mutate } = useMutation("tenants", () =>
    tenantApi.getTenantsByPropertyApiV1TenantsPropertyIdGet(requestParametersForTeneats)
  );

  useEffect(() => {
    if (propertyId !== -1) {
      mutate();
    }
  }, [mutate, propertyId]);

  return { tenants, mutate };
};
