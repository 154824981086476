/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferIn
 */
export interface OfferIn {
    /**
     * 
     * @type {number}
     * @memberof OfferIn
     */
    propertyId: number;
    /**
     * 
     * @type {string}
     * @memberof OfferIn
     */
    module?: string;
}

export function OfferInFromJSON(json: any): OfferIn {
    return OfferInFromJSONTyped(json, false);
}

export function OfferInFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'propertyId': json['propertyId'],
        'module': !exists(json, 'module') ? undefined : json['module'],
    };
}

export function OfferInToJSON(value?: OfferIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'propertyId': value.propertyId,
        'module': value.module,
    };
}


