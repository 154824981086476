import styled from "styled-components";
import { Icon } from "@components/icon";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

export const ListIcon = styled(Icon)`
  &.div {
    margin-left: 10px;
    border: 1px solid black;
    border-radius: 3px;
  }
`;

export const SearchHeading = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SymbolSearchWrapper = styled.div`
  align-self: flex-start;
  padding-right: 5px;
`;

export const SearchHeadingWrapper = styled(Wrapper)`
  flex-flow: row;
  justify-content: flex-start;
`;
