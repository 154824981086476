import React from "react";
import { Table } from "antd";
import { AuditTrailAttributeFloor, AuditTrailBase } from "@api/client";
import { TableProps } from "antd/lib/table/Table";

type RecordType = AuditTrailBase | AuditTrailAttributeFloor;

type Props = TableProps<RecordType>;

export const AuditTable: React.FC<Props> = ({ ...rest }) => {
  return (
    <div>
      <Table {...rest} />
    </div>
  );
};
