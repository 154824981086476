import styled, { css } from "styled-components";
import { Property } from "csstype";

export const Flex = styled.div<{
  justifyContent?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
}>`
  display: flex;
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};

  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};

  width: 100%;
`;
