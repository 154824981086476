import { createAction, createAsyncAction } from "typesafe-actions";
import {
  BaseAttribute,
  CustomAttributeDistinctUnits,
  CustomAttributeDistribution,
  GetAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRequest,
  AttributeTranslationOut,
} from "@api/client";
import { AttributesState } from "@state/attribures/types";

const FETCH_ATTRIBUTES_REQUEST = "ATTRIBUTES/FETCH_ATTRIBUTES_REQUEST";
const FETCH_ATTRIBUTES_SUCCESS = "ATTRIBUTES/FETCH_ATTRIBUTES_SUCCESS";
const FETCH_ATTRIBUTES_FAILURE = "ATTRIBUTES/FETCH_ATTRIBUTES_FAILURE";

const FETCH_DISTINCT_UNITS_REQUEST = "ATTRIBUTES/FETCH_DISTINCT_UNITS_REQUEST";
const FETCH_DISTINCT_UNITS_SUCCESS = "ATTRIBUTES/FETCH_DISTINCT_UNITS_SUCCESS";
const FETCH_DISTINCT_UNITS_FAILURE = "ATTRIBUTES/FETCH_DISTINCT_UNITS_FAILURE";

const FETCH_DISTRIBUTION_REQUEST = "ATTRIBUTES/FETCH_DISTRIBUTION_REQUEST";
const FETCH_DISTRIBUTION_SUCCESS = "ATTRIBUTES/FETCH_DISTRIBUTION_SUCCESS";
const FETCH_DISTRIBUTION_FAILURE = "ATTRIBUTES/FETCH_DISTRIBUTION_FAILURE";

const SET_ATTRIBUTE_TRANSLATION_REQUEST = "ATTRIBUTES/SET_ATTRIBUTE_TRANSLATION_REQUEST";
const SET_ATTRIBUTE_TRANSLATION_SUCCESS = "ATTRIBUTES/SET_ATTRIBUTE_TRANSLATION_SUCCESS";
const SET_ATTRIBUTE_TRANSLATION_FAILURE = "ATTRIBUTES/SET_ATTRIBUTE_TRANSLATION_FAILURE";

const SET_CUSTOM_FILTER = "ATTRIBUTES/SET_CUSTOM_FILTER";
const SET_CUSTOM_FILTER_KEY = "ATTRIBUTES/SET_CUSTOM_FILTER_KEY";
const SET_CUSTOM_FILTER_VALUE = "ATTRIBUTES/SET_CUSTOM_FILTER_VALUE";
const SET_CUSTOM_FILTER_FLOORS = "ATTRIBUTES/SET_CUSTOM_FILTER_FLOORS";
const SET_CUSTOM_FILTER_MODULES = "ATTRIBUTES/SET_CUSTOM_FILTER_MODULES";
const SET_CUSTOM_FILTER_SEARCH_TYPE = "ATTRIBUTES/SET_CUSTOM_FILTER_SEARCH_TYPE";
const CHANGE_SEARCH_TYPE = "ATTRIBUTES/CHANGE_SEARCH_TYPE";
const SET_CUSTOM_FILTER_UNIT = "ATTRIBUTES/SET_CUSTOM_FILTER_UNIT";

const SET_SHOW_MODAL_TO_ADD_CUSTOM_FILTER = "ATTRIBUTES/SET_SHOW_MODAL_TO_ADD_CUSTOM_FILTER";
const SET_VISIBLE_FLOOR_SLIDER = "ATTRIBUTES/SET_VISIBLE_FLOOR_SLIDER";
const SET_VISIBLE_MODULE_SLIDER = "ATTRIBUTES/SET_VISIBLE_MODULE_SLIDER";
const SET_VISIBLE_VALUE_SLIDER = "ATTRIBUTES/SET_VISIBLE_VALUE_SLIDER";

const RESET_CUSTOM_FILTER_VALUE = "ATTRIBUTES/RESET_CUSTOM_FILTER_VALUE";

export const fetchAttributes = createAsyncAction(
  FETCH_ATTRIBUTES_REQUEST,
  FETCH_ATTRIBUTES_SUCCESS,
  FETCH_ATTRIBUTES_FAILURE
)<void, BaseAttribute[], Error>();

export const fetchDistinctUnits = createAsyncAction(
  FETCH_DISTINCT_UNITS_REQUEST,
  FETCH_DISTINCT_UNITS_SUCCESS,
  FETCH_DISTINCT_UNITS_FAILURE
)<void, CustomAttributeDistinctUnits, Error>();

export const fetchDistribution = createAsyncAction(
  FETCH_DISTRIBUTION_REQUEST,
  FETCH_DISTRIBUTION_SUCCESS,
  FETCH_DISTRIBUTION_FAILURE
)<
  void,
  {
    response: CustomAttributeDistribution;
    params: GetAttributeDistributionApiV1AttributesAttributeCodeDistributionGetRequest;
  },
  Error
>();

export const translateAttribute = createAsyncAction(
  SET_ATTRIBUTE_TRANSLATION_REQUEST,
  SET_ATTRIBUTE_TRANSLATION_SUCCESS,
  SET_ATTRIBUTE_TRANSLATION_FAILURE
)<void, AttributeTranslationOut, Error>();

export const setCustomFilter =
  createAction(SET_CUSTOM_FILTER)<
    Partial<{
      key: string;
      value: string | string[];
      modalActive: boolean;
      valueUnit?: string;
      searchType?: string;
      floors: number[];
      modules: number[];
      isValueFilterActivie: boolean;
      isFloorFilterActivie: boolean;
      isModuleFilterActivie: boolean;
    }>
  >();

export const setCustomFilterValue =
  createAction(SET_CUSTOM_FILTER_VALUE)<Partial<AttributesState["customFilter"]>>();

export const changeSearchType = createAction(CHANGE_SEARCH_TYPE)<string>();

export const setCustomFilterUnit = createAction(SET_CUSTOM_FILTER_UNIT)<string>();

export const setShowModalToAddCustomFilter = createAction(SET_SHOW_MODAL_TO_ADD_CUSTOM_FILTER)<boolean>();

export const setCustomFilterFloors = createAction(SET_CUSTOM_FILTER_FLOORS)<number[] | undefined>();
export const setCustomFilterModules = createAction(SET_CUSTOM_FILTER_MODULES)<number[] | undefined>();
export const setCustomFilterKey = createAction(SET_CUSTOM_FILTER_KEY)<string>();
export const setCustomFilterSearchType = createAction(SET_CUSTOM_FILTER_SEARCH_TYPE)<string>();
export const setVisibleValueSlider = createAction(SET_VISIBLE_VALUE_SLIDER)<boolean>();
export const setVisibleFloorSlider = createAction(SET_VISIBLE_FLOOR_SLIDER)<boolean>();
export const setVisibleModuleSlider = createAction(SET_VISIBLE_MODULE_SLIDER)<boolean>();
export const resetCustomFilterValue = createAction(RESET_CUSTOM_FILTER_VALUE)<boolean>();
