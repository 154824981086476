import React from "react";
import { Collapse } from "antd";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { lettingsApi } from "@api/core";
import { LettingAttributes } from "@pages/transactions/letting-attributes/letting-attributes";

import { Letting } from "@api/client";
import { Loader } from "@components/loader";
import { Header } from "./header";
import { AddLetting } from "../generic-add/add-letting";
import "../tenant-list/tenant-list.less"

const { Panel } = Collapse;

interface Props {
  tenantId: number;
}

export const LettingList: React.FC<Props> = ({ tenantId }) => {
  const propertyId = useSelector((state) => state.offers.offerDetails.data?.propertyId) || 0;
  // Get lettings
  const { data: lettings, refetch } = useQuery([`lettings_${tenantId}`, propertyId], () =>
    lettingsApi.getLettingsApiV1LettingsPropertyIdTenantIdGet({
      tenantId,
      propertyId,
    })
  );

  const deleteEntityApi = () => lettingsApi.deleteLettingApiV1LettingsLettingIdDelete.bind(lettingsApi);

  const deleteEntityApiArg = (lettingId: number): Record<string, number> => ({
    lettingId,
  });

  const createLettingComponent = (letting: Letting) => (
    <Panel
      header={
        <Header
          entityId={letting.id}
          onDelete={refetch}
          title={`Letting #${letting.id} (${letting.tenantName})`}
          deleteApi={deleteEntityApi}
          delteApiArg={deleteEntityApiArg(letting.id)}
        />
      }
      key={letting.id}
    >
      <LettingAttributes lettingId={letting.id} />
    </Panel>
  );

  const createListOfLettingsForTenat = () => {
    return lettings?.map((letting) => createLettingComponent(letting));
  };

  return (
    <>
      {!lettings && <Loader />}
      <Collapse>{createListOfLettingsForTenat()}</Collapse>
      <AddLetting tenantId={tenantId} onAdd={refetch} />
    </>
  );
};
