import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { Tooltip, message, Modal, Button } from "antd";
import { StyledButton } from "@components/button";
import { Icon } from "@components/icon";
import { Heading } from "@components/heading";
import { HeadingWithIcon } from "@components/heading-with-icon";
import { theme } from "@components/variables";
import { refreshData } from "@state/offers/actions";
import { Module } from "@state/types";
import { ReactComponent as AddOfferIcon } from "@svg/icon-add-offer.svg";
import { ReactComponent as DownlaodIcon } from "@svg/icon-download.svg";
import { ReactComponent as RefreshIcon } from "@svg/icon-refresh.svg";
import { ReactComponent as ListSvg } from "@svg/symbol-list.svg";
import { ReactComponent as SymbolSearch } from "@svg/symbol-search.svg";
import { UploadOutlined as UploadIcon } from "@ant-design/icons";
import { downloadExcelOfferList } from "@utils/download-excel-list";
import { usePermissions } from "@hooks/use-permission";

import { offerApi } from "@api/core";
import { ApiResponse } from "@api/client";

import { NewOfferModal } from "./add-new-offer";
import * as S from "./headline.styled";

interface Error {
  message: string;
  row_number: number;
}

interface ApiError {
  errors: Error[];
}

interface ErrorModalOptions {
  data: ApiError | null;
  isOpen: boolean;
}

const defaultErrorModalOptions = { isOpen: false, data: null };

export const Headline: React.FC = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { hasPermission } = usePermissions();
  const fileInputId = "fileInputId";
  const canDownload = hasPermission("GET_LIST_OF_OFFERS");
  const canAdd = hasPermission("ADD_NEW_OFFER");
  const [modalVisible, setModalVisible] = useState(false);
  const module = useSelector((state) => state.filters.currentModule) as Module;
  const filters = useSelector((state) => state.filters[state.filters.currentModule].data);
  const { isPickingFromMainTable, typeOfPicking } = useSelector(
    (state) => state.newsletterGenerateBreakdownReportReducer
  );
  const [errorModalOptions, setErrorModalOptions] = useState<ErrorModalOptions>(defaultErrorModalOptions);
  const handleRefresh = useCallback(() => dispatch(refreshData()), [dispatch]);
  const handeDownload = useCallback(() => downloadExcelOfferList(filters, module), [filters, module]);
  const handleToggleModal = useCallback(() => setModalVisible((o) => !o), [setModalVisible]);

  const { mutate } = useMutation(
    (attachment: File) => {
      const res = offerApi.uploadOfferAsExcelFilePost({
        importType: module.toString(),
        attachment: attachment,
      });
      return res;
    },
    {
      onSuccess: async (data: ApiResponse<any>) => {
        const fileName = `Data - ${new Date().toLocaleDateString("en-US")}`;

        const url = window.URL.createObjectURL(
          new Blob([await data.raw.blob()], {
            type: "text/plain",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        handleRefresh();
        message.success("success");
      },
      onError: (res: Response) => {
        res.json().then((body) => {
          const data = body as ApiError;
          setErrorModalOptions({ isOpen: true, data: data });
        });
      },
    }
  );
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (!file) {
        message.error("Failed to upload excel file");
        return;
      }
      mutate(file);
    }
  };

  const pickingType = typeOfPicking === "newsletter" ? "newsletter" : "properties breakdown report";

  return (
    <S.Wrapper>
      <HeadingWithIcon level="3" icon={<S.ListIcon icon={ListSvg} />}>
        List of Properties&nbsp;
        {isPickingFromMainTable && typeOfPicking && <span>- you are in picking mode for {pickingType}</span>}
      </HeadingWithIcon>
      <div>
        <Tooltip title="Add offer" color={theme.primaryColor}>
          <StyledButton type="ghost" onClick={handleToggleModal} disabled={!canAdd}>
            <Icon icon={AddOfferIcon} />
          </StyledButton>
        </Tooltip>
        <NewOfferModal visible={modalVisible} onCancel={handleToggleModal} />
        <Tooltip title="Add offer as excel file" color={theme.primaryColor}>
          <StyledButton
            type="ghost"
            onClick={(e) => {
              e.currentTarget.blur();
              if (fileInputRef !== null) {
                // Make sure we cleared last file input field value otherwise uploading same file will be not possible
                (document.getElementById(fileInputId) as HTMLInputElement).value = "";
                fileInputRef.current?.click();
              }
            }}
            disabled={!canAdd}
          >
            <Icon icon={UploadIcon} />
            <input
              type="file"
              name="file"
              id={fileInputId}
              onChange={(e) => handleUpload(e)}
              ref={fileInputRef}
              style={{ visibility: "hidden", display: "none" }}
            />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Download as excel" color={theme.primaryColor}>
          <StyledButton type="ghost" onClick={handeDownload} disabled={!canDownload}>
            <Icon icon={DownlaodIcon} />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Refresh" color={theme.primaryColor}>
          <StyledButton type="ghost" onClick={handleRefresh}>
            <Icon icon={RefreshIcon} />
          </StyledButton>
        </Tooltip>
      </div>
      <Modal
        visible={errorModalOptions.isOpen}
        title="Error while uploading excel"
        width={800}
        footer={[
          <Button key="back" onClick={() => setErrorModalOptions(defaultErrorModalOptions)}>
            Ok
          </Button>,
        ]}
        onCancel={() => setErrorModalOptions(defaultErrorModalOptions)}
        onOk={() => setErrorModalOptions(defaultErrorModalOptions)}
      >
        {errorModalOptions.data?.errors.map((e) => {
          return (
            <p>
              Row number: <b>{e.row_number}</b> Message: <b>{e.message}</b>
            </p>
          );
        })}
      </Modal>
    </S.Wrapper>
  );
};

export const SearchHeadline: React.FC = () => (
  <S.SearchHeadingWrapper>
    <S.SymbolSearchWrapper>
      <SymbolSearch height="20px" width="20px" />
    </S.SymbolSearchWrapper>
    <Heading level="3">Search for Properties</Heading>
  </S.SearchHeadingWrapper>
);
