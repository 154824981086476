import React from "react";

import { Heading, HeadingProps } from "@components/heading";

import * as S from "./heading-with-icon.styled";

interface HeadingWithIconProps {
  icon?: React.ReactNode;
}

export const HeadingWithIcon: React.FC<HeadingWithIconProps & HeadingProps & S.HeadingWithIconStyleProps> = ({
  children,
  level,
  icon,
  iconLeft,
}) => (
  <S.Wrapper iconLeft={iconLeft}>
    {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
    <Heading level={level}>{children}</Heading>
  </S.Wrapper>
);

HeadingWithIcon.defaultProps = {
  iconLeft: true,
};
