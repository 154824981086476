/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoiOutGeo
 */
export interface PoiOutGeo {
    /**
     * 
     * @type {number}
     * @memberof PoiOutGeo
     */
    geographyId: number;
    /**
     * 
     * @type {number}
     * @memberof PoiOutGeo
     */
    poiId: number;
    /**
     * 
     * @type {Date}
     * @memberof PoiOutGeo
     */
    modificationDate: Date;
}

export function PoiOutGeoFromJSON(json: any): PoiOutGeo {
    return PoiOutGeoFromJSONTyped(json, false);
}

export function PoiOutGeoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiOutGeo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geographyId': json['geographyId'],
        'poiId': json['poiId'],
        'modificationDate': (new Date(json['modificationDate'])),
    };
}

export function PoiOutGeoToJSON(value?: PoiOutGeo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geographyId': value.geographyId,
        'poiId': value.poiId,
        'modificationDate': (value.modificationDate.toISOString()),
    };
}


