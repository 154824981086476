import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPredefinedAttributes } from "@utils/offer";
import { useParams } from "react-router";
import * as S from "./add-attributes.styled";
import { CustomAttributes } from "./custom-attributes";
import { PopupContentColumn } from "./popup-column";
import { PredefinedAttributes } from "./predefined-attributes";
import { RecommendedAttributes } from "./recommended-attributes";

export const PopupContent: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setPredefinedAttributes(dispatch)({ offerId: +id });
  }, [dispatch, id]);

  return (
    <S.PopupContent>
      <PopupContentColumn title="Recommended">
        <RecommendedAttributes />
      </PopupContentColumn>
      <PopupContentColumn title="Other Predefined">
        <PredefinedAttributes />
      </PopupContentColumn>
      <PopupContentColumn title="Create or Update Attribute">
        <CustomAttributes />
      </PopupContentColumn>
    </S.PopupContent>
  );
};
