import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { message, Popover } from "antd";
import { useMutation } from "react-query";
import { AddButton, Button } from "@components/button";
import { useToggle } from "@hooks/use-toggle";
import { usePermissions } from "@hooks/use-permission";
import { Input } from "@components/input";
import { getOfferApi } from "@api/core";
import {
  AppSchemasOfferFloorIn,
  PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest,
} from "@api/client";
import { updateAttributeAfterFloorUpdate } from "@state/offers/actions";

import { Label } from "./attributes.styled";

interface OnlyFormProps {
  onClose: () => void;
}

interface FormProps {
  attributeId: number;
}

const { offerApi } = getOfferApi();
const typeCallback =
  (callback: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) =>
    callback(event.target.value);

const floorLabelId = "Floor";

const Form: React.FC<FormProps & OnlyFormProps> = ({ attributeId, onClose }) => {
  const dispatch = useDispatch();
  const [floor, setFloor] = useState("");

  const { mutate, isLoading, error } = useMutation(
    () =>
      offerApi.postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPost({
        attributeId,
        appSchemasOfferFloorIn: { floors: floor } as AppSchemasOfferFloorIn,
      } as PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("success");
      },
    }
  );
  const handleFloorChange = typeCallback(setFloor);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    mutate();
  };

  if (error) return <>Something went wrong</>;

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlFor={floorLabelId}>{floorLabelId}:</Label>
      <Input id={floorLabelId} type="string" value={floor} onChange={handleFloorChange} min={0} />
      <div style={{ marginTop: 20 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          Add
        </Button>
      </div>
    </form>
  );
};

export const AddFloorValueForm: React.FC<FormProps> = (props) => {
  const { state: visible, toggle } = useToggle();
  const { hasPermission } = usePermissions();
  return (
    <Popover
      visible={visible}
      title="Add value for specific floor"
      content={<Form {...props} onClose={toggle} />}
      destroyTooltipOnHide
      placement="rightTop"
      trigger="click"
      onVisibleChange={toggle}
    >
      <AddButton onClick={toggle} disabled={!hasPermission("POST_OFFER_ATTRIBUTE_FLOOR")}>
        Add floor value
      </AddButton>
    </Popover>
  );
};
