export const officeAttributes = [
  {
    code: "",
    cellWidth: 60,
    key: "emptyFirst",
  },
  {
    code: "Av. Off. Space",
    cellWidth: 200,
    key: "availableOfficeSpace",
  },
  {
    code: "Availability",
    cellWidth: 200,
    key: "availability",
  },
  {
    code: "Asking Rent",
    cellWidth: 200,
    key: "askingRent",
  },
  {
    code: "Type",
    cellWidth: 150,
    key: "type",
  },
  {
    code: "Document Comments",
    cellWidth: 250,
    key: "documentComments",
  },
  {
    code: "Internal Comments",
    cellWidth: 250,
    key: "internalComments",
  },
  {
    code: "",
    cellWidth: 60,
    key: "emptyLast",
  },
];
