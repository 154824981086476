import styled, { css } from "styled-components";

export interface StylingProps {
  grow?: boolean;
  isActive?: boolean;
}

export const buttonActive = css`
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.orange2};
  box-shadow: inset 0 0 6px #88330d;
`;

export const StyledButton = styled.button`
  color: ${(props) => props.theme.greyBlue};
  margin: 5px 3px;
  background: linear-gradient(#ffffff, #e9ebed, #eceef0);
  border: 1px solid #cbccce;
  padding: 3px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;

  &:hover {
    ${buttonActive}
  }
`;
