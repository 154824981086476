import React from "react";

import { Layout } from "@components/layout";
import { Heading } from "@components/heading";

import { Table } from "./table";

import * as S from "./poi.styled";

export const Poi: React.FC = () => (
  <Layout className="ant-layout-has-sider">
    <S.Container>
      <Heading level="1">Points of interests</Heading>
      <Table />
    </S.Container>
  </Layout>
);
