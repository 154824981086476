import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  AttributeValue,
  AttributeVisibility,
  PutOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPutRequest,
} from "@api/client";

import {
  updateAttribute,
  deleteAttribute,
  updateAttributeNote,
  changeAttributeVisibility,
} from "@utils/offer";
import { Table } from "@components/table";

import { Heading } from "../offer-detail.styled";

import { TableRow } from "./table-row";

import * as S from "./attributes.styled";

export const Attributes: React.FC = () => {
  const offers = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [selectedFloorAttributeManager, setSelectedFloorAttributeManager] = useState("");

  const handleUpdateAttribute = useCallback(
    (attributeId: number, attributeValue: AttributeValue, index: number, groupKey: string): void =>
      updateAttribute(dispatch)({ offerId: +id, attributeId, attributeValue }, index, groupKey),
    [dispatch, id]
  );

  const handleDelete = useCallback(
    (attributeId: number, groupKey: string) => deleteAttribute(dispatch)({ attributeId, groupKey }),
    [dispatch]
  );

  const handleUpdateNote = useCallback(
    (attributeId: number) => (note: string | null) =>
      updateAttributeNote(dispatch)({ attributeId, attributeNote: { note } }),
    [dispatch]
  );

  const handleIsVisibleChange = useCallback(
    (attributeId: number) => (isVisible: boolean) => {
      return changeAttributeVisibility(dispatch)({
        attributeId,
        attributeVisibility: { isVisible } as AttributeVisibility,
      } as PutOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPutRequest);
    },
    [dispatch]
  );

  const handleModalClose = () => setSelectedFloorAttributeManager("");

  return (
    <div>
      <Heading level="2">Attributes</Heading>
      {offers.offerDetails.data &&
        Object.keys(offers.offerDetails.data?.customAttributes).map((groupKey) => (
          <React.Fragment key={groupKey}>
            <S.HeadingGroup level="3">{groupKey}</S.HeadingGroup>
            <Table>
              {offers.offerDetails.data?.customAttributes[groupKey].map((attribute, index) => (
                <TableRow
                  {...attribute}
                  key={attribute.code}
                  index={index}
                  groupKey={groupKey}
                  onAttributeUpdate={handleUpdateAttribute}
                  currentlyLoading={offers.attributeLoading}
                  onDelete={() => handleDelete(attribute.attributeId, groupKey)}
                  deleting={offers.attributeDeleting}
                  noteUpdated={offers.attributeNoteUpdating}
                  onNoteUpdate={() => handleUpdateNote(attribute.attributeId)}
                  onFloorManageClick={setSelectedFloorAttributeManager}
                  selectedFloorAtributeManager={selectedFloorAttributeManager}
                  onFloorManageModalClose={handleModalClose}
                  onVisibilityClick={handleIsVisibleChange(attribute.attributeId)}
                />
              ))}
            </Table>
          </React.Fragment>
        ))}
    </div>
  );
};
