import React from "react";

import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";

import styled from "styled-components";
import { Heading } from "@components/heading";

import { AppState } from "@state/store";
import { useSelector } from "react-redux";
import { AttributeValueProps } from "./attribute-value";
import { AddFloorValueForm } from "./add-floor-value-form";
import { FloorListForAttributeManager } from "./floor-table-for-attribute-manager";
import { AttributeValueChangeInModal } from "./attribute-value-change-in-modal/attribute-value-change-in-modal";

const PaddingForButton = styled.div`
  padding-top: 20px;
`;
interface AttributeFloorManageModalProps extends ModalProps {
  attributeName: any;
  attributeId: number;
  attributeCode: string;
}

export const AttributeFloorManageModal: React.FC<AttributeFloorManageModalProps & AttributeValueProps> = ({
  attributeName,
  index,
  code,
  onUpdate,
  currentlyLoading,
  attributeId,
  attributeCode,
  groupKey,
  ...modalProps
}) => {
  const floors = useSelector(
    (state: AppState) =>
      state.offers.offerDetails.data?.customAttributes[groupKey].find((x) => x.code === attributeCode)?.floors
  );

  return (
    <Modal {...modalProps} destroyOnClose footer={null}>
      <Heading level="3">{attributeName}</Heading>
      <AttributeValueChangeInModal
        attributeId={attributeId}
        onUpdate={onUpdate}
        code={attributeCode}
        index={index}
        currentlyLoading={currentlyLoading}
        groupKey={groupKey}
      />
      <FloorListForAttributeManager floors={floors} />
      <PaddingForButton>
        <AddFloorValueForm attributeId={attributeId} />
      </PaddingForButton>
    </Modal>
  );
};
