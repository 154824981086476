/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferAttachmentDescriptionOut
 */
export interface OfferAttachmentDescriptionOut {
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentDescriptionOut
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDescriptionOut
     */
    offerId: number;
    /**
     * 
     * @type {Date}
     * @memberof OfferAttachmentDescriptionOut
     */
    modificationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDescriptionOut
     */
    attachmentId: number;
}

export function OfferAttachmentDescriptionOutFromJSON(json: any): OfferAttachmentDescriptionOut {
    return OfferAttachmentDescriptionOutFromJSONTyped(json, false);
}

export function OfferAttachmentDescriptionOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferAttachmentDescriptionOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'offerId': json['offerId'],
        'modificationDate': (new Date(json['modificationDate'])),
        'attachmentId': json['attachmentId'],
    };
}

export function OfferAttachmentDescriptionOutToJSON(value?: OfferAttachmentDescriptionOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'offerId': value.offerId,
        'modificationDate': (value.modificationDate.toISOString()),
        'attachmentId': value.attachmentId,
    };
}


