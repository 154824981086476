import { Dropdown } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { HeadingSmall } from "@components/heading";
import {
  deletePhotoFromService,
  getPhotoFormService,
  putPhotoToService,
} from "@utils/clinet-api-for-photo-managment";
import { Input, Image, ImageWrapper, IconOptions, Options, IconOptionsClicked } from "./photo.styled";
import defaultPhoto from "../../../img/background.png";
import { DropDownMenu } from "./drop-down";

type ILocalState = {
  clicked: boolean;
  photoToShow: string;
};

export const Photo = () => {
  const [localState, setLocalState] = useState<ILocalState>({ clicked: false, photoToShow: defaultPhoto });
  const propertyId = useSelector((state) => {
    return state.offers.offerDetails.data?.propertyId;
  });

  const onOptionsClickHandler = () => {
    setLocalState({ ...localState, clicked: !localState.clicked });
  };

  const refOfInput = useRef<HTMLInputElement | any>();

  const fileSelectedHendler = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setLocalState({
            ...localState,
            photoToShow: reader.result as string,
            clicked: false,
          });
        }
      };
      reader.readAsDataURL(selectedFile);
    }
    if (propertyId) {
      putPhotoToService(propertyId, selectedFile);
    }
  };

  const fireClickOnInput = () => {
    setLocalState({
      ...localState,
      clicked: false,
    });
    refOfInput.current.click();
  };
  const onRemovehandler = () => {
    setLocalState({
      ...localState,
      photoToShow: defaultPhoto,
      clicked: false,
    });
    if (propertyId) {
      deletePhotoFromService(propertyId);
    }
  };

  const fetchingPhotoForProperty = async (propId: number) => {
    const result = await getPhotoFormService(propId);
    if (result?.data) {
      const photoAsUrl = URL.createObjectURL(result?.data);
      setLocalState({ ...localState, photoToShow: photoAsUrl });
    }
  };

  useEffect(() => {
    if (propertyId) {
      fetchingPhotoForProperty(propertyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <HeadingSmall level="2">Images</HeadingSmall>
      <ImageWrapper>
        <Options>
          <Dropdown
            overlay={DropDownMenu({
              changePhotoHandler: fireClickOnInput,
              removePhotoHandler: onRemovehandler,
            })}
            placement="bottomLeft"
            trigger={["click"]}
            onVisibleChange={onOptionsClickHandler}
          >
            <button type="submit">{localState.clicked ? <IconOptionsClicked /> : <IconOptions />}</button>
          </Dropdown>
        </Options>

        <Image src={localState?.photoToShow} alt="" />
        <Input
          type="file"
          name="image-upload"
          accept="image/*"
          onChange={fileSelectedHendler}
          ref={refOfInput}
        />
      </ImageWrapper>
    </div>
  );
};
