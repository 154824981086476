import React, { useState, useEffect } from "react";
import { Progress as ProgressCore, ProgressProps } from "antd";

import { theme } from "@components/variables";

export interface CustomProps {
  max: number;
}

export const Progress = ({ max }: ProgressProps & CustomProps) => {
  const estimatedMaxTime = (max * 60) / 50;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress + 1 >= estimatedMaxTime) return;
      setProgress(progress + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [estimatedMaxTime, progress]);

  return (
    <div>
      <ProgressCore
        showInfo={false}
        percent={(progress / estimatedMaxTime) * 100}
        status="active"
        strokeColor={theme.lightBlue}
      />
      <p>Around {(estimatedMaxTime - progress).toFixed(0)} seconds left or less</p>
    </div>
  );
};
