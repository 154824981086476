/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientAttributesMain
 */
export interface ClientAttributesMain {
    /**
     * 
     * @type {string}
     * @memberof ClientAttributesMain
     */
    name?: string;
     /**
     * 
     * @type {string}
     * @memberof ClientAttributesMain
     */
    nameLng?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributesMain
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientAttributesMain
     */
    valueDecimal?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributesMain
     */
    valueOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributesMain
     */
    unitOfMeasureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributesMain
     */
    dataTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributesMain
     */
    unitName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAttributesMain
     */
    separator?: boolean;
    valueDecimalIntervalLowerLimit: number;
    valueDecimalIntervalUpperLimit: number;
}

export function ClientAttributesMainFromJSON(json: any): ClientAttributesMain {
    return ClientAttributesMainFromJSONTyped(json, false);
}

export function ClientAttributesMainFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientAttributesMain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameLng': !exists(json, 'nameLng') ? undefined : json['nameLng'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'valueDecimal': !exists(json, 'valueDecimal') ? undefined : json['valueDecimal'],
        'valueOriginal': !exists(json, 'valueOriginal') ? undefined : json['valueOriginal'],
        'unitOfMeasureCode': !exists(json, 'unitOfMeasureCode') ? undefined : json['unitOfMeasureCode'],
        'dataTypeCode': !exists(json, 'dataTypeCode') ? undefined : json['dataTypeCode'],
        'unitName': !exists(json, 'unitName') ? undefined : json['unitName'],
        'separator': !exists(json, 'separator') ? undefined : json['separator'],
        'valueDecimalIntervalLowerLimit': !exists(json, 'valueDecimalIntervalLowerLimit') ? undefined : json['valueDecimalIntervalLowerLimit'],
        'valueDecimalIntervalUpperLimit': !exists(json, 'valueDecimalIntervalUpperLimit') ? undefined : json['valueDecimalIntervalUpperLimit'],
    };
}

export function ClientAttributesMainToJSON(value?: ClientAttributesMain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'nameLng': value.nameLng,
        'code': value.code,
        'valueDecimal': value.valueDecimal,
        'valueOriginal': value.valueOriginal,
        'unitOfMeasureCode': value.unitOfMeasureCode,
        'dataTypeCode': value.dataTypeCode,
        'unitName': value.unitName,
        'separator': value.separator,
        'valueDecimalIntervalLowerLimit': value.valueDecimalIntervalLowerLimit,
        'valueDecimalIntervalUpperLimit': value.valueDecimalIntervalUpperLimit
    };
}


