import { useParams } from "react-router";
import { message } from "antd";
import { BaseAttributeIn } from "@api/client";
import { lettingsApi } from "@api/core";
import { addAttribute } from "@utils/offer";
import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { LettingIdContext } from "./local-context/letting-context";
import * as S from "./add-attributes.styled";

export const RecommendedAttributes: React.FC = () => {
  const suggestedAttributes = useSelector((state) => state.offers.offerDetails.data?.suggestedAttributes);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { lettingId, onAdd } = useContext(LettingIdContext);

  const handleAddAttribute = useCallback(
    (selectedAttribute: BaseAttributeIn) => {
      addAttribute(dispatch)({ offerId: +id, baseAttributeIn: selectedAttribute });
    },
    [dispatch, id]
  );

  const handleAddAttributeWithLettingId = useMutation(
    (attribute: BaseAttributeIn) =>
      lettingsApi.postLettingAttributeApiV1LettingsLettingIdAttributesPost({
        lettingId: lettingId || -1,
        baseAttributeIn: attribute,
      }),
    {
      onSuccess: () => {
        onAdd?.();
        message.success("success");
      },
      onError: (error: Error) => {
        message.error(`error: ${error.message}`);
      },
    }
  );

  const selectedHandleAddAttribute = (attribute: BaseAttributeIn) =>
    lettingId ? handleAddAttributeWithLettingId.mutate(attribute) : handleAddAttribute(attribute);

  return (
    <S.AttributeButtonsGroup>
      <div hidden>{JSON.stringify(suggestedAttributes)}</div>
      {suggestedAttributes &&
        suggestedAttributes.map((attribute) => (
          <li key={attribute.code}>
            <S.AttributeButton
              key={`${attribute.code}-button`}
              type="button"
              onClick={() => selectedHandleAddAttribute(attribute)}
            >
              {attribute.name}
            </S.AttributeButton>
          </li>
        ))}
    </S.AttributeButtonsGroup>
  );
};
