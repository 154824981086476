import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message, Popover } from "antd";
import { useMutation } from "react-query";
import { AddButton, Button } from "@components/button";
import { useToggle } from "@hooks/use-toggle";
import { usePermissions } from "@hooks/use-permission";
import { Input } from "@components/input";
import { getOfferApi } from "@api/core";
import {
  ModuleIn,
  PostOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRequest,
  QuickformAttribute,
} from "@api/client";
import { updateAttributeAfterFloorUpdate } from "@state/offers/actions";
import * as S from "./attribute-value.styled";

interface OnlyFormProps {
  onClose: () => void;
}

interface FormProps {
  floorsOriginal: string;
}

const { offerApi } = getOfferApi();
const typeCallback =
  (callback: React.Dispatch<React.SetStateAction<number | undefined>>) =>
  (event: ChangeEvent<HTMLInputElement>) =>
    callback(Number(event.target.value));

const floorLabelId = "Floor";

const Form: React.FC<FormProps & OnlyFormProps> = ({ floorsOriginal, onClose }) => {
  const dispatch = useDispatch();
  const floorAttributes = useSelector((state) =>
    state.offers.offerDetails.data?.quickformAttributes.floors.find(
      (s) => s.floorsOriginal === floorsOriginal
    )
  );
  const [module, setModule] = useState<number>();
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>();
  const selectedHandleAddAttribute = (attributeId: number) => setSelectedAttributeId(attributeId);

  const { mutate } = useMutation(
    () =>
      offerApi.postOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPost({
        attributeId: selectedAttributeId,
        moduleIn: { module: module ?? -1 } as ModuleIn,
      } as PostOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("Success");
        onClose();
      },
      onError: () => {
        message.error("Operation failed");
      },
    }
  );
  const handleModuleChange = typeCallback(setModule);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    mutate();
  };

  return (
    <form onSubmit={handleSubmit}>
      <S.AttributeButtonsGroup>
        <div hidden>{JSON.stringify(floorAttributes)}</div>
        {floorAttributes &&
          Object.entries(floorAttributes).map(([key, value]: [string, QuickformAttribute]) => {
            if (key === "floorsOriginal" || key === "modules") return;
            return (
              <S.AttributeButtonsGroup>
                <li key={value.id}>
                  <S.AttributeButton
                    key={`${value.label}-button`}
                    type="button"
                    onClick={() => selectedHandleAddAttribute(value.id)}
                    isSelected={value.id === selectedAttributeId}
                  >
                    {value.label}
                  </S.AttributeButton>
                </li>
              </S.AttributeButtonsGroup>
            );
          })}
      </S.AttributeButtonsGroup>
      <Input id={floorLabelId} type="string" value={module} onChange={handleModuleChange} min={0} />
      <div style={{ marginTop: 20 }}>
        <Button onClick={onClose}>Cancel</Button>
        {module && selectedAttributeId ? (
          <Button htmlType="submit" type="primary">
            Add
          </Button>
        ) : (
          <Button type="primary" disabled>
            Add
          </Button>
        )}
      </div>
    </form>
  );
};

export const AddModuleValueForm: React.FC<FormProps> = ({ floorsOriginal }: FormProps) => {
  const { state: visible, toggle } = useToggle();
  const { hasPermission } = usePermissions();
  return (
    <Popover
      visible={visible}
      title="Add number of Attrbiute"
      content={<Form floorsOriginal={floorsOriginal} onClose={toggle} />}
      destroyTooltipOnHide
      placement="rightTop"
      trigger="click"
      onVisibleChange={toggle}
    >
      <AddButton
        onClick={toggle}
        disabled={!hasPermission("POST_OFFER_ATTRIBUTE_FLOOR")}
        style={{ marginBottom: "20px", marginTop: "20px", position: "absolute" }}
      >
        Add module
      </AddButton>
    </Popover>
  );
};

