import styled, { DefaultTheme, ThemeProps } from "styled-components";
import React, { useRef } from "react";
import { DeleteButton } from "@components/delete-button";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import {
  AttributeValue,
  DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest,
  PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest,
} from "@api/client";
import { offerApi } from "@api/core";
import { updateAttributeAfterFloorUpdate } from "@state/offers/actions";
import { message } from "antd";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 15% 70% 15%;
`;

const FloorRange = styled.div`
  margin: auto;
`;

const Input = styled.input<ThemeProps<DefaultTheme>>`
  outline-color: ${({ theme }) => theme.primaryColor};
`;

interface FloorHeaderProps {
  floorId: number;
  floorRange: string;
  value: string;
}

export const FloorHeader: React.FC<FloorHeaderProps> = ({ floorId, floorRange, value }) => {
  const dispatch = useDispatch();
  const inputRef = useRef<any>();

  const { mutate } = useMutation(
    () =>
      offerApi.deleteOfferAttributeApiV1OffersAttributesAttributeIdDelete({
        attributeId: floorId,
      } as DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("success");
      },
      onError: () => {
        message.error("Somthing went wrong");
      },
    }
  );

  const { mutate: mutateInput } = useMutation(
    (newValue: string) =>
      offerApi.putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePut({
        attributeId: floorId,
        attributeValue: { valueOriginal: newValue } as AttributeValue,
      } as PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("success");
      },
      onError: () => {
        message.error("Somthing went wrong");
      },
    }
  );

  const handleDelete = (event: React.MouseEvent) => {
    mutate();
    event.stopPropagation();
  };

  const handleInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
    mutateInput(String(event.target.value));
    event.stopPropagation();
  };

  const handleInputOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handlerKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      inputRef.current.blur();
      event.stopPropagation();
    }
  };

  return (
    <Wrapper key={floorId}>
      <FloorRange>{floorRange}</FloorRange>
      <Input
        ref={inputRef}
        defaultValue={value}
        onBlur={handleInputChange}
        onClick={handleInputOnClick}
        onKeyPress={handlerKeyPress}
      />
      <DeleteButton onClick={handleDelete} />
    </Wrapper>
  );
};
