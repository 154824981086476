import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;

  input {
    font-size: 13px;
  }

  .ant-input {
    height: 32px;
  }
`;
