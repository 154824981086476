import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useEffect, useState } from "react";

export const useIsSignedIn = (): [ProviderState] => {
  const [isSignedIn, setIsSignedIn] = useState<ProviderState>(ProviderState.Loading);

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state);
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  return [isSignedIn];
};
