import styled from "styled-components";
import { Heading as HeadingCore, HeadingProps } from "@components/heading";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const Heading = styled(HeadingCore)<HeadingProps>`
  font-weight: ${({ level, theme }) => (level === "4" ? theme.fontMedium : theme.fontLight)};
  margin: 0;
  white-space: nowrap;
`;

export const SelectWrapper = styled.div`
  margin-right: 10px;
  height: 100%;
`;
