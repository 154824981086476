import Axios, { AxiosRequestConfig } from "axios";
import { getEnv } from "@utils/get-env";

const API_URL = `${getEnv("REACT_APP_API_URL")}/api/v1/properties`;

// eslint-disable-next-line consistent-return
export const getPhotoFormService = async (propertyId: number) => {
  try {
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: { Accept: "application/json" },
      params: {
        propertyId,
      },
      responseType: "blob",
    };
    const response = await Axios.get(
      `${API_URL}/${propertyId}/photo`.replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId))),
      axiosRequestConfig
    );
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const putPhotoToService = async (propertyId: number, photo: Blob) => {
  try {
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    const fd = new FormData();
    fd.append("image", photo);
    const response = await Axios.put(
      `${API_URL}/${propertyId}/photo`.replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId))),
      fd,
      axiosRequestConfig
    );
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const deletePhotoFromService = async (propertyId: number) => {
  try {
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        Accept: "*/*",
      },
    };
    const response = await Axios.delete(
      `${API_URL}/${propertyId}/pois/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId))),
      axiosRequestConfig
    );
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};
