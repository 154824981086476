import React, { ChangeEvent, useState } from "react";
import { Radio, RadioChangeEvent } from "antd";
import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";

import { Input } from "@components/input";
import { Input as FormikInput } from "@components/form/input";
import { Icon } from "@components/icon";
import { Button } from "@components/button";
import { ReactComponent as SearchIcon } from "@svg/icon-search.svg";

import { Empty } from "@components/empty";
import { ClientNameOut } from "@api/client";

import * as S from "./get-link-form.styled";

const { Group: RadioGroup } = Radio;

interface GenerateLinkProps {
  onSubmit: (values: any) => void;
  contacts: ClientNameOut[];
  isLoading: boolean;
}

export interface FormValues {
  clientId: number;
  mainLocation: string;
  secondaryLocation: string;
}

const clientNameLabelId = "Search client name";

const createOfferSchema = Yup.object().shape({
  clientId: Yup.number().required("Required").positive("Required").integer("Required"),
  mainLocation: Yup.string().min(2, "Too Short value").max(25, "Too Long value").required("Required"),
  secondaryLocation: Yup.string().min(2, "Too Short value").max(25, "Too Long value").required("Required"),
});

export const GenerateLink = ({ onSubmit, contacts, isLoading }: GenerateLinkProps) => {
  const [clientName, setClientName] = useState("");

  const handleClientIdChange = (fn: (v: number) => void) => (e: RadioChangeEvent) => fn(Number(e.target.id));

  const handleInputChange = (fn: (value: string) => void) => (e: ChangeEvent<HTMLInputElement>) =>
    fn(e.target.value);

  const handleClientNameChange = handleInputChange(setClientName);

  const radioButtonsList = () => {
    let filteredContacts = contacts;
    if (clientName) {
      filteredContacts = filteredContacts.filter(({ name }) =>
        name.toLocaleLowerCase().includes(clientName.toLocaleLowerCase())
      );
    }

    return filteredContacts.map(({ clientId, name }) => (
      <S.RadioButton key={clientId} id={clientId.toString()} value={clientId}>
        {name}
      </S.RadioButton>
    ));
  };

  let radioButtons: JSX.Element[] | JSX.Element = radioButtonsList();
  radioButtons = radioButtons.length ? radioButtons : <Empty />;

  return (
    <Formik<FormValues>
      initialValues={{
        clientId: -1,
        mainLocation: "",
        secondaryLocation: "",
      }}
      onSubmit={onSubmit}
      validationSchema={createOfferSchema}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Input
            value={clientName}
            onChange={handleClientNameChange}
            placeholder={clientNameLabelId}
            suffix={<Icon icon={SearchIcon} />}
            style={{ width: 400 }}
          />

          <S.RadioWrapper>
            <Field name="clientId">
              {({ form, meta }: FieldProps) => (
                <>
                  <RadioGroup
                    onChange={handleClientIdChange((value: number) => form.setFieldValue("clientId", value))}
                  >
                    {radioButtons}
                  </RadioGroup>
                  {meta.error && meta.touched && <S.Error>{meta.error}</S.Error>}
                </>
              )}
            </Field>
          </S.RadioWrapper>

          <S.InputWrapper>
            <Field name="mainLocation">
              {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder="Title" />}
            </Field>
          </S.InputWrapper>

          <S.InputWrapper>
            <Field name="secondaryLocation">
              {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder="Subtitle" />}
            </Field>
          </S.InputWrapper>

          <div style={{ marginTop: 10 }}>
            <Button size="small" htmlType="submit" loading={isLoading} type="primary">
              Generate portal link
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
