import { BaseAttribute } from "@api/client";
import { Heading } from "@components/heading";
import { Button } from "@components/button";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { setCustomFilter, setCustomFilterSearchType, setCustomFilterValue } from "@state/attribures/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import { AppState } from "@state/store";
import { String, StringModes } from "../string";
import * as S from "./styled";
import { updateDistribution } from "./utils/update-distribution";
import { NumbericSetFilterSection } from "../numeric-set-filter-section/numeric-set-filter-section";

const isStringType = (type: string) => {
  return type === "string" || type === "bit";
};

interface Props {
  currentAttribute: BaseAttribute;
}

export const SecondStepCustomFilterModal: React.FC<Props> = ({ currentAttribute }) => {
  const dispatch = useDispatch();
  const attributeCode = useSelector((state: AppState) => state.attributes.customFilter.key);
  const filtersData = useSelector((state: AppState) => state.filters[state.filters.currentModule].data);
  const selectedUnit = useSelector((state: AppState) => state.attributes.customFilter.valueUnit);
  const currentModule = useSelector((state: AppState) => state.filters.currentModule);

  useEffect(() => {
    updateDistribution(dispatch, attributeCode, currentModule, filtersData, selectedUnit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttribute, currentModule]);

  const isLoadingDistributionData = useSelector((state) => state.attributes.loadingDistribution);
  const { key: currentGroup, value: groupValue } = useSelector((state) => state.attributes.customFilter);

  const onGroupValueChangeForString = (value: string[] | string) => {
    if (typeof value === "string") {
      dispatch(setCustomFilterSearchType(StringModes.FT));
    } else {
      dispatch(setCustomFilterSearchType(StringModes.CAT));
    }
    dispatch(
      setCustomFilterValue({
        value,
      })
    );
  };

  const onClearValue = () => {
    dispatch(
      setCustomFilter({
        value: "",
        key: "",
        searchType: undefined,
        floors: undefined,
        modules: undefined,
        valueUnit: undefined,
      })
    );
  };

  if (isLoadingDistributionData) {
    return <Skeleton active />;
  }

  return (
    <S.ContainerChild>
      <Heading level="5">Attributes:</Heading>
      <S.ContainerChild>
        <S.SelectedGroup>
          {currentAttribute.name}
          <Button
            size="small"
            icon={<CloseOutlined />}
            onClick={onClearValue}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
            }}
          />
        </S.SelectedGroup>
        <S.SecondStep>
          {isStringType(currentAttribute.dataTypeCode) ? (
            <String onChange={onGroupValueChangeForString} currentGroup={currentGroup} value={groupValue} />
          ) : (
            <NumbericSetFilterSection currentAttribute={currentAttribute} />
          )}
        </S.SecondStep>
      </S.ContainerChild>
    </S.ContainerChild>
  );
};
