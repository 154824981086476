import styled, { DefaultTheme, ThemeProps } from "styled-components";
import React from "react";
import { DeleteButton } from "@components/delete-button";
import { useMutation } from "react-query";
import { offerApi } from "@api/core";
import {
  AttributeValue,
  DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest,
  PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest,
} from "@api/client";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { updateAttributeAfterFloorUpdate } from "@state/offers/actions";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 15% 70% 15%;
  padding: 5px 0;

  :last-of-type {
    padding-bottom: 10px;
  }
`;

const FloorRange = styled.div`
  margin: auto;
`;

const Input = styled.input<ThemeProps<DefaultTheme>>`
  outline-color: ${({ theme }) => theme.primaryColor};
`;

interface ModuleElementProp {
  moduleId: number;
  moduleNumber: string;
  value: string;
}

export const ModuleElement: React.FC<ModuleElementProp> = ({ moduleId, moduleNumber, value }) => {
  const dispatch = useDispatch();
  const { mutate } = useMutation(
    () =>
      offerApi.deleteOfferAttributeApiV1OffersAttributesAttributeIdDelete({
        attributeId: moduleId,
      } as DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("success");
      },
      onError: () => {
        message.error("Somthing went wrong");
      },
    }
  );

  const { mutate: mutateInput } = useMutation(
    (newValue: string) =>
      offerApi.putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePut({
        attributeId: moduleId,
        attributeValue: { valueOriginal: newValue } as AttributeValue,
      } as PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("success");
      },
      onError: () => {
        message.error("Somthing went wrong");
      },
    }
  );

  const handleDelete = (event: React.MouseEvent) => {
    mutate();
    event.stopPropagation();
  };

  const handleInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
    mutateInput(event.target.value);
    event.stopPropagation();
  };

  return (
    <Wrapper key={moduleId}>
      <FloorRange>{moduleNumber}</FloorRange>
      <Input defaultValue={value === "null" ? "" : value} onBlur={handleInputChange} />
      <DeleteButton onClick={handleDelete} />
    </Wrapper>
  );
};
