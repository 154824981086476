import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import { useMutation } from "react-query";
import { offerApi } from "@api/core";
import { ApiResponse } from "@api/client";
import { usePermissions } from "@hooks/use-permission";
import { triggerFetchDetails } from "@state/offers/actions";
import * as S from "./redd-buttons.styled";

export const REDDButton = () => {
  const hasREDD = useSelector((state) => state.offers.offerDetails.data?.hasREDD);

  const { hasPermission } = usePermissions();
  const dispatch = useDispatch();

  const { id: offerId } = useParams<{ id: string }>();
  const { mutateAsync, isLoading } = useMutation<ApiResponse<any>>(["offer-redd", offerId], () =>
    offerApi.putReddAttributesApiV1OffersOfferIdReddPutRaw({ offerId: Number(offerId) })
  );

  const isError = (status: number) => {
    return !(status === 200 || status === 201);
  };

  const showMessage = (status: number) => {
    const error = isError(status);
    if (error) {
      // notification.error({
      //   message: "An error occurred while updating data. Try again later",
      // });
      // who cares
    }
    if (status === 200) {
      notification.info({
        message: "No update required",
      });
    }
    if (status === 201) {
      // Update attributes
      dispatch(
        triggerFetchDetails({
          id: offerId,
        })
      );
      notification.success({
        message: "The data have been updated",
      });
    }
  };

  const handleClick = async () => {
    try {
      const res = await mutateAsync();
      const status = res?.raw.status;
      showMessage(status);
    } catch (e) {
      showMessage(0);
    }
  };
  return (
    <>
      {hasPermission("PUT_UPDATE_REDD") && (
        <S.Button loading={isLoading} type="default" onClick={handleClick} grey={!hasREDD}>
          REDD
        </S.Button>
      )}
    </>
  );
};
