import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomFilterValue,
  setVisibleFloorSlider,
  setVisibleModuleSlider,
  setVisibleValueSlider,
} from "@state/attribures/actions";
import { BaseAttribute } from "@api/client";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Numeric } from "../numeric";
import {
  useDistribution,
  useUpdateFloorAndModules,
} from "../custom-filter-modal/utils/use-set-default-floor-and-modules";
import handlersFloorsAndModulesGenerator from "../sliders/utils/handlers-for-sliders";
import { FloorSlider } from "../sliders/floor-slider";
import { ModuleSlider } from "../sliders/module-slider";
import { SliderWithCheckbox } from "../slider-with-checkbox/slider-with-checkbox";

interface Props {
  currentAttribute: BaseAttribute;
}

export const NumbericSetFilterSection: React.FC<Props> = ({ currentAttribute }) => {
  const dispatch = useDispatch();
  const onGroupValueChange = (value: string | string[]) => {
    dispatch(
      setCustomFilterValue({
        value,
      })
    );
  };

  const handleFloorCheckboxChange = useCallback(
    (event: CheckboxChangeEvent) => {
      dispatch(setVisibleFloorSlider(event.target.checked));
    },
    [dispatch]
  );

  const handleModuleCheckboxChange = useCallback(
    (event: CheckboxChangeEvent) => {
      dispatch(setVisibleModuleSlider(event.target.checked));
    },
    [dispatch]
  );

  const handleValueCheckboxChange = useCallback(
    (event: CheckboxChangeEvent) => {
      dispatch(setVisibleValueSlider(event.target.checked));
    },
    [dispatch]
  );

  const {
    key: currentGroup,
    value: groupValue,
    valueUnit: unitGroupValue,
    floors,
    modules,
    isValueFilterActivie,
    isFloorFilterActivie,
    isModuleFilterActivie,
  } = useSelector((state) => state.attributes.customFilter);

  const { floors: distributionFloors, modules: distributionModules } = useDistribution(currentAttribute);

  const paramsForHandlersGenerator: Parameters<typeof handlersFloorsAndModulesGenerator>[0] = {
    dispatch,
    groupValue,
    floors: floors || distributionFloors,
    modules: modules || distributionModules,
  };

  const {
    onFloorsLowerInputChange,
    onFloorsSliderValueChange,
    onFloorsUpperInputChange,
    onModulesLowerInputChange,
    onModulesSliderValueChange,
    onModulesUpperInputChange,
  } = handlersFloorsAndModulesGenerator(paramsForHandlersGenerator);

  useUpdateFloorAndModules(currentAttribute);
  return (
    <>
      <SliderWithCheckbox
        title="Add value filter"
        isSelected={isValueFilterActivie}
        onChange={handleValueCheckboxChange}
      >
        <Numeric
          onChange={onGroupValueChange}
          currentGroup={currentGroup}
          value={groupValue as string}
          unitValue={unitGroupValue}
        />
      </SliderWithCheckbox>
      <SliderWithCheckbox
        title="Add floor filter"
        isSelected={isFloorFilterActivie}
        onChange={handleFloorCheckboxChange}
      >
        <FloorSlider
          currentGroup={currentGroup}
          onLowerInputChange={onFloorsLowerInputChange}
          onSliderChange={onFloorsSliderValueChange}
          onUpperInputChange={onFloorsUpperInputChange}
        />
      </SliderWithCheckbox>
      <SliderWithCheckbox
        title="Add module filter"
        isSelected={isModuleFilterActivie}
        onChange={handleModuleCheckboxChange}
      >
        <ModuleSlider
          currentGroup={currentGroup}
          onLowerInputChange={onModulesLowerInputChange}
          onSliderChange={onModulesSliderValueChange}
          onUpperInputChange={onModulesUpperInputChange}
        />
      </SliderWithCheckbox>
    </>
  );
};
