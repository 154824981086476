import { createReducer } from "typesafe-actions";
import { filterLeavesId } from "@utils/geography";
import { GeographyState } from "./types";
import { fetchGeographyAction } from "./actions";

const initialState: GeographyState = {
  data: [],
  loading: false,
  leaves: [],
};

type GeographyAction = {
  type: string;
};

export const geographyReducer = createReducer<GeographyState, GeographyAction>(initialState)
  .handleAction(fetchGeographyAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchGeographyAction.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchGeographyAction.success, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload,
    leaves: filterLeavesId(action.payload),
  }));
