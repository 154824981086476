/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactResponse
 */
export interface ContactResponse {
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ContactResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof ContactResponse
     */
    parentId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactResponse
     */
    isRemovable: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ContactResponse
     */
    modificationDate: Date;
}

export function ContactResponseFromJSON(json: any): ContactResponse {
    return ContactResponseFromJSONTyped(json, false);
}

export function ContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': json['name'],
        'id': json['id'],
        'type': json['type'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'isRemovable': json['isRemovable'],
        'modificationDate': (new Date(json['modificationDate'])),
    };
}

export function ContactResponseToJSON(value?: ContactResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'email': value.email,
        'name': value.name,
        'id': value.id,
        'type': value.type,
        'parentId': value.parentId,
        'isRemovable': value.isRemovable,
        'modificationDate': (value.modificationDate.toISOString()),
    };
}


