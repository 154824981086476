import { createAsyncAction } from "typesafe-actions";
import { DictBase, DictPropertyCategory } from "@api/client";

export const PROPERTY_CATEGORIES_REQUEST = "DICTS/PROPERTY_CATEGORIES_REQUEST";
export const PROPERTY_CATEGORIES_SUCCESS = "DICTS/PROPERTY_CATEGORIES_SUCCESS";
export const PROPERTY_CATEGORIES_FAILURE = "DICTS/PROPERTY_CATEGORIES_FAILURE";

export const POI_CATEGORIES_REQUEST = "DICTS/POI_CATEGORIES_REQUEST";
export const POI_CATEGORIES_SUCCESS = "DICTS/POI_CATEGORIES_SUCCESS";
export const POI_CATEGORIES_FAILURE = "DICTS/POI_CATEGORIES_FAILURE";

export const OFFER_STATUSES_REQUEST = "DICTS/OFFER_STATUSES_REQUEST";
export const OFFER_STATUSES_SUCCESS = "DICTS/OFFER_STATUSES_SUCCESS";
export const OFFER_STATUSES_FAILURE = "DICTS/OFFER_STATUSES_FAILURE";

export const PROPERTY_STATUSES_REQUEST = "DICTS/PROPERTY_STATUSES_REQUEST";
export const PROPERTY_STATUSES_SUCCESS = "DICTS/PROPERTY_STATUSES_SUCCESS";
export const PROPERTY_STATUSES_FAILURE = "DICTS/PROPERTY_STATUSES_FAILURE";

export const fetchPropertyCategoriesAction = createAsyncAction(
  PROPERTY_CATEGORIES_REQUEST,
  PROPERTY_CATEGORIES_SUCCESS,
  PROPERTY_CATEGORIES_FAILURE
)<void, DictPropertyCategory[], Error>();

export const fetchPoiCategoriesAction = createAsyncAction(
  POI_CATEGORIES_REQUEST,
  POI_CATEGORIES_SUCCESS,
  POI_CATEGORIES_FAILURE
)<void, DictPropertyCategory[], Error>();

export const fetchOfferStatuesAction = createAsyncAction(
  OFFER_STATUSES_REQUEST,
  OFFER_STATUSES_SUCCESS,
  OFFER_STATUSES_FAILURE
)<void, DictBase[], Error>();

export const fetchPropertyStatuesAction = createAsyncAction(
  PROPERTY_STATUSES_REQUEST,
  PROPERTY_STATUSES_SUCCESS,
  PROPERTY_STATUSES_FAILURE
)<void, DictBase[], Error>();
