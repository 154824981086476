import React, { useState } from "react";
import { Popconfirm } from "antd";

import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { Floors, QuickformAttributes } from "@api/client";
import { useToggle } from "@hooks/use-toggle";
import { usePermissions } from "@hooks/use-permission";
import { DeleteButton } from "@components/delete-button";

import { HandleAttributeValueUpdate, HandleDeleteFloor, HandleDeleteModule } from "../types";
import * as S from "../quick-form.styled";
import { AddModuleValueForm } from "../add-module-value-form";
import { AttributeFloorValue } from "../attribute-floor-value";
import { CollapsibleTableRowOffice } from "../collapsedTableRows/collapsible-table-row-office";

interface TableRowProps {
  floor: Floors;
  parentAttributes: QuickformAttributes;
  onAttributeUpdate: HandleAttributeValueUpdate;
  onDeleteFloor: HandleDeleteFloor;
  onDeleteModule: HandleDeleteModule;
}

const arrowOutlinedStyles = { cursor: "pointer", marginRight: "5px", fontSize: "10px", alignSelf: "center" };

export const TableRowOffice = ({
  floor,
  parentAttributes,
  onAttributeUpdate,
  onDeleteFloor,
  onDeleteModule,
}: TableRowProps) => {
  const [showCollapsed, setShowCollapsed] = useState(false);
  const { state: showFloorPopconfirm, toggle: toggleFloorPopconfirm } = useToggle();
  const { hasPermission } = usePermissions();

  return (
    <>
      <S.TableRow>
        <S.TableCell
          style={{ display: "flex", width: "80px" }}
          onClick={() => setShowCollapsed(!showCollapsed)}
        >
          {showCollapsed ? (
            <UpOutlined style={arrowOutlinedStyles} />
          ) : (
            <DownOutlined style={arrowOutlinedStyles} />
          )}
          <span>{floor.floorsOriginal}</span>
        </S.TableCell>
        <S.TableCell>
          <AttributeFloorValue
            attributeId={floor.availableOfficeSpace?.id}
            currentValue={floor.availableOfficeSpace?.valueOriginal}
            parentAttributeId={parentAttributes.availableOfficeSpace?.id}
            onUpdate={onAttributeUpdate}
            floorsOriginal={floor.floorsOriginal}
            groupKey={floor.availableOfficeSpace?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeFloorValue
            attributeId={floor.availability?.id}
            currentValue={floor.availability?.valueOriginal}
            parentAttributeId={parentAttributes.availability?.id}
            onUpdate={onAttributeUpdate}
            floorsOriginal={floor.floorsOriginal}
            groupKey={floor.availability?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeFloorValue
            attributeId={floor.askingRent?.id}
            currentValue={floor.askingRent?.valueOriginal}
            parentAttributeId={parentAttributes.askingRent?.id}
            onUpdate={onAttributeUpdate}
            floorsOriginal={floor.floorsOriginal}
            groupKey={floor.askingRent?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeFloorValue
            attributeId={floor.type?.id}
            currentValue={floor.type?.valueOriginal}
            parentAttributeId={parentAttributes.type?.id}
            onUpdate={onAttributeUpdate}
            floorsOriginal={floor.floorsOriginal}
            groupKey={floor.type?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeFloorValue
            attributeId={floor.documentComments?.id}
            currentValue={floor.documentComments?.valueOriginal}
            parentAttributeId={parentAttributes.documentComments?.id}
            onUpdate={onAttributeUpdate}
            floorsOriginal={floor.floorsOriginal}
            groupKey={floor.documentComments?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeFloorValue
            attributeId={floor.internalComments?.id}
            currentValue={floor.internalComments?.valueOriginal}
            parentAttributeId={parentAttributes.internalComments?.id}
            onUpdate={onAttributeUpdate}
            floorsOriginal={floor.floorsOriginal}
            groupKey={floor.internalComments?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <Popconfirm
            title="Are you sure that you want to delete this floor?"
            visible={showFloorPopconfirm}
            onConfirm={() => {
              onDeleteFloor(floor.floorsOriginal);
              toggleFloorPopconfirm();
            }}
            onCancel={toggleFloorPopconfirm}
          >
            <DeleteButton onClick={toggleFloorPopconfirm} disabled={!hasPermission("DELETE_ATTRIBUTE")} />
          </Popconfirm>
        </S.TableCell>
      </S.TableRow>
      {showCollapsed &&
        floor.modules &&
        floor.modules.map((module) => {
          return (
            <CollapsibleTableRowOffice
              module={module}
              floor={floor}
              onDeleteModule={onDeleteModule}
              onAttributeUpdate={onAttributeUpdate}
            />
          );
        })}
      {showCollapsed && (
        // avoid stretching rows after list is collapsed
        <div style={{ height: "70px" }}>
          <AddModuleValueForm floorsOriginal={floor.floorsOriginal} />
        </div>
      )}
    </>
  );
};
