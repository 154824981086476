import { FiltersQuery } from "@state/filters/types";

export const filters = (filtersQuery: FiltersQuery) =>
  Object.entries(filtersQuery).reduce(
    (selectedFilters, [key, value]) =>
      value.toString.length || typeof value === "boolean" || Object.keys(value).length
        ? { ...selectedFilters, [key]: value }
        : { ...selectedFilters },
    {} as FiltersQuery
  );
