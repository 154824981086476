export const appPath = "/app";
export const portalPath = "/portal";

const idRegex = ":id(\\d+)";

export const offersId = (id?: string | number): string => `${appPath}/offers/${id || idRegex}`;
export const offerTransactionsId = (id?: string | number): string =>
  `${appPath}/offers/transactions/${id || idRegex}`;
export const offerContactsId = (id?: string | number): string =>
  `${appPath}/offers/contacts/${id || idRegex}`;
export const auditTrailId = (id?: string | number): string => `${appPath}/offers/audittrail/${id || idRegex}`;
