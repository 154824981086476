export const getPrecisionNumber = (num: number): number => {
  const numAsString = num.toString();
  const decimalPart = numAsString.split(".")?.[1];
  if (!decimalPart) {
    return 1;
  }
  const numOfDigits = decimalPart.length;
  const result = 1 / 10 ** numOfDigits;
  return result === 0 ? 1 : result;
};
