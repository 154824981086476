import { createApiAction } from "@utils/createApiAction";
import { propertyApi } from "@api/core";
import * as actions from "@state/offers/actions";

export const updatePropertyStatus = createApiAction(
  actions.updatePropertyStatus,
  propertyApi.putPropertyStatusApiV1PropertiesPropertyIdStatusPut.bind(propertyApi)
);

export const updatePropertyName = createApiAction(
  actions.updatePropertyName,
  propertyApi.putPropertyNameApiV1PropertiesPropertyIdNamePut.bind(propertyApi)
);

export const updatePropertyAddress = createApiAction(
  actions.updatePropertyAddress,
  propertyApi.putPropertyAddressApiV1PropertiesPropertyIdAddressPut.bind(propertyApi)
);

export const updatePropertyGeography = createApiAction(
  actions.updatePropertyGeography,
  propertyApi.putPropertyGeographyApiV1PropertiesPropertyIdGeographyPut.bind(propertyApi)
);

export const assignPoi = createApiAction(
  actions.assignPropertyPoi,
  propertyApi.putPropertyPoiApiV1PropertiesPropertyIdPoisPut.bind(propertyApi) as any
);

export const unassignPoi = createApiAction(
  actions.unassignPropertyPoi,
  propertyApi.deletePropertyPoiApiV1PropertiesPropertyIdPoisPoiIdDelete.bind(propertyApi)
);

export const getSuggestedPoi = createApiAction(
  actions.getSuggestedPoi,
  propertyApi.getSuggestedPoisApiV1PropertiesPropertyIdSuggestedPoisGet.bind(propertyApi)
);

export const updateCoordinates = createApiAction(
  actions.updateCoordinates,
  propertyApi.putPropertyCoordinatesApiV1PropertiesPropertyIdCoordinatesPut.bind(propertyApi)
);
