import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message, Popover } from "antd";
import { useMutation } from "react-query";
import { AddButton, Button } from "@components/button";
import { useToggle } from "@hooks/use-toggle";
import { usePermissions } from "@hooks/use-permission";
import { Input } from "@components/input";
import { getOfferApi } from "@api/core";
import {
  AppSchemasOfferFloorIn,
  PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest,
  QuickformAttribute,
} from "@api/client";
import { updateAttributeAfterFloorUpdate } from "@state/offers/actions";
import * as S from "./attribute-value.styled";

interface OnlyFormProps {
  onClose: () => void;
}

const { offerApi } = getOfferApi();
const typeCallback =
  (callback: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) =>
    callback(event.target.value);

const floorLabelId = "Floor";

const Form: React.FC<OnlyFormProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const quickformAttributes = useSelector((state) => state.offers.offerDetails.data?.quickformAttributes);
  const [floor, setFloor] = useState("");
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>(0);
  const selectedHandleAddAttribute = (attributeId: number) => setSelectedAttributeId(attributeId);

  const { mutate, error } = useMutation(
    () =>
      offerApi.postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPost({
        attributeId: selectedAttributeId,
        appSchemasOfferFloorIn: { floors: floor } as AppSchemasOfferFloorIn,
      } as PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest),
    {
      onSuccess: () => {
        dispatch(updateAttributeAfterFloorUpdate());
        message.success("success");
        onClose();
      },
    }
  );
  const handleFloorChange = typeCallback(setFloor);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    mutate();
  };

  if (error) return <>Something went wrong</>;

  return (
    <form onSubmit={handleSubmit}>
      <S.AttributeButtonsGroup>
        <div hidden>{JSON.stringify(quickformAttributes)}</div>
        {quickformAttributes &&
          Object.entries(quickformAttributes).map(([key, value]: [string, QuickformAttribute]) => {
            if (key === "floors") return;
            return (
              <S.AttributeButtonsGroup>
                <li key={value.id}>
                  <S.AttributeButton
                    key={`${value.label}-button`}
                    type="button"
                    onClick={() => selectedHandleAddAttribute(value.id)}
                    isSelected={value.id === selectedAttributeId}
                  >
                    {value.label}
                  </S.AttributeButton>
                </li>
              </S.AttributeButtonsGroup>
            );
          })}
      </S.AttributeButtonsGroup>
      <Input id={floorLabelId} type="string" value={floor} onChange={handleFloorChange} min={0} />
      <div style={{ marginTop: 20 }}>
        <Button onClick={onClose}>Cancel</Button>
        {floor !== "" && selectedAttributeId !== 0 ? (
          <Button htmlType="submit" type="primary">
            Add
          </Button>
        ) : (
          <Button type="primary" disabled>
            Add
          </Button>
        )}
      </div>
    </form>
  );
};

export const AddFloorValueForm: React.FC = () => {
  const { state: visible, toggle } = useToggle();
  const { hasPermission } = usePermissions();
  return (
    <Popover
      visible={visible}
      title="Add value for specific floor"
      content={<Form onClose={toggle} />}
      destroyTooltipOnHide
      placement="rightTop"
      trigger="click"
      onVisibleChange={toggle}
    >
      <AddButton onClick={toggle} disabled={!hasPermission("POST_OFFER_ATTRIBUTE_FLOOR")}>
        Add floor
      </AddButton>
    </Popover>
  );
};

