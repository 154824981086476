import React from "react";
import styled from "styled-components";

export interface HeadingProps {
  level?: "1" | "2" | "3" | "4" | "5" | "6";
}

const HeadingCore: React.FC<HeadingProps> = ({ level, children, ...restProps }) => {
  return React.createElement(`h${level}`, restProps, children);
};

HeadingCore.defaultProps = {
  level: "2",
};

const gothamBook = 500;
const gothamLight = 400;

export const Heading = styled(HeadingCore)`
  font-size: ${({ level }) =>
    (level === "1" && "16px") ||
    (level === "2" && "14px") ||
    (level === "3" && "16px") ||
    (level === "4" && "13px") ||
    (level === "5" && "12px") ||
    (level === "6" && "12px") ||
    "14px"};
  font-weight: ${({ level }) =>
    (level === "1" && gothamBook) ||
    (level === "2" && gothamBook) ||
    ((level === "3" || level === "4" || level === "5") && gothamLight) ||
    (level === "6" && gothamBook) ||
    gothamBook};
  color: ${({ theme, level }) =>
    (level === "1" && theme.darkBlue) ||
    (level === "2" && theme.darkGrey) ||
    ((level === "3" || level === "4" || level === "5") && theme.greyBlue) ||
    (level === "6" && theme.lightGrey) ||
    theme.darkGrey};
`;

export const HeadingSmall = styled(Heading)`
  padding-left: 7px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.1em;
`;
