import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ColumnType } from "antd/es/table";
import { DateTime } from "luxon";
import { Tabs } from "antd";

import { attributeApi } from "@api/core";
import { AuditTrailAttributeFloor, AuditTrailBase } from "@api/client";

import { useSearchProps } from "@pages/audit-trail/audit-table/use-search-props";
import { AuditTable } from "@pages/audit-trail/audit-table/audit-table";
import * as S from "./audit-trail.styled";

const { TabPane } = Tabs;

export const AuditTrail = () => {
  const { id } = useParams<{ id: string }>();
  const getSearchProps = useSearchProps();
  const offerId = Number(id);

  const baseColumns: ColumnType<AuditTrailBase>[] = [
    {
      title: "Code",
      key: "code",
      dataIndex: "code",
      ...getSearchProps("code", "Code"),
    },
    {
      title: "Value",
      key: "valueOriginal",
      dataIndex: "valueOriginal",
      ...getSearchProps("valueOriginal", "Value"),
    },
    {
      title: "Date",
      key: "timeInterval",
      dataIndex: "timeInterval",
      render: (value: Date) => DateTime.fromJSDate(value).toFormat("yyyy-MM-dd HH:mm:ss"),
      sorter: (a, b) => +a.timeInterval - +b.timeInterval,
    },
    {
      title: "Created by",
      key: "createdBy",
      dataIndex: "createdBy",
      ...getSearchProps("createdBy", "Created by"),
    },
  ];

  const floorColumns: ColumnType<AuditTrailAttributeFloor>[] = [
    ...baseColumns,
    {
      title: "Floors",
      key: "floorsOriginal",
      dataIndex: "floorsOriginal",
      ...getSearchProps("floorsOriginal", "Floors"),
    },
    {
      title: "Module",
      key: "module",
      dataIndex: "module",
      ...getSearchProps("module", "Module"),
    },
  ];

  const { data } = useQuery(
    ["audit-trail", offerId],
    () =>
      attributeApi.getOfferAttributesAuditTrailApiV1AttributesOfferIdAuditTrailGet({
        offerId,
      }),
    { cacheTime: 0 }
  );

  if (!data) {
    return null;
  }

  return (
    <S.Wrapper>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Attributes" key="1">
          <AuditTable dataSource={data.attributesAuditTrail} columns={baseColumns} />
        </TabPane>
        <TabPane tab="Floor attributes" key="2">
          <AuditTable dataSource={data.floorsAttributesAuditTrail} columns={floorColumns} />
        </TabPane>
        <TabPane tab="Modules Attributes" key="3">
          <AuditTable dataSource={data.modulesAttributesAuditTrail} columns={floorColumns} />
        </TabPane>
      </Tabs>
    </S.Wrapper>
  );
};
