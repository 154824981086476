import React, { useCallback } from "react";
import { FastField, FieldProps } from "formik";
import { EditableContentInput } from "@components/form/editable-content-input";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { updateDescription } from "@utils/offer";
import { usePermissions } from "@hooks/use-permission";

import * as S from "./description.styled";
import { Heading } from "../offer-detail.styled";

export const Description: React.FC<{ title: string; type: string; name: string }> = ({
  title,
  type,
  name,
}) => {
  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();

  const { id } = useParams<{ id: string }>();

  const onDescriptionChange = useCallback(
    (description: string) => {
      updateDescription(dispatch)({
        offerId: +id,
        offerInDescription: {
          description,
        },
        descriptionType: type,
      });
    },
    [dispatch, id, type]
  );

  return (
    <S.Container>
      <Heading level="2">{title}</Heading>

      <FastField name={name}>
        {(fieldProps: FieldProps) => (
          <EditableContentInput
            edibable={hasPermission("PUT_OFFER_DESCRIPTION")}
            onBlur={onDescriptionChange}
            multiline
            {...fieldProps}
          />
        )}
      </FastField>
    </S.Container>
  );
};
