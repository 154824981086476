import React from "react";
import { useMutation } from "react-query";
import { propertyApi } from "@api/core";
import { useDispatch, useSelector } from "react-redux";
import * as S from "@pages/offer-detail/offer-redd-panel/redd-button/redd-buttons.styled";
import { setAllowReddDownload } from "@state/offers/actions";

const states = {
  disabled: "default",
  enabled: "primary",
} as const;

export const REDDAllowDownload = () => {
  const dispatch = useDispatch();
  const allowReddDownload = useSelector((state) => !!state.offers.offerDetails.data?.allowReddDownload);
  const propertyId = useSelector((state) => state.offers.offerDetails.data?.propertyId) || 0;
  const type = allowReddDownload ? states.enabled : states.disabled;

  const { mutate, isLoading } = useMutation(
    ["put-redd-state", propertyId],
    () =>
      propertyApi.putReddStateApiV1PropertiesPropertyIdReddstatePut({
        propertyId,
        reddState: { state: !allowReddDownload },
      }),
    {
      onSuccess: (data) => {
        dispatch(setAllowReddDownload(data.state));
      },
    }
  );

  const handleClick = () => {
    mutate();
  };

  return (
    <>
      <S.Button loading={isLoading} type={type} onClick={handleClick}>
        Allow REDD Download
      </S.Button>
    </>
  );
};
