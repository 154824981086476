/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutLettingAttributesOut
 */
export interface PutLettingAttributesOut {
    /**
     * 
     * @type {string}
     * @memberof PutLettingAttributesOut
     */
    valueInterpretationStatusCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PutLettingAttributesOut
     */
    valueInterpretationMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof PutLettingAttributesOut
     */
    valueOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof PutLettingAttributesOut
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof PutLettingAttributesOut
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PutLettingAttributesOut
     */
    dataTypeCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutLettingAttributesOut
     */
    isVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutLettingAttributesOut
     */
    attributeId: number;
}

export function PutLettingAttributesOutFromJSON(json: any): PutLettingAttributesOut {
    return PutLettingAttributesOutFromJSONTyped(json, false);
}

export function PutLettingAttributesOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutLettingAttributesOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valueInterpretationStatusCode': !exists(json, 'valueInterpretationStatusCode') ? undefined : json['valueInterpretationStatusCode'],
        'valueInterpretationMessage': !exists(json, 'valueInterpretationMessage') ? undefined : json['valueInterpretationMessage'],
        'valueOriginal': !exists(json, 'valueOriginal') ? undefined : json['valueOriginal'],
        'code': json['code'],
        'name': json['name'],
        'dataTypeCode': json['dataTypeCode'],
        'isVisible': !exists(json, 'isVisible') ? undefined : json['isVisible'],
        'attributeId': json['attributeId'],
    };
}

export function PutLettingAttributesOutToJSON(value?: PutLettingAttributesOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valueInterpretationStatusCode': value.valueInterpretationStatusCode,
        'valueInterpretationMessage': value.valueInterpretationMessage,
        'valueOriginal': value.valueOriginal,
        'code': value.code,
        'name': value.name,
        'dataTypeCode': value.dataTypeCode,
        'isVisible': value.isVisible,
        'attributeId': value.attributeId,
    };
}


