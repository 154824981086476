import { createAsyncAction } from "typesafe-actions";
import { GeoBase } from "@api/client";

const FETCH_GEOGRAPHY_REQUEST = "GEOGRAPHY/FETCH_GEOGRAPHY_REQUEST";
const FETCH_GEOGRAPHY_SUCCESS = "GEOGRAPHY/FETCH_GEOGRAPHY_SUCCESS";
const FETCH_GEOGRAPHY_FAILURE = "GEOGRAPHY/FETCH_GEOGRAPHY_FAILURE";

export const fetchGeographyAction = createAsyncAction(
  FETCH_GEOGRAPHY_REQUEST,
  FETCH_GEOGRAPHY_SUCCESS,
  FETCH_GEOGRAPHY_FAILURE
)<void, GeoBase[], Error>();
