import { fetchGeographyAction } from "@state/geography/actions";
import { geographyApi } from "@api/core";
import { createApiAction } from "@utils/createApiAction";
import { GeoBase } from "@api/client";

export const loadGeography = createApiAction(
  fetchGeographyAction,
  geographyApi.getGeographiesApiV1GeographiesGet.bind(geographyApi)
);

export const filterLeavesId = (data: GeoBase[]): number[] => {
  const leaves = data.map((x) => {
    if (data.every((y) => y.parentId !== x.id)) {
      return x;
    }
    return null;
  });
  const filteredLeaves = leaves.filter((x) => x !== null) as GeoBase[];
  return filteredLeaves.map((x) => x.id);
};
