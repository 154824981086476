import React from "react";
import { Modal } from "antd";
import { Heading } from "@components/heading";

interface Props {
  show: boolean;
  onCancel: () => void;
  onOk: () => void;
}
export const DeleteConfirmationModal: React.FC<Props> = ({ show, onCancel, onOk }) => {
  return (
    <Modal
      visible={show}
      onCancel={onCancel}
      //   okButtonProps={{ loading }}
      onOk={onOk}
      width={500}
    >
      <Heading>Are you sure that you want delete?</Heading>
    </Modal>
  );
};
