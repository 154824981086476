import React from "react";
import { Content, Sider } from "@components/layout";
import { Headline, SearchHeadline } from "../headline";
import { Filters } from "../filters/filters";
import { MainTable } from "../main-table/main-table";
import * as S from "./list.styled";

export const List = () => (
  <>
    <Sider theme="light" width={360}>
      <S.SearchWrapper>
        <SearchHeadline />
        <Filters />
      </S.SearchWrapper>
    </Sider>
    <Content>
      <Headline />
      <MainTable />
    </Content>
  </>
);
