import { useEffect, useState } from "react";

export type Dimensions = {
  height: number;
  width: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
};

export const combineChartDimensions = (dimensions: Dimensions) => {
  const parsedDimensions = {
    marginTop: 20,
    marginRight: 10,
    marginBottom: 20,
    marginLeft: 25,
    ...dimensions,
  };

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(
      parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom,
      0
    ),
    boundedWidth: Math.max(
      parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight,
      0
    ),
  };
};

export const useChartDimensions = (passedSettings: Dimensions) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const dimensions = combineChartDimensions(passedSettings);

  const [width, changeWidth] = useState(0);
  const [height, changeHeight] = useState(0);

  useEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) return;
        if (!entries.length) return;

        const entry = entries[0];

        if (width !== entry.contentRect.width) changeWidth(entry.contentRect.width);
        if (height !== entry.contentRect.height) changeHeight(entry.contentRect.height);
      });

      resizeObserver.observe(element);
      // eslint-disable-next-line consistent-return
      return () => {
        resizeObserver.unobserve(element);
      };
    }
    return undefined;
  }, [passedSettings, height, width, dimensions, element]);

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height,
  });

  return { setElement, dimensions: newSettings };
};
