/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReddState
 */
export interface ReddState {
    /**
     * 
     * @type {boolean}
     * @memberof ReddState
     */
    state: boolean;
}

export function ReddStateFromJSON(json: any): ReddState {
    return ReddStateFromJSONTyped(json, false);
}

export function ReddStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReddState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
    };
}

export function ReddStateToJSON(value?: ReddState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
    };
}


