import { Menu } from "antd";
import React from "react";
import { usePermissions } from "@hooks/use-permission";
import { ReactComponent as IconChangeNotActive } from "@svg/icon-change-notactive.svg";
import { ReactComponent as IconDeleteNotActive } from "@svg/icon-delete-notactive.svg";
import { ButtonInDropdown, IconInDropdownWrapper } from "./photo.styled";

type IDropDownMenu = {
  changePhotoHandler: () => void;
  removePhotoHandler: () => void;
};

export const DropDownMenu = ({ changePhotoHandler, removePhotoHandler }: IDropDownMenu): JSX.Element => {
  const { hasPermission } = usePermissions();

  return (
    <Menu>
      <Menu.Item>
        <ButtonInDropdown
          type="submit"
          onClick={changePhotoHandler}
          disabled={!hasPermission("PUT_PROPERTY_PHOTO")}
        >
          <IconInDropdownWrapper>
            <IconChangeNotActive />
          </IconInDropdownWrapper>
          Change picture
        </ButtonInDropdown>
      </Menu.Item>
      <Menu.Item>
        <ButtonInDropdown
          type="submit"
          onClick={removePhotoHandler}
          disabled={!hasPermission("DELETE_PROPERTY_PHOTO")}
        >
          <IconInDropdownWrapper>
            <IconDeleteNotActive />
          </IconInDropdownWrapper>
          Remove picture
        </ButtonInDropdown>
      </Menu.Item>
    </Menu>
  );
};
