import React from "react";
import { Popconfirm } from "antd";

import { Floors, Module } from "@api/client";
import { useToggle } from "@hooks/use-toggle";
import { usePermissions } from "@hooks/use-permission";
import { DeleteButton } from "@components/delete-button";

import { HandleAttributeValueUpdate, HandleDeleteModule } from "../types";
import * as S from "../quick-form.styled";
import { AttributeModuleValue } from "../attribute-module-value";

interface CollapsibleTableRowProps {
  module: Module;
  floor: Floors;
  onAttributeUpdate: HandleAttributeValueUpdate;
  onDeleteModule: HandleDeleteModule;
}

export const CollapsibleTableRowRetail = ({
  module,
  floor,
  onAttributeUpdate,
  onDeleteModule,
}: CollapsibleTableRowProps) => {
  const { state: showModulePopconfirm, toggle: toggleModulePopconfirm } = useToggle();
  const { hasPermission } = usePermissions();

  return (
    <>
      <S.CollapseTableRow key={module?.module}>
        <S.TableCell style={{ fontSize: "13px", textAlign: "right" }}>{module?.module}</S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.unit?.id}
            currentValue={module.unit?.valueOriginal}
            parentAttributeId={floor.unit?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.unit?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.brand?.id}
            currentValue={module.brand?.valueOriginal}
            parentAttributeId={floor.brand?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.brand?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.category1?.id}
            currentValue={module.category1?.valueOriginal}
            parentAttributeId={floor.category1?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.category1?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.category2?.id}
            currentValue={module.category2?.valueOriginal}
            parentAttributeId={floor.category2?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.category2?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.gLA?.id}
            currentValue={module.gLA?.valueOriginal}
            parentAttributeId={floor.gLA?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.gLA?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.salesArea?.id}
            currentValue={module.salesArea?.valueOriginal}
            parentAttributeId={floor.salesArea?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.salesArea?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.rent?.id}
            currentValue={module.rent?.valueOriginal}
            parentAttributeId={floor.rent?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.rent?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.date?.id}
            currentValue={module.date?.valueOriginal}
            parentAttributeId={floor.date?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.date?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.level?.id}
            currentValue={module.level?.valueOriginal}
            parentAttributeId={floor.level?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.level?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <AttributeModuleValue
            attributeId={module.notes?.id}
            currentValue={module.notes?.valueOriginal}
            parentAttributeId={floor.date?.id}
            onUpdate={onAttributeUpdate}
            module={module.module}
            groupKey={module.notes?.groupKey}
          />
        </S.TableCell>
        <S.TableCell>
          <Popconfirm
            title="Are you sure that you want to delete this module?"
            visible={showModulePopconfirm}
            onConfirm={() => {
              onDeleteModule(floor.floorsOriginal, module.module.toString());
              toggleModulePopconfirm();
            }}
            onCancel={toggleModulePopconfirm}
          >
            <DeleteButton onClick={toggleModulePopconfirm} disabled={!hasPermission("DELETE_ATTRIBUTE")} />
          </Popconfirm>
        </S.TableCell>
      </S.CollapseTableRow>
    </>
  );
};
