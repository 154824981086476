import React, { useCallback } from "react";
import { Collapse } from "antd";
import { TenantProperty } from "@api/client";
import { LettingList } from "../letting-list/letting-list";
import { TenantElementHeader } from "./tenant-element-header";
import "./tenant-list.less";

const { Panel } = Collapse;

interface Props {
  tenants: TenantProperty[];
  mutate: any;
}

export const TenantList: React.FC<Props> = ({ tenants, mutate }) => {
  const handleDelete = useCallback(() => mutate(), [mutate]);

  const handleOnChange = () => {};

  return (
    <Collapse onChange={handleOnChange}>
      {tenants &&
        tenants.map((tenant) => (
          <Panel
            header={
              <TenantElementHeader
                propertyId={tenant.propertyId}
                tenantId={tenant.id}
                name={tenant.name}
                onDelete={handleDelete}
              />
            }
            key={tenant.id}
          >
            <LettingList tenantId={tenant.id} key={tenant.id} />
          </Panel>
        ))}
    </Collapse>
  );
};
