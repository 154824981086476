import { attributeApi } from "@api/core";
import { filters } from "@utils/filters";
import * as attributeActions from "@state/attribures/actions";
import { Dispatch } from "redux";
import { FiltersQuery } from "@state/filters/types";
import { Module } from "@state/types";

export const updateDistribution = async (
  dispatch: Dispatch<any>,
  attributeCode: string,
  currentModule: Module,
  filtersData: FiltersQuery,
  selectedUnit?: string
) => {
  if (attributeCode === "") return;
  try {
    if (attributeCode) {
      dispatch(attributeActions.fetchDistribution.request());
      const distribution =
        await attributeApi.getAttributeDistributionApiV1AttributesAttributeCodeDistributionGet.bind(
          attributeApi
        )({
          attributeCode,
          unit: selectedUnit,
          module: currentModule,
          ...filters(filtersData),
        });

      dispatch(
        attributeActions.fetchDistribution.success({
          params: {
            attributeCode,
            ...filters(filtersData),
          },
          response: distribution,
        })
      );
      return distribution;
    }
  } catch (e) {
    return dispatch(attributeActions.fetchDistribution.failure(e));
  }
};
