/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DictAttribute,
    DictAttributeFromJSON,
    DictAttributeToJSON,
    DictBase,
    DictBaseFromJSON,
    DictBaseToJSON,
    DictDataType,
    DictDataTypeFromJSON,
    DictDataTypeToJSON,
    DictPropertyCategory,
    DictPropertyCategoryFromJSON,
    DictPropertyCategoryToJSON,
    DictUnitOfMeasure,
    DictUnitOfMeasureFromJSON,
    DictUnitOfMeasureToJSON,
    FileTag,
    FileTagFromJSON,
    FileTagToJSON,
    FileType,
    FileTypeFromJSON,
    FileTypeToJSON,
    FileTypeIn,
    FileTypeInFromJSON,
    FileTypeInToJSON,
    FileTypeOut,
    FileTypeOutFromJSON,
    FileTypeOutToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    OfferTags,
    OfferTagsFromJSON,
    OfferTagsToJSON,
} from '../models';

export interface DeleteFileTypeApiV1DictsFileTypeFileTypeIdDeleteRequest {
    fileTypeId: number;
}

export interface PostFileTypesApiV1DictsFileTypesPostRequest {
    fileTypeIn: FileTypeIn;
}

/**
 * 
 */
export class DictApi extends runtime.BaseAPI {

    /**
     * Delete File Type
     */
    async deleteFileTypeApiV1DictsFileTypeFileTypeIdDeleteRaw(requestParameters: DeleteFileTypeApiV1DictsFileTypeFileTypeIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileTypeId === null || requestParameters.fileTypeId === undefined) {
            throw new runtime.RequiredError('fileTypeId','Required parameter requestParameters.fileTypeId was null or undefined when calling deleteFileTypeApiV1DictsFileTypeFileTypeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/fileType/{fileTypeId}`.replace(`{${"fileTypeId"}}`, encodeURIComponent(String(requestParameters.fileTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete File Type
     */
    async deleteFileTypeApiV1DictsFileTypeFileTypeIdDelete(requestParameters: DeleteFileTypeApiV1DictsFileTypeFileTypeIdDeleteRequest): Promise<void> {
        await this.deleteFileTypeApiV1DictsFileTypeFileTypeIdDeleteRaw(requestParameters);
    }

    /**
     * Get all attributes
     */
    async getAllFileTagsApiV1DictsAttributesGetRaw(): Promise<runtime.ApiResponse<Array<DictAttribute>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DictAttributeFromJSON));
    }

    /**
     * Get all attributes
     */
    async getAllFileTagsApiV1DictsAttributesGet(): Promise<Array<DictAttribute>> {
        const response = await this.getAllFileTagsApiV1DictsAttributesGetRaw();
        return await response.value();
    }

    /**
     * Get all data types
     */
    async getAllFileTagsApiV1DictsDataTypesGetRaw(): Promise<runtime.ApiResponse<Array<DictDataType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/dataTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DictDataTypeFromJSON));
    }

    /**
     * Get all data types
     */
    async getAllFileTagsApiV1DictsDataTypesGet(): Promise<Array<DictDataType>> {
        const response = await this.getAllFileTagsApiV1DictsDataTypesGetRaw();
        return await response.value();
    }

    /**
     * Get all attachment file tags
     */
    async getAllFileTagsApiV1DictsFileTagsGetRaw(): Promise<runtime.ApiResponse<Array<FileTag>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/fileTags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileTagFromJSON));
    }

    /**
     * Get all attachment file tags
     */
    async getAllFileTagsApiV1DictsFileTagsGet(): Promise<Array<FileTag>> {
        const response = await this.getAllFileTagsApiV1DictsFileTagsGetRaw();
        return await response.value();
    }

    /**
     * Get all units of measures
     */
    async getAllFileTagsApiV1DictsUnitOfMeasuresGetRaw(): Promise<runtime.ApiResponse<Array<DictUnitOfMeasure>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/unitOfMeasures`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DictUnitOfMeasureFromJSON));
    }

    /**
     * Get all units of measures
     */
    async getAllFileTagsApiV1DictsUnitOfMeasuresGet(): Promise<Array<DictUnitOfMeasure>> {
        const response = await this.getAllFileTagsApiV1DictsUnitOfMeasuresGetRaw();
        return await response.value();
    }

    /**
     * Get all file types
     */
    async getAllFileTypesApiV1DictsFileTypesGetRaw(): Promise<runtime.ApiResponse<Array<FileType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/fileTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileTypeFromJSON));
    }

    /**
     * Get all file types
     */
    async getAllFileTypesApiV1DictsFileTypesGet(): Promise<Array<FileType>> {
        const response = await this.getAllFileTypesApiV1DictsFileTypesGetRaw();
        return await response.value();
    }

    /**
     * Get all offer tags
     */
    async getAllOfferTagsApiV1DictsOfferTagsGetRaw(): Promise<runtime.ApiResponse<Array<OfferTags>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/offerTags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferTagsFromJSON));
    }

    /**
     * Get all offer tags
     */
    async getAllOfferTagsApiV1DictsOfferTagsGet(): Promise<Array<OfferTags>> {
        const response = await this.getAllOfferTagsApiV1DictsOfferTagsGetRaw();
        return await response.value();
    }

    /**
     * Get Offer Statuses
     */
    async getOfferStatusesApiV1DictsOfferStatusesGetRaw(): Promise<runtime.ApiResponse<Array<DictBase>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/offerStatuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DictBaseFromJSON));
    }

    /**
     * Get Offer Statuses
     */
    async getOfferStatusesApiV1DictsOfferStatusesGet(): Promise<Array<DictBase>> {
        const response = await this.getOfferStatusesApiV1DictsOfferStatusesGetRaw();
        return await response.value();
    }

    /**
     * Get POI Categories
     */
    async getPoiCategoriesApiV1DictsPoiCategoriesGetRaw(): Promise<runtime.ApiResponse<Array<DictPropertyCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/poiCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DictPropertyCategoryFromJSON));
    }

    /**
     * Get POI Categories
     */
    async getPoiCategoriesApiV1DictsPoiCategoriesGet(): Promise<Array<DictPropertyCategory>> {
        const response = await this.getPoiCategoriesApiV1DictsPoiCategoriesGetRaw();
        return await response.value();
    }

    /**
     * Get Property Categories
     */
    async getPropertyCategoriesApiV1DictsPropertyCategoriesGetRaw(): Promise<runtime.ApiResponse<Array<DictPropertyCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/propertyCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DictPropertyCategoryFromJSON));
    }

    /**
     * Get Property Categories
     */
    async getPropertyCategoriesApiV1DictsPropertyCategoriesGet(): Promise<Array<DictPropertyCategory>> {
        const response = await this.getPropertyCategoriesApiV1DictsPropertyCategoriesGetRaw();
        return await response.value();
    }

    /**
     * Get Property Statuses
     */
    async getPropertyStatusesApiV1DictsPropertyStatusesGetRaw(): Promise<runtime.ApiResponse<Array<DictBase>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/propertyStatuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DictBaseFromJSON));
    }

    /**
     * Get Property Statuses
     */
    async getPropertyStatusesApiV1DictsPropertyStatusesGet(): Promise<Array<DictBase>> {
        const response = await this.getPropertyStatusesApiV1DictsPropertyStatusesGetRaw();
        return await response.value();
    }

    /**
     * Add new file type
     */
    async postFileTypesApiV1DictsFileTypesPostRaw(requestParameters: PostFileTypesApiV1DictsFileTypesPostRequest): Promise<runtime.ApiResponse<FileTypeOut>> {
        if (requestParameters.fileTypeIn === null || requestParameters.fileTypeIn === undefined) {
            throw new runtime.RequiredError('fileTypeIn','Required parameter requestParameters.fileTypeIn was null or undefined when calling postFileTypesApiV1DictsFileTypesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/dicts/fileTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileTypeInToJSON(requestParameters.fileTypeIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileTypeOutFromJSON(jsonValue));
    }

    /**
     * Add new file type
     */
    async postFileTypesApiV1DictsFileTypesPost(requestParameters: PostFileTypesApiV1DictsFileTypesPostRequest): Promise<FileTypeOut> {
        const response = await this.postFileTypesApiV1DictsFileTypesPostRaw(requestParameters);
        return await response.value();
    }

}
