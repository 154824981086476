import styled, { css } from "styled-components";

export interface StylingProps {
  grow?: boolean;
}

const buttonActive = css`
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.orange2};
  box-shadow: inset 0 0 6px #88330d;
`;

export const StyledContainer = styled.div<StylingProps>`
  ${({ grow }) => grow && "flex-grow: 1;"}

  &:nth-last-child(4),
  &:nth-last-child(4) ~ span {
    width: 25%;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  font-weight: 300;
  font-size: 12px;
  color: ${(props) => props.theme.greyBlue};
  height: 20px;
  margin: 10px 0;
  visibility: hidden;
  display: none;

  + label {
    background: linear-gradient(#ffffff, #e9ebed, #eceef0);
    border: 1px solid #cbccce;
    padding: 3px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:checked + label {
    ${buttonActive}
  }
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;
  text-align: center;
`;
