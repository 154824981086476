import styled from "styled-components";

import { theme } from "@components/variables";
import { Heading as HeadingCore } from "@components/heading";

export const Wrapper = styled.div`
  margin-top: 20px;
  padding: 10px 0;
  border-top: 1px solid ${theme.borderColor};
`;

export const Heading = styled(HeadingCore)`
  margin-bottom: 20px;
`;

export const UploadItem = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const LinkWrapper = styled.div`
  min-width: 250px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex: 1.6;
  align-items: baseline;
  margin-right: 10px;

  & div {
    flex: 1;
    white-space: nowrap;

    &:first-child {
      flex: 1.4;
      margin-right: 10px;
    }
  }

  & div:nth-child(3) {
    flex: 0;
    padding: 0 20px;
  }
`;

export const CheckboxLabel = styled.span`
  display: inline-block;
  margin-right: 10px;
`;

export const TagsWrapper = styled.div`
  flex: 1;
`;

export const NewTagForm = styled.form`
  display: inline-flex;
  align-items: baseline;
  margin-right: 20px;
`;
