import { useCallback, useEffect } from "react";
import { useIsSignedIn } from "@components/authentication/is-authenticate";
import { Providers } from "@microsoft/mgt-element";
import { getEnv } from "@utils/get-env";

const eventsToResetTimeOut = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

const createListOfEventListeners = (callbackFn: () => void) => {
  return eventsToResetTimeOut.map((event) => {
    window.addEventListener(event, callbackFn);
    return () => window.removeEventListener(event, callbackFn);
  });
};

let timerId: NodeJS.Timeout;

const timeToAutomateLogout = getEnv("REACT_APP_AUTOMATE_LOGOUT_TIME");

export const useAutomateLogout = () => {
  const [isSignIn] = useIsSignedIn();

  const logout = () => {
    Providers.globalProvider.logout?.();
  };

  const creatingNewTimeout = useCallback(() => {
    if (isSignIn !== 2) {
      return;
    }
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      logout();
    }, timeToAutomateLogout);
  }, [isSignIn]);

  const handleEventPrelongSession = useCallback(() => {
    creatingNewTimeout();
  }, [creatingNewTimeout]);

  useEffect(() => {
    const fooTable = createListOfEventListeners(handleEventPrelongSession);
    return () => fooTable.forEach((f) => f());
  }, [handleEventPrelongSession, isSignIn]);
};
