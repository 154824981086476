import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";
import { Tooltip, Popconfirm } from "antd";
import { offerApi } from "@api/core";
import { AppState } from "@state/store";
import { Breadcrumb as BreadcrumbCore, Item } from "@components/breadcrumb";
import { StyledButton } from "@components/button";
import { Icon } from "@components/icon";
import { theme } from "@components/variables";
import { triggerFetchDetails } from "@state/offers/actions";

import { ReactComponent as IconDuplicate } from "@svg/icon-duplicate.svg";
import { ReactComponent as IconDelete } from "@svg/icon-delete.svg";
import { ReactComponent as IconRefresh } from "@svg/icon-refresh.svg";
import { ReactComponent as IconExit } from "@svg/icon-exit.svg";
import { usePermissions } from "@hooks/use-permission";

import * as S from "./breadcrumb.styled";

export const Breadcrumb: React.FC = () => {
  const data = useSelector((state: AppState) => state.offers.offerDetails.data);
  const { hasPermission } = usePermissions();
  const canClone = hasPermission("CLONE_OFFER");
  const canDelete = hasPermission("DELETE_OFFER");
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const offerId = useMemo(() => ({ offerId: +params.id }), [params]);

  const handleClone = useCallback(
    () =>
      offerApi
        .postOfferApiV1OffersOfferIdPost(offerId)
        .then((response) => history.push(`/offers/${response.offerId}`)),
    [offerId, history]
  );

  const handleDelete = useCallback(
    () => offerApi.deleteOfferApiV1OffersOfferIdDelete(offerId).then(() => history.push("/")),
    [offerId, history]
  );

  const handleRefresh = useCallback(
    () => dispatch(triggerFetchDetails({ id: params.id })),
    [dispatch, params]
  );

  return (
    <S.Wrapper>
      <BreadcrumbCore separator="&nbsp;&nbsp;>&nbsp;&nbsp;">
        <Item>
          <S.Link to="/">List of Properties</S.Link>
        </Item>
        <S.Item>{data?.offerName || "Loading"}</S.Item>
      </BreadcrumbCore>
      <div>
        <Popconfirm
          title="Are you sure? You will be redirected to the clone of the Offer."
          placement="topRight"
          okText="Clone"
          okButtonProps={{ onClick: handleClone }}
        >
          <Tooltip title="Duplicate" color={theme.primaryColor}>
            <StyledButton type="ghost" disabled={!canClone}>
              <Icon icon={IconDuplicate} />
            </StyledButton>
          </Tooltip>
        </Popconfirm>
        <Popconfirm
          title="Are you sure?"
          okText="Delete"
          okType="danger"
          okButtonProps={{ onClick: handleDelete }}
          arrowPointAtCenter
        >
          <Tooltip title="Delete" color={theme.primaryColor}>
            <StyledButton type="ghost" disabled={!canDelete}>
              <Icon icon={IconDelete} />
            </StyledButton>
          </Tooltip>
        </Popconfirm>
        <Tooltip title="Refresh" color={theme.primaryColor}>
          <StyledButton type="ghost" onClick={handleRefresh}>
            <Icon icon={IconRefresh} />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Exit" color={theme.primaryColor}>
          <StyledButton type="ghost" onClick={history.goBack}>
            <Icon icon={IconExit} />
          </StyledButton>
        </Tooltip>
      </div>
    </S.Wrapper>
  );
};
