import { createApiAction } from "@utils/createApiAction";
import { dictApi, poiApi } from "@api/core";
import { fetchPoiCategoriesAction } from "@state/dics/actions";
import * as actions from "@state/poi/actions";

export const fetchPoiDicts = createApiAction(
  fetchPoiCategoriesAction,
  dictApi.getPoiCategoriesApiV1DictsPoiCategoriesGet.bind(dictApi)
);

export const putPoiName = createApiAction(
  actions.putPoiName,
  poiApi.putPoiNameApiV1PoisPoiIdNamePut.bind(poiApi)
);

export const putPoiAddress = createApiAction(
  actions.putPoiAddress,
  poiApi.putPoiAddressApiV1PoisPoiIdAddressPut.bind(poiApi)
);

export const putPoiCoordinates = createApiAction(
  actions.putPoiCoordinates,
  poiApi.putPoiCoordinatesApiV1PoisPoiIdCoordinatesPut.bind(poiApi)
);

export const putPoiGeographyId = createApiAction(
  actions.putPoiGeographyId,
  poiApi.putPoiGeographyApiV1PoisPoiIdGeographyPut.bind(poiApi)
);

export const putPoiCategoryId = createApiAction(
  actions.putPoiCategoryId,
  poiApi.putPoiCategoryApiV1PoisPoiIdCategoryPut.bind(poiApi)
);
