/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Comment,
    CommentFromJSON,
    CommentToJSON,
    CommentOut,
    CommentOutFromJSON,
    CommentOutToJSON,
    CommentOutWithUser,
    CommentOutWithUserFromJSON,
    CommentOutWithUserToJSON,
    CommentWithUser,
    CommentWithUserFromJSON,
    CommentWithUserToJSON,
    ContactEmail,
    ContactEmailFromJSON,
    ContactEmailToJSON,
    ContactEmailResponse,
    ContactEmailResponseFromJSON,
    ContactEmailResponseToJSON,
    ContactIn,
    ContactInFromJSON,
    ContactInToJSON,
    ContactOut,
    ContactOutFromJSON,
    ContactOutToJSON,
    ContactPhone,
    ContactPhoneFromJSON,
    ContactPhoneToJSON,
    ContactPhoneResponse,
    ContactPhoneResponseFromJSON,
    ContactPhoneResponseToJSON,
    ContactResponse,
    ContactResponseFromJSON,
    ContactResponseToJSON,
    ContactType,
    ContactTypeFromJSON,
    ContactTypeToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface DeleteCommentApiV1ContactsContactIdCommentsCommentIdDeleteRequest {
    contactId: number;
    commentId: number;
}

export interface DeleteContactApiV1ContactsPropertyPropertyIdContactIdDeleteRequest {
    propertyId: number;
    contactId: number;
}

export interface GetCommentsApiV1ContactsContactIdCommentsGetRequest {
    contactId: number;
}

export interface GetContactsApiV1ContactsPropertyPropertyIdGetRequest {
    propertyId: number;
}

export interface PostCommentApiV1ContactsContactIdCommentsPostRequest {
    contactId: number;
    comment: Comment;
}

export interface PostContactApiV1ContactsPropertyPropertyIdPostRequest {
    propertyId: number;
    contactIn: ContactIn;
}

export interface PutCommentApiV1ContactsContactIdCommentsCommentIdPutRequest {
    contactId: number;
    commentId: number;
    comment: Comment;
}

export interface PutContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPutRequest {
    propertyId: number;
    contactId: number;
    contactEmail: ContactEmail;
}

export interface PutContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePutRequest {
    propertyId: number;
    contactId: number;
    contactPhone: ContactPhone;
}

/**
 * 
 */
export class ContactsApi extends runtime.BaseAPI {

    /**
     * Remove comment from given contact
     */
    async deleteCommentApiV1ContactsContactIdCommentsCommentIdDeleteRaw(requestParameters: DeleteCommentApiV1ContactsContactIdCommentsCommentIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteCommentApiV1ContactsContactIdCommentsCommentIdDelete.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling deleteCommentApiV1ContactsContactIdCommentsCommentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/{contactId}/comments/{commentId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove comment from given contact
     */
    async deleteCommentApiV1ContactsContactIdCommentsCommentIdDelete(requestParameters: DeleteCommentApiV1ContactsContactIdCommentsCommentIdDeleteRequest): Promise<void> {
        await this.deleteCommentApiV1ContactsContactIdCommentsCommentIdDeleteRaw(requestParameters);
    }

    /**
     * Remove contact from given property
     */
    async deleteContactApiV1ContactsPropertyPropertyIdContactIdDeleteRaw(requestParameters: DeleteContactApiV1ContactsPropertyPropertyIdContactIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling deleteContactApiV1ContactsPropertyPropertyIdContactIdDelete.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteContactApiV1ContactsPropertyPropertyIdContactIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/property/{propertyId}/{contactId}/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove contact from given property
     */
    async deleteContactApiV1ContactsPropertyPropertyIdContactIdDelete(requestParameters: DeleteContactApiV1ContactsPropertyPropertyIdContactIdDeleteRequest): Promise<void> {
        await this.deleteContactApiV1ContactsPropertyPropertyIdContactIdDeleteRaw(requestParameters);
    }

    /**
     * Get comments for a given contact
     */
    async getCommentsApiV1ContactsContactIdCommentsGetRaw(requestParameters: GetCommentsApiV1ContactsContactIdCommentsGetRequest): Promise<runtime.ApiResponse<Array<CommentWithUser>>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getCommentsApiV1ContactsContactIdCommentsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/{contactId}/comments`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommentWithUserFromJSON));
    }

    /**
     * Get comments for a given contact
     */
    async getCommentsApiV1ContactsContactIdCommentsGet(requestParameters: GetCommentsApiV1ContactsContactIdCommentsGetRequest): Promise<Array<CommentWithUser>> {
        const response = await this.getCommentsApiV1ContactsContactIdCommentsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all contact types
     */
    async getContactTypesApiV1ContactsTypesGetRaw(): Promise<runtime.ApiResponse<Array<ContactType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactTypeFromJSON));
    }

    /**
     * Get all contact types
     */
    async getContactTypesApiV1ContactsTypesGet(): Promise<Array<ContactType>> {
        const response = await this.getContactTypesApiV1ContactsTypesGetRaw();
        return await response.value();
    }

    /**
     * Get contacts for given property
     */
    async getContactsApiV1ContactsPropertyPropertyIdGetRaw(requestParameters: GetContactsApiV1ContactsPropertyPropertyIdGetRequest): Promise<runtime.ApiResponse<Array<ContactOut>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getContactsApiV1ContactsPropertyPropertyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/property/{propertyId}/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactOutFromJSON));
    }

    /**
     * Get contacts for given property
     */
    async getContactsApiV1ContactsPropertyPropertyIdGet(requestParameters: GetContactsApiV1ContactsPropertyPropertyIdGetRequest): Promise<Array<ContactOut>> {
        const response = await this.getContactsApiV1ContactsPropertyPropertyIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post a new comment
     */
    async postCommentApiV1ContactsContactIdCommentsPostRaw(requestParameters: PostCommentApiV1ContactsContactIdCommentsPostRequest): Promise<runtime.ApiResponse<CommentOutWithUser>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling postCommentApiV1ContactsContactIdCommentsPost.');
        }

        if (requestParameters.comment === null || requestParameters.comment === undefined) {
            throw new runtime.RequiredError('comment','Required parameter requestParameters.comment was null or undefined when calling postCommentApiV1ContactsContactIdCommentsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/{contactId}/comments`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentToJSON(requestParameters.comment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentOutWithUserFromJSON(jsonValue));
    }

    /**
     * Post a new comment
     */
    async postCommentApiV1ContactsContactIdCommentsPost(requestParameters: PostCommentApiV1ContactsContactIdCommentsPostRequest): Promise<CommentOutWithUser> {
        const response = await this.postCommentApiV1ContactsContactIdCommentsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post contact for given property
     */
    async postContactApiV1ContactsPropertyPropertyIdPostRaw(requestParameters: PostContactApiV1ContactsPropertyPropertyIdPostRequest): Promise<runtime.ApiResponse<ContactResponse>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling postContactApiV1ContactsPropertyPropertyIdPost.');
        }

        if (requestParameters.contactIn === null || requestParameters.contactIn === undefined) {
            throw new runtime.RequiredError('contactIn','Required parameter requestParameters.contactIn was null or undefined when calling postContactApiV1ContactsPropertyPropertyIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/property/{propertyId}/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactInToJSON(requestParameters.contactIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactResponseFromJSON(jsonValue));
    }

    /**
     * Post contact for given property
     */
    async postContactApiV1ContactsPropertyPropertyIdPost(requestParameters: PostContactApiV1ContactsPropertyPropertyIdPostRequest): Promise<ContactResponse> {
        const response = await this.postContactApiV1ContactsPropertyPropertyIdPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Edit comment
     */
    async putCommentApiV1ContactsContactIdCommentsCommentIdPutRaw(requestParameters: PutCommentApiV1ContactsContactIdCommentsCommentIdPutRequest): Promise<runtime.ApiResponse<CommentOut>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling putCommentApiV1ContactsContactIdCommentsCommentIdPut.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling putCommentApiV1ContactsContactIdCommentsCommentIdPut.');
        }

        if (requestParameters.comment === null || requestParameters.comment === undefined) {
            throw new runtime.RequiredError('comment','Required parameter requestParameters.comment was null or undefined when calling putCommentApiV1ContactsContactIdCommentsCommentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/{contactId}/comments/{commentId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CommentToJSON(requestParameters.comment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentOutFromJSON(jsonValue));
    }

    /**
     * Edit comment
     */
    async putCommentApiV1ContactsContactIdCommentsCommentIdPut(requestParameters: PutCommentApiV1ContactsContactIdCommentsCommentIdPutRequest): Promise<CommentOut> {
        const response = await this.putCommentApiV1ContactsContactIdCommentsCommentIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Edit email for given contact
     */
    async putContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPutRaw(requestParameters: PutContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPutRequest): Promise<runtime.ApiResponse<ContactEmailResponse>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPut.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling putContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPut.');
        }

        if (requestParameters.contactEmail === null || requestParameters.contactEmail === undefined) {
            throw new runtime.RequiredError('contactEmail','Required parameter requestParameters.contactEmail was null or undefined when calling putContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/property/{propertyId}/{contactId}/email`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactEmailToJSON(requestParameters.contactEmail),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactEmailResponseFromJSON(jsonValue));
    }

    /**
     * Edit email for given contact
     */
    async putContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPut(requestParameters: PutContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPutRequest): Promise<ContactEmailResponse> {
        const response = await this.putContactEmailApiV1ContactsPropertyPropertyIdContactIdEmailPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Edit phone number for given contact
     */
    async putContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePutRaw(requestParameters: PutContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePutRequest): Promise<runtime.ApiResponse<ContactPhoneResponse>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling putContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePut.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling putContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePut.');
        }

        if (requestParameters.contactPhone === null || requestParameters.contactPhone === undefined) {
            throw new runtime.RequiredError('contactPhone','Required parameter requestParameters.contactPhone was null or undefined when calling putContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/contacts/property/{propertyId}/{contactId}/phone`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactPhoneToJSON(requestParameters.contactPhone),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactPhoneResponseFromJSON(jsonValue));
    }

    /**
     * Edit phone number for given contact
     */
    async putContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePut(requestParameters: PutContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePutRequest): Promise<ContactPhoneResponse> {
        const response = await this.putContactPhoneApiV1ContactsPropertyPropertyIdContactIdPhonePutRaw(requestParameters);
        return await response.value();
    }

}
