import { AppState } from "@state/store";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "@state/attribures/actions";
import { BaseAttribute, CustomAttributeFloorsDistributionNumeric } from "@api/client";
import { mapApiResponseToFloorOrModulesArray } from "@state/attribures/utils/map-distribution-response-to-custom-filter";

export const useDistribution = (currentAttribute: BaseAttribute) => {
  const distribution = useSelector((state: AppState) => state.attributes.distribution);
  const distributionForAttribute = distribution?.[currentAttribute.code];
  return {
    floors: mapApiResponseToFloorOrModulesArray(distributionForAttribute?.floors),
    modules: mapApiResponseToFloorOrModulesArray(distributionForAttribute?.modules),
  };
};

export const isMinAndMaxInside = (
  prop: CustomAttributeFloorsDistributionNumeric | undefined
): prop is Required<CustomAttributeFloorsDistributionNumeric> => {
  if (prop === undefined) {
    return false;
  }
  if (prop.valueMax === undefined || prop.valueMin === undefined) {
    return false;
  }
  return true;
};

export const useUpdateFloorAndModules = (currentAttribute: BaseAttribute) => {
  const dispatch = useDispatch();
  const { floors, modules, modalActive } = useSelector((state) => state.attributes.customFilter);
  const { floors: floorsFromDistribution, modules: modulesFromDistribution } =
    useDistribution(currentAttribute);

  if (!floors && modalActive) {
    if (floorsFromDistribution) {
      dispatch(actions.setCustomFilterFloors(floorsFromDistribution));
    }
  }

  if (!modules && modalActive) {
    if (modulesFromDistribution) {
      dispatch(actions.setCustomFilterModules(modulesFromDistribution));
    }
  }
};
