import React from "react";
import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";

import { Heading } from "@components/heading";
import { NotesModalProps } from "./types";
import { ModalContent } from "./modal-content";

export const NotesModal: React.FC<NotesModalProps & ModalProps> = ({ contactId, name, ...modalProps }) => (
  <Modal {...modalProps} footer={null} width={1600}>
    <Heading level="3">Notes about {name}</Heading>
    <ModalContent contactId={contactId} />
  </Modal>
);
