/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferZoomOut
 */
export interface OfferZoomOut {
    /**
     * 
     * @type {number}
     * @memberof OfferZoomOut
     */
    zoom?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferZoomOut
     */
    offerId: number;
}

export function OfferZoomOutFromJSON(json: any): OfferZoomOut {
    return OfferZoomOutFromJSONTyped(json, false);
}

export function OfferZoomOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferZoomOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zoom': !exists(json, 'zoom') ? undefined : json['zoom'],
        'offerId': json['offerId'],
    };
}

export function OfferZoomOutToJSON(value?: OfferZoomOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zoom': value.zoom,
        'offerId': value.offerId,
    };
}


