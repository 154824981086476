import React from "react";
import styled from "styled-components";
import AntButton, { ButtonProps } from "antd/lib/button";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import "./button.less";

const AlignContentCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftPadding = styled.div`
  padding-left: 10px;
`;

export const Button: React.FC<ButtonProps> = (props) => <AntButton {...props} />;
export const ButtonGroup = AntButton.Group;
export const AddButton: React.FC<ButtonProps> = ({ children, ...buttonProps }) => (
  <Button {...buttonProps}>
    <AlignContentCenter>
      <PlusOutlined />
      <LeftPadding>{children}</LeftPadding>
    </AlignContentCenter>
  </Button>
);
