/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewsletterSchemaOfferName,
    NewsletterSchemaOfferNameFromJSON,
    NewsletterSchemaOfferNameFromJSONTyped,
    NewsletterSchemaOfferNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewsletterLocationName
 */
export interface NewsletterLocationName {
    /**
     * 
     * @type {string}
     * @memberof NewsletterLocationName
     */
    name: string;
    /**
     * 
     * @type {Array<NewsletterSchemaOfferName>}
     * @memberof NewsletterLocationName
     */
    offers: Array<NewsletterSchemaOfferName>;
}

export function NewsletterLocationNameFromJSON(json: any): NewsletterLocationName {
    return NewsletterLocationNameFromJSONTyped(json, false);
}

export function NewsletterLocationNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterLocationName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'offers': ((json['offers'] as Array<any>).map(NewsletterSchemaOfferNameFromJSON)),
    };
}

export function NewsletterLocationNameToJSON(value?: NewsletterLocationName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'offers': ((value.offers as Array<any>).map(NewsletterSchemaOfferNameToJSON)),
    };
}


