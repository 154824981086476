/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OfferFileTag,
    OfferFileTagFromJSON,
    OfferFileTagFromJSONTyped,
    OfferFileTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface OfferDetailsAttachments
 */
export interface OfferDetailsAttachments {
    /**
     * 
     * @type {boolean}
     * @memberof OfferDetailsAttachments
     */
    isMainFile: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDetailsAttachments
     */
    isPublic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDetailsAttachments
     */
    isVisibleInCp: boolean;
    /**
     * 
     * @type {number}
     * @memberof OfferDetailsAttachments
     */
    attachmentId: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsAttachments
     */
    urlPath: string;
    /**
     * 
     * @type {Array<OfferFileTag>}
     * @memberof OfferDetailsAttachments
     */
    fileTags?: Array<OfferFileTag>;
    /**
     * 
     * @type {Date}
     * @memberof OfferDetailsAttachments
     */
    uploadDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsAttachments
     */
    uploadBy?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsAttachments
     */
    fileSize: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsAttachments
     */
    fileDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsAttachments
     */
    fileType?: string;
}

export function OfferDetailsAttachmentsFromJSON(json: any): OfferDetailsAttachments {
    return OfferDetailsAttachmentsFromJSONTyped(json, false);
}

export function OfferDetailsAttachmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferDetailsAttachments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isMainFile': json['isMainFile'],
        'isPublic': json['isPublic'],
        'isVisibleInCp': json['isVisibleInCp'],
        'attachmentId': json['attachmentId'],
        'urlPath': json['urlPath'],
        'fileTags': !exists(json, 'fileTags') ? undefined : ((json['fileTags'] as Array<any>).map(OfferFileTagFromJSON)),
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (new Date(json['uploadDate'])),
        'uploadBy': !exists(json, 'uploadBy') ? undefined : json['uploadBy'],
        'fileSize': json['fileSize'],
        'fileDescription': !exists(json, 'fileDescription') ? undefined : json['fileDescription'],
        'fileType': !exists(json, 'fileType') ? undefined : json['fileType'],
    };
}

export function OfferDetailsAttachmentsToJSON(value?: OfferDetailsAttachments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isMainFile': value.isMainFile,
        'isPublic': value.isPublic,
        'isVisibleInCp': value.isVisibleInCp,
        'attachmentId': value.attachmentId,
        'urlPath': value.urlPath,
        'fileTags': value.fileTags === undefined ? undefined : ((value.fileTags as Array<any>).map(OfferFileTagToJSON)),
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate.toISOString()),
        'uploadBy': value.uploadBy,
        'fileSize': value.fileSize,
        'fileDescription': value.fileDescription,
        'fileType': value.fileType,
    };
}


