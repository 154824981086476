import { all, AllEffect } from "redux-saga/effects";
import offerSaga from "./offers/offers-saga";
import propertiesSaga from "./properties/properties-saga";
import poiSaga from "./poi/poi-saga";
import filtersSaga from "./filters/filters-saga";

export type RootSaga = ReturnType<typeof offerSaga>;

export function* rootSaga(): Iterator<AllEffect<RootSaga>> {
  yield all([offerSaga(), propertiesSaga(), poiSaga(), filtersSaga()]);
}
