export const en = {
  available_space: "Available space",
  park_details: "Park details",
  park_desc: "Park description",
  park_location_desc: "Park location description",
  other_attributes: "Description",
  map: "Map",
  gallery: "Gallery",
  contact: "Contact",
  /* aut - Available units table */
  available_units: "Available units",
  aut: {
    building: "Building",
    total_size: "Total size",
    available_size: "Available size",
    available_from: "Available from",
    clear_height: "Clear height",
    construction_status: "Construction status",
  },
  your_building_list: "Your building list",
  more_info: "More info",
  attachments: "Attachments",
  floors: {
    available_areas: "Available areas",
    floor: "Floor",
    net_area: "Net area",
    availability: "Availability",
  },
};
