import React from "react";
import { Tag as TagCore, TagProps } from "antd";

import { ReactComponent as IconDeleteCircle } from "@svg/icon-delete-circle.svg";

import * as S from "./tag.styled";

interface CustomTagProps {
  closeIconSize?: number;
}

const size = 15;

export const Tag = ({
  closeIcon = IconDeleteCircle,
  closeIconSize = size,
  ...restProps
}: TagProps & CustomTagProps) => (
  <TagCore
    {...restProps}
    closeIcon={<S.Icon icon={closeIcon as React.ComponentType} size={closeIconSize} />}
  />
);

Tag.defaultProps = {
  closeIconSize: size,
};
