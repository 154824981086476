import { useContext } from "react";
import { UserDataContext } from "@components/user-data-provider";
import { permissions } from "../config/permissions";

export const usePermissions = () => {
  const values = useContext(UserDataContext);
  const currentUserRole = values.userRole;
  const hasPermission = (permission: keyof typeof permissions) => {
    if (currentUserRole === null) {
      return false;
    }

    return permissions[permission].includes(currentUserRole);
  };

  return {
    hasPermission,
  };
};
