import { createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { DicsState } from "./types";

const initialState: DicsState = {
  categories: {
    data: [],
    loading: false,
  },
  poi: {
    data: [],
    loading: false,
  },
  offer: {
    data: [],
    loading: false,
  },
  property: {
    data: [],
    loading: false,
  },
};

type DictsAction = {
  type: string;
};

export const dictsReducer = createReducer<DicsState, DictsAction>(initialState)
  .handleAction(actions.fetchPropertyCategoriesAction.request, (state) => ({
    ...state,
    categories: {
      data: state.categories.data,
      loading: true,
    },
  }))
  .handleAction(actions.fetchPropertyCategoriesAction.success, (state, action) => ({
    ...state,
    categories: {
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(actions.fetchPropertyCategoriesAction.failure, (state) => ({
    ...state,
    categories: {
      data: state.categories.data,
      loading: false,
    },
  }))
  .handleAction(actions.fetchPoiCategoriesAction.request, (state) => ({
    ...state,
    poi: {
      data: state.poi.data,
      loading: true,
    },
  }))
  .handleAction(actions.fetchPoiCategoriesAction.success, (state, action) => ({
    ...state,
    poi: {
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(actions.fetchPoiCategoriesAction.failure, (state) => ({
    ...state,
    poi: {
      data: state.poi.data,
      loading: false,
    },
  }))
  .handleAction(actions.fetchOfferStatuesAction.request, (state) => ({
    ...state,
    offer: {
      data: state.offer.data,
      loading: true,
    },
  }))
  .handleAction(actions.fetchOfferStatuesAction.success, (state, action) => ({
    ...state,
    offer: {
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(actions.fetchOfferStatuesAction.failure, (state) => ({
    ...state,
    offer: {
      data: state.offer.data,
      loading: false,
    },
  }))
  .handleAction(actions.fetchPropertyStatuesAction.request, (state) => ({
    ...state,
    property: {
      data: state.property.data,
      loading: true,
    },
  }))
  .handleAction(actions.fetchPropertyStatuesAction.success, (state, action) => ({
    ...state,
    property: {
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(actions.fetchPropertyStatuesAction.failure, (state) => ({
    ...state,
    property: {
      data: state.property.data,
      loading: false,
    },
  }));
