import React, { useState, SyntheticEvent } from "react";
import { Select, Tooltip, AutoComplete } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { offerApi } from "@api/core";
import { OfferTags } from "@api/client/models/OfferTags";
import { OfferDetailsAttachments } from "@api/client/models/OfferDetailsAttachments";

import { Tag } from "@components/tag";
import { ExternalLink, DisabledLink } from "@components/external-link";
import { DeleteButton } from "@components/delete-button";
import { Button } from "@components/button";
import { Checkbox } from "@components/checkbox";

import * as S from "./attachements.styled";

export interface File extends OfferDetailsAttachments {
  name: string;
  uid: number;
}

export type FileType = {
  label: string;
  key: number;
  value: number;
};

interface UploadItemProps {
  file: File;
  // eslint-disable-next-line react/require-default-props
  fileTypes?: FileType[];
  onDelete: React.MouseEventHandler<HTMLElement>;
  onMainChange: (e: CheckboxChangeEvent) => void;
  onFileTypeSelect: (attachmentId: number) => (fileTypeId: number) => void;
  onTagMutate: () => void;
  onUpdateVisibilty: (e: CheckboxChangeEvent, publicValue: boolean, cpValue: boolean) => void;
  // eslint-disable-next-line react/require-default-props
  fileTags?: OfferTags[];
  onDeleteTag: (props: { attachmentId: number; fileTagId: number }) => void;
  isEditable: boolean;
  isDownloadable: boolean;
  isVisibilityChangeable: boolean;
  isMainEditable: boolean;
  isFileTypeEditable: boolean;
  isDeleteAllowed: boolean;
  isDeleteTagAllowed: boolean;
}

export const UploadItem = ({
  file,
  fileTypes = [],
  onDelete,
  onMainChange,
  onFileTypeSelect,
  onTagMutate,
  onUpdateVisibilty,
  fileTags = [],
  onDeleteTag,
  isEditable,
  isDownloadable,
  isVisibilityChangeable,
  isMainEditable,
  isFileTypeEditable,
  isDeleteAllowed,
  isDeleteTagAllowed,
}: UploadItemProps) => {
  const [newTag, setNewTag] = useState("");
  const currentModule = useSelector((state) => state.offers.currentModule);
  const addNewTag = useMutation(
    () =>
      offerApi.postFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPost({
        attachmentId: file.attachmentId,
        fileTagsIn: { fileTags: [newTag] },
      }),
    {
      onSuccess: () => {
        onTagMutate();
      },
    }
  );
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (newTag.length < 3) return;
    setNewTag("");
    addNewTag.mutate();
  };

  const options = !newTag
    ? fileTags.map(({ offerTag, tagId }) => ({ label: offerTag, value: tagId }))
    : fileTags
        .filter(({ offerTag }) => offerTag.toLowerCase().includes(newTag.toLowerCase()))
        .map(({ offerTag, tagId }) => ({ label: offerTag, value: tagId }));

  const defaultSelectValue = fileTypes.find(({ label }) => label === file.fileType);
  const handleTagDelete = (fileTagId: number) => () =>
    onDeleteTag({ attachmentId: file.attachmentId, fileTagId });
  return (
    <S.UploadItem>
      <S.ActionsWrapper>
        <S.LinkWrapper>
          {isDownloadable ? (
            <ExternalLink href={file.urlPath} target="_blank">
              {file.name}
            </ExternalLink>
          ) : (
            <DisabledLink>{file.name}</DisabledLink>
          )}
        </S.LinkWrapper>
        <div>
          <Select
            placeholder="Select file type"
            options={fileTypes}
            defaultValue={defaultSelectValue?.value}
            style={{ width: "100%" }}
            onChange={onFileTypeSelect(file.uid)}
            disabled={isFileTypeEditable}
          />
        </div>
        <div>
          <Tooltip title={`Delete ${file.name}`}>
            <DeleteButton onClick={onDelete} value={file.attachmentId} disabled={isDeleteAllowed} />
          </Tooltip>
        </div>
        <div>
          <S.CheckboxLabel>Main</S.CheckboxLabel>
          <Tooltip title="To check please select file type" visible={file.fileType ? false : undefined}>
            <Checkbox
              checked={file.isMainFile}
              onChange={onMainChange}
              value={file.attachmentId}
              disabled={!file.fileType || isMainEditable}
            />
          </Tooltip>
        </div>
        <div>
          <S.CheckboxLabel>Public</S.CheckboxLabel>
          <Checkbox
            checked={file.isPublic}
            onChange={(e) => onUpdateVisibilty(e, file.isPublic, file.isVisibleInCp)}
            value={`public-${file.attachmentId}`}
            disabled={isVisibilityChangeable}
          />
        </div>
        {currentModule !== "retail" && (
          <div>
            <S.CheckboxLabel>Show in Client Portal</S.CheckboxLabel>
            <Tooltip
              title='To check please select "Public" checkbox'
              visible={file.isPublic ? false : undefined}
            >
              <Checkbox
                checked={file.isVisibleInCp}
                onChange={(e) => onUpdateVisibilty(e, file.isPublic, file.isVisibleInCp)}
                value={`cp-${file.attachmentId}`}
                disabled={!file.isPublic || isVisibilityChangeable}
              />
            </Tooltip>
          </div>
        )}
      </S.ActionsWrapper>
      <S.TagsWrapper>
        <S.NewTagForm onSubmit={handleSubmit}>
          <AutoComplete
            size="small"
            value={newTag}
            onChange={(value) => setNewTag(value)}
            placeholder="New tag"
            style={{ marginRight: 10 }}
            options={options}
            onSelect={(_, { label }) => setNewTag(label as string)}
          />
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            loading={addNewTag.isLoading}
            disabled={isEditable}
          >
            Add Tag
          </Button>
        </S.NewTagForm>
        {file.fileTags?.map(({ fileTag, fileTagId }) => (
          <Tag
            key={fileTagId}
            color="orange"
            closable={isDeleteTagAllowed}
            onClose={handleTagDelete(fileTagId as number)}
          >
            {fileTag}
          </Tag>
        ))}
      </S.TagsWrapper>
    </S.UploadItem>
  );
};
