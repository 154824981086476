import styled from "styled-components";

import { Heading as HeadingCore } from "@components/heading";

interface InputContainerProps {
  halfWidth?: boolean;
}

export const Heading = styled(HeadingCore)`
  color: ${({ theme }) => theme.primaryColor};
`;

export const PropertiesAndMapWrapper = styled.div`
  display: flex;
`;

export const PropertiesListWrapper = styled.div`
  flex: 1;
  height: 440px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const MapWrapper = styled.div`
  flex: 1;
`;

export const InputContainer = styled.div<InputContainerProps>`
  ${({ halfWidth }) => halfWidth && "width: 50%;"}

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;
