/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferOutDescription
 */
export interface OfferOutDescription {
    /**
     * 
     * @type {string}
     * @memberof OfferOutDescription
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof OfferOutDescription
     */
    offerId: number;
    /**
     * 
     * @type {Date}
     * @memberof OfferOutDescription
     */
    modificationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof OfferOutDescription
     */
    type?: string;
}

export function OfferOutDescriptionFromJSON(json: any): OfferOutDescription {
    return OfferOutDescriptionFromJSONTyped(json, false);
}

export function OfferOutDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferOutDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'offerId': json['offerId'],
        'modificationDate': (new Date(json['modificationDate'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function OfferOutDescriptionToJSON(value?: OfferOutDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'offerId': value.offerId,
        'modificationDate': (value.modificationDate.toISOString()),
        'type': value.type,
    };
}


