import React, { useState, SyntheticEvent } from "react";
import { useMutation, useQuery } from "react-query";

import { offerApi, dictApi } from "@api/core";

import { Button } from "@components/button";

import * as S from "./offer-tags.styled";

interface NewTagProps {
  offerId: number;
  onSuccess: () => void;
  hasPermission: boolean;
  canLoadTags: boolean;
}

export const NewTag = ({ offerId, onSuccess, hasPermission, canLoadTags }: NewTagProps) => {
  const [newOfferTag, setNewOfferTag] = useState("");
  const allTags = useQuery("all-file-tags", () => {
    if (canLoadTags) return dictApi.getAllOfferTagsApiV1DictsOfferTagsGet();
  });
  const addNewTag = useMutation(
    () =>
      offerApi.postOfferTagsApiV1OffersOfferIdTagsPost({
        offerId,
        offerInTag: { offerTag: newOfferTag },
      }),
    {
      onSuccess: () => {
        setNewOfferTag("");
        onSuccess();
      },
    }
  );
  const options = !newOfferTag
    ? allTags.data?.map(({ tagId, offerTag }) => ({ label: offerTag, value: tagId }))
    : allTags.data
        ?.filter(({ offerTag }) => offerTag.toLowerCase().includes(newOfferTag.toLowerCase()))
        .map(({ tagId, offerTag }) => ({ label: offerTag, value: tagId }));

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (newOfferTag.length < 3) return;
    addNewTag.mutate();
  };
  return (
    <S.Form onSubmit={handleSubmit}>
      <S.Input
        value={newOfferTag}
        onChange={(value) => setNewOfferTag(value)}
        placeholder="Add new tag"
        style={{ width: 200, display: "block", height: 24, fontSize: 14, marginBottom: 10 }}
        options={options}
        onSelect={(_, { label }) => setNewOfferTag(label as string)}
        disabled={allTags.isLoading || !hasPermission}
        size="small"
      />
      <Button
        type="primary"
        htmlType="submit"
        size="small"
        loading={addNewTag.isLoading}
        disabled={!hasPermission}
      >
        Add new tag
      </Button>
    </S.Form>
  );
};
