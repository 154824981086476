import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import xss from "xss";
import { Input } from "@components/input";
import { setCustomFilter } from "@state/attribures/actions";
import { Heading } from "@components/heading";
import { Radio, RadioChangeEvent } from "antd";
import * as S from "./styled";

export const FirstStepCustomFilterModal: React.FC = () => {
  const dispatch = useDispatch();
  const [phrase, setPhrase] = useState("");
  const { key: currentGroup } = useSelector((state) => state.attributes.customFilter);
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhrase(event.target.value);
  };
  const attributes = useSelector((state) => state.attributes.data);
  const values = attributes.filter(
    (item) => item.name.toLocaleLowerCase().indexOf(phrase.toLocaleLowerCase()) !== -1
  );
  const onGroupChange = (e: RadioChangeEvent) => {
    const attributeCode = e.target.value;
    dispatch(
      setCustomFilter({
        key: attributeCode,
        value: "",
        floors: undefined,
        modules: undefined,
      })
    );
  };
  const showValue = (value: string) => {
    try {
      return xss(value.replace(new RegExp(phrase, "i"), (v) => `<b>${v}</b>`));
    } catch (e) {
      return value;
    }
  };
  return (
    <>
      <S.SearchContainer>
        <Input value={phrase} onChange={onSearchChange} />
      </S.SearchContainer>
      <Heading level="6">Select a filter you would like to apply</Heading>
      <S.ContainerChild>
        <Heading level="5">Attributes:</Heading>
        <Radio.Group
          key={phrase}
          name="attributeName"
          onChange={onGroupChange}
          value={currentGroup}
          style={{
            overflowX: "hidden",
            overflowY: "scroll",
            height: "350px",
            width: "100%",
          }}
        >
          {values.map((item) => (
            <div key={`radio_buttons_${item.code}`}>
              <Radio value={item.code}>
                <S.Value
                  dangerouslySetInnerHTML={{
                    __html: showValue(item.name),
                  }}
                />
              </Radio>
            </div>
          ))}
        </Radio.Group>
      </S.ContainerChild>
    </>
  );
};
