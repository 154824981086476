import React, { useCallback } from "react";
import { useMutation } from "react-query";
import { offerApi } from "@api/core";
import { MapLazy as Map } from "@components/map";
import { HeadingSmall } from "@components/heading";
import { centerOfPoland, zoomForPoland } from "@components/variables";
import AutoSizer from "react-virtualized-auto-sizer";

import { notAuthorized } from "@utils/not-authorised-info";
import { updateCoordinates } from "@utils/property";
import { usePermissions } from "@hooks/use-permission";
import { useDispatch, useSelector } from "react-redux";
import type { DragEvent } from "react-map-gl/index";

import * as S from "../offer-detail.styled";
import { OfferNote } from "../offer-note";
import { OfferStatus } from "../offer-status";
import { Photo } from "../photo";

export const RightSideBar = () => {
  const { hasPermission } = usePermissions();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.offers.offerDetails);
  const handleMapClick = useCallback(
    (event: DragEvent) => {
      if (!hasPermission("PUT_PROPERTY_COORDINATES")) {
        notAuthorized();
      }
      updateCoordinates(dispatch)({
        propertyId: data?.propertyId as number,
        propertyCoordinates: { geoLongitude: event.lngLat[0], geoLatitude: event.lngLat[1] },
      });
    },
    [dispatch, data, hasPermission]
  );

  const zoom = useMutation(({ offerId, value }: { offerId: number; value: number }) =>
    offerApi.putOfferZoomApiV1OffersOfferIdZoomPut({ offerId, offerZoomIn: { zoom: value } })
  );

  const onZoom = useCallback(
    (value: number) => {
      if (data) {
        zoom.mutate({ offerId: data.offerId, value });
      }
    },
    [zoom, data]
  );

  if (!data) {
    return null;
  }

  const getZoom = (): number => {
    if (typeof data.zoom === "number" && data.propertyGeoLatitude) {
      return data.zoom;
    }

    return data.propertyGeoLatitude ? 14 : zoomForPoland;
  };

  return (
    <>
      <S.MapPictureWrapper>
        <OfferStatus />
        <S.MapWrapper>
          <AutoSizer>
            {({ height, width }) => (
              <Map
                latitude={data.propertyGeoLatitude || centerOfPoland.latitude}
                longitude={data.propertyGeoLongitude || centerOfPoland.longitude}
                zoom={getZoom()}
                height={height}
                width={width}
                hideMain={!data.propertyGeoLatitude}
                onDragEnd={handleMapClick}
                draggable={hasPermission("PUT_PROPERTY_COORDINATES")}
                onZoom={onZoom}
              />
            )}
          </AutoSizer>
        </S.MapWrapper>
        <Photo />
      </S.MapPictureWrapper>
      <OfferNote />
      <S.StickyPoiMapContainer>
        <HeadingSmall level="3">Points of Interests</HeadingSmall>
        {data.propertyGeoLongitude && data.propertyGeoLatitude && (
          <AutoSizer>
            {({ height, width }) => (
              <Map
                pois={data.pois.map((poi) => ({
                  type: poi.categoryName as any,
                  latitude: poi.geoLatitude as number,
                  longitude: poi.geoLongitude as number,
                  id: poi.id,
                }))}
                latitude={data.propertyGeoLatitude as number}
                longitude={data.propertyGeoLongitude as number}
                zoom={10}
                height={height - 40}
                width={width}
                calculateViewport
                maxZoom={15}
              />
            )}
          </AutoSizer>
        )}
      </S.StickyPoiMapContainer>
    </>
  );
};
