import React from "react";
import { useSelector } from "react-redux";
import { ModalProps } from "antd";

import { NewsletterPropertyBreakdownReportShared } from "../newsletter-property-breakdown-report-shared";
import { Property } from "../generate-newsletter/types";

type Props = ModalProps & { properties: Property[] };

export type Module = "office";

const options: Record<
  Module,
  {
    heading: string;
    method: "getSummaryApiV1OffersSummaryLNGPut";
  }
> = {
  office: { heading: "Generate breakdown report LNG", method: "getSummaryApiV1OffersSummaryLNGPut" },
};

export const GenerateBreakdownReportLng = (props: Props) => {
  const moduleOptions = options.office;

  return (
    <NewsletterPropertyBreakdownReportShared
      {...props}
      method={moduleOptions.method}
      heading={moduleOptions.heading}
    />
  );
};

