/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyStatus
 */
export interface PropertyStatus {
    /**
     * 
     * @type {string}
     * @memberof PropertyStatus
     */
    statusCode: string;
}

export function PropertyStatusFromJSON(json: any): PropertyStatus {
    return PropertyStatusFromJSONTyped(json, false);
}

export function PropertyStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
    };
}

export function PropertyStatusToJSON(value?: PropertyStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
    };
}


