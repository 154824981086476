import { createAsyncAction, createAction } from "typesafe-actions";

import {
  PoiBase,
  PutPoiNameApiV1PoisPoiIdNamePutRequest,
  PoiOutName,
  PutPoiAddressApiV1PoisPoiIdAddressPutRequest,
  PoiOutAddressAndCoordinates,
  PutPoiCoordinatesApiV1PoisPoiIdCoordinatesPutRequest,
  PutPoiGeographyApiV1PoisPoiIdGeographyPutRequest,
  PoiOutGeo,
  PutPoiCategoryApiV1PoisPoiIdCategoryPutRequest,
  PoiOutCategory,
} from "@api/client";

const FETCH_POIS_REQUEST = "FETCH_POIS_REQUEST";
const FETCH_POIS_SUCCESS = "FETCH_POIS_SUCCESS";
const FETCH_POIS_FAILURE = "FETCH_POIS_FAILURE";

const FETCH_MORE_POIS_REQUEST = "FETCH_MORE_POIS_REQUEST";
const FETCH_MORE_POIS_SUCCESS = "FETCH_MORE_POIS_SUCCESS";
const FETCH_MORE_POIS_FAILURE = "FETCH_MORE_POIS_FAILURE";

const THERE_ARE_MORE_PAGES = "THERE_ARE_MORE_PAGES";
const NO_MORE_PAGES = "NO_MORE_PAGES";
const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

const PUT_POI_NAME_REQUEST = "PUT_POI_NAME_REQUEST";
const PUT_POI_NAME_SUCCESS = "PUT_POI_NAME_SUCCESS";
const PUT_POI_NAME_FAILURE = "PUT_POI_NAME_FAILURE";

const PUT_POI_ADDRESS_REQUEST = "PUT_POI_ADDRESS_REQUEST";
const PUT_POI_ADDRESS_SUCCESS = "PUT_POI_ADDRESS_SUCCESS";
const PUT_POI_ADDRESS_FAILURE = "PUT_POI_ADDRESS_FAILURE";

const PUT_POI_COORDINATES_REQUEST = "PUT_POI_COORDINATES_REQUEST";
const PUT_POI_COORDINATES_SUCCESS = "PUT_POI_COORDINATES_SUCCESS";
const PUT_POI_COORDINATES_FAILURE = "PUT_POI_COORDINATES_FAILURE";

const PUT_POI_GEOGRAPHY_ID_REQUEST = "PUT_POI_GEOGRAPHY_ID_REQUEST";
const PUT_POI_GEOGRAPHY_ID_SUCCESS = "PUT_POI_GEOGRAPHY_ID_SUCCESS";
const PUT_POI_GEOGRAPHY_ID_FAILURE = "PUT_POI_GEOGRAPHY_ID_FAILURE";

const PUT_POI_CATEGORY_ID_REQUEST = "PUT_POI_CATEGORY_ID_REQUEST";
const PUT_POI_CATEGORY_ID_SUCCESS = "PUT_POI_CATEGORY_ID_SUCCESS";
const PUT_POI_CATEGORY_ID_FAILURE = "PUT_POI_CATEGORY_ID_FAILURE";

export const setPois = createAsyncAction(FETCH_POIS_REQUEST, FETCH_POIS_SUCCESS, FETCH_POIS_FAILURE)<
  { nameOrAddress?: string },
  PoiBase[],
  Error
>();

export const setMorePois = createAsyncAction(
  FETCH_MORE_POIS_REQUEST,
  FETCH_MORE_POIS_SUCCESS,
  FETCH_MORE_POIS_FAILURE
)<void, PoiBase[], Error>();

export const noMorePages = createAction(NO_MORE_PAGES)();
export const thereAreMorePages = createAction(THERE_ARE_MORE_PAGES)();
export const setPageNumber = createAction(SET_PAGE_NUMBER)<number>();

export const putPoiName = createAsyncAction(PUT_POI_NAME_REQUEST, PUT_POI_NAME_SUCCESS, PUT_POI_NAME_FAILURE)<
  PutPoiNameApiV1PoisPoiIdNamePutRequest,
  PoiOutName,
  Error
>();

export const putPoiAddress = createAsyncAction(
  PUT_POI_ADDRESS_REQUEST,
  PUT_POI_ADDRESS_SUCCESS,
  PUT_POI_ADDRESS_FAILURE
)<PutPoiAddressApiV1PoisPoiIdAddressPutRequest, PoiOutAddressAndCoordinates, Error>();

export const putPoiCoordinates = createAsyncAction(
  PUT_POI_COORDINATES_REQUEST,
  PUT_POI_COORDINATES_SUCCESS,
  PUT_POI_COORDINATES_FAILURE
)<PutPoiCoordinatesApiV1PoisPoiIdCoordinatesPutRequest, PoiOutAddressAndCoordinates, Error>();

export const putPoiGeographyId = createAsyncAction(
  PUT_POI_GEOGRAPHY_ID_REQUEST,
  PUT_POI_GEOGRAPHY_ID_SUCCESS,
  PUT_POI_GEOGRAPHY_ID_FAILURE
)<PutPoiGeographyApiV1PoisPoiIdGeographyPutRequest, PoiOutGeo, Error>();

export const putPoiCategoryId = createAsyncAction(
  PUT_POI_CATEGORY_ID_REQUEST,
  PUT_POI_CATEGORY_ID_SUCCESS,
  PUT_POI_CATEGORY_ID_FAILURE
)<PutPoiCategoryApiV1PoisPoiIdCategoryPutRequest, PoiOutCategory, Error>();
