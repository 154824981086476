import styled from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LoginTopBarRightCorner = styled.div`
  display: flex;
  justify-content: flex-end;
`;
