import React, { ChangeEvent, SyntheticEvent } from "react";

import { Input } from "@components/input";
import { Button } from "@components/button";

interface AddNewClientProps {
  onSubmit: (e: SyntheticEvent) => void;
  newClientName: string;
  onNewClientNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}

const addNewClientLabelId = "Add new client";

export const AddNewClient = ({
  onSubmit,
  newClientName,
  onNewClientNameChange,
  isLoading,
}: AddNewClientProps) => (
  <form onSubmit={onSubmit} style={{ marginTop: 20 }}>
    <Input
      value={newClientName}
      onChange={onNewClientNameChange}
      placeholder={addNewClientLabelId}
      style={{ width: 400 }}
    />
    <div style={{ marginTop: 10 }}>
      <Button type="primary" size="small" icon="+" loading={isLoading} htmlType="submit">
        Add
      </Button>
    </div>
  </form>
);
