import styled, { css } from "styled-components";
import { StyledButton } from "@components/button/button.styled";
import { theme } from "@components/variables";

export const StyledFilterbox = styled.div`
  border: 1px solid #a8adb3;
  border-radius: 4px;
  font-weight: 300;
  font-size: 12px;
  margin: 6px 0;
  padding: 12px;
  width: 100%;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.primaryColor};
  font-weight: bold;
`;

export const StyledSingleCheckbox = styled.div`
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const StyledInput = styled.input`
  margin-right: 6px;
  &:checked + label {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const ItemValue = styled.div``;

const iconStyles = css`
  color: #83868b;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const Delete = styled.span`
  ${iconStyles}
`;

export const Edit = styled.span`
  ${iconStyles}
`;

export const FilterBoxButton = styled(StyledButton)`
  &.ant-btn-ghost {
    border: none;
    height: 20px;
    width: 20px;
    padding: 0;

    &:hover {
      svg path {
        fill: ${theme.primaryColor};
      }
    }

    &:active {
      background-color: #cecfd2;
    }
  }
`;

export const FloorsAndModulesWrapper = styled.div`
  display: flex;
  padding-top: 10px;
`;

export const Width50Percent = styled.div`
  width: 50%;
  span {
    margin-left: 10px;
  }
`;
