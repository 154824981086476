import React from "react";
import { QuickformAttributes } from "@api/client";
import * as S from "../quick-form.styled";
import { AttributeValue as AttributeValueInput } from "../attribute-value";

import { HandleAddAttributeValue, HandleAttributeValueUpdate } from "../types";

type AttributeDataTypes = {
  string: string;
  numeric: string;
};

type Props = {
  quickformAttributes: QuickformAttributes;
  handleUpdateAttribute: HandleAttributeValueUpdate;
  handleAddAttribute?: HandleAddAttributeValue;
  attributeNames: any;
  attributeDataTypes: AttributeDataTypes;
};

export const IndustrialInputs: React.FC<Props> = ({
  quickformAttributes,
  handleUpdateAttribute,
  handleAddAttribute,
  attributeNames,
  attributeDataTypes,
}) => {
  return (
    <>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.buildingName?.id}
          attributeName={attributeNames.buildingName}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.buildingName?.valueOriginal}
          parentAttributeId={quickformAttributes.buildingName?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.buildingName?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.availableSpace?.id}
          attributeName={attributeNames.availableSpace}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.availableSpace?.valueOriginal}
          parentAttributeId={quickformAttributes.availableSpace?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.availableSpace?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.availability?.id}
          attributeName={attributeNames.availability}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.availability?.valueOriginal}
          parentAttributeId={quickformAttributes.availability?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.availability?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.clearHeight?.id}
          attributeName={attributeNames.clearHeight}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.clearHeight?.valueOriginal}
          parentAttributeId={quickformAttributes.clearHeight?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.clearHeight?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.columnGrid?.id}
          attributeName={attributeNames.columnGrid}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.columnGrid?.valueOriginal}
          parentAttributeId={quickformAttributes.columnGrid?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.columnGrid?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.floorLoadingCapacity?.id}
          attributeName={attributeNames.floorLoadingCapacity}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.floorLoadingCapacity?.valueOriginal}
          parentAttributeId={quickformAttributes.floorLoadingCapacity?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.floorLoadingCapacity?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.documentComments?.id}
          attributeName={attributeNames.documentComments}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.documentComments?.valueOriginal}
          parentAttributeId={quickformAttributes.documentComments?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.documentComments?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.internalComments?.id}
          attributeName={attributeNames.internalComments}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.internalComments?.valueOriginal}
          parentAttributeId={quickformAttributes.internalComments?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.internalComments?.groupKey}
        />
      </S.HeadTableCell>
    </>
  );
};
