import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Input, List, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BaseAttributeIn } from "@api/client";
import { useParams } from "react-router";
import { addAttribute } from "@utils/offer";
import { EmphesizeSearchText } from "@utils/emphesize-search-text";
import { lettingsApi } from "@api/core";
import { useMutation } from "react-query";
import { ElementOnPredefinedAttributeList, PredefinedAttributeInputWrapper } from "./add-attributes.styled";
import { LettingIdContext } from "./local-context/letting-context";

export const PredefinedAttributes = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const inputRef = useRef<any>();
  const fullListOfAttributes = useSelector((state) => state.offers.offerDetails.predefinedAttribute);
  const [listDataSource, setListDataSource] = useState(fullListOfAttributes);
  const filter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regexExpression = new RegExp(event.target.value, "i");
    setListDataSource(fullListOfAttributes?.filter((attribute) => attribute.name.match(regexExpression)));
  };
  const { lettingId, onAdd } = useContext(LettingIdContext);

  useEffect(() => {
    setListDataSource(fullListOfAttributes);
  }, [fullListOfAttributes]);

  const addAttributeCallback = useCallback(
    (selectedAttribute: BaseAttributeIn) => {
      addAttribute(dispatch)({ offerId: +id, baseAttributeIn: selectedAttribute });
    },
    [dispatch, id]
  );

  const handleAddAttributeWithLettingId = useMutation(
    (attribute: BaseAttributeIn) =>
      lettingsApi.postLettingAttributeApiV1LettingsLettingIdAttributesPost({
        lettingId: lettingId || -1,
        baseAttributeIn: attribute,
      }),
    {
      onSuccess: () => {
        onAdd?.();
        message.success("success");
      },
      onError: (error: Error) => {
        message.error(`error: ${error.message}`);
      },
    }
  );

  const selectedHandleAddAttribute = (attribute: BaseAttributeIn) =>
    lettingId ? handleAddAttributeWithLettingId.mutate(attribute) : addAttributeCallback(attribute);

  const onClickHandler = (attribute: BaseAttributeIn) => async () => {
    console.log(attribute);
    const newAttribute: BaseAttributeIn = {
      name: attribute.name,
      dataTypeCode: attribute.dataTypeCode,
      baseUnitOfMeasureCode: attribute.baseUnitOfMeasureCode,
      namePL: attribute.namePL,
    };

    selectedHandleAddAttribute(newAttribute);
  };

  return (
    <>
      <PredefinedAttributeInputWrapper>
        <Input placeholder="Attribute Name" ref={inputRef} onChange={filter} />
      </PredefinedAttributeInputWrapper>
      <List
        style={{ overflowY: "scroll" }}
        itemLayout="horizontal"
        dataSource={listDataSource}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <ElementOnPredefinedAttributeList
                  key={item.code}
                  type="button"
                  value={item.code}
                  onClick={onClickHandler(item)}
                >
                  <EmphesizeSearchText
                    sourceText={item.name}
                    textToEmphesize={inputRef.current?.state.value}
                  />
                </ElementOnPredefinedAttributeList>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};
