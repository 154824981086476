import { Dispatch } from "redux";
import { offerApi } from "@api/core";
import * as actions from "@state/offers/actions";
import {
  DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest,
  PostOfferAttributeApiV1OffersOfferIdAttributesPostRequest,
  PutOfferAttributesOut,
  PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest,
} from "@api/client";
import { createApiAction } from "@utils/createApiAction";
import { QuickformDeleteModuleReq } from "@api/client/models/QuickformDeleteModule";
import { QuickformDeleteFloorReq } from "@api/client/models/QuickformDeleteFloor";

export const updateTitle = createApiAction(
  actions.updateTitle,
  offerApi.putOfferNameApiV1OffersOfferIdNamePut.bind(offerApi)
);

export const updateDescription = createApiAction(
  actions.updateDescription,
  offerApi.putOfferDescriptionApiV1OffersOfferIdDescriptionPut.bind(offerApi)
);

export const updateNote = createApiAction(
  actions.updateNote,
  offerApi.putOfferNoteApiV1OffersOfferIdNotePut.bind(offerApi)
);

export const updateStatus = createApiAction(
  actions.updateStatus,
  offerApi.putOfferStatusApiV1OffersOfferIdStatusPut.bind(offerApi)
);

export const updateAttribute =
  (dispatch: Dispatch): any =>
  (
    params: PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest,
    index: number,
    groupKey: string
  ): any => {
    dispatch(actions.updateAttribute.request({ attributeId: params.attributeId, groupKey }));
    offerApi
      .putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePut(params)
      .then((response: PutOfferAttributesOut) =>
        dispatch(actions.updateAttribute.success({ ...response, groupKey }))
      )
      .catch(() => dispatch(actions.updateAttribute.failure(index)));
  };

export const deleteAttribute =
  (dispatch: Dispatch) =>
  (params: DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest & { groupKey: string }) => {
    dispatch(actions.deleteAttribute.request({ attributeId: params.attributeId, groupKey: params.groupKey }));
    offerApi
      .deleteOfferAttributeApiV1OffersAttributesAttributeIdDelete(params)
      .then(() =>
        dispatch(
          actions.deleteAttribute.success({ attributeId: params.attributeId, groupKey: params.groupKey })
        )
      )
      .catch((error) => dispatch(actions.deleteAttribute.failure(error)));
  };

export const addQuickformAttribute =
  (dispatch: Dispatch) => (params: { offerId: number; dataTypeCode: string; name: string }) => {
    dispatch(actions.addQuickformAttribute.request({ dataTypeCode: params.dataTypeCode, name: params.name }));
    offerApi
      .postQuickformOfferAttributeApiV1OffersOfferIdAttributesPost(params)
      .then((resp) =>
        dispatch(
          actions.addQuickformAttribute.success({
            attributeId: resp.attributeId,
            dataTypeCode: params.dataTypeCode,
            name: params.name,
          })
        )
      )
      .catch((error) => dispatch(actions.addQuickformAttribute.failure(error)));
  };

export const deleteQuickformFloor = (dispatch: Dispatch) => (params: QuickformDeleteFloorReq) => {
  dispatch(actions.deleteQuickformFloor.request());
  offerApi
    .deleteQuickformFloorRequest(params)
    .then(() => dispatch(actions.deleteQuickformFloor.success()))
    .catch((error) => dispatch(actions.deleteQuickformFloor.failure(error)));
};

export const deleteQuickformModule = (dispatch: Dispatch) => (params: QuickformDeleteModuleReq) => {
  dispatch(actions.deleteQuickformModule.request());
  offerApi
    .deleteQuickformModuleRequest(params)
    .then(() => dispatch(actions.deleteQuickformModule.success()))
    .catch((error) => dispatch(actions.deleteQuickformFloor.failure(error)));
};

export const changeAttributeVisibility = createApiAction(
  actions.setAttributeVisibility,
  offerApi.putOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPut.bind(offerApi)
);

export const updateAttributeNote = createApiAction(
  actions.setAttributeNote,
  offerApi.putOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePut.bind(offerApi)
);

export const addAttribute = createApiAction(
  actions.addAttribute,
  offerApi.postOfferAttributeApiV1OffersOfferIdAttributesPost.bind(offerApi)
);

export const setPredefinedAttributes = createApiAction(
  actions.setPredefinedAttributes,
  offerApi.getOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGet.bind(offerApi)
);

