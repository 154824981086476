/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewsletterLocation,
    NewsletterLocationFromJSON,
    NewsletterLocationFromJSONTyped,
    NewsletterLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewsletterSchemaOut
 */
export interface NewsletterSchemaOut {
    /**
     * 
     * @type {string}
     * @memberof NewsletterSchemaOut
     */
    name: string;
    /**
     * 
     * @type {Array<NewsletterLocation>}
     * @memberof NewsletterSchemaOut
     */
    locations: Array<NewsletterLocation>;
    /**
     * 
     * @type {string}
     * @memberof NewsletterSchemaOut
     */
    module?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsletterSchemaOut
     */
    id: number;
}

export function NewsletterSchemaOutFromJSON(json: any): NewsletterSchemaOut {
    return NewsletterSchemaOutFromJSONTyped(json, false);
}

export function NewsletterSchemaOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterSchemaOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'locations': ((json['locations'] as Array<any>).map(NewsletterLocationFromJSON)),
        'module': !exists(json, 'module') ? undefined : json['module'],
        'id': json['id'],
    };
}

export function NewsletterSchemaOutToJSON(value?: NewsletterSchemaOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'locations': ((value.locations as Array<any>).map(NewsletterLocationToJSON)),
        'module': value.module,
        'id': value.id,
    };
}


