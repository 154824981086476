import React, { useCallback } from "react";

import { Title } from "@pages/offer-detail/title";
import { AppState } from "@state/store";
import { useSelector } from "react-redux";
import { Loader } from "@components/loader";
import { TenantList } from "./tenant-list";
import { NewTenant } from "./new-tenant/new-tenant";

import * as S from "./transactions.styled";
import { useCallTenantsList } from "./tenant-list/utils/useTenantsApi";

export const Transactions = () => {
  const propertyId = useSelector((state: AppState) => state.offers.offerDetails.data?.propertyId);

  const { tenants, mutate } = useCallTenantsList(propertyId ?? -1);

  const handleMutate = useCallback(mutate, [mutate]);

  if (!(propertyId || tenants)) {
    return <Loader />;
  }
  return (
    <S.Container>
      <S.TopContent>
        <S.TopContentTitle>
          <Title />
        </S.TopContentTitle>
      </S.TopContent>
      {propertyId && (
        <>
          <TenantList tenants={tenants ?? []} mutate={handleMutate} />
          <NewTenant propertyId={propertyId} mutateListOfTenants={handleMutate} />
        </>
      )}
    </S.Container>
  );
};
