import {
  CustomLettingAttributeOut,
  DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest,
  PutLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePutRequest,
} from "@api/client";
import { lettingsApi } from "@api/core";
import { Header } from "@pages/transactions/letting-list/header";
import { AppState } from "@state/store";
import { Input } from "antd";
import React, { createRef } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { InfoIcon } from "../../../offer-detail/attributes/info-icon";
import * as S from "./attribute-with-floors.styled";

interface Props {
  attribute: CustomLettingAttributeOut;
  lettingId: number;
  refetchListOfAttributes: () => void;
}

export const AttributeWithFlors: React.FC<Props> = ({ attribute, refetchListOfAttributes }) => {
  const offers = useSelector((state: AppState) => state.offers);
  const inputValueRef = createRef<Input>();
  const { valueInterpretationStatusCode, valueInterpretationMessage } = Object.keys(
    offers.offerDetails.data?.customAttributes ?? {}
  )
    .flatMap((groupKey) => offers.offerDetails.data?.customAttributes[groupKey])
    .find((entity) => entity?.code === attribute.code) || {
    valueInterpretationStatusCode: "",
    valueInterpretationMessage: "",
  };

  const deleteApi = () =>
    lettingsApi.deleteLettingAttributeApiV1LettingsAttributesAttributeIdDelete.bind(lettingsApi);

  const deleteApiArg = {
    attributeId: attribute?.attributeId,
  } as DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest;

  const { mutate: updateAttribute } = useMutation(
    `update_${attribute?.attributeId}`,
    (value: string) =>
      lettingsApi.putLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePut({
        attributeId: attribute.attributeId,
        attributeValue: { valueOriginal: value },
      } as PutLettingAttributeValueApiV1LettingsAttributesAttributeIdValuePutRequest),
    {
      onSuccess: () => {
        refetchListOfAttributes();
      },
      onError: (error: Error) => {
        console.log({ error });
      },
    }
  );

  const headerInsidesCreator = () => (
    <>
      <Input
        ref={inputValueRef}
        defaultValue={attribute.valueOriginal}
        onBlur={(event) => {
          updateAttribute(event.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            inputValueRef.current?.blur();
          }
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <InfoIcon
        info={attribute.dataTypeCode}
        statusCode={valueInterpretationStatusCode}
        responseMessage={valueInterpretationMessage}
      />
    </>
  );

  const panelCreator = () => (
    <S.PaddingTopAndButton>
      <Header
        title={<S.Title>{attribute.name}</S.Title>}
        deleteApi={deleteApi}
        delteApiArg={deleteApiArg}
        entityId={attribute.attributeId}
        onDelete={refetchListOfAttributes}
      >
        {headerInsidesCreator()}
      </Header>
    </S.PaddingTopAndButton>
  );

  return panelCreator();
};
