/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientName,
    ClientNameFromJSON,
    ClientNameToJSON,
    ClientNameOut,
    ClientNameOutFromJSON,
    ClientNameOutToJSON,
    ClientOfferIn,
    ClientOfferInFromJSON,
    ClientOfferInToJSON,
    ClientOfferOutList,
    ClientOfferOutListFromJSON,
    ClientOfferOutListToJSON,
    ClientOut,
    ClientOutFromJSON,
    ClientOutToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    OfferDetailsClient,
    OfferDetailsClientFromJSON,
    OfferDetailsClientToJSON,
} from '../models';

export interface CreateClientUrlApiV1ClientsUrlsPostRequest {
    clientOfferIn: ClientOfferIn;
}

export interface DeleteClientApiV1ClientsClientIdDeleteRequest {
    clientId: number;
}

export interface GetClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGetRequest {
    uuid: string;
    propertyId: number;
    contactEmail: string;
}

export interface GetClientOffersDetailsApiV1ClientsOffersUuidGetRequest {
    uuid: string;
}

export interface GetClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGetRequest {
    uuid: string;
    attachmentId: number;
}

export interface PostClientApiV1ClientsPostRequest {
    clientName: ClientName;
}

/**
 * 
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     * Create and archive client portal url
     */
    async createClientUrlApiV1ClientsUrlsPostRaw(requestParameters: CreateClientUrlApiV1ClientsUrlsPostRequest): Promise<runtime.ApiResponse<ClientOfferOutList>> {
        if (requestParameters.clientOfferIn === null || requestParameters.clientOfferIn === undefined) {
            throw new runtime.RequiredError('clientOfferIn','Required parameter requestParameters.clientOfferIn was null or undefined when calling createClientUrlApiV1ClientsUrlsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/clients/urls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientOfferInToJSON(requestParameters.clientOfferIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientOfferOutListFromJSON(jsonValue));
    }

    /**
     * Create and archive client portal url
     */
    async createClientUrlApiV1ClientsUrlsPost(requestParameters: CreateClientUrlApiV1ClientsUrlsPostRequest): Promise<ClientOfferOutList> {
        const response = await this.createClientUrlApiV1ClientsUrlsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete client
     */
    async deleteClientApiV1ClientsClientIdDeleteRaw(requestParameters: DeleteClientApiV1ClientsClientIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling deleteClientApiV1ClientsClientIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete client
     */
    async deleteClientApiV1ClientsClientIdDelete(requestParameters: DeleteClientApiV1ClientsClientIdDeleteRequest): Promise<void> {
        await this.deleteClientApiV1ClientsClientIdDeleteRaw(requestParameters);
    }

    /**
     * Get all clients
     */
    async getAllClientsApiV1ClientsGetRaw(): Promise<runtime.ApiResponse<Array<ClientNameOut>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/clients/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientNameOutFromJSON));
    }

    /**
     * Get all clients
     */
    async getAllClientsApiV1ClientsGet(): Promise<Array<ClientNameOut>> {
        const response = await this.getAllClientsApiV1ClientsGetRaw();
        return await response.value();
    }

    /**
     * Get Client Contact Photo
     */
    async getClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGetRaw(requestParameters: GetClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGet.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGet.');
        }

        if (requestParameters.contactEmail === null || requestParameters.contactEmail === undefined) {
            throw new runtime.RequiredError('contactEmail','Required parameter requestParameters.contactEmail was null or undefined when calling getClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/clients/offers/{uuid}/property/{propertyId}/contacts/{contactEmail}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"contactEmail"}}`, encodeURIComponent(String(requestParameters.contactEmail))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Client Contact Photo
     */
    async getClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGet(requestParameters: GetClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGetRequest): Promise<void> {
        await this.getClientContactPhotoApiV1ClientsOffersUuidPropertyPropertyIdContactsContactEmailGetRaw(requestParameters);
    }

    /**
     * Get details of all client offers
     */
    async getClientOffersDetailsApiV1ClientsOffersUuidGetRaw(requestParameters: GetClientOffersDetailsApiV1ClientsOffersUuidGetRequest): Promise<runtime.ApiResponse<OfferDetailsClient>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getClientOffersDetailsApiV1ClientsOffersUuidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/clients/offers/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferDetailsClientFromJSON(jsonValue));
    }

    /**
     * Get details of all client offers
     */
    async getClientOffersDetailsApiV1ClientsOffersUuidGet(requestParameters: GetClientOffersDetailsApiV1ClientsOffersUuidGetRequest): Promise<OfferDetailsClient> {
        const response = await this.getClientOffersDetailsApiV1ClientsOffersUuidGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Client Portal Attachment
     */
    async getClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGetRaw(requestParameters: GetClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGet.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling getClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/clients/offers/{uuid}/attachments/{attachmentId}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get Client Portal Attachment
     */
    async getClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGet(requestParameters: GetClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGetRequest): Promise<void> {
        await this.getClientPortalAttachmentApiV1ClientsOffersUuidAttachmentsAttachmentIdGetRaw(requestParameters);
    }

    /**
     * Add new client
     */
    async postClientApiV1ClientsPostRaw(requestParameters: PostClientApiV1ClientsPostRequest): Promise<runtime.ApiResponse<ClientOut>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling postClientApiV1ClientsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/clients/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientNameToJSON(requestParameters.clientName),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientOutFromJSON(jsonValue));
    }

    /**
     * Add new client
     */
    async postClientApiV1ClientsPost(requestParameters: PostClientApiV1ClientsPostRequest): Promise<ClientOut> {
        const response = await this.postClientApiV1ClientsPostRaw(requestParameters);
        return await response.value();
    }

}
