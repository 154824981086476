import { Msal2Config } from "@microsoft/mgt-msal2-provider";
import { getEnv } from "@utils/get-env";

const scopes = ["email", "profile"];

export const msalConfiguration: Msal2Config =
  getEnv("REACT_APP_USE_MULTITENANT") === "true"
    ? {
        clientId: getEnv("REACT_APP_CLIENT_ID"),
        redirectUri: getEnv("REACT_APP_AAD_REDIRECTION_URL"),
        scopes,
      }
    : {
        clientId: getEnv("REACT_APP_CLIENT_ID"),
        redirectUri: getEnv("REACT_APP_AAD_REDIRECTION_URL"),
        authority: `https://login.microsoftonline.com/${getEnv("REACT_APP_AZURE_ACTIVE_DIRECTORY_ID")}`,
        scopes,
      };
