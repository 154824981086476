/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomAttributeDistributionText
 */
export interface CustomAttributeDistributionText {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CustomAttributeDistributionText
     */
    counts: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeDistributionText
     */
    searchType: string;
}

export function CustomAttributeDistributionTextFromJSON(json: any): CustomAttributeDistributionText {
    return CustomAttributeDistributionTextFromJSONTyped(json, false);
}

export function CustomAttributeDistributionTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAttributeDistributionText {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'counts': json['counts'],
        'searchType': json['searchType'],
    };
}

export function CustomAttributeDistributionTextToJSON(value?: CustomAttributeDistributionText | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'counts': value.counts,
        'searchType': value.searchType,
    };
}


