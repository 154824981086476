/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactPhoneResponse
 */
export interface ContactPhoneResponse {
    /**
     * 
     * @type {number}
     * @memberof ContactPhoneResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ContactPhoneResponse
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof ContactPhoneResponse
     */
    modificationDate: Date;
}

export function ContactPhoneResponseFromJSON(json: any): ContactPhoneResponse {
    return ContactPhoneResponseFromJSONTyped(json, false);
}

export function ContactPhoneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPhoneResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'modificationDate': (new Date(json['modificationDate'])),
    };
}

export function ContactPhoneResponseToJSON(value?: ContactPhoneResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'phone': value.phone,
        'modificationDate': (value.modificationDate.toISOString()),
    };
}


