/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientOfferInfo
 */
export interface ClientOfferInfo {
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientOfferInfo
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientOfferInfo
     */
    lon?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    mainPhoto?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    parentLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    descriptionLongENG?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
     descriptionLocationENG?: string;
     /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
    descriptionLong?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOfferInfo
     */
     descriptionLocation?: string;
}

export function ClientOfferInfoFromJSON(json: any): ClientOfferInfo {
    return ClientOfferInfoFromJSONTyped(json, false);
}

export function ClientOfferInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOfferInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'mainPhoto': !exists(json, 'mainPhoto') ? undefined : json['mainPhoto'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'parentLocation': !exists(json, 'parent_location') ? undefined : json['parent_location'],
        'descriptionLongENG': !exists(json, 'descriptionLongENG') ? undefined : json['descriptionLongENG'],
        'descriptionLong': !exists(json, 'descriptionLong') ? undefined : json['descriptionLong'],
        'descriptionLocationENG': !exists(json, 'descriptionLocationENG') ? undefined : json['descriptionLocationENG'],
        'descriptionLocation': !exists(json, 'descriptionLocation') ? undefined : json['descriptionLocation'],
    };
}

export function ClientOfferInfoToJSON(value?: ClientOfferInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'name': value.name,
        'lat': value.lat,
        'lon': value.lon,
        'mainPhoto': value.mainPhoto,
        'status': value.status,
        'location': value.location,
        'parent_location': value.parentLocation,
        'descriptionLongENG': value.descriptionLongENG,
        'descriptionLong': value.descriptionLong,
        'descriptionLocationENG': value.descriptionLocationENG,
        'descriptionLocation': value.descriptionLocation
    };
}


