import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import { createAppStore } from "@state/store";

import { theme } from "@components/variables";
import { Spin } from "@components/spin";
import { withAuthentication } from "@components/authentication/authentication-wrapper";
import { ClientProvider } from "@components/client-provider/client-provider";

import { Root } from "./layout/root/root";

const store = createAppStore();

const ClientPortalLazy = React.lazy(() => import("@pages/client-portal/client-portal"));
const RootComponent = withAuthentication(Root);

const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ClientProvider>
        <React.Suspense fallback={<Spin />}>
          <Router>
            <Switch>
              <Route component={ClientPortalLazy} path="/portal/:offerId" />
              <Route component={RootComponent} path="/app" />
              <Redirect to="/app" />
            </Switch>
          </Router>
        </React.Suspense>
      </ClientProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
