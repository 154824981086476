import styled from "styled-components";
import { AutoComplete } from "antd";
// import { Input as InputCore } from "@components/input";

export const TagsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 10px;
`;

export const TagsContainer = styled.div`
  display: inline-block;
`;

export const Input = styled(AutoComplete)`
  width: 200px;
  height: 24px;
  font-size: 14px;
  margin-right: 5px;
`;

export const Form = styled.form`
  margin-right: 10px;
  display: flex;
`;
