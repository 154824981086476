import React from "react";
import { theme } from "@components/variables";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import * as S from "./loader.styled";
import { Spin, SpinProps } from "../spin";

const antIcon = <LoadingOutlined color={theme.primaryColor} style={{ fontSize: 36 }} spin />;

export const Loader: React.FC<S.LoaderWrapperStyles & Partial<SpinProps>> = ({
  size = "large",
  noMargin,
  justifyContent,
  ...restSpinProps
}) => (
  <S.Container noMargin={noMargin} justifyContent={justifyContent}>
    <Spin size={size} indicator={antIcon} {...restSpinProps} />
  </S.Container>
);
