import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Field, FieldProps } from "formik";
import { updateNote } from "@utils/offer";
import { EditableContentInput } from "@components/form/editable-content-input";
import { usePermissions } from "@hooks/use-permission";
import { Heading } from "../offer-detail.styled";
import * as S from "./offer-note.styled";

export const OfferNote = () => {
  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();
  const { id } = useParams<{ id: string }>();

  const onDescriptionChange = useCallback(
    (note: string) => {
      updateNote(dispatch)({
        offerId: +id,
        offerInNote: {
          note,
        },
      });
    },
    [dispatch, id]
  );

  return (
    <S.Container>
      <Heading level="2">Additional property note</Heading>

      <Field name="offerNote">
        {(fieldProps: FieldProps) => (
          <EditableContentInput
            edibable={hasPermission("PUT_OFFER_NOTE")}
            onBlur={onDescriptionChange}
            multiline
            {...fieldProps}
          />
        )}
      </Field>
    </S.Container>
  );
};
