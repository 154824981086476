import styled, { keyframes } from "styled-components";
import { theme } from "@components/variables";
import { ReactComponent as IconOptionsRaw } from "@svg/icon-options.svg";

const rotate = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;

export const ImageWrapper = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Input = styled.input`
  display: none;
`;

export const Options = styled.div`
  position: absolute;
  margin: 5px;
  background: transparent;
  border: none;
  .ant-dropdown-trigger {
    display: flex;
    margin: auto;
    padding: 0;
    background: transparent;
    border: none;
    :focus {
      outline: none;
    }
  }
`;

export const OptionsButton = styled.button`
  position: absolute;
  margin: 5px;
  background: transparent;
  border: none;
`;

export const ButtonInDropdown = styled.button`
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;

  &[disabled] {
    cursor: not-allowed;
  }

  :hover:not([disabled]) {
    svg {
      animation: ${rotate} 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    svg path {
      fill: ${theme.primaryColor};
    }
    color: ${theme.primaryColor};
  }
  :focus {
    outline: none;
    svg path {
      fill: ${theme.primaryColor};
    }
    color: ${theme.primaryColor};
  }
`;

export const IconInDropdownWrapper = styled.div`
  display: flex;
  margin: auto;
  padding-right: 10px;
  background: transparent;
`;

export const IconOptions = styled(IconOptionsRaw)`
  :focus {
    outline-color: ${theme.primaryColor};
  }
  :hover {
    path {
      fill: ${theme.primaryColor};
    }
    circle {
      fill: white;
    }
  }
`;

export const IconOptionsClicked = styled(IconOptionsRaw)`
  path {
    fill: ${theme.primaryColor};
  }
  circle {
    fill: white;
  }
`;
