/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentMainOut
 */
export interface AttachmentMainOut {
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentMainOut
     */
    isMainFile: boolean;
    /**
     * 
     * @type {number}
     * @memberof AttachmentMainOut
     */
    offerId: number;
    /**
     * 
     * @type {Date}
     * @memberof AttachmentMainOut
     */
    modificationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof AttachmentMainOut
     */
    attachmentId: number;
}

export function AttachmentMainOutFromJSON(json: any): AttachmentMainOut {
    return AttachmentMainOutFromJSONTyped(json, false);
}

export function AttachmentMainOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentMainOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isMainFile': json['isMainFile'],
        'offerId': json['offerId'],
        'modificationDate': (new Date(json['modificationDate'])),
        'attachmentId': json['attachmentId'],
    };
}

export function AttachmentMainOutToJSON(value?: AttachmentMainOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isMainFile': value.isMainFile,
        'offerId': value.offerId,
        'modificationDate': (value.modificationDate.toISOString()),
        'attachmentId': value.attachmentId,
    };
}


