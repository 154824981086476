import React, { useState } from "react";
import { Heading } from "@components/heading";
import { Input } from "@components/form/input";
import { Button, Modal, message } from "antd";
import { Field, FieldProps, Form, Formik } from "formik";
import { tenantApi } from "@api/core";
import { AddTenantApiV1TenantsPropertyIdPostRequest, TenantBase } from "@api/client";
import { useMutation } from "react-query";
import { Loader } from "@components/loader";
import * as Yup from "yup";

import { ValidationError } from "@components/validation-error/validation-error.styled";
import * as S from "./modal-with-form.styled";

interface Props {
  show: boolean;
  onCancel: () => void;
  onOk: () => void;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  propertyId: number;
  callUpdateTenantList: () => void;
}

const initialValues = {
  tenantName: "",
};

const validationSchema = Yup.object().shape({
  tenantName: Yup.string().required("The name is required"),
});

const TenantNamePlaceHolder = "Tenant name ex. Savills";
const TenantAddedSuccessfuly = "Tenant added successfuly";
const TenantAddingWasRejected = "Tenant adding was rejected";

export const ModalWithForm: React.FC<Props> = ({
  show,
  onCancel,
  onOk,
  submitButtonRef,
  callUpdateTenantList,
  propertyId,
}) => {
  const [tenantName, setTenantName] = useState("");
  const postMethod = tenantApi.addTenantApiV1TenantsPropertyIdPost.bind(tenantApi);
  const postParams = {
    tenantBase: { name: tenantName } as TenantBase,
    propertyId,
  } as AddTenantApiV1TenantsPropertyIdPostRequest;

  const { mutate, isLoading } = useMutation(() => postMethod({ ...postParams }), {
    onSuccess: () => {
      message.success(TenantAddedSuccessfuly);
    },
    onError: () => {
      message.error(TenantAddingWasRejected);
    },
  });

  const handleSubmit = (values: typeof initialValues): void => {
    setTenantName(values.tenantName);
    mutate();
    callUpdateTenantList();
  };

  return (
    <Modal visible={show} onCancel={onCancel} onOk={onOk} width={500} destroyOnClose keyboard>
      {isLoading && <Loader />}
      <Heading>Please enter the name for a new tenant</Heading>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ errors, touched }) => (
          <Form>
            <Field name="tenantName">
              {(fieldProps: FieldProps) => <Input placeholder={TenantNamePlaceHolder} {...fieldProps} />}
            </Field>
            {errors.tenantName && touched.tenantName ? (
              <ValidationError>{errors.tenantName}</ValidationError>
            ) : null}
            <S.TopPadding>
              <Button htmlType="submit" ref={submitButtonRef}>
                Add new tenant
              </Button>
            </S.TopPadding>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
