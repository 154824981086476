import styled, { css } from "styled-components";
import { Checkbox as CheckboxCore } from "@components/checkbox";

export const mainTableMixin = css`
  &:nth-child(1) {
    max-width: 30px;
  }
  &:nth-child(2) {
    //flex-basis: 190px;
    padding-right: 20px;
  }
  &:nth-child(3) {
    //flex-basis: 70px;
  }
  &:nth-child(4) {
    // flex-basis: 150px;
  }
  &:nth-child(5) {
    // flex-basis: 290px;
  }
  &:nth-child(6) {
    // flex-basis: 100px;
    white-space: nowrap;
  }
  &:nth-child(7) {
    // flex-basis: 190px;
  }
  &:nth-child(8) {
    // flex-basis: 180px;
  }
`;

export const Checkbox = styled(CheckboxCore)`
  height: 20px;
  width: 20px;

  span {
    height: 20px;
    width: 20px;
  }
`;
