import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "@microsoft/mgt-react";
import { loadOfferStatues, loadPropertyCategories, loadPropertyStatues } from "@utils/dicts";
import { Tabs, TabPane } from "@components/tabs";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as IconAgency } from "@svg/icon-agencymodule-active.svg";
import { ReactComponent as IconOffices } from "@svg/icon-offices-notactive.svg";
import { ReactComponent as PoiIcon } from "@svg/icon-shops-notactive.svg";
import { HeadingWithIcon } from "@components/heading-with-icon";
import { LoginTopBarRightCorner } from "@components/login-wrapper/login-wrapper.styles";
import { UserDataContext, UserDataProvider } from "@components/user-data-provider";
import { appPath } from "@router/paths";
import { usePermissions } from "@hooks/use-permission";
import { setCurrentModule as setOffersCurrentModule } from "@state/offers/actions";
import { setCurrentModule as setFiltersCurrentModule } from "@state/filters/actions";
import { Module } from "@state/types";
import { permissions, Roles } from "../../config/permissions";
import { Header } from "../header/header";
import { AgencyModule } from "../../pages/agency-module/agency-module";
import { IndustrialModule } from "../../pages/industrial-module/industrial-module";
import { Poi } from "../../pages/poi";
import * as S from "./root.styles";
import "./root.less";
import { RetailModule } from "../../pages/retail-module/retail-module";

type Route = {
  path: string;
  label: string;
  icon: JSX.Element;
  component: React.FC<any>;
  disabled?: boolean;
  permission: keyof typeof permissions;
  module?: Module;
};
type RouterVonfig = Record<string, Route>;

const routerConfig: RouterVonfig = {
  office: {
    path: appPath,
    label: "Agency Module",
    icon: <IconAgency height="20px" width="20px" />,
    component: AgencyModule,
    permission: "ACCESS_AGENCY_MODULE",
    module: "office",
  },
  industrial: {
    path: `${appPath}/industrial-module`,
    label: "Industrial Module",
    icon: <IconOffices height="20px" width="20px" />,
    component: IndustrialModule,
    permission: "ACCESS_INDUSTRIAL_MODULE",
    module: "industrial",
  },
  retail: {
    path: `${appPath}/retail`,
    label: "Retail Module",
    icon: <PoiIcon height="20px" width="20px" />,
    component: RetailModule,
    permission: "ACCESS_RETAIL_MODULE",
    module: "retail",
  },
  poi: {
    path: `${appPath}/points-of-interests`,
    label: "Points of interests",
    icon: <PoiIcon height="20px" width="20px" />,
    component: Poi,
    permission: "ACCESS_POI_MODULE",
  },
};

const RootTabs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { hasPermission } = usePermissions();
  const values = useContext(UserDataContext);
  const currentModule = useSelector((state) => state.offers.currentModule);

  let activeKey = Object.keys(routerConfig).filter((k) => {
    return routerConfig[k].path.includes(pathname) && hasPermission(routerConfig[k].permission);
  })[0];

  if (activeKey !== "poi" && activeKey !== "retail") {
    activeKey =
      currentModule ||
      Object.keys(routerConfig).filter((k) => {
        return routerConfig[k].path.includes(pathname) && hasPermission(routerConfig[k].permission);
      })[0];
  }

  useEffect(() => {
    dispatch(setOffersCurrentModule(activeKey as Module));
    dispatch(setFiltersCurrentModule(activeKey as Module));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onChange = (key: string) => {
    history.push(routerConfig[key].path);

    if (routerConfig[key].module) {
      dispatch(setOffersCurrentModule(key as Module));
      dispatch(setFiltersCurrentModule(key as Module));
    }
  };

  if (
    activeKey === "office" &&
    (values.userRole === Roles.INDUSTRIAL_AGENT || Roles.INDUSTRIAL_ADMIN) &&
    values.userRole !== Roles.GLOBAL_ADMIN &&
    values.userRole !== Roles.ADMIN &&
    values.userRole !== Roles.AGENT &&
    values.userRole !== Roles.RETAIL_ADMINISTRATOR &&
    values.userRole !== Roles.RETAIL_AGENT
  ) {
    activeKey = "industrial";
  }

  return (
    <Tabs activeKey={activeKey} onChange={onChange} type="card">
      {Object.entries(routerConfig).map(
        ([key, { label, icon, component: Component, disabled, permission }]) => {
          if (permission && !hasPermission(permission)) {
            return null;
          }

          return (
            <TabPane
              tab={<HeadingWithIcon icon={icon}>{label}</HeadingWithIcon>}
              key={key}
              disabled={disabled}
            >
              <Component />
            </TabPane>
          );
        }
      )}
    </Tabs>
  );
};

export const Root: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadPropertyCategories(dispatch)();
    loadOfferStatues(dispatch)();
    loadPropertyStatues(dispatch)();
  }, [dispatch]);

  return (
    <UserDataProvider>
      <S.Background>
        <LoginTopBarRightCorner>
          <Login />
        </LoginTopBarRightCorner>
        <Header />
        <S.Wrapper>
          <RootTabs />
        </S.Wrapper>
      </S.Background>
    </UserDataProvider>
  );
};
