import React from "react";
import { theme } from "@components/variables";

type TEmpesizeSearchText = {
  sourceText: string;
  textToEmphesize: string;
};

export const EmphesizeSearchText = ({
  sourceText,
  textToEmphesize = "",
}: TEmpesizeSearchText): JSX.Element => {
  const regexExpression = new RegExp(textToEmphesize, "i");
  const textToReplace = sourceText.match(regexExpression);
  const dengerouseHTML = sourceText.replace(
    regexExpression,
    `<span style="color:${theme.primaryColor}"><b>${textToReplace}</b></span>`
  );
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: dengerouseHTML }} />;
};
