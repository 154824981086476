import React, { useCallback, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { AutoComplete, Tooltip, Collapse } from "antd";
import { useQuery } from "react-query";
import { debounce } from "throttle-debounce";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import { offerApi } from "@api/core";

import { DeleteButton } from "@components/delete-button";

import {
  togglePickingGenerateBreakdownReport,
  togglePickingNewsletter,
} from "@state/newsletter-generate-breakdown-report/actions";
import { Property } from "./types";

import * as S from "./newsletter-property-breakdown-report-shared.styled";
import { PropertyDndItem } from "./property-dnd-item";

interface GroupProps {
  onSelect: (id: string, value: Property) => void;
  onDeleteGroup: (id: string) => void;
  onOfferDelete: (name: number, id: string) => void;
  name: string;
  properties: Property[];
  isNewsletter?: boolean;
  reoderProperties: (id: string, properties: Property[]) => void;
}

const { Panel } = Collapse;

export const Group = ({
  name: id,
  properties,
  onSelect,
  onDeleteGroup,
  onOfferDelete,
  isNewsletter,
  reoderProperties,
}: GroupProps) => {
  const filters = useSelector((appState) => appState.dicts);
  const currentModule = useSelector((appState) => appState.offers.currentModule);
  const propertyCategoryCode = useSelector(
    (appState) => appState.filters[appState.filters.currentModule].data.propertyCategoryCode
  );
  const dispatch = useDispatch();

  const propertyStatusCode = filters.property.data.map(({ code }) => code);
  const offerStatusCode = filters.offer.data.map(({ code }) => code);
  const [value, setValue] = useState("");
  const offers = useQuery(`autocomplete-options-${currentModule}-${id}`, () =>
    offerApi.getOffersApiV1OffersGet({
      pageNumber: 1,
      pageSize: 50,
      propertyCategoryCode,
      propertyNameOrAddress: value,
      offerStatusCode,
      propertyStatusCode,
      module: currentModule,
    })
  );

  const options = offers.data?.map(({ offerId, offerName }) => ({
    value: offerName,
    text: offerId,
    key: offerId,
  }));
  const debounceFunc = debounce(300, () => {
    offers.refetch();
  });
  const handleChange = (searchedValue: string) => {
    setValue(searchedValue);
    debounceFunc();
  };
  const handleSelect = (_: string, optionData: any) => {
    onSelect(id, optionData);
    setValue("");
  };
  const onAddFromMainTable = () => {
    if (isNewsletter) dispatch(togglePickingNewsletter(id));
    else dispatch(togglePickingGenerateBreakdownReport(id));
  };

  const onMoveProperty = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragProperty = properties[dragIndex];
      reoderProperties(
        id,
        update(properties, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragProperty],
          ],
        })
      );
    },
    [properties, reoderProperties, id]
  );

  return (
    <S.GroupWrapper>
      <S.NameWithDeleteButton>
        <S.Label htmlFor={id}>{id}</S.Label>
        <Tooltip title={`Delete ${id} group`}>
          <DeleteButton onClick={() => onDeleteGroup(id)} />
        </Tooltip>
      </S.NameWithDeleteButton>
      {!!properties.length && (
        <Collapse>
          <Panel key={id} header="Show properties">
            <DndProvider backend={HTML5Backend}>
              {properties.map(({ text, value: propertyId }, index) => (
                <PropertyDndItem key={text} onMove={onMoveProperty} id={propertyId} index={index}>
                  <S.NameWithDeleteButton>
                    <S.Name>{propertyId}</S.Name>
                    <Tooltip title={`Delete ${propertyId}`}>
                      <DeleteButton onClick={() => onOfferDelete(text, id)} />
                    </Tooltip>
                  </S.NameWithDeleteButton>
                </PropertyDndItem>
              ))}
            </DndProvider>
          </Panel>
        </Collapse>
      )}
      <AutoComplete
        id={id}
        value={value}
        onChange={handleChange}
        style={{ width: "100%" }}
        onSelect={handleSelect}
        placeholder={`Add offer to ${id} group`}
        options={options}
      />
      <S.AddButton type="primary" onClick={onAddFromMainTable}>
        Add from main table
      </S.AddButton>
    </S.GroupWrapper>
  );
};
