import React from "react";
import { Tooltip } from "antd";

import { StyledButton } from "@components/button";
import { Icon } from "@components/icon";
import { theme } from "@components/variables";

import { ReactComponent as Search } from "@svg/symbol-search.svg";
import { ReactComponent as AddOfferIcon } from "@svg/icon-add-offer.svg";

import { AddNewPoi } from "./add-new-poi";

import * as S from "./table.styled";

export const TableHeading: React.FC<{
  isOpen: boolean;
  search?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggleModal: () => void;
}> = ({ isOpen, search, handleChange, handleToggleModal }) => {
  return (
    <S.HeadingWrapper>
      {!!search && !!handleChange && (
        <S.Input value={search} onChange={handleChange} placeholder="Search for poi" suffix={<Search />} />
      )}
      <Tooltip title="Add new POI" color={theme.primaryColor}>
        <StyledButton type="ghost" color={theme.primaryColor} onClick={handleToggleModal}>
          <Icon icon={AddOfferIcon} />
        </StyledButton>
      </Tooltip>
      <AddNewPoi visible={isOpen} onCancel={handleToggleModal} />
    </S.HeadingWrapper>
  );
};
