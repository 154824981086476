/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    Tenant,
    TenantFromJSON,
    TenantToJSON,
    TenantBase,
    TenantBaseFromJSON,
    TenantBaseToJSON,
    TenantOut,
    TenantOutFromJSON,
    TenantOutToJSON,
    TenantProperty,
    TenantPropertyFromJSON,
    TenantPropertyToJSON,
} from '../models';

export interface AddTenantApiV1TenantsPropertyIdPostRequest {
    propertyId: number;
    tenantBase: TenantBase;
}

export interface DeletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDeleteRequest {
    propertyId: number;
    tenantId: number;
}

export interface GetTenantsByPropertyApiV1TenantsPropertyIdGetRequest {
    propertyId: number;
}

/**
 * 
 */
export class TenantsApi extends runtime.BaseAPI {

    /**
     * Add new tenant
     */
    async addTenantApiV1TenantsPropertyIdPostRaw(requestParameters: AddTenantApiV1TenantsPropertyIdPostRequest): Promise<runtime.ApiResponse<TenantOut>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling addTenantApiV1TenantsPropertyIdPost.');
        }

        if (requestParameters.tenantBase === null || requestParameters.tenantBase === undefined) {
            throw new runtime.RequiredError('tenantBase','Required parameter requestParameters.tenantBase was null or undefined when calling addTenantApiV1TenantsPropertyIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/tenants/{propertyId}/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TenantBaseToJSON(requestParameters.tenantBase),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantOutFromJSON(jsonValue));
    }

    /**
     * Add new tenant
     */
    async addTenantApiV1TenantsPropertyIdPost(requestParameters: AddTenantApiV1TenantsPropertyIdPostRequest): Promise<TenantOut> {
        const response = await this.addTenantApiV1TenantsPropertyIdPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Remove tenant assignments
     */
    async deletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDeleteRaw(requestParameters: DeletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling deletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDelete.');
        }

        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/tenants/properties/{propertyId}/tenants/{tenantId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove tenant assignments
     */
    async deletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDelete(requestParameters: DeletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDeleteRequest): Promise<void> {
        await this.deletePropertyTenantApiV1TenantsPropertiesPropertyIdTenantsTenantIdDeleteRaw(requestParameters);
    }

    /**
     * Get all tenants
     */
    async getAllTenantsApiV1TenantsGetRaw(): Promise<runtime.ApiResponse<Array<Tenant>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/tenants/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantFromJSON));
    }

    /**
     * Get all tenants
     */
    async getAllTenantsApiV1TenantsGet(): Promise<Array<Tenant>> {
        const response = await this.getAllTenantsApiV1TenantsGetRaw();
        return await response.value();
    }

    /**
     * Get tenants for given property
     */
    async getTenantsByPropertyApiV1TenantsPropertyIdGetRaw(requestParameters: GetTenantsByPropertyApiV1TenantsPropertyIdGetRequest): Promise<runtime.ApiResponse<Array<TenantProperty>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getTenantsByPropertyApiV1TenantsPropertyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/tenants/{propertyId}/`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantPropertyFromJSON));
    }

    /**
     * Get tenants for given property
     */
    async getTenantsByPropertyApiV1TenantsPropertyIdGet(requestParameters: GetTenantsByPropertyApiV1TenantsPropertyIdGetRequest): Promise<Array<TenantProperty>> {
        const response = await this.getTenantsByPropertyApiV1TenantsPropertyIdGetRaw(requestParameters);
        return await response.value();
    }

}
