import React from "react";
import { Slider } from "antd";
import * as S from "./generic-slider.styled";
import { getPrecisionNumber } from "./utils/get-precision-number";

export interface GenericSliderProps {
  title: string;
  minVal: number;
  maxVal: number;
  selectedLowerValue: number;
  selectedUpperValue: number;
  onSliderChange?: (v: number[]) => void;
  onLowerInputChange?: (event: Parameters<React.ChangeEventHandler<HTMLInputElement>>[0]) => void;
  onUpperInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const GenericSlider: React.FC<GenericSliderProps> = ({
  title,
  minVal,
  maxVal,
  selectedLowerValue,
  selectedUpperValue,
  onSliderChange,
  onLowerInputChange,
  onUpperInputChange,
}) => {
  return (
    <S.GeneralSliderWrapper>
      {title}
      <S.SliderContainer>
        <Slider
          range
          onChange={onSliderChange}
          value={[selectedLowerValue, selectedUpperValue]}
          min={minVal}
          max={maxVal}
          step={getPrecisionNumber(maxVal)}
        />
      </S.SliderContainer>
      <S.InputsWrapper>
        <S.SliderInput
          value={selectedLowerValue}
          type="number"
          min={minVal}
          max={maxVal}
          onChange={onLowerInputChange}
        />
        <S.SliderInput
          value={selectedUpperValue}
          type="number"
          min={minVal}
          max={maxVal}
          onChange={onUpperInputChange}
        />
      </S.InputsWrapper>
    </S.GeneralSliderWrapper>
  );
};
