import { createReducer, ActionType } from "typesafe-actions";
import { PropertyBase } from "@api/client";
import { actions } from "./actions";

const initialState = {
  properties: [] as PropertyBase[],
  loading: false,
  error: "",
  page: 1,
  canLoadMore: true,
};

type PropertyState = typeof initialState;
type PropertyActions = ActionType<typeof actions>;

export const propertiesReducer = createReducer<PropertyState, PropertyActions>(initialState)
  .handleAction(actions.setLoading, (state) => ({ ...state, loading: true }))
  .handleAction(actions.setProperties.success, (state, action) => ({
    ...state,
    properties: action.payload,
    loading: false,
  }))
  .handleAction(actions.setProperties.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(actions.loadMoreProperties.success, (state, action) => ({
    ...state,
    loading: false,
    properties: [...state.properties, ...action.payload],
  }))
  .handleAction(actions.setPageNumber, (state, action) => ({
    ...state,
    page: action.payload,
  }))
  .handleAction(actions.noMorePages, (state) => ({
    ...state,
    canLoadMore: false,
  }))
  .handleAction(actions.thereAreMorePages, (state) => ({
    ...state,
    canLoadMore: true,
  }));
