/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GeoBase,
    GeoBaseFromJSON,
    GeoBaseToJSON,
} from '../models';

/**
 * 
 */
export class GeographyApi extends runtime.BaseAPI {

    /**
     * Get Geographies
     */
    async getGeographiesApiV1GeographiesGetRaw(): Promise<runtime.ApiResponse<Array<GeoBase>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/geographies/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeoBaseFromJSON));
    }

    /**
     * Get Geographies
     */
    async getGeographiesApiV1GeographiesGet(): Promise<Array<GeoBase>> {
        const response = await this.getGeographiesApiV1GeographiesGetRaw();
        return await response.value();
    }

}
