/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PoiBase,
    PoiBaseFromJSON,
    PoiBaseToJSON,
    PoiIn,
    PoiInFromJSON,
    PoiInToJSON,
    PoiInAddress,
    PoiInAddressFromJSON,
    PoiInAddressToJSON,
    PoiInCategory,
    PoiInCategoryFromJSON,
    PoiInCategoryToJSON,
    PoiInCoordinates,
    PoiInCoordinatesFromJSON,
    PoiInCoordinatesToJSON,
    PoiInGeo,
    PoiInGeoFromJSON,
    PoiInGeoToJSON,
    PoiInName,
    PoiInNameFromJSON,
    PoiInNameToJSON,
    PoiOutAddressAndCoordinates,
    PoiOutAddressAndCoordinatesFromJSON,
    PoiOutAddressAndCoordinatesToJSON,
    PoiOutCategory,
    PoiOutCategoryFromJSON,
    PoiOutCategoryToJSON,
    PoiOutGeo,
    PoiOutGeoFromJSON,
    PoiOutGeoToJSON,
    PoiOutName,
    PoiOutNameFromJSON,
    PoiOutNameToJSON,
} from '../models';

export interface DeletePoiApiV1PoisPoiIdDeleteRequest {
    poiId: number;
}

export interface GetPoisApiV1PoisGetRequest {
    pageNumber?: number;
    pageSize?: number;
    poiName?: string;
}

export interface PostPoisApiV1PoisPostRequest {
    poiIn: PoiIn;
}

export interface PutPoiAddressApiV1PoisPoiIdAddressPutRequest {
    poiId: number;
    poiInAddress: PoiInAddress;
}

export interface PutPoiCategoryApiV1PoisPoiIdCategoryPutRequest {
    poiId: number;
    poiInCategory: PoiInCategory;
}

export interface PutPoiCoordinatesApiV1PoisPoiIdCoordinatesPutRequest {
    poiId: number;
    poiInCoordinates: PoiInCoordinates;
}

export interface PutPoiGeographyApiV1PoisPoiIdGeographyPutRequest {
    poiId: number;
    poiInGeo: PoiInGeo;
}

export interface PutPoiNameApiV1PoisPoiIdNamePutRequest {
    poiId: number;
    poiInName: PoiInName;
}

/**
 * 
 */
export class POIApi extends runtime.BaseAPI {

    /**
     * Delete POI
     */
    async deletePoiApiV1PoisPoiIdDeleteRaw(requestParameters: DeletePoiApiV1PoisPoiIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling deletePoiApiV1PoisPoiIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/{poiId}`.replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete POI
     */
    async deletePoiApiV1PoisPoiIdDelete(requestParameters: DeletePoiApiV1PoisPoiIdDeleteRequest): Promise<void> {
        await this.deletePoiApiV1PoisPoiIdDeleteRaw(requestParameters);
    }

    /**
     * Get Pois
     */
    async getPoisApiV1PoisGetRaw(requestParameters: GetPoisApiV1PoisGetRequest): Promise<runtime.ApiResponse<Array<PoiBase>>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.poiName !== undefined) {
            queryParameters['poiName'] = requestParameters.poiName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PoiBaseFromJSON));
    }

    /**
     * Get Pois
     */
    async getPoisApiV1PoisGet(requestParameters: GetPoisApiV1PoisGetRequest): Promise<Array<PoiBase>> {
        const response = await this.getPoisApiV1PoisGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Pois
     */
    async postPoisApiV1PoisPostRaw(requestParameters: PostPoisApiV1PoisPostRequest): Promise<runtime.ApiResponse<PoiOutAddressAndCoordinates>> {
        if (requestParameters.poiIn === null || requestParameters.poiIn === undefined) {
            throw new runtime.RequiredError('poiIn','Required parameter requestParameters.poiIn was null or undefined when calling postPoisApiV1PoisPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PoiInToJSON(requestParameters.poiIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiOutAddressAndCoordinatesFromJSON(jsonValue));
    }

    /**
     * Post Pois
     */
    async postPoisApiV1PoisPost(requestParameters: PostPoisApiV1PoisPostRequest): Promise<PoiOutAddressAndCoordinates> {
        const response = await this.postPoisApiV1PoisPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Poi Address
     */
    async putPoiAddressApiV1PoisPoiIdAddressPutRaw(requestParameters: PutPoiAddressApiV1PoisPoiIdAddressPutRequest): Promise<runtime.ApiResponse<PoiOutAddressAndCoordinates>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling putPoiAddressApiV1PoisPoiIdAddressPut.');
        }

        if (requestParameters.poiInAddress === null || requestParameters.poiInAddress === undefined) {
            throw new runtime.RequiredError('poiInAddress','Required parameter requestParameters.poiInAddress was null or undefined when calling putPoiAddressApiV1PoisPoiIdAddressPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/{poiId}/address`.replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoiInAddressToJSON(requestParameters.poiInAddress),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiOutAddressAndCoordinatesFromJSON(jsonValue));
    }

    /**
     * Put Poi Address
     */
    async putPoiAddressApiV1PoisPoiIdAddressPut(requestParameters: PutPoiAddressApiV1PoisPoiIdAddressPutRequest): Promise<PoiOutAddressAndCoordinates> {
        const response = await this.putPoiAddressApiV1PoisPoiIdAddressPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Poi Category
     */
    async putPoiCategoryApiV1PoisPoiIdCategoryPutRaw(requestParameters: PutPoiCategoryApiV1PoisPoiIdCategoryPutRequest): Promise<runtime.ApiResponse<PoiOutCategory>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling putPoiCategoryApiV1PoisPoiIdCategoryPut.');
        }

        if (requestParameters.poiInCategory === null || requestParameters.poiInCategory === undefined) {
            throw new runtime.RequiredError('poiInCategory','Required parameter requestParameters.poiInCategory was null or undefined when calling putPoiCategoryApiV1PoisPoiIdCategoryPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/{poiId}/category`.replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoiInCategoryToJSON(requestParameters.poiInCategory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiOutCategoryFromJSON(jsonValue));
    }

    /**
     * Put Poi Category
     */
    async putPoiCategoryApiV1PoisPoiIdCategoryPut(requestParameters: PutPoiCategoryApiV1PoisPoiIdCategoryPutRequest): Promise<PoiOutCategory> {
        const response = await this.putPoiCategoryApiV1PoisPoiIdCategoryPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Poi Coordinates
     */
    async putPoiCoordinatesApiV1PoisPoiIdCoordinatesPutRaw(requestParameters: PutPoiCoordinatesApiV1PoisPoiIdCoordinatesPutRequest): Promise<runtime.ApiResponse<PoiOutAddressAndCoordinates>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling putPoiCoordinatesApiV1PoisPoiIdCoordinatesPut.');
        }

        if (requestParameters.poiInCoordinates === null || requestParameters.poiInCoordinates === undefined) {
            throw new runtime.RequiredError('poiInCoordinates','Required parameter requestParameters.poiInCoordinates was null or undefined when calling putPoiCoordinatesApiV1PoisPoiIdCoordinatesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/{poiId}/coordinates`.replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoiInCoordinatesToJSON(requestParameters.poiInCoordinates),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiOutAddressAndCoordinatesFromJSON(jsonValue));
    }

    /**
     * Put Poi Coordinates
     */
    async putPoiCoordinatesApiV1PoisPoiIdCoordinatesPut(requestParameters: PutPoiCoordinatesApiV1PoisPoiIdCoordinatesPutRequest): Promise<PoiOutAddressAndCoordinates> {
        const response = await this.putPoiCoordinatesApiV1PoisPoiIdCoordinatesPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Poi Geography
     */
    async putPoiGeographyApiV1PoisPoiIdGeographyPutRaw(requestParameters: PutPoiGeographyApiV1PoisPoiIdGeographyPutRequest): Promise<runtime.ApiResponse<PoiOutGeo>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling putPoiGeographyApiV1PoisPoiIdGeographyPut.');
        }

        if (requestParameters.poiInGeo === null || requestParameters.poiInGeo === undefined) {
            throw new runtime.RequiredError('poiInGeo','Required parameter requestParameters.poiInGeo was null or undefined when calling putPoiGeographyApiV1PoisPoiIdGeographyPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/{poiId}/geography`.replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoiInGeoToJSON(requestParameters.poiInGeo),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiOutGeoFromJSON(jsonValue));
    }

    /**
     * Put Poi Geography
     */
    async putPoiGeographyApiV1PoisPoiIdGeographyPut(requestParameters: PutPoiGeographyApiV1PoisPoiIdGeographyPutRequest): Promise<PoiOutGeo> {
        const response = await this.putPoiGeographyApiV1PoisPoiIdGeographyPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Poi Name
     */
    async putPoiNameApiV1PoisPoiIdNamePutRaw(requestParameters: PutPoiNameApiV1PoisPoiIdNamePutRequest): Promise<runtime.ApiResponse<PoiOutName>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling putPoiNameApiV1PoisPoiIdNamePut.');
        }

        if (requestParameters.poiInName === null || requestParameters.poiInName === undefined) {
            throw new runtime.RequiredError('poiInName','Required parameter requestParameters.poiInName was null or undefined when calling putPoiNameApiV1PoisPoiIdNamePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/pois/{poiId}/name`.replace(`{${"poiId"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoiInNameToJSON(requestParameters.poiInName),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiOutNameFromJSON(jsonValue));
    }

    /**
     * Put Poi Name
     */
    async putPoiNameApiV1PoisPoiIdNamePut(requestParameters: PutPoiNameApiV1PoisPoiIdNamePutRequest): Promise<PoiOutName> {
        const response = await this.putPoiNameApiV1PoisPoiIdNamePutRaw(requestParameters);
        return await response.value();
    }

}
