import React, { useCallback } from "react";
import { FastField, FieldProps } from "formik";

import { EditableContentInput } from "@components/form/editable-content-input";
import { Loader } from "@components/loader";
import { usePermissions } from "@hooks/use-permission";

import { HandleAttributeUpdate } from "./types";

export interface AttributeValueProps {
  groupKey: string;
  attributeId: number;
  code: string;
  index: number;
  onUpdate: HandleAttributeUpdate;
  currentlyLoading?: number;
}

export const AttributeValue: React.FC<AttributeValueProps> = ({
  attributeId,
  index,
  onUpdate,
  currentlyLoading,
  groupKey,
}) => {
  const handleBlur = useCallback(
    (valueOriginal: string) => onUpdate(attributeId, { valueOriginal }, index, groupKey),
    [onUpdate, attributeId, index, groupKey]
  );
  const { hasPermission } = usePermissions();

  const isLoading = currentlyLoading === attributeId;

  if (isLoading) return <Loader size="small" noMargin justifyContent="flex-end" />;

  return (
    <FastField name={`customAttributes[${groupKey}][${index}].valueOriginal`}>
      {(fieldProps: FieldProps) => (
        <EditableContentInput
          edibable={hasPermission("PUT_OFFER_ATTRIBUTE_VALUE")}
          onBlur={handleBlur}
          {...fieldProps}
        />
      )}
    </FastField>
  );
};

