import {
  all,
  put,
  AllEffect,
  ForkEffect,
  select,
  call,
  takeLatest,
  debounce,
  StrictEffect,
} from "redux-saga/effects";

import { poiApi } from "@api/core";
import { PoiBase } from "@api/client";
import { AppState } from "@state/store";

import * as actions from "./actions";

const pageSize = 25;

function* fetchPoi(): Generator<StrictEffect, any, PoiBase[]> {
  try {
    const response = yield call(poiApi.getPoisApiV1PoisGet.bind(poiApi), {
      pageNumber: 1,
      pageSize,
    });

    if (pageSize + 1 === response.length) {
      yield put(actions.thereAreMorePages());
      response.pop();
    } else {
      yield put(actions.noMorePages());
    }

    yield put(actions.setPois.success(response));
    yield put(actions.setPageNumber(1));
  } catch (error) {
    console.log(error);
  }
}

function* fetchMorePois(): Generator<StrictEffect, any, any> {
  const poi = yield select((state: AppState) => state.poi);
  const pageNumber = poi.page + 1;

  try {
    const response = yield call(poiApi.getPoisApiV1PoisGet.bind(poiApi), {
      pageNumber,
      pageSize,
    });

    if (pageSize + 1 === response.length) {
      yield put(actions.thereAreMorePages());
      response.pop();
    } else {
      yield put(actions.noMorePages());
    }

    yield put(actions.setMorePois.success(response));
    yield put(actions.setPageNumber(pageNumber));
  } catch (error) {
    console.log(error);
  }
}

export default function* (): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    debounce(300, actions.setPois.request, fetchPoi),
    takeLatest(actions.setMorePois.request, fetchMorePois),
  ]);
}
