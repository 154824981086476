import * as S from "@components/map/map.styled";
import React from "react";
import { MapProps } from "@components/map/map";
import { Loader } from "@components/loader";

const MapBase = React.lazy(() => import(/* webpackChunkName: "map" */ "@components/map/map"));

export const MapLazy = (props: MapProps & S.MapWrapperStyle) => {
  return (
    <React.Suspense fallback={<Loader />}>
      <MapBase {...props} />
    </React.Suspense>
  );
};
