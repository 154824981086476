import React from "react";
import { useSelector } from "react-redux";
import { ModalProps } from "antd";
import { Module } from "@state/types";

import { NewsletterPropertyBreakdownReportShared } from "../newsletter-property-breakdown-report-shared";
import { Property } from "./types";

type Props = ModalProps & { properties: Property[] };
type Modules = "office" | "industrial";

const options: Record<
  Modules | string,
  {
    heading: string;
    method: "getNewsletterApiV1OffersNewsletterPut" | "getIndustrialListApiV1OffersIndustrialListENGPut";
  }
> = {
  office: { heading: "Generate Newsletter", method: "getNewsletterApiV1OffersNewsletterPut" },
  industrial: {
    heading: "Generate Properties breakdown report English",
    method: "getIndustrialListApiV1OffersIndustrialListENGPut",
  },
};

export const GenerateNewsletter = (props: Props) => {
  const currentModule = useSelector((appState) => appState.offers.currentModule);
  const moduleOptions = options[currentModule];

  return (
    <NewsletterPropertyBreakdownReportShared
      heading={moduleOptions.heading}
      method={moduleOptions.method}
      isNewsletter
      {...props}
    />
  );
};
