import Nprogress from "nprogress";
import {
  AttributeApi,
  Configuration,
  ConfigurationParameters,
  DictApi,
  GeographyApi,
  Middleware,
  OfferApi,
  PropertyApi,
  RequestContext,
  POIApi,
  LettingsApi,
  TenantsApi,
  ContactsApi,
  ClientApi,
} from "./client";
import { getEnv } from "@utils/get-env";
import { Providers } from "@microsoft/mgt-element";
import Axios from "axios";

export const getToken = async () => {
  const tokenId = await Providers.globalProvider.getAccessToken({
    scopes: [`${getEnv("REACT_APP_CLIENT_ID_API")}/.default`],
  });
  return `Bearer ${tokenId}`;
};

Axios.interceptors.request.use(async function (config) {
  const token = await getToken();
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token;

  return config;
});

export const middlewares: Middleware[] = [
  {
    pre: ({ init, url }: RequestContext) => {
      Nprogress.start();
      return Promise.resolve({
        init,
        url,
      });
    },
    post: ({ response }) => {
      return Promise.resolve(response).finally(() => {
        Nprogress.done();
      });
    },
  },
  {
    pre: async ({ init, url }: RequestContext) => {
      const tokenId = await getToken();
      const newInit = { ...init, headers: { ...init.headers, Authorization: tokenId } };
      return Promise.resolve({
        init: newInit,
        url,
      });
    },
  },
];

const configuration: ConfigurationParameters = {
  basePath: getEnv("REACT_APP_API_URL") || window.location.origin,
  middleware: middlewares,
  headers: {
    Accept: "application/json",
  },
};

export const config = new Configuration(configuration);
export const noAuthConfig = new Configuration({
  ...configuration,
  middleware: [middlewares[0]],
});

export const configForExcelDownload = new Configuration({
  basePath: getEnv("REACT_APP_API_URL") || window.location.origin,
  middleware: middlewares,
  headers: {
    Accept: "application/vnd.ms-excel",
  },
});

export const attributeApi = new AttributeApi(config);
export const dictApi = new DictApi(config);
export const geographyApi = new GeographyApi(config);
export const offerApi = new OfferApi(config);
export const offerApiForExcel = new OfferApi(configForExcelDownload);
export const propertyApi = new PropertyApi(config);
export const poiApi = new POIApi(config);
export const lettingsApi = new LettingsApi(config);
export const tenantApi = new TenantsApi(config);
export const contactsApi = new ContactsApi(config);
export const clientApi = new ClientApi(config);
export const clientApiWithoutAuth = new ClientApi(noAuthConfig);

export const getOfferApi = (configParams: Partial<ConfigurationParameters> = {}) => {
  const abortController = new AbortController();
  const fetchApi = (input: RequestInfo, init?: RequestInit) => {
    return typeof input === "string"
      ? fetch(input, { ...init, signal: abortController.signal })
      : fetch({ ...input, signal: abortController.signal }, init);
  };
  const offerApi = new OfferApi(new Configuration({ ...configuration, ...configParams, fetchApi }));

  return {
    offerApi,
    abortController,
  };
};
