import styled from "styled-components";

export interface LoaderWrapperStyles {
  noMargin?: boolean;
  justifyContent?: "center" | "flex-start" | "flex-end";
}

export const Container = styled.div<LoaderWrapperStyles>`
  margin: ${({ noMargin }) => (noMargin ? 0 : "10px")};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
`;
