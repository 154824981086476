import { Radio } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RadioChangeEvent } from "antd/lib/radio/interface";

interface Props {
  currentGroup: string;
  onChangeUnit: (param: string) => void;
}

export const UnitSelection: React.FC<Props> = ({ currentGroup, onChangeUnit }) => {
  const distinctUnits = useSelector((state) => state.attributes.distinctUnits);
  const values = distinctUnits[currentGroup];
  const valueUnitFromState = useSelector((state) => state.attributes.customFilter.valueUnit);

  const handleChange = (event: RadioChangeEvent) => {
    onChangeUnit(event.target.value);
  };

  return (
    <Radio.Group onChange={handleChange} value={valueUnitFromState}>
      {values?.map((x) => (
        <Radio value={x} key={x}>
          {x}
        </Radio>
      ))}
    </Radio.Group>
  );
};
