import styled from "styled-components";
import { buttonActive, StyledButton } from "@components/form/button/button.styled";

interface StyleProps {
  isActive: boolean;
}

export const SearchTypesButtonsContainer = styled.div`
  text-align-last: end;
  padding-bottom: 20px;
`;

export const ButtonToChangeSearchType = styled(StyledButton)<StyleProps>`
  ${(props) => props.isActive && buttonActive}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 200px;
`;

export const RowStringAndFrequency = styled.div`
  display: flex;
  justify-content: space-between;
`;
