import {
  all,
  put,
  AllEffect,
  ForkEffect,
  debounce,
  select,
  call,
  takeLatest,
  StrictEffect,
} from "redux-saga/effects";
import { propertyApi } from "@api/core";
import { GetPropertiesApiV1PropertiesGetRequest } from "@api/client";
import { AppState } from "@state/store";

import { actions } from "./actions";

const getProperties = (props: GetPropertiesApiV1PropertiesGetRequest) =>
  propertyApi.getPropertiesApiV1PropertiesGet(props);

const pageSize = 25;

function* fetchProperties(action: any): Generator<StrictEffect, any, any> {
  yield put(actions.setLoading());
  const propertyCategoryCode = yield select(
    (state: AppState) => state.filters[state.filters.currentModule].data.propertyCategoryCode
  );

  try {
    const response = yield call(getProperties, {
      propertyCategoryCode,
      propertyNameOrAddress: action.payload,
      pageNumber: 1,
      pageSize,
    });

    if (pageSize + 1 === response.length) {
      yield put(actions.thereAreMorePages());
      response.pop();
    } else {
      yield put(actions.noMorePages());
    }

    yield put(actions.setProperties.success(response));
    yield put(actions.setPageNumber(1));
  } catch (error) {
    console.log(error);
  }
}

function* fetchMoreProperties(action: any): Generator<StrictEffect, any, any> {
  yield put(actions.setLoading());
  const propertyCategoryCode = yield select(
    (state: AppState) => state.filters[state.filters.currentModule].data.propertyCategoryCode
  );
  const propertyState = yield select((state: AppState) => state.properties);
  const pageNumber = propertyState.page + 1;

  try {
    const response = yield call(getProperties, {
      propertyCategoryCode,
      propertyNameOrAddress: action.payload,
      pageNumber,
      pageSize,
    });

    if (pageSize + 1 === response.length) {
      yield put(actions.thereAreMorePages());
      response.pop();
    } else {
      yield put(actions.noMorePages());
    }

    yield put(actions.loadMoreProperties.success(response));
    yield put(actions.setPageNumber(pageNumber));
  } catch (error) {
    console.log(error);
  }
}

export default function* (): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    debounce(300, actions.setProperties.request, fetchProperties),
    takeLatest(actions.loadMoreProperties.request, fetchMoreProperties),
  ]);
}
