import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const StyledRadioboxIcon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 12px;
  height: 46px;
  border-radius: 2px;
  background: linear-gradient(#ffffff, #e9ebed, #eceef0);
  position: relative;
  border: 1px solid ${(props) => props.theme.grey};

  &:first-of-type {
    display: none;
  }

  &:after {
    background: ${(props) => props.theme.primaryColor};
    bottom: 0;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    transition: 0.2s ease-in-out;
    width: 100%;
  }
`;

export const StyledRadioboxIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  &:not(:last-child) ${StyledRadioboxIcon} {
    border-right: 0;
    &:before {
      content: "";
      width: 1px;
      height: 70%;
      background: ${(props) => props.theme.grey};
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  &:not(:first-child) ${StyledRadioboxIcon} {
    border-left: 0;
  }
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledLabel = styled.label`
  text-align: center;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.primaryColor};

    svg {
      fill: ${(props) => props.theme.primaryColor};
    }

    ${StyledRadioboxIcon}:first-of-type {
      display: flex;
    }

    ${StyledRadioboxIcon}:nth-of-type(2) {
      display: none;
    }
  }
`;

export const Option = styled.div<{ selected: boolean }>`
  text-align: center;

  ${({ selected }) =>
    selected &&
    css`
      ${StyledLabel} {
        color: ${(props) => props.theme.primaryColor};

        svg {
          fill: ${(props) => props.theme.primaryColor};
        }
      }

      ${StyledRadioboxIcon}:first-of-type {
        display: flex;
      }

      ${StyledRadioboxIcon}:nth-of-type(2) {
        display: none;
      }

      ${StyledRadioboxIcon}:after {
        height: 2px;
      }
    `}
`;

export const LabelText = styled.span`
  font-size: 12px;
  padding: 3px 0;
  line-height: 1;
`;
