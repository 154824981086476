import React, { CSSProperties } from "react";
import styled from "styled-components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { OffersBase } from "@api/client";

import * as S from "@components/table";
import { Link } from "@components/link";

import { offersId } from "@router/paths";

import { mainTableMixin, Checkbox } from "./styled";
import { ModificationDate } from "./modification-date";

interface TableRowProps extends OffersBase {
  style?: CSSProperties;
  onSelect: (e: CheckboxChangeEvent) => void;
  ids: number[];
}

const StyledTCell = styled(S.TableCell)`
  ${mainTableMixin}
  color: ${({ theme }) => theme.greyBlue};
  font-variant-numeric: tabular-nums;
`;

export const TableRow: React.FC<TableRowProps> = ({
  offerName,
  offerStatus,
  propertyGeography,
  propertyAddress,
  propertyName,
  offerBaseRent,
  offerServiceCharge,
  offerLastModificationDate,
  style,
  offerId: id,
  onSelect,
  ids,
}) => (
  <S.TableRow style={style}>
    <StyledTCell>
      <Checkbox checked={ids.includes(id)} onChange={onSelect} name={propertyName} value={id} />
    </StyledTCell>
    <StyledTCell>
      <Link to={offersId(id)}>{offerName}</Link>
    </StyledTCell>
    <StyledTCell>{offerStatus}</StyledTCell>
    <StyledTCell>{propertyGeography?.name}</StyledTCell>
    <StyledTCell>{propertyAddress}</StyledTCell>
    <StyledTCell textAlign="right">{offerBaseRent}</StyledTCell>
    <StyledTCell textAlign="right">{offerServiceCharge}</StyledTCell>
    <StyledTCell textAlign="right">
      <ModificationDate date={offerLastModificationDate} />
    </StyledTCell>
  </S.TableRow>
);
