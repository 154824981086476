import styled, { css } from "styled-components";
import { Sider as SiderCore, Content as ContentCore } from "@components/layout";
import { HeadingSmall } from "@components/heading";

const mixin = css`
  border: 1px solid ${({ theme }) => theme.grey};
  background-color: ${({ theme }) => theme.white};
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;
`;

export const Container = styled.main`
  ${mixin}
`;

export const MapPictureWrapper = styled.div`
  ${mixin}
  margin-top: 32px;
`;

export const MapWrapper = styled.div`
  height: 400px;
`;

export const Content = styled(ContentCore)`
  display: grid;
  grid-template-columns: 60fr 40fr;
  grid-column-gap: 20px;
`;

export const Sider = styled(SiderCore)`
  grid-column: 2 / 3;
`;

export const TopContent = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.grey};
  padding: 20px 10px 15px;
  display: flex;
  justify-content: space-between;
`;

export const TopContentTitle = styled.div`
  width: 60%;
`;

export const StickyPoiMapContainer = styled.div`
  height: 500px;
  position: sticky;
  top: 20px;
`;

export const Heading = HeadingSmall;

export const PropertyParticular = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

