import { createReducer } from "typesafe-actions";
import {
  setAllFilters,
  setPropertyCategoryCode,
  setPropertyStatusCode,
  setOfferStatusCode,
  setPropertyNameOrAddress,
  setPropertyGeographyIds,
  addCustomFilter,
  removeCustomFilter,
  changeCustomFilter,
  setCustomFilterRequest,
  setRefreshFilters,
  setCurrentModule,
} from "./actions";
import { FilterState } from "./types";

const initialState: FilterState = {
  currentModule: "office",
  office: {
    data: {
      propertyCategoryCode: "OFF",
      propertyStatusCode: ["I", "P", "W"],
      offerStatusCode: ["A"],
      propertyNameOrAddress: "",
      propertyGeographyIds: [],
      customAttributeFilter: {},
      refreshFilters: false,
    },
    usedCustomFilters: [],
  },
  industrial: {
    data: {
      propertyCategoryCode: "WRH",
      propertyStatusCode: ["I", "P", "W"],
      offerStatusCode: ["A"],
      propertyNameOrAddress: "",
      propertyGeographyIds: [],
      customAttributeFilter: {},
      refreshFilters: false,
    },
    usedCustomFilters: [],
  },
  retail: {
    data: {
      propertyCategoryCode: "COM",
      propertyStatusCode: ["I", "P", "W"],
      offerStatusCode: ["A"],
      propertyNameOrAddress: "",
      propertyGeographyIds: [],
      customAttributeFilter: {},
      refreshFilters: false,
    },
    usedCustomFilters: [],
  },
};

type FilterAction = {
  type: string;
};

export const filtersReducer = createReducer<FilterState, FilterAction>(initialState)
  .handleAction(setAllFilters, (state, action) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      data: action.payload,
    },
  }))
  .handleAction(setPropertyCategoryCode, (state, action) => ({
    ...state,
    [state.currentModule]: {
      data: {
        ...state[state.currentModule].data,
        propertyCategoryCode: action.payload,
      },
    },
  }))
  .handleAction(setPropertyStatusCode, (state, action) => ({
    ...state,
    [state.currentModule]: {
      data: {
        ...state[state.currentModule].data,
        propertyStatusCode: action.payload,
      },
    },
  }))
  .handleAction(setOfferStatusCode, (state, action) => ({
    ...state,
    [state.currentModule]: {
      data: {
        ...state[state.currentModule].data,
        offerStatusCode: action.payload,
      },
    },
  }))
  .handleAction(setPropertyNameOrAddress, (state, action) => ({
    ...state,
    [state.currentModule]: {
      data: {
        ...state[state.currentModule].data,
        propertyNameOrAddress: action.payload,
      },
    },
  }))
  .handleAction(setPropertyGeographyIds, (state, action) => ({
    ...state,
    [state.currentModule]: {
      data: {
        ...state[state.currentModule].data,
        propertyGeographyIds: action.payload,
      },
    },
  }))
  .handleAction(addCustomFilter, (state, action) => {
    return {
      ...state,
      [state.currentModule]: {
        ...state[state.currentModule],
        usedCustomFilters: [
          ...state[state.currentModule].usedCustomFilters,
          {
            code: action.payload.code,
            name: action.payload.name,
            dataTypeCode: action.payload.dataTypeCode,
            value: action.payload.value,
            unitValue: action.payload.unitValue,
            searchType: action.payload.searchType,
            floors: action.payload.floors,
            modules: action.payload.modules,
          },
        ],
      },
    };
  })
  .handleAction(changeCustomFilter, (state, action) => {
    const filterIndex = state[state.currentModule].usedCustomFilters.findIndex(
      (x) => x.code === action.payload.code
    );
    const newList = [...state[state.currentModule].usedCustomFilters];
    newList[filterIndex] = {
      code: action.payload.code,
      name: action.payload.name,
      dataTypeCode: action.payload.dataTypeCode,
      value: action.payload.value,
      unitValue: action.payload.unitValue,
      searchType: action.payload.searchType,
      floors: action.payload.floors,
      modules: action.payload.modules,
    };
    return {
      ...state,
      [state.currentModule]: {
        ...state[state.currentModule],
        usedCustomFilters: newList,
      },
    };
  })
  .handleAction(removeCustomFilter, (state, action) => {
    if (action.payload === "removeAll") {
      return {
        ...state,
        [state.currentModule]: {
          ...state[state.currentModule],
          usedCustomFilters: [],
        },
      };
    }
    return {
      ...state,
      [state.currentModule]: {
        ...state[state.currentModule],
        usedCustomFilters: state[state.currentModule].usedCustomFilters.filter(
          (x) => x.code !== action.payload
        ),
      },
    };
  })
  .handleAction(setCustomFilterRequest, (state) => {
    const customAttributeFilter = state[state.currentModule].usedCustomFilters.reduce((prev, curr) => {
      let newObj = {};
      if (curr.dataTypeCode === "string" || curr.dataTypeCode === "bit") {
        newObj = {
          ...newObj,
          [`offers[${curr.code}]`]: curr.value ?? "distribution",
          [`${curr.code}-Mode`]: curr.searchType,
          // [`floors[${curr.code}]`]: curr.floors,
          // [`modules[${curr.code}]`]: curr.modules,
        };
      } else if (curr.dataTypeCode === "numeric") {
        newObj = {
          ...newObj,
          [`offers[${curr.code}]`]: curr.unitValue ? `${curr.value}~${curr.unitValue}` : `${curr.value}`,
          [`floors[${curr.code}]`]: curr.floors,
          [`modules[${curr.code}]`]: curr.modules,
        };
      } /* This will be needed when bussinse decide to treat bit differently then string
      else if (curr.dataTypeCode === "bit") {
        newObj = { ...newObj, [curr.code]: `${curr.value}` };
      } */
      return { ...prev, ...newObj };
    }, {});
    return {
      ...state,
      [state.currentModule]: {
        ...state[state.currentModule],
        data: { ...state[state.currentModule].data, customAttributeFilter },
      },
    };
  })
  .handleAction(setRefreshFilters, (state, action) => {
    return {
      ...state,
      [state.currentModule]: {
        ...state[state.currentModule],
        data: {
          ...state[state.currentModule].data,
          refreshFilters: action.payload,
        },
      },
    };
  })
  .handleAction(setCurrentModule, (state, action) => {
    return {
      ...state,
      currentModule: action.payload,
    };
  });
