import React from "react";
import { Route, Switch } from "react-router";
import { Layout } from "@components/layout";
import { offerContactsId, offersId, offerTransactionsId, auditTrailId } from "@router/paths";
import { List } from "@pages/shared-module/list/list";
import { EntryDetailTransactionContact } from "@pages/detail-transaction-contact";

export const RetailModule: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <Route
          path={[offerContactsId(), offerTransactionsId(), offersId(), auditTrailId()]}
          component={EntryDetailTransactionContact}
        />

        <Route path="/*" exact component={List} />
      </Switch>
    </Layout>
  );
};
