/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferOut
 */
export interface OfferOut {
    /**
     * 
     * @type {number}
     * @memberof OfferOut
     */
    offerId: number;
    /**
     * 
     * @type {Date}
     * @memberof OfferOut
     */
    modificationDate: Date;
}

export function OfferOutFromJSON(json: any): OfferOut {
    return OfferOutFromJSONTyped(json, false);
}

export function OfferOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offerId': json['offerId'],
        'modificationDate': (new Date(json['modificationDate'])),
    };
}

export function OfferOutToJSON(value?: OfferOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offerId': value.offerId,
        'modificationDate': (value.modificationDate.toISOString()),
    };
}


