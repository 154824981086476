import React from "react";
import { CustomLettingAttributeOut } from "@api/client";
import { AttributeWithFlors } from "../attribute-with-floors/attribute-with-floors";

interface Props {
  lettingId: number;
  attributeList: CustomLettingAttributeOut[];
  refetchListOfAttributes: () => void;
}

export const AttributesList: React.FC<Props> = ({ lettingId, attributeList, refetchListOfAttributes }) => {
  return (
    <div>
      {attributeList?.map((attribute) => (
        <AttributeWithFlors
          attribute={attribute}
          lettingId={lettingId}
          refetchListOfAttributes={refetchListOfAttributes}
        />
      ))}
    </div>
  );
};
