import { createAction } from "typesafe-actions";
import { Property } from "@pages/shared-module/main-table/types";

const SWITCH_IS_PICKING_FROM_MAIN_TABLE_NEWSLETTER =
  "newsletter/SWITCH_IS_PICKING_FROM_MAIN_TABLE_NEWSLETTER";
const SWITCH_IS_PICKING_FROM_MAIN_TABLE_GENERATE_BREAKDOWN_REPORT =
  "newsletter/SWITCH_IS_PICKING_FROM_MAIN_TABLE_GENERATE_BREAKDOWN_REPORT";
const TURN_OFF_PICKING = "newsletter/TURN_OFF_PICKING";
const ADD_TO_GROUP = "newsletter/ADD_TO_GROUP";
const TURN_ON_MODAL = "newsletter/TURN_ON_MODAL";

export const togglePickingNewsletter = createAction(SWITCH_IS_PICKING_FROM_MAIN_TABLE_NEWSLETTER)<string>();
export const togglePickingGenerateBreakdownReport = createAction(
  SWITCH_IS_PICKING_FROM_MAIN_TABLE_GENERATE_BREAKDOWN_REPORT
)<string>();
export const turnOffPicking = createAction(TURN_OFF_PICKING)();
export const addToGroup = createAction(ADD_TO_GROUP)<Property>();
export const turnOnModal = createAction(TURN_ON_MODAL)();
