import { FloorsValueAttribute } from "@api/client";
import { Collapse } from "antd";
import React from "react";
import { AddModuleValueForm } from "../add-module-value-form";
import { FloorHeader } from "./floors-header";
import { ModuleElement } from "./modules";

interface FloorsCollapseProps {
  key: string | number;
  floor: FloorsValueAttribute;
}

const { Panel } = Collapse;

export const FloorPanel: React.FC<FloorsCollapseProps> = ({ key, floor }) => {
  const floorRange = `${floor.floorsFrom}-${floor.floorsTo}`;
  const value = floor.valueOriginal || "";

  return (
    <Collapse>
      <Panel key={key} header={FloorHeader({ floorId: floor.attributeId, floorRange, value })}>
        {floor.modules?.map((module) => (
          <ModuleElement
            moduleId={module.attributeId}
            moduleNumber={String(module.module)}
            value={String(module.valueOriginal)}
          />
        ))}
        <AddModuleValueForm attributeId={floor.attributeId} />
      </Panel>
    </Collapse>
  );
};
