import styled from "styled-components";
import { Link as LinkCore } from "@components/link";
import { Item as ItemCore } from "@components/breadcrumb";

export const Wrapper = styled.div`
  padding: 28px 0 8px;
  display: flex;
  justify-content: space-between;
`;

export const Item = styled(ItemCore)`
  font-size: 15px;
`;

export const Link = styled(LinkCore)`
  font-size: 15px;
`;
