import React from "react";
import { Route, Switch } from "react-router";
import { Layout } from "@components/layout";
import { offerContactsId, offersId, offerTransactionsId, auditTrailId } from "@router/paths";
import { EntryDetailTransactionContact } from "@pages/detail-transaction-contact";
import { List } from "@pages/shared-module/list/list";

export const IndustrialModule: React.FC = () => (
  <Layout>
    <Switch>
      <Route
        path={[offerContactsId(), offerTransactionsId(), offersId(), auditTrailId()]}
        component={EntryDetailTransactionContact}
      />
      <Route path="/*" exact component={List} />
    </Switch>
  </Layout>
);
