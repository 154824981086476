import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { actions } from "@state/properties";

import { InitialFormType } from "./formik";

export const AutoCompleteHelper = () => {
  const formik = useFormikContext<InitialFormType>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setProperties.request(formik.values.findProperty));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.findProperty]);

  return null;
};
