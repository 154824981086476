import { createReducer } from "typesafe-actions";
import _ from "lodash";
import { OfferDetails, CustomAttributeOut, PoiProperty, GeoBase } from "@api/client";
import { capitalize } from "@utils/stringUtils";
import * as actions from "./actions";
import { OffersState } from "./types";

const initialState: OffersState = {
  currentModule: "office",
  office: {
    offers: {
      data: [],
      loading: false,
      totalOffers: 0,
    },
    pageSettings: {
      pageNumber: 1,
      pageSize: 100,
    },
    error: undefined,
  },
  industrial: {
    offers: {
      data: [],
      loading: false,
      totalOffers: 0,
    },
    pageSettings: {
      pageNumber: 1,
      pageSize: 100,
    },
    error: undefined,
  },
  retail: {
    offers: {
      data: [],
      loading: false,
      totalOffers: 0,
    },
    pageSettings: {
      pageNumber: 1,
      pageSize: 100,
    },
    error: undefined,
  },
  
  offerDetails: {
    loading: false,
    data: null,
    predefinedAttribute: [],
  },
  suggestedPoi: {
    loading: false,
    data: [] as PoiProperty[],
  },
  attributeLoading: undefined,
  attributeDeleting: undefined,
  attributeNoteUpdating: undefined,
  attributeError: new Set(),
  quickformAttributeAdding: undefined,
};

type OffersAction = {
  type: string;
};

export const offersReducer = createReducer<OffersState, OffersAction>(initialState)
  .handleAction(actions.fetchOffersAction.request, (state) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      offers: {
        ...state[state.currentModule].offers,
        data: state[state.currentModule].offers.data,
        loading: true,
      },
    },
  }))
  .handleAction(actions.fetchOffersAction.success, (state, action) => {
    const { offerBase, resetData, emptyData } = action.payload;
    let data = [...state[state.currentModule].offers.data, ...offerBase];
    if (resetData) data = offerBase;
    if (emptyData) data = [];

    return {
      ...state,
      [state.currentModule]: {
        ...state[state.currentModule],
        offers: {
          ...state[state.currentModule].offers,
          data,
          loading: false,
        },
      },
    };
  })
  .handleAction(actions.fetchOffersAction.failure, (state) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      offers: {
        ...state[state.currentModule].offers,
        data: state[state.currentModule].offers.data,
        loading: false,
      },
    },
  }))
  .handleAction(actions.setPageNumber, (state, action) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      pageSettings: {
        ...state[state.currentModule].pageSettings,
        pageNumber: action.payload,
      },
    },
  }))
  .handleAction(actions.setOffersList, (state, action) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      offers: { ...state[state.currentModule].offers, data: action.payload },
    },
  }))
  .handleAction(actions.setTotalOffers.request, (state) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      offers: { ...state[state.currentModule].offers, loading: true },
    },
  }))
  .handleAction(actions.setTotalOffers.success, (state, action) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      offers: { ...state[state.currentModule].offers, loading: false, totalOffers: action.payload },
    },
  }))
  .handleAction(actions.setTotalOffers.failure, (state, action) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      offers: { ...state[state.currentModule].offers, loading: false },
    },
    [state.currentModule]: {
      ...state[state.currentModule],
      error: action.payload,
    },
  }))
  .handleAction(actions.resetData, (state) => {
    return {
      ...state,
      [state.currentModule]: {
        ...state[state.currentModule],
        offers: { ...state[state.currentModule].offers, data: [] },
      },
    };
  })
  .handleAction(actions.refreshData, (state) => ({
    ...state,
    [state.currentModule]: {
      ...state[state.currentModule],
      offers: { ...state[state.currentModule].offers, loading: true },
    },
  }))
  .handleAction(actions.fetchOfferDetails.request, (state) => ({
    ...state,
    offerDetails: { ...state.offerDetails, loading: true },
  }))
  .handleAction(actions.fetchOfferDetails.failure, (state) => ({
    ...state,
    offerDetails: { ...state.offerDetails, loading: false },
  }))
  .handleAction(actions.fetchOfferDetails.success, (state, action) => ({
    ...state,
    offerDetails: { ...state.offerDetails, loading: false, data: action.payload },
  }))
  .handleAction(actions.updateStatus.success, (state, action) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        offerStatus: action.payload.statusCode,
        offerLastModificationDate: action.payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.updateNote.success, (state, action) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        offerNote: action.payload.note,
        offerLastModificationDate: action.payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.updateTitle.success, (state, action) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        offerName: action.payload.name,
        offerLastModificationDate: action.payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.updateDescription.success, (state, action) => {
    const offerDescKey = `offer${capitalize(action.payload.type || "description")}`;

    return {
      ...state,
      offerDetails: {
        ...state.offerDetails,
        data: {
          ...(state.offerDetails.data as OfferDetails),
          [offerDescKey]: action.payload.description,
          offerLastModificationDate: action.payload.modificationDate,
        },
      },
    };
  })
  .handleAction(actions.updateAttribute.request, (state, action) => ({
    ...state,
    attributeLoading: action.payload.attributeId,
  }))
  .handleAction(actions.updateAttribute.success, (state, action) => {
    const newPartCustomAttributes = state.offerDetails.data?.customAttributes[action.payload.groupKey].map(
      (x) => {
        if (x.code === action.payload.code) {
          return { ...x, ...(action.payload as CustomAttributeOut) };
        }
        return x;
      }
    );

    return {
      ...state,
      offerDetails: {
        ...state.offerDetails,
        data: {
          ...(state.offerDetails.data as OfferDetails),
          customAttributes: {
            ...state.offerDetails.data?.customAttributes,
            [action.payload.groupKey]: newPartCustomAttributes ?? [],
          },
          quickformAttributes: {
            ...state.offerDetails.data!.quickformAttributes,
            [_.camelCase(action.payload.name)]: {
              id: action.payload.attributeId,
              label: action.payload.name,
              valueOriginal: null,
            },
          },
        },
      },
      attributeLoading: undefined,
      // attributeError: newAttributeError,
    };
  })
  .handleAction(actions.updateAttribute.failure, (state, action) => ({
    ...state,
    attributeLoading: undefined,
    attributeError: new Set(state.attributeError.add(action.payload)),
  }))
  .handleAction(actions.deleteAttribute.request, (state, action) => ({
    ...state,
    attributeDeleting: action.payload.attributeId,
  }))
  .handleAction(actions.deleteAttribute.success, (state, action) => {
    const { groupKey } = action.payload;
    const newPartcustomAttributes = state.offerDetails.data?.customAttributes[groupKey].filter(
      ({ attributeId }) => attributeId !== action.payload.attributeId
    );

    return {
      ...state,
      offerDetails: {
        ...state.offerDetails,
        data: {
          ...(state.offerDetails.data as OfferDetails),
          customAttributes: {
            ...state.offerDetails.data?.customAttributes,
            [groupKey]: newPartcustomAttributes ?? [],
          },
        },
      },
    };
  })
  .handleAction(actions.deleteAttribute.failure, (state) => ({ ...state, attributeDeleting: undefined }))
  .handleAction(actions.deleteQuickformFloor.request, (state) => ({
    ...state,
  }))
  .handleAction(actions.deleteQuickformFloor.success, (state) => ({
    ...state,
  }))
  .handleAction(actions.deleteQuickformFloor.failure, (state) => ({
    ...state,
  }))
  .handleAction(actions.deleteQuickformModule.request, (state) => ({
    ...state,
  }))
  .handleAction(actions.deleteQuickformModule.success, (state) => ({
    ...state,
  }))
  .handleAction(actions.deleteQuickformModule.failure, (state) => ({
    ...state,
  }))
  .handleAction(actions.addQuickformAttribute.request, (state, action) => ({
    ...state,
    quickformAttributeAdding: action.payload.name,
  }))
  .handleAction(actions.addQuickformAttribute.success, (state, { payload }) => {
    return {
      ...state,
      offerDetails: {
        ...state.offerDetails,
        data: {
          ...(state.offerDetails.data as OfferDetails),
          quickformAttributes: {
            ...state.offerDetails.data!.quickformAttributes,
            [_.camelCase(payload.name)]: {
              id: payload.attributeId,
              label: payload.name,
              valueOriginal: null,
            },
          },
        },
      },
    };
  })
  .handleAction(actions.addQuickformAttribute.failure, (state) => ({
    ...state,
    quickformAttributeAdding: undefined,
  }))
  .handleAction(actions.setAttributeNote.request, (state, action) => ({
    ...state,
    attributeNoteUpdating: action.payload,
  }))
  .handleAction(actions.setAttributeNote.success, (state) => ({
    ...state,
    attributeNoteUpdating: undefined,
  }))
  .handleAction(actions.setAttributeNote.failure, (state) => ({
    ...state,
    attributeNoteUpdating: undefined,
  }))
  .handleAction(actions.updatePropertyStatus.success, (state, { payload }) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        propertyStatus: payload.statusCode,
        offerLastModificationDate: payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.updatePropertyName.success, (state, { payload }) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        propertyName: payload.name,
        offerLastModificationDate: payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.updatePropertyGeography.success, (state, { payload }) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        propertyGeography: {
          ...((state.offerDetails.data as OfferDetails).propertyGeography as GeoBase),
          id: payload.geographyId,
        },
        offerLastModificationDate: payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.updatePropertyAddress.success, (state, { payload }) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        propertyAddress: payload.address || state.offerDetails.data?.propertyAddress,
        propertyGeoLatitude: payload.geoLatitude || state.offerDetails.data?.propertyGeoLatitude,
        propertyGeoLongitude: payload.geoLongitude || state.offerDetails.data?.propertyGeoLongitude,
        offerLastModificationDate: payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.updateCoordinates.success, (state, { payload }) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        propertyAddress: payload.address || state.offerDetails.data?.propertyAddress,
        propertyGeoLatitude: payload.geoLatitude || state.offerDetails.data?.propertyGeoLatitude,
        propertyGeoLongitude: payload.geoLongitude || state.offerDetails.data?.propertyGeoLongitude,
        offerLastModificationDate: payload.modificationDate,
      },
    },
  }))
  .handleAction(actions.addAttribute.request, (state) => ({
    ...state,
  }))
  .handleAction(actions.addAttribute.failure, (state) => ({
    ...state,
  }))
  .handleAction(actions.addAttribute.success, (state) => ({
    ...state,
  }))
  .handleAction(actions.setAttributes.request, (state) => ({
    ...state,
  }))
  .handleAction(actions.setAttributes.failure, (state) => ({
    ...state,
  }))
  .handleAction(actions.setAttributes.success, (state, action) => {
    if (!state.offerDetails.data) return { ...state };
    const { quickformAttributes, customAttributes } = action.payload;
    return {
      ...state,
      offerDetails: {
        ...state.offerDetails,
        data: {
          ...state.offerDetails.data,
          customAttributes: customAttributes,
          quickformAttributes: quickformAttributes,
        },
      },
    };
  })
  .handleAction(actions.getSuggestedPoi.request, (state) => ({
    ...state,
    suggestedPoi: {
      ...state.suggestedPoi,
      loading: true,
    },
  }))
  .handleAction(actions.getSuggestedPoi.request, (state) => ({
    ...state,
    suggestedPoi: {
      ...state.suggestedPoi,
      loading: false,
    },
  }))
  .handleAction(actions.getSuggestedPoi.success, (state, { payload }) => ({
    ...state,
    suggestedPoi: {
      ...state.suggestedPoi,
      data: payload,
      loading: false,
    },
  }))
  .handleAction(actions.clearDetails, (state) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: null,
    },
  }))
  .handleAction(actions.setPredefinedAttributes.request, (state) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      loading: true,
    },
  }))
  .handleAction(actions.setPredefinedAttributes.failure, (state) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      loading: false,
    },
  }))
  .handleAction(actions.setPredefinedAttributes.success, (state, action) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      predefinedAttribute: action.payload,
      loading: false,
    },
  }))
  .handleAction(actions.setAllowReddDownload, (state, action) => ({
    ...state,
    offerDetails: {
      ...state.offerDetails,
      data: {
        ...(state.offerDetails.data as OfferDetails),
        allowReddDownload: action.payload,
      },
    },
  }))
  .handleAction(actions.setCurrentModule, (state, action) => ({
    ...state,
    currentModule: action.payload,
  }));

