import React, { useEffect, useMemo } from "react";
import { SHOW_PARENT } from "@components/tree-select";
import { useDispatch, useSelector } from "react-redux";
import { loadGeography } from "@utils/geography";
import * as S from "./select-geography.styled";

type Props = {
  value: number[];
  onChange: (values: number[] | number) => void;
  multiple?: boolean;
  treeCheckable?: boolean;
  treeNodeLabelProp?: string;
  disabled?: boolean;
};

const treeDataSimpleMode = {
  id: "id",
  pId: "parentId",
};

const dropdownStyle = { maxHeight: 400, overflow: "auto" };

export const SelectGeography: React.FC<Props> = ({
  value,
  onChange,
  multiple,
  treeCheckable,
  treeNodeLabelProp,
  disabled,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    loadGeography(dispatch)();
  }, [dispatch]);

  const geographyData = useSelector((state) => state.geography.data);
  const data = useMemo(
    () =>
      geographyData.map((item) => ({
        ...item,
        title: item.name,
      })),
    [geographyData]
  );

  return (
    <div>
      <S.StyledTreeSelect
        allowClear
        dropdownStyle={dropdownStyle}
        onChange={onChange}
        placeholder="Select geography area"
        showCheckedStrategy={treeCheckable ? SHOW_PARENT : undefined}
        showSearch
        treeCheckable={treeCheckable}
        treeData={data}
        treeDataSimpleMode={treeDataSimpleMode}
        multiple={multiple}
        treeNodeFilterProp="title"
        treeNodeLabelProp={treeNodeLabelProp}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

SelectGeography.defaultProps = {
  multiple: true,
  treeCheckable: true,
  treeNodeLabelProp: "name",
};
