/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useMutation } from "react-query";
import { Popover } from "antd";
import { useToggle } from "@hooks/use-toggle";
import { Input } from "@components/input";
import { Button } from "@components/button";

import { contactsApi } from "@api/core";

import { emailPattern, telPattern } from "@utils/regexp-and-input-patterns";

import { AddButton, StyledLabel } from "./contact-group.styled";

interface AddContactFormProps {
  propertyId: number;
  onAdd: () => void;
  contactGroupName: string;
  contactTypeId: number;
  parentId?: number;
}

interface AddFormProps extends Omit<AddContactFormProps, "contactGroupName"> {
  onClose: () => void;
}

const nameLabelId = "name";
const emailLabelId = "email";
const phoneLabelId = "phone";
const typeCallback = (callback: React.Dispatch<React.SetStateAction<string>>) => (
  event: ChangeEvent<HTMLInputElement>
) => callback(event.target.value);

const AddForm: React.FC<AddFormProps> = ({ onClose, propertyId, onAdd, contactTypeId, parentId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { mutate, isLoading, error } = useMutation(
    () =>
      contactsApi.postContactApiV1ContactsPropertyPropertyIdPost({
        propertyId,
        contactIn: {
          name,
          email,
          phone,
          contactTypeId,
          parentId,
        },
      }),
    {
      onSuccess: () => {
        onAdd();
        onClose();
      },
    }
  );

  const handleNameChange = typeCallback(setName);
  const handleEmailChange = typeCallback(setEmail);
  const handlePhoneChange = typeCallback(setPhone);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    mutate();
  };

  if (error) return <>Something went wrong!</>;

  return (
    <form onSubmit={handleSubmit}>
      <StyledLabel htmlFor={nameLabelId}>Name:</StyledLabel>
      <Input
        id={nameLabelId}
        placeholder={nameLabelId}
        value={name}
        onChange={handleNameChange}
        minLength={3}
        required
      />
      <StyledLabel htmlFor={emailLabelId}>Email:</StyledLabel>
      <Input
        id={emailLabelId}
        value={email}
        onChange={handleEmailChange}
        placeholder={emailLabelId}
        type="email"
        pattern={emailPattern}
      />
      <StyledLabel htmlFor={phoneLabelId}>Phone number:</StyledLabel>
      <Input
        id={phoneLabelId}
        value={phone}
        onChange={handlePhoneChange}
        placeholder={phoneLabelId}
        type="tel"
        pattern={telPattern}
      />
      <div style={{ marginTop: 20 }}>
        <Button size="small" onClick={onClose}>
          Close
        </Button>
        <Button htmlType="submit" size="small" type="primary" loading={isLoading}>
          Add
        </Button>
      </div>
    </form>
  );
};

export const AddContactForm: React.FC<AddContactFormProps> = ({
  propertyId,
  onAdd,
  contactGroupName,
  parentId,
  contactTypeId,
}) => {
  const { state: visible, toggle } = useToggle();
  return (
    <Popover
      visible={visible}
      destroyTooltipOnHide
      title="Add contact"
      content={
        <AddForm
          propertyId={propertyId}
          onClose={toggle}
          onAdd={onAdd}
          contactTypeId={contactTypeId}
          parentId={parentId}
        />
      }
      placement="topRight"
      trigger="click"
      onVisibleChange={toggle}
    >
      <AddButton onClick={toggle}>Add new contact to {contactGroupName}&apos;s</AddButton>
    </Popover>
  );
};
