import React, { useCallback, useContext } from "react";
import { Form, Input, Button, Radio, message } from "antd";
import { RadioButton } from "@components/radio-button";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { addAttribute } from "@utils/offer";
import {
  BaseAttributeIn,
  PutAttributeTranslationApiV1AttributesAttributeCodeTranslationPutRequest,
} from "@api/client";
import { useMutation } from "react-query";
import { lettingsApi, attributeApi } from "@api/core";
import * as attributesActions from "@state/attribures/actions";
import { LettingIdContext } from "./local-context/letting-context";
import * as S from "./custom-attributes.styled";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

type FormValues = { attributeName: string; attributeValueType: string; attributeNamePl: string };

export const CustomAttributes = (): JSX.Element => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { lettingId, onAdd } = useContext(LettingIdContext);
  const [form] = Form.useForm();
  const { mutateAsync, isLoading } = useMutation(
    (params: PutAttributeTranslationApiV1AttributesAttributeCodeTranslationPutRequest) =>
      attributeApi.putAttributeTranslationApiV1AttributesAttributeCodeTranslationPut(params)
  );

  const addAttributeCallback = useCallback(
    (selectedAttribute: BaseAttributeIn) => {
      addAttribute(dispatch)({ offerId: +id, baseAttributeIn: selectedAttribute });
    },
    [dispatch, id]
  );

  const handleAddAttributeWithLettingId = useMutation(
    (attribute: BaseAttributeIn) =>
      lettingsApi.postLettingAttributeApiV1LettingsLettingIdAttributesPost({
        lettingId: lettingId || -1,
        baseAttributeIn: attribute,
      }),
    {
      onSuccess: () => {
        onAdd?.();
        message.success("success");
      },
      onError: (error: Error) => {
        message.error(`error: ${error.message}`);
      },
    }
  );

  const selectedHandleAddAttribute = (attribute: BaseAttributeIn) =>
    lettingId ? handleAddAttributeWithLettingId.mutate(attribute) : addAttributeCallback(attribute);

  const onFinish = async (values: FormValues) => {
    const newAttribute: BaseAttributeIn = {
      name: values.attributeName,
      dataTypeCode: values.attributeValueType,
      namePL: values.attributeNamePl,
    };
    await selectedHandleAddAttribute(newAttribute);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const rules = [
    { required: true, message: "Please input attribute name" },
    {
      pattern: new RegExp(/^(.)+$/gi),
      message: "Special characters can't be used in attribute name. Please provide different name.",
    },
  ];

  const onTranslationUpdate = async () => {
    await form.validateFields();
    const values = form.getFieldsValue(true);

    try {
      const res = await mutateAsync({
        attributeCode: values.attributeName,
        attributeTranslation: { nameLocal: values.attributeNamePl },
      });
      dispatch(attributesActions.translateAttribute.success(res));
      message.success("The data have been updated");
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ attributeValueType: "string" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="attributeName" rules={rules}>
        <Input placeholder="Attribute Name" />
      </Form.Item>
      <Form.Item name="attributeNamePl" rules={rules}>
        <Input placeholder="Attribute Name LNG" />
      </Form.Item>
      <Form.Item name="attributeValueType">
        <Radio.Group>
          <RadioButton value="string">Text Value</RadioButton>
          <RadioButton value="numeric">Number Value</RadioButton>
          <RadioButton value="datetime">Date</RadioButton>
          <RadioButton value="bit">Bit</RadioButton>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <S.Button type="primary" loading={isLoading} onClick={onTranslationUpdate} htmlType="button">
          Translation Update
        </S.Button>
      </Form.Item>
    </Form>
  );
};
