import React from "react";
import { QuickformAttributes } from "@api/client";

import * as S from "../quick-form.styled";
import { AttributeValue as AttributeValueInput } from "../attribute-value";

import { HandleAddAttributeValue, HandleAttributeValueUpdate } from "../types";

type AttributeDataTypes = {
  string: string;
  numeric: string;
};

type Props = {
  quickformAttributes: QuickformAttributes;
  handleUpdateAttribute: HandleAttributeValueUpdate;
  handleAddAttribute?: HandleAddAttributeValue;
  attributeNames: any;
  attributeDataTypes: AttributeDataTypes;
};

export const OfficeInputs: React.FC<Props> = ({
  quickformAttributes,
  handleUpdateAttribute,
  handleAddAttribute,
  attributeNames,
  attributeDataTypes,
}) => {
  return (
    <>
      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.availableOfficeSpace?.id}
          attributeName={attributeNames.availableOfficeSpace}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.availableOfficeSpace?.valueOriginal}
          parentAttributeId={quickformAttributes.availableOfficeSpace?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.availableOfficeSpace?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.availability?.id}
          attributeName={attributeNames.availability}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.availability?.valueOriginal}
          parentAttributeId={quickformAttributes.availability?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.availability?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.askingRent?.id}
          attributeName={attributeNames.askingRent}
          attributeDataTypeCode={attributeDataTypes.numeric}
          currentValue={quickformAttributes.askingRent?.valueOriginal}
          parentAttributeId={quickformAttributes.askingRent?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.askingRent?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.type?.id}
          attributeName={attributeNames.type}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.type?.valueOriginal}
          parentAttributeId={quickformAttributes.type?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.type?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.documentComments?.id}
          attributeName={attributeNames.documentComments}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.documentComments?.valueOriginal}
          parentAttributeId={quickformAttributes.documentComments?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.documentComments?.groupKey}
        />
      </S.HeadTableCell>

      <S.HeadTableCell>
        <AttributeValueInput
          attributeId={quickformAttributes.internalComments?.id}
          attributeName={attributeNames.internalComments}
          attributeDataTypeCode={attributeDataTypes.string}
          currentValue={quickformAttributes.internalComments?.valueOriginal}
          parentAttributeId={quickformAttributes.internalComments?.id}
          onUpdate={handleUpdateAttribute}
          onAdd={handleAddAttribute}
          groupKey={quickformAttributes.internalComments?.groupKey}
        />
      </S.HeadTableCell>
    </>
  );
};
