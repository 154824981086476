/* tslint:disable */
/* eslint-disable */
/**
 * Property Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppSchemasOfferFloorIn,
    AppSchemasOfferFloorInFromJSON,
    AppSchemasOfferFloorInToJSON,
    AppSchemasOfferFloorOut,
    AppSchemasOfferFloorOutFromJSON,
    AppSchemasOfferFloorOutToJSON,
    AttachmentFileTypeId,
    AttachmentFileTypeIdFromJSON,
    AttachmentFileTypeIdToJSON,
    AttachmentFileTypeOut,
    AttachmentFileTypeOutFromJSON,
    AttachmentFileTypeOutToJSON,
    AttachmentMain,
    AttachmentMainFromJSON,
    AttachmentMainToJSON,
    AttachmentMainOut,
    AttachmentMainOutFromJSON,
    AttachmentMainOutToJSON,
    AttachmentVisibility,
    AttachmentVisibilityFromJSON,
    AttachmentVisibilityToJSON,
    AttachmentVisibilityOut,
    AttachmentVisibilityOutFromJSON,
    AttachmentVisibilityOutToJSON,
    AttributeNote,
    AttributeNoteFromJSON,
    AttributeNoteToJSON,
    AttributeValue,
    AttributeValueFromJSON,
    AttributeValueToJSON,
    AttributeVisibility,
    AttributeVisibilityFromJSON,
    AttributeVisibilityToJSON,
    BaseAttribute,
    BaseAttributeFromJSON,
    BaseAttributeToJSON,
    BaseAttributeIn,
    BaseAttributeInFromJSON,
    BaseAttributeInToJSON,
    BaseCustomAttributeOut,
    BaseCustomAttributeOutFromJSON,
    BaseCustomAttributeOutToJSON,
    CustomAttributeNoteOut,
    CustomAttributeNoteOutFromJSON,
    CustomAttributeNoteOutToJSON,
    CustomAttributeOut,
    CustomAttributeOutFromJSON,
    CustomAttributeOutToJSON,
    CustomAttributeVisibilityOut,
    CustomAttributeVisibilityOutFromJSON,
    CustomAttributeVisibilityOutToJSON,
    FileTagOut,
    FileTagOutFromJSON,
    FileTagOutToJSON,
    FileTagsIn,
    FileTagsInFromJSON,
    FileTagsInToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ModuleIn,
    ModuleInFromJSON,
    ModuleInToJSON,
    ModuleOut,
    ModuleOutFromJSON,
    ModuleOutToJSON,
    NewsletterLocation,
    NewsletterLocationFromJSON,
    NewsletterLocationToJSON,
    NewsletterSchemaIn,
    NewsletterSchemaInFromJSON,
    NewsletterSchemaInToJSON,
    NewsletterSchemaOut,
    NewsletterSchemaOutFromJSON,
    NewsletterSchemaOutToJSON,
    NewsletterSchemaOutName,
    NewsletterSchemaOutNameFromJSON,
    NewsletterSchemaOutNameToJSON,
    NewsletterSchemasOut,
    NewsletterSchemasOutFromJSON,
    NewsletterSchemasOutToJSON,
    OfferAttachmentDescription,
    OfferAttachmentDescriptionFromJSON,
    OfferAttachmentDescriptionToJSON,
    OfferAttachmentDescriptionOut,
    OfferAttachmentDescriptionOutFromJSON,
    OfferAttachmentDescriptionOutToJSON,
    OfferAttachmentOut,
    OfferAttachmentOutFromJSON,
    OfferAttachmentOutToJSON,
    OfferCount,
    OfferCountFromJSON,
    OfferCountToJSON,
    OfferDetails,
    OfferDetailsFromJSON,
    OfferDetailsToJSON,
    OfferDetailsAttachments,
    OfferDetailsAttachmentsFromJSON,
    OfferDetailsAttachmentsToJSON,
    OfferIn,
    OfferInFromJSON,
    OfferInToJSON,
    OfferInDescription,
    OfferInDescriptionFromJSON,
    OfferInDescriptionToJSON,
    OfferInName,
    OfferInNameFromJSON,
    OfferInNameToJSON,
    OfferInNote,
    OfferInNoteFromJSON,
    OfferInNoteToJSON,
    OfferInStatus,
    OfferInStatusFromJSON,
    OfferInStatusToJSON,
    OfferInTag,
    OfferInTagFromJSON,
    OfferInTagToJSON,
    OfferOut,
    OfferOutFromJSON,
    OfferOutToJSON,
    OfferOutDescription,
    OfferOutDescriptionFromJSON,
    OfferOutDescriptionToJSON,
    OfferOutName,
    OfferOutNameFromJSON,
    OfferOutNameToJSON,
    OfferOutNote,
    OfferOutNoteFromJSON,
    OfferOutNoteToJSON,
    OfferOutStatus,
    OfferOutStatusFromJSON,
    OfferOutStatusToJSON,
    OfferTags,
    OfferTagsFromJSON,
    OfferTagsToJSON,
    OfferTagsOut,
    OfferTagsOutFromJSON,
    OfferTagsOutToJSON,
    OfferZoomIn,
    OfferZoomInFromJSON,
    OfferZoomInToJSON,
    OfferZoomOut,
    OfferZoomOutFromJSON,
    OfferZoomOutToJSON,
    OffersBase,
    OffersBaseFromJSON,
    OffersBaseToJSON,
    PutOfferAttributesOut,
    PutOfferAttributesOutFromJSON,
    PutOfferAttributesOutToJSON
} from '../models';
import { UploadAsExcelFileOut, UploadAsExcelFileOutFromJSON } from '../models/UploadAsExcelFileOut';
import { QuickformDeleteModuleReq } from '../models/QuickformDeleteModule';
import { QuickformDeleteFloorReq } from '../models/QuickformDeleteFloor';

export interface DeleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDeleteRequest {
    attachmentId: number;
    fileTagId: number;
}

export interface DeleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDeleteRequest {
    schemaId: number;
}

export interface DeleteOfferApiV1OffersOfferIdDeleteRequest {
    offerId: number;
}

export interface DeleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDeleteRequest {
    offerId: number;
    attachmentId: number;
}

export interface DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest {
    attributeId: number;
}

export interface DeleteOfferTagApiV1OffersOfferIdTagsTagIdDeleteRequest {
    offerId: number;
    tagId: number;
}

export interface GetIndustrialListApiV1OffersIndustrialListENGPutRequest {
    newsletterLocation: Array<NewsletterLocation>;
}

export interface GetIndustrialListEngApiV1OffersIndustrialListPutRequest {
    newsletterLocation: Array<NewsletterLocation>;
}

export interface GetNewsletterApiV1OffersNewsletterPutRequest {
    newsletterLocation: Array<NewsletterLocation>;
}

export interface GetNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGetRequest {
    schemaId: number;
}

export interface GetNewsletterSchemasApiV1OffersNewsletterSchemaGetRequest {
    module?: string;
}

export interface GetOfferApiV1OffersOfferIdGetRequest {
    offerId: number;
}

export interface GetOfferAttachmentApiV1OffersAttachmentsAttachmentIdGetRequest {
    attachmentId: number;
}

export interface GetOfferAttachmentsApiV1OffersOfferIdAttachmentsGetRequest {
    offerId: number;
}

export interface GetOfferAttributesApiV1OffersOfferIdAttributesGetRequest {
    offerId: number;
}

export interface GetOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGetRequest {
    offerId: number;
}

export interface GetOfferTagsApiV1OffersOfferIdTagsGetRequest {
    offerId: number;
}

export interface GetOffersApiV1OffersGetRequest {
    propertyCategoryCode: string;
    pageNumber?: number;
    pageSize?: number;
    propertyStatusCode?: Array<string>;
    offerStatusCode?: Array<string>;
    propertyNameOrAddress?: string;
    propertyGeographyIds?: Array<number>;
    refreshFilters?: boolean;
    module?: string;
    customAttributeFilter?: object;
}

export interface GetOffersCountApiV1OffersCountGetRequest {
    propertyCategoryCode: string;
    propertyStatusCode?: Array<string>;
    offerStatusCode?: Array<string>;
    propertyNameOrAddress?: string;
    propertyGeographyIds?: Array<number>;
    module?: string;
    customAttributeFilter?: object;
}

export interface GetPropertyParticularApiV1OffersPropertyParticularGetRequest {
    offerIds: Array<number>;
}

export interface GetRecommendedOfferTagsApiV1OffersOfferIdTagsRecommendedGetRequest {
    offerId: number;
}

export interface GetSummaryApiV1OffersSummaryPutRequest {
    newsletterLocation: Array<NewsletterLocation>;
}

export interface PostFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPostRequest {
    attachmentId: number;
    fileTagsIn: FileTagsIn;
}

export interface PostNewsletterSchemaApiV1OffersNewsletterSchemaPostRequest {
    newsletterSchemaIn: NewsletterSchemaIn;
}

export interface PostOfferApiV1OffersOfferIdPostRequest {
    offerId: number;
}

export interface PostOfferApiV1OffersPostRequest {
    offerIn: OfferIn;
}

export interface PostOfferAttachmentApiV1OffersOfferIdAttachmentsPostRequest {
    offerId: number;
    attachment: Blob;
}

export interface UploadOfferAsExcelReq {
    importType: string; // office or industrial
    attachment: File;
}

export interface PostOfferAttributeApiV1OffersOfferIdAttributesPostRequest {
    offerId: number;
    baseAttributeIn: BaseAttributeIn;
}

export interface PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest {
    attributeId: number;
    appSchemasOfferFloorIn: AppSchemasOfferFloorIn;
}

export interface PostOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRequest {
    attributeId: number;
    moduleIn: ModuleIn;
}

export interface PostOfferTagsApiV1OffersOfferIdTagsPostRequest {
    offerId: number;
    offerInTag: OfferInTag;
}

export interface PutOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPutRequest {
    offerId: number;
    attachmentId: number;
    offerAttachmentDescription: OfferAttachmentDescription;
}

export interface PutOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePutRequest {
    offerId: number;
    attachmentId: number;
    attachmentFileTypeId: AttachmentFileTypeId;
}

export interface PutOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePutRequest {
    attributeId: number;
    attributeNote: AttributeNote;
}

export interface PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest {
    attributeId: number;
    attributeValue: AttributeValue;
}

export interface PutOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPutRequest {
    attributeId: number;
    attributeVisibility: AttributeVisibility;
}

export interface PutOfferDescriptionApiV1OffersOfferIdDescriptionPutRequest {
    offerId: number;
    offerInDescription: OfferInDescription;
    descriptionType?: string;
}

export interface PutOfferNameApiV1OffersOfferIdNamePutRequest {
    offerId: number;
    offerInName: OfferInName;
}

export interface PutOfferNoteApiV1OffersOfferIdNotePutRequest {
    offerId: number;
    offerInNote: OfferInNote;
}

export interface PutOfferStatusApiV1OffersOfferIdStatusPutRequest {
    offerId: number;
    offerInStatus: OfferInStatus;
}

export interface PutOfferZoomApiV1OffersOfferIdZoomPutRequest {
    offerId: number;
    offerZoomIn: OfferZoomIn;
}

export interface PutReddApiV1OffersReddUpdatePutRequest {
    offerIds: Array<number>;
}

export interface PutReddAttributesApiV1OffersOfferIdReddPutRequest {
    offerId: number;
}

export interface SetOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPutRequest {
    offerId: number;
    attachmentId: number;
    attachmentMain: AttachmentMain;
}

export interface SetOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPutRequest {
    offerId: number;
    attachmentId: number;
    attachmentVisibility: AttachmentVisibility;
}

/**
 * 
 */
export class OfferApi extends runtime.BaseAPI {

    /**
     * Delete File Tag
     */
    async deleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDeleteRaw(requestParameters: DeleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling deleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDelete.');
        }

        if (requestParameters.fileTagId === null || requestParameters.fileTagId === undefined) {
            throw new runtime.RequiredError('fileTagId', 'Required parameter requestParameters.fileTagId was null or undefined when calling deleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attachments/{attachmentId}/fileTags/{fileTagId}`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))).replace(`{${"fileTagId"}}`, encodeURIComponent(String(requestParameters.fileTagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload offer as excel file
     */
    async uploadOfferAsExcelFileRaw(requestParameters: UploadOfferAsExcelReq): Promise<runtime.ApiResponse<void>> {
        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.attachment !== undefined) {
            formParams.append('excel_file', requestParameters.attachment as any);
        }

        const response = await this.request({
            path: `/api/v1/import/{importType}`.replace(`{${"importType"}}`, encodeURIComponent(String(requestParameters.importType))),
            method: 'POST',
            headers: headerParameters,
            body: formParams,
        });

        return new runtime.BlobApiResponse(response) as any;
    }

    async uploadOfferAsExcelFilePost(requestParameters: UploadOfferAsExcelReq): Promise<any> {
        const response = await this.uploadOfferAsExcelFileRaw(requestParameters);
        return await response;
    }

    /**
     * Delete File Tag
     */
    async deleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDelete(requestParameters: DeleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDeleteRequest): Promise<void> {
        await this.deleteFileTagApiV1OffersAttachmentsAttachmentIdFileTagsFileTagIdDeleteRaw(requestParameters);
    }

    /**
     * Delete user defined newsletter schemas data by id
     */
    async deleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDeleteRaw(requestParameters: DeleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.schemaId === null || requestParameters.schemaId === undefined) {
            throw new runtime.RequiredError('schemaId', 'Required parameter requestParameters.schemaId was null or undefined when calling deleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/newsletterSchema/{schemaId}`.replace(`{${"schemaId"}}`, encodeURIComponent(String(requestParameters.schemaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete user defined newsletter schemas data by id
     */
    async deleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDelete(requestParameters: DeleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDeleteRequest): Promise<void> {
        await this.deleteNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdDeleteRaw(requestParameters);
    }

    /**
     * Delete Offer
     */
    async deleteOfferApiV1OffersOfferIdDeleteRaw(requestParameters: DeleteOfferApiV1OffersOfferIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling deleteOfferApiV1OffersOfferIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Offer
     */
    async deleteOfferApiV1OffersOfferIdDelete(requestParameters: DeleteOfferApiV1OffersOfferIdDeleteRequest): Promise<void> {
        await this.deleteOfferApiV1OffersOfferIdDeleteRaw(requestParameters);
    }

    /**
     * Delete Offer Attachment
     */
    async deleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDeleteRaw(requestParameters: DeleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling deleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDelete.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling deleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attachments/{attachmentId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Offer Attachment
     */
    async deleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDelete(requestParameters: DeleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDeleteRequest): Promise<void> {
        await this.deleteOfferAttachmentApiV1OffersOfferIdAttachmentsAttachmentIdDeleteRaw(requestParameters);
    }

    /**
     * Delete Offer attribute
     */
    async deleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRaw(requestParameters: DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling deleteOfferAttributeApiV1OffersAttributesAttributeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attributes/{attributeId}`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Offer attribute
     */
    async deleteOfferAttributeApiV1OffersAttributesAttributeIdDelete(requestParameters: DeleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRequest): Promise<void> {
        await this.deleteOfferAttributeApiV1OffersAttributesAttributeIdDeleteRaw(requestParameters);
    }

    /**
     * Delete Offer Tag
     */
    async deleteOfferTagApiV1OffersOfferIdTagsTagIdDeleteRaw(requestParameters: DeleteOfferTagApiV1OffersOfferIdTagsTagIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling deleteOfferTagApiV1OffersOfferIdTagsTagIdDelete.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling deleteOfferTagApiV1OffersOfferIdTagsTagIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/tags/{tagId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Offer Tag
     */
    async deleteOfferTagApiV1OffersOfferIdTagsTagIdDelete(requestParameters: DeleteOfferTagApiV1OffersOfferIdTagsTagIdDeleteRequest): Promise<void> {
        await this.deleteOfferTagApiV1OffersOfferIdTagsTagIdDeleteRaw(requestParameters);
    }

    /**
     * Generate industrial list of buildings
     */
    async getIndustrialListApiV1OffersIndustrialListENGPutRaw(requestParameters: GetIndustrialListApiV1OffersIndustrialListENGPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newsletterLocation === null || requestParameters.newsletterLocation === undefined) {
            throw new runtime.RequiredError('newsletterLocation', 'Required parameter requestParameters.newsletterLocation was null or undefined when calling getIndustrialListApiV1OffersIndustrialListENGPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/industrialListENG/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.newsletterLocation.map(NewsletterLocationToJSON),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Generate industrial list of buildings
     */
    async getIndustrialListApiV1OffersIndustrialListENGPut(requestParameters: GetIndustrialListApiV1OffersIndustrialListENGPutRequest): Promise<any> {
        return await this.getIndustrialListApiV1OffersIndustrialListENGPutRaw(requestParameters);
    }

    /**
     * Generate industrial list of buildings
     */
    async getIndustrialListEngApiV1OffersIndustrialListPutRaw(requestParameters: GetIndustrialListEngApiV1OffersIndustrialListPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newsletterLocation === null || requestParameters.newsletterLocation === undefined) {
            throw new runtime.RequiredError('newsletterLocation', 'Required parameter requestParameters.newsletterLocation was null or undefined when calling getIndustrialListEngApiV1OffersIndustrialListPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/industrialList/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.newsletterLocation.map(NewsletterLocationToJSON),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Generate industrial list of buildings
     */
    async getIndustrialListEngApiV1OffersIndustrialListPut(requestParameters: GetIndustrialListEngApiV1OffersIndustrialListPutRequest): Promise<any> {
        return await this.getIndustrialListEngApiV1OffersIndustrialListPutRaw(requestParameters);
    }

    /**
     * Generate newsletter
     */
    async getNewsletterApiV1OffersNewsletterPutRaw(requestParameters: GetNewsletterApiV1OffersNewsletterPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newsletterLocation === null || requestParameters.newsletterLocation === undefined) {
            throw new runtime.RequiredError('newsletterLocation', 'Required parameter requestParameters.newsletterLocation was null or undefined when calling getNewsletterApiV1OffersNewsletterPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/newsletter/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.newsletterLocation.map(NewsletterLocationToJSON),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Generate newsletter
     */
    async getNewsletterApiV1OffersNewsletterPut(requestParameters: GetNewsletterApiV1OffersNewsletterPutRequest): Promise<any> {
        return await this.getNewsletterApiV1OffersNewsletterPutRaw(requestParameters);
    }

    /**
     * Get user defined newsletter schema data by id
     */
    async getNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGetRaw(requestParameters: GetNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGetRequest): Promise<runtime.ApiResponse<NewsletterSchemaOutName>> {
        if (requestParameters.schemaId === null || requestParameters.schemaId === undefined) {
            throw new runtime.RequiredError('schemaId', 'Required parameter requestParameters.schemaId was null or undefined when calling getNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/newsletterSchema/{schemaId}`.replace(`{${"schemaId"}}`, encodeURIComponent(String(requestParameters.schemaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterSchemaOutNameFromJSON(jsonValue));
    }

    /**
     * Get user defined newsletter schema data by id
     */
    async getNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGet(requestParameters: GetNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGetRequest): Promise<NewsletterSchemaOutName> {
        const response = await this.getNewsletterSchemaApiV1OffersNewsletterSchemaSchemaIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all user defined newsletter schemas base data
     */
    async getNewsletterSchemasApiV1OffersNewsletterSchemaGetRaw(requestParameters: GetNewsletterSchemasApiV1OffersNewsletterSchemaGetRequest): Promise<runtime.ApiResponse<Array<NewsletterSchemasOut>>> {
        const queryParameters: any = {};

        if (requestParameters.module !== undefined) {
            queryParameters['module'] = requestParameters.module;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/newsletterSchema/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsletterSchemasOutFromJSON));
    }

    /**
     * Get all user defined newsletter schemas base data
     */
    async getNewsletterSchemasApiV1OffersNewsletterSchemaGet(requestParameters: GetNewsletterSchemasApiV1OffersNewsletterSchemaGetRequest): Promise<Array<NewsletterSchemasOut>> {
        const response = await this.getNewsletterSchemasApiV1OffersNewsletterSchemaGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Offer Details
     */
    async getOfferApiV1OffersOfferIdGetRaw(requestParameters: GetOfferApiV1OffersOfferIdGetRequest): Promise<runtime.ApiResponse<OfferDetails>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling getOfferApiV1OffersOfferIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferDetailsFromJSON(jsonValue));
    }

    /**
     * Get Offer Details
     */
    async getOfferApiV1OffersOfferIdGet(requestParameters: GetOfferApiV1OffersOfferIdGetRequest): Promise<OfferDetails> {
        const response = await this.getOfferApiV1OffersOfferIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Download offer attachment
     */
    async getOfferAttachmentApiV1OffersAttachmentsAttachmentIdGetRaw(requestParameters: GetOfferAttachmentApiV1OffersAttachmentsAttachmentIdGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling getOfferAttachmentApiV1OffersAttachmentsAttachmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attachments/{attachmentId}`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Download offer attachment
     */
    async getOfferAttachmentApiV1OffersAttachmentsAttachmentIdGet(requestParameters: GetOfferAttachmentApiV1OffersAttachmentsAttachmentIdGetRequest): Promise<void> {
        await this.getOfferAttachmentApiV1OffersAttachmentsAttachmentIdGetRaw(requestParameters);
    }

    /**
     * Get Offer Attachments
     */
    async getOfferAttachmentsApiV1OffersOfferIdAttachmentsGetRaw(requestParameters: GetOfferAttachmentsApiV1OffersOfferIdAttachmentsGetRequest): Promise<runtime.ApiResponse<Array<OfferDetailsAttachments>>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling getOfferAttachmentsApiV1OffersOfferIdAttachmentsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attachments`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferDetailsAttachmentsFromJSON));
    }

    /**
     * Get Offer Attachments
     */
    async getOfferAttachmentsApiV1OffersOfferIdAttachmentsGet(requestParameters: GetOfferAttachmentsApiV1OffersOfferIdAttachmentsGetRequest): Promise<Array<OfferDetailsAttachments>> {
        const response = await this.getOfferAttachmentsApiV1OffersOfferIdAttachmentsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Offer Attributes
     */
    async getOfferAttributesApiV1OffersOfferIdAttributesGetRaw(requestParameters: GetOfferAttributesApiV1OffersOfferIdAttributesGetRequest): Promise<runtime.ApiResponse<{ [key: string]: Array<CustomAttributeOut>; }>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling getOfferAttributesApiV1OffersOfferIdAttributesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attributes`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Offer Attributes
     */
    async getOfferAttributesApiV1OffersOfferIdAttributesGet(requestParameters: GetOfferAttributesApiV1OffersOfferIdAttributesGetRequest): Promise<{ [key: string]: Array<CustomAttributeOut>; }> {
        const response = await this.getOfferAttributesApiV1OffersOfferIdAttributesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Offer Other Attributes
     */
    async getOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGetRaw(requestParameters: GetOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGetRequest): Promise<runtime.ApiResponse<Array<BaseAttribute>>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling getOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/api/v1/offers/{offerId}/otherAttributes`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BaseAttributeFromJSON));
    }

    /**
     * Get Offer Other Attributes
     */
    async getOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGet(requestParameters: GetOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGetRequest): Promise<Array<BaseAttribute>> {
        const response = await this.getOfferOtherAttributesApiV1OffersApiV1OffersOfferIdOtherAttributesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Offer tags
     */
    async getOfferTagsApiV1OffersOfferIdTagsGetRaw(requestParameters: GetOfferTagsApiV1OffersOfferIdTagsGetRequest): Promise<runtime.ApiResponse<Array<OfferTags>>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling getOfferTagsApiV1OffersOfferIdTagsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/tags`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferTagsFromJSON));
    }

    /**
     * Get Offer tags
     */
    async getOfferTagsApiV1OffersOfferIdTagsGet(requestParameters: GetOfferTagsApiV1OffersOfferIdTagsGetRequest): Promise<Array<OfferTags>> {
        const response = await this.getOfferTagsApiV1OffersOfferIdTagsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get List of Offers
     */
    async getOffersApiV1OffersGetRaw(requestParameters: GetOffersApiV1OffersGetRequest): Promise<runtime.ApiResponse<Array<OffersBase>>> {
        if (requestParameters.propertyCategoryCode === null || requestParameters.propertyCategoryCode === undefined) {
            throw new runtime.RequiredError('propertyCategoryCode', 'Required parameter requestParameters.propertyCategoryCode was null or undefined when calling getOffersApiV1OffersGet.');
        }

        let queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.propertyCategoryCode !== undefined) {
            queryParameters['propertyCategoryCode'] = requestParameters.propertyCategoryCode;
        }

        if (requestParameters.propertyStatusCode) {
            queryParameters['propertyStatusCode'] = requestParameters.propertyStatusCode;
        }

        if (requestParameters.offerStatusCode) {
            queryParameters['offerStatusCode'] = requestParameters.offerStatusCode;
        }

        if (requestParameters.propertyNameOrAddress !== undefined) {
            queryParameters['propertyNameOrAddress'] = requestParameters.propertyNameOrAddress;
        }

        if (requestParameters.propertyGeographyIds) {
            queryParameters['propertyGeographyIds'] = requestParameters.propertyGeographyIds;
        }

        if (requestParameters.refreshFilters !== undefined) {
            queryParameters['refreshFilters'] = requestParameters.refreshFilters;
        }

        if (requestParameters.module !== undefined) {
            queryParameters['module'] = requestParameters.module;
        }

        if (requestParameters.customAttributeFilter !== undefined) {
            queryParameters = {
                ...queryParameters,
                ...requestParameters.customAttributeFilter
            };
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OffersBaseFromJSON));
    }

    /**
     * Get List of Offers
     */
    async getOffersApiV1OffersGet(requestParameters: GetOffersApiV1OffersGetRequest): Promise<Array<OffersBase>> {
        const response = await this.getOffersApiV1OffersGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Number of Offers
     */
    async getOffersCountApiV1OffersCountGetRaw(requestParameters: GetOffersCountApiV1OffersCountGetRequest): Promise<runtime.ApiResponse<OfferCount>> {
        if (requestParameters.propertyCategoryCode === null || requestParameters.propertyCategoryCode === undefined) {
            throw new runtime.RequiredError('propertyCategoryCode', 'Required parameter requestParameters.propertyCategoryCode was null or undefined when calling getOffersCountApiV1OffersCountGet.');
        }

        let queryParameters: any = {};

        if (requestParameters.propertyCategoryCode !== undefined) {
            queryParameters['propertyCategoryCode'] = requestParameters.propertyCategoryCode;
        }

        if (requestParameters.propertyStatusCode) {
            queryParameters['propertyStatusCode'] = requestParameters.propertyStatusCode;
        }

        if (requestParameters.offerStatusCode) {
            queryParameters['offerStatusCode'] = requestParameters.offerStatusCode;
        }

        if (requestParameters.propertyNameOrAddress !== undefined) {
            queryParameters['propertyNameOrAddress'] = requestParameters.propertyNameOrAddress;
        }

        if (requestParameters.propertyGeographyIds) {
            queryParameters['propertyGeographyIds'] = requestParameters.propertyGeographyIds;
        }

        if (requestParameters.module !== undefined) {
            queryParameters['module'] = requestParameters.module;
        }

        if (requestParameters.customAttributeFilter !== undefined) {
            queryParameters = {
                ...queryParameters,
                ...requestParameters.customAttributeFilter
            };
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferCountFromJSON(jsonValue));
    }

    /**
     * Get Number of Offers
     */
    async getOffersCountApiV1OffersCountGet(requestParameters: GetOffersCountApiV1OffersCountGetRequest): Promise<OfferCount> {
        const response = await this.getOffersCountApiV1OffersCountGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Generate property particular
     */
    async getPropertyParticularApiV1OffersPropertyParticularGetRaw(requestParameters: GetPropertyParticularApiV1OffersPropertyParticularGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerIds === null || requestParameters.offerIds === undefined) {
            throw new runtime.RequiredError('offerIds', 'Required parameter requestParameters.offerIds was null or undefined when calling getPropertyParticularApiV1OffersPropertyParticularGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.offerIds) {
            queryParameters['offerIds'] = requestParameters.offerIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/property_particular/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response) as any;
    }

    /**
     * Generate property particular
     */
    async getPropertyParticularApiV1OffersPropertyParticularGet(requestParameters: GetPropertyParticularApiV1OffersPropertyParticularGetRequest): Promise<any> {
        const response = await this.getPropertyParticularApiV1OffersPropertyParticularGetRaw(requestParameters);
        return await response;
    }

    /**
     * Get reccomended offer tags
     */
    async getRecommendedOfferTagsApiV1OffersOfferIdTagsRecommendedGetRaw(requestParameters: GetRecommendedOfferTagsApiV1OffersOfferIdTagsRecommendedGetRequest): Promise<runtime.ApiResponse<Array<OfferTags>>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling getRecommendedOfferTagsApiV1OffersOfferIdTagsRecommendedGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/tags/recommended`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferTagsFromJSON));
    }

    /**
     * Get reccomended offer tags
     */
    async getRecommendedOfferTagsApiV1OffersOfferIdTagsRecommendedGet(requestParameters: GetRecommendedOfferTagsApiV1OffersOfferIdTagsRecommendedGetRequest): Promise<Array<OfferTags>> {
        const response = await this.getRecommendedOfferTagsApiV1OffersOfferIdTagsRecommendedGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Generate list of buildings
     */
    async getSummaryApiV1OffersSummaryPutRaw(requestParameters: GetSummaryApiV1OffersSummaryPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newsletterLocation === null || requestParameters.newsletterLocation === undefined) {
            throw new runtime.RequiredError('newsletterLocation', 'Required parameter requestParameters.newsletterLocation was null or undefined when calling getSummaryApiV1OffersSummaryPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/summary/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.newsletterLocation.map(NewsletterLocationToJSON),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Generate list of buildings
     */
    async getSummaryApiV1OffersSummaryPut(requestParameters: GetSummaryApiV1OffersSummaryPutRequest): Promise<any> {
        return await this.getSummaryApiV1OffersSummaryPutRaw(requestParameters);
    }

    /**
     * Generate list of buildings
     */
    async getSummaryApiV1OffersSummaryLNGPutRaw(requestParameters: GetSummaryApiV1OffersSummaryPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newsletterLocation === null || requestParameters.newsletterLocation === undefined) {
            throw new runtime.RequiredError('newsletterLocation', 'Required parameter requestParameters.newsletterLocation was null or undefined when calling getSummaryApiV1OffersSummaryLNGPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/summaryLNG/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.newsletterLocation.map(NewsletterLocationToJSON),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Generate list of buildings
     */
    async getSummaryApiV1OffersSummaryLNGPut(requestParameters: GetSummaryApiV1OffersSummaryPutRequest): Promise<any> {
        return await this.getSummaryApiV1OffersSummaryLNGPutRaw(requestParameters);
    }

    /**
     * Add new file tag
     */
    async postFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPostRaw(requestParameters: PostFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPostRequest): Promise<runtime.ApiResponse<Array<FileTagOut>>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling postFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPost.');
        }

        if (requestParameters.fileTagsIn === null || requestParameters.fileTagsIn === undefined) {
            throw new runtime.RequiredError('fileTagsIn', 'Required parameter requestParameters.fileTagsIn was null or undefined when calling postFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attachments/{attachmentId}/fileTags`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileTagsInToJSON(requestParameters.fileTagsIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileTagOutFromJSON));
    }

    /**
     * Add new file tag
     */
    async postFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPost(requestParameters: PostFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPostRequest): Promise<Array<FileTagOut>> {
        const response = await this.postFileTagsApiV1OffersAttachmentsAttachmentIdFileTagsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post user defined newsletter schema
     */
    async postNewsletterSchemaApiV1OffersNewsletterSchemaPostRaw(requestParameters: PostNewsletterSchemaApiV1OffersNewsletterSchemaPostRequest): Promise<runtime.ApiResponse<NewsletterSchemaOut>> {
        if (requestParameters.newsletterSchemaIn === null || requestParameters.newsletterSchemaIn === undefined) {
            throw new runtime.RequiredError('newsletterSchemaIn', 'Required parameter requestParameters.newsletterSchemaIn was null or undefined when calling postNewsletterSchemaApiV1OffersNewsletterSchemaPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/newsletterSchema/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewsletterSchemaInToJSON(requestParameters.newsletterSchemaIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterSchemaOutFromJSON(jsonValue));
    }

    /**
     * Post user defined newsletter schema
     */
    async postNewsletterSchemaApiV1OffersNewsletterSchemaPost(requestParameters: PostNewsletterSchemaApiV1OffersNewsletterSchemaPostRequest): Promise<NewsletterSchemaOut> {
        const response = await this.postNewsletterSchemaApiV1OffersNewsletterSchemaPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Clone offer
     */
    async postOfferApiV1OffersOfferIdPostRaw(requestParameters: PostOfferApiV1OffersOfferIdPostRequest): Promise<runtime.ApiResponse<OfferOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling postOfferApiV1OffersOfferIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferOutFromJSON(jsonValue));
    }

    /**
     * Clone offer
     */
    async postOfferApiV1OffersOfferIdPost(requestParameters: PostOfferApiV1OffersOfferIdPostRequest): Promise<OfferOut> {
        const response = await this.postOfferApiV1OffersOfferIdPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add new offer
     */
    async postOfferApiV1OffersPostRaw(requestParameters: PostOfferApiV1OffersPostRequest): Promise<runtime.ApiResponse<OfferOut>> {
        if (requestParameters.offerIn === null || requestParameters.offerIn === undefined) {
            throw new runtime.RequiredError('offerIn', 'Required parameter requestParameters.offerIn was null or undefined when calling postOfferApiV1OffersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferInToJSON(requestParameters.offerIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferOutFromJSON(jsonValue));
    }

    /**
     * Add new offer
     */
    async postOfferApiV1OffersPost(requestParameters: PostOfferApiV1OffersPostRequest): Promise<OfferOut> {
        const response = await this.postOfferApiV1OffersPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Offer Attachment
     */
    async postOfferAttachmentApiV1OffersOfferIdAttachmentsPostRaw(requestParameters: PostOfferAttachmentApiV1OffersOfferIdAttachmentsPostRequest): Promise<runtime.ApiResponse<OfferAttachmentOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling postOfferAttachmentApiV1OffersOfferIdAttachmentsPost.');
        }

        if (requestParameters.attachment === null || requestParameters.attachment === undefined) {
            throw new runtime.RequiredError('attachment', 'Required parameter requestParameters.attachment was null or undefined when calling postOfferAttachmentApiV1OffersOfferIdAttachmentsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.attachment !== undefined) {
            formParams.append('attachment', requestParameters.attachment as any);
        }

        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attachments`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferAttachmentOutFromJSON(jsonValue));
    }

    /**
     * Post Offer Attachment
     */
    async postOfferAttachmentApiV1OffersOfferIdAttachmentsPost(requestParameters: PostOfferAttachmentApiV1OffersOfferIdAttachmentsPostRequest): Promise<OfferAttachmentOut> {
        const response = await this.postOfferAttachmentApiV1OffersOfferIdAttachmentsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Offer Attribute
     */
    async postOfferAttributeApiV1OffersOfferIdAttributesPostRaw(requestParameters: PostOfferAttributeApiV1OffersOfferIdAttributesPostRequest): Promise<runtime.ApiResponse<BaseCustomAttributeOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling postOfferAttributeApiV1OffersOfferIdAttributesPost.');
        }

        if (requestParameters.baseAttributeIn === null || requestParameters.baseAttributeIn === undefined) {
            throw new runtime.RequiredError('baseAttributeIn', 'Required parameter requestParameters.baseAttributeIn was null or undefined when calling postOfferAttributeApiV1OffersOfferIdAttributesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attributes`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseAttributeInToJSON(requestParameters.baseAttributeIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCustomAttributeOutFromJSON(jsonValue));
    }

    /**
    * Post Quickform Offer Attribute
    */
    async postQuickformOfferAttributeApiV1OffersOfferIdAttributesPostRaw(requestParameters: { offerId: number, dataTypeCode: string, name: string }): Promise<runtime.ApiResponse<BaseCustomAttributeOut>> {

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attributes`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: { dataTypeCode: requestParameters.dataTypeCode, name: requestParameters.name },
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseCustomAttributeOutFromJSON(jsonValue));
    }

    /**
     * Post Offer Attribute
     */
    async postOfferAttributeApiV1OffersOfferIdAttributesPost(requestParameters: PostOfferAttributeApiV1OffersOfferIdAttributesPostRequest): Promise<BaseCustomAttributeOut> {
        const response = await this.postOfferAttributeApiV1OffersOfferIdAttributesPostRaw(requestParameters);
        return await response.value();
    }

    /**
   * Post Quickform Offer Attribute
   */
    async postQuickformOfferAttributeApiV1OffersOfferIdAttributesPost(requestParameters: { offerId: number, dataTypeCode: string, name: string }): Promise<BaseCustomAttributeOut> {
        const response = await this.postQuickformOfferAttributeApiV1OffersOfferIdAttributesPostRaw(requestParameters);
        return await response.value();
    }

    async deleteQuickformFloorDelete(requestParameters: QuickformDeleteFloorReq): Promise<void> {
        await this.deleteQuickformFloorRequest(requestParameters);
    }

    async deleteQuickformFloorRequest(requestParameters: QuickformDeleteFloorReq): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling deleteOfferApiV1OffersOfferIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attributes/quickformFloor/${requestParameters.floor}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    async deleteQuickformModuleDelete(requestParameters: QuickformDeleteModuleReq): Promise<void> {
        await this.deleteQuickformFloorRequest(requestParameters);
    }

    async deleteQuickformModuleRequest(requestParameters: QuickformDeleteModuleReq): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling deleteOfferApiV1OffersOfferIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attributes/quickformModule/${requestParameters.floor}/${requestParameters.module}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }


    /**
     * Post Offer Attribute Floor
     */
    async postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRaw(requestParameters: PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest): Promise<runtime.ApiResponse<Array<AppSchemasOfferFloorOut>>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPost.');
        }

        if (requestParameters.appSchemasOfferFloorIn === null || requestParameters.appSchemasOfferFloorIn === undefined) {
            throw new runtime.RequiredError('appSchemasOfferFloorIn', 'Required parameter requestParameters.appSchemasOfferFloorIn was null or undefined when calling postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attributes/{attributeId}/floors`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppSchemasOfferFloorInToJSON(requestParameters.appSchemasOfferFloorIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppSchemasOfferFloorOutFromJSON));
    }

    /**
     * Post Offer Attribute Floor
     */
    async postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPost(requestParameters: PostOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRequest): Promise<Array<AppSchemasOfferFloorOut>> {
        const response = await this.postOfferAttributeFloorApiV1OffersAttributesAttributeIdFloorsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post Offer Attribute Module
     */
    async postOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRaw(requestParameters: PostOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRequest): Promise<runtime.ApiResponse<Array<ModuleOut>>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling postOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPost.');
        }

        if (requestParameters.moduleIn === null || requestParameters.moduleIn === undefined) {
            throw new runtime.RequiredError('moduleIn', 'Required parameter requestParameters.moduleIn was null or undefined when calling postOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attributes/{attributeId}/modules`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleInToJSON(requestParameters.moduleIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModuleOutFromJSON));
    }

    /**
     * Post Offer Attribute Module
     */
    async postOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPost(requestParameters: PostOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRequest): Promise<Array<ModuleOut>> {
        const response = await this.postOfferAttributeModuleApiV1OffersAttributesAttributeIdModulesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add new offer tag
     */
    async postOfferTagsApiV1OffersOfferIdTagsPostRaw(requestParameters: PostOfferTagsApiV1OffersOfferIdTagsPostRequest): Promise<runtime.ApiResponse<OfferTagsOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling postOfferTagsApiV1OffersOfferIdTagsPost.');
        }

        if (requestParameters.offerInTag === null || requestParameters.offerInTag === undefined) {
            throw new runtime.RequiredError('offerInTag', 'Required parameter requestParameters.offerInTag was null or undefined when calling postOfferTagsApiV1OffersOfferIdTagsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/tags`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferInTagToJSON(requestParameters.offerInTag),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferTagsOutFromJSON(jsonValue));
    }

    /**
     * Add new offer tag
     */
    async postOfferTagsApiV1OffersOfferIdTagsPost(requestParameters: PostOfferTagsApiV1OffersOfferIdTagsPostRequest): Promise<OfferTagsOut> {
        const response = await this.postOfferTagsApiV1OffersOfferIdTagsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Attachment Description
     */
    async putOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPutRaw(requestParameters: PutOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPutRequest): Promise<runtime.ApiResponse<OfferAttachmentDescriptionOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPut.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling putOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPut.');
        }

        if (requestParameters.offerAttachmentDescription === null || requestParameters.offerAttachmentDescription === undefined) {
            throw new runtime.RequiredError('offerAttachmentDescription', 'Required parameter requestParameters.offerAttachmentDescription was null or undefined when calling putOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attachments/{attachmentId}/description`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferAttachmentDescriptionToJSON(requestParameters.offerAttachmentDescription),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferAttachmentDescriptionOutFromJSON(jsonValue));
    }

    /**
     * Put Offer Attachment Description
     */
    async putOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPut(requestParameters: PutOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPutRequest): Promise<OfferAttachmentDescriptionOut> {
        const response = await this.putOfferAttachmentDescriptionApiV1OffersOfferIdAttachmentsAttachmentIdDescriptionPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Attachment File Type
     */
    async putOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePutRaw(requestParameters: PutOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePutRequest): Promise<runtime.ApiResponse<AttachmentFileTypeOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePut.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling putOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePut.');
        }

        if (requestParameters.attachmentFileTypeId === null || requestParameters.attachmentFileTypeId === undefined) {
            throw new runtime.RequiredError('attachmentFileTypeId', 'Required parameter requestParameters.attachmentFileTypeId was null or undefined when calling putOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attachments/{attachmentId}/fileType`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttachmentFileTypeIdToJSON(requestParameters.attachmentFileTypeId),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFileTypeOutFromJSON(jsonValue));
    }

    /**
     * Put Offer Attachment File Type
     */
    async putOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePut(requestParameters: PutOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePutRequest): Promise<AttachmentFileTypeOut> {
        const response = await this.putOfferAttachmentFileTypeApiV1OffersOfferIdAttachmentsAttachmentIdFileTypePutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Attribute Note
     */
    async putOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePutRaw(requestParameters: PutOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePutRequest): Promise<runtime.ApiResponse<CustomAttributeNoteOut>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling putOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePut.');
        }

        if (requestParameters.attributeNote === null || requestParameters.attributeNote === undefined) {
            throw new runtime.RequiredError('attributeNote', 'Required parameter requestParameters.attributeNote was null or undefined when calling putOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attributes/{attributeId}/note`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeNoteToJSON(requestParameters.attributeNote),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAttributeNoteOutFromJSON(jsonValue));
    }

    /**
     * Put Offer Attribute Note
     */
    async putOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePut(requestParameters: PutOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePutRequest): Promise<CustomAttributeNoteOut> {
        const response = await this.putOfferAttributeNoteApiV1OffersAttributesAttributeIdNotePutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Attribute Value
     */
    async putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRaw(requestParameters: PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest): Promise<runtime.ApiResponse<PutOfferAttributesOut>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePut.');
        }

        if (requestParameters.attributeValue === null || requestParameters.attributeValue === undefined) {
            throw new runtime.RequiredError('attributeValue', 'Required parameter requestParameters.attributeValue was null or undefined when calling putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attributes/{attributeId}/value`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeValueToJSON(requestParameters.attributeValue),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PutOfferAttributesOutFromJSON(jsonValue));
    }

    /**
     * Put Offer Attribute Value
     */
    async putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePut(requestParameters: PutOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRequest): Promise<PutOfferAttributesOut> {
        const response = await this.putOfferAttributeValueApiV1OffersAttributesAttributeIdValuePutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Attribute Visibility
     */
    async putOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPutRaw(requestParameters: PutOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPutRequest): Promise<runtime.ApiResponse<CustomAttributeVisibilityOut>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId', 'Required parameter requestParameters.attributeId was null or undefined when calling putOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPut.');
        }

        if (requestParameters.attributeVisibility === null || requestParameters.attributeVisibility === undefined) {
            throw new runtime.RequiredError('attributeVisibility', 'Required parameter requestParameters.attributeVisibility was null or undefined when calling putOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/attributes/{attributeId}/visibility`.replace(`{${"attributeId"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeVisibilityToJSON(requestParameters.attributeVisibility),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAttributeVisibilityOutFromJSON(jsonValue));
    }

    /**
     * Put Offer Attribute Visibility
     */
    async putOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPut(requestParameters: PutOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPutRequest): Promise<CustomAttributeVisibilityOut> {
        const response = await this.putOfferAttributeVisibilityApiV1OffersAttributesAttributeIdVisibilityPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Description
     */
    async putOfferDescriptionApiV1OffersOfferIdDescriptionPutRaw(requestParameters: PutOfferDescriptionApiV1OffersOfferIdDescriptionPutRequest): Promise<runtime.ApiResponse<OfferOutDescription>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putOfferDescriptionApiV1OffersOfferIdDescriptionPut.');
        }

        if (requestParameters.offerInDescription === null || requestParameters.offerInDescription === undefined) {
            throw new runtime.RequiredError('offerInDescription', 'Required parameter requestParameters.offerInDescription was null or undefined when calling putOfferDescriptionApiV1OffersOfferIdDescriptionPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.descriptionType !== undefined) {
            queryParameters['descriptionType'] = requestParameters.descriptionType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/description`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferInDescriptionToJSON(requestParameters.offerInDescription),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferOutDescriptionFromJSON(jsonValue));
    }

    /**
     * Put Offer Description
     */
    async putOfferDescriptionApiV1OffersOfferIdDescriptionPut(requestParameters: PutOfferDescriptionApiV1OffersOfferIdDescriptionPutRequest): Promise<OfferOutDescription> {
        const response = await this.putOfferDescriptionApiV1OffersOfferIdDescriptionPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Name
     */
    async putOfferNameApiV1OffersOfferIdNamePutRaw(requestParameters: PutOfferNameApiV1OffersOfferIdNamePutRequest): Promise<runtime.ApiResponse<OfferOutName>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putOfferNameApiV1OffersOfferIdNamePut.');
        }

        if (requestParameters.offerInName === null || requestParameters.offerInName === undefined) {
            throw new runtime.RequiredError('offerInName', 'Required parameter requestParameters.offerInName was null or undefined when calling putOfferNameApiV1OffersOfferIdNamePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/name`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferInNameToJSON(requestParameters.offerInName),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferOutNameFromJSON(jsonValue));
    }

    /**
     * Put Offer Name
     */
    async putOfferNameApiV1OffersOfferIdNamePut(requestParameters: PutOfferNameApiV1OffersOfferIdNamePutRequest): Promise<OfferOutName> {
        const response = await this.putOfferNameApiV1OffersOfferIdNamePutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Note
     */
    async putOfferNoteApiV1OffersOfferIdNotePutRaw(requestParameters: PutOfferNoteApiV1OffersOfferIdNotePutRequest): Promise<runtime.ApiResponse<OfferOutNote>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putOfferNoteApiV1OffersOfferIdNotePut.');
        }

        if (requestParameters.offerInNote === null || requestParameters.offerInNote === undefined) {
            throw new runtime.RequiredError('offerInNote', 'Required parameter requestParameters.offerInNote was null or undefined when calling putOfferNoteApiV1OffersOfferIdNotePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/note`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferInNoteToJSON(requestParameters.offerInNote),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferOutNoteFromJSON(jsonValue));
    }

    /**
     * Put Offer Note
     */
    async putOfferNoteApiV1OffersOfferIdNotePut(requestParameters: PutOfferNoteApiV1OffersOfferIdNotePutRequest): Promise<OfferOutNote> {
        const response = await this.putOfferNoteApiV1OffersOfferIdNotePutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Status
     */
    async putOfferStatusApiV1OffersOfferIdStatusPutRaw(requestParameters: PutOfferStatusApiV1OffersOfferIdStatusPutRequest): Promise<runtime.ApiResponse<OfferOutStatus>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putOfferStatusApiV1OffersOfferIdStatusPut.');
        }

        if (requestParameters.offerInStatus === null || requestParameters.offerInStatus === undefined) {
            throw new runtime.RequiredError('offerInStatus', 'Required parameter requestParameters.offerInStatus was null or undefined when calling putOfferStatusApiV1OffersOfferIdStatusPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/status`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferInStatusToJSON(requestParameters.offerInStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferOutStatusFromJSON(jsonValue));
    }

    /**
     * Put Offer Status
     */
    async putOfferStatusApiV1OffersOfferIdStatusPut(requestParameters: PutOfferStatusApiV1OffersOfferIdStatusPutRequest): Promise<OfferOutStatus> {
        const response = await this.putOfferStatusApiV1OffersOfferIdStatusPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Put Offer Zoom
     */
    async putOfferZoomApiV1OffersOfferIdZoomPutRaw(requestParameters: PutOfferZoomApiV1OffersOfferIdZoomPutRequest): Promise<runtime.ApiResponse<OfferZoomOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putOfferZoomApiV1OffersOfferIdZoomPut.');
        }

        if (requestParameters.offerZoomIn === null || requestParameters.offerZoomIn === undefined) {
            throw new runtime.RequiredError('offerZoomIn', 'Required parameter requestParameters.offerZoomIn was null or undefined when calling putOfferZoomApiV1OffersOfferIdZoomPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/zoom`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferZoomInToJSON(requestParameters.offerZoomIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferZoomOutFromJSON(jsonValue));
    }

    /**
     * Put Offer Zoom
     */
    async putOfferZoomApiV1OffersOfferIdZoomPut(requestParameters: PutOfferZoomApiV1OffersOfferIdZoomPutRequest): Promise<OfferZoomOut> {
        const response = await this.putOfferZoomApiV1OffersOfferIdZoomPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * REDD API data mass integration
     */
    async putReddApiV1OffersReddUpdatePutRaw(requestParameters: PutReddApiV1OffersReddUpdatePutRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.offerIds === null || requestParameters.offerIds === undefined) {
            throw new runtime.RequiredError('offerIds', 'Required parameter requestParameters.offerIds was null or undefined when calling putReddApiV1OffersReddUpdatePut.');
        }

        const queryParameters: any = {};

        if (requestParameters.offerIds) {
            queryParameters['offerIds'] = requestParameters.offerIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/redd_update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * REDD API data mass integration
     */
    async putReddApiV1OffersReddUpdatePut(requestParameters: PutReddApiV1OffersReddUpdatePutRequest): Promise<any> {
        const response = await this.putReddApiV1OffersReddUpdatePutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Integrate offer attributes with REDD API data
     */
    async putReddAttributesApiV1OffersOfferIdReddPutRaw(requestParameters: PutReddAttributesApiV1OffersOfferIdReddPutRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling putReddAttributesApiV1OffersOfferIdReddPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/redd`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Integrate offer attributes with REDD API data
     */
    async putReddAttributesApiV1OffersOfferIdReddPut(requestParameters: PutReddAttributesApiV1OffersOfferIdReddPutRequest): Promise<any> {
        const response = await this.putReddAttributesApiV1OffersOfferIdReddPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set Offer Attachment Main
     */
    async setOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPutRaw(requestParameters: SetOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPutRequest): Promise<runtime.ApiResponse<AttachmentMainOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling setOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPut.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling setOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPut.');
        }

        if (requestParameters.attachmentMain === null || requestParameters.attachmentMain === undefined) {
            throw new runtime.RequiredError('attachmentMain', 'Required parameter requestParameters.attachmentMain was null or undefined when calling setOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attachments/{attachmentId}/main`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttachmentMainToJSON(requestParameters.attachmentMain),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentMainOutFromJSON(jsonValue));
    }

    /**
     * Set Offer Attachment Main
     */
    async setOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPut(requestParameters: SetOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPutRequest): Promise<AttachmentMainOut> {
        const response = await this.setOfferAttachmentMainApiV1OffersOfferIdAttachmentsAttachmentIdMainPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set Offer Attachment Visibility
     */
    async setOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPutRaw(requestParameters: SetOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPutRequest): Promise<runtime.ApiResponse<AttachmentVisibilityOut>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId', 'Required parameter requestParameters.offerId was null or undefined when calling setOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPut.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId', 'Required parameter requestParameters.attachmentId was null or undefined when calling setOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPut.');
        }

        if (requestParameters.attachmentVisibility === null || requestParameters.attachmentVisibility === undefined) {
            throw new runtime.RequiredError('attachmentVisibility', 'Required parameter requestParameters.attachmentVisibility was null or undefined when calling setOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/offers/{offerId}/attachments/{attachmentId}/visibility`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttachmentVisibilityToJSON(requestParameters.attachmentVisibility),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentVisibilityOutFromJSON(jsonValue));
    }

    /**
     * Set Offer Attachment Visibility
     */
    async setOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPut(requestParameters: SetOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPutRequest): Promise<AttachmentVisibilityOut> {
        const response = await this.setOfferAttachmentVisibilityApiV1OffersOfferIdAttachmentsAttachmentIdVisibilityPutRaw(requestParameters);
        return await response.value();
    }

}
