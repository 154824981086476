import Qs from "qs";
import { FiltersQuery } from "@state/filters/types";
import Axios, { AxiosRequestConfig } from "axios";
import { getEnv } from "@utils/get-env";
import { Module } from "@state/types";

const API_URL = `${getEnv("REACT_APP_API_URL")}/api/v1/offers/`;

const filters = (filtersQuery: FiltersQuery): Partial<FiltersQuery> =>
  Object.entries(filtersQuery).reduce(
    (selectedFilters, [key, value]) =>
      value.toString.length || Object.keys(value).length
        ? { ...selectedFilters, [key]: value }
        : { ...selectedFilters },
    {}
  );

const extractFileName = (sourceString: string): string => {
  const indexStart = sourceString.indexOf('"');
  const indexEnd = sourceString.lastIndexOf('"');
  return sourceString.slice(indexStart + 1, indexEnd);
};

export const downloadExcelOfferList = async (filtersQuery: FiltersQuery, module: Module) => {
  try {
    const customFiltersObj = filters(filtersQuery);
    const paramsObj = {
      ...customFiltersObj,
      customAttributeFilter: undefined,
      ...customFiltersObj?.customAttributeFilter,
      module,
    };
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: { Accept: "application/vnd.ms-excel" },
      params: {
        pageNumber: 1,
        pageSize: 10,
        ...paramsObj,
      },
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "repeat" }),
      responseType: "blob",
    };
    const response = await Axios.get(API_URL, axiosRequestConfig);
    const fileName = extractFileName(response.headers["content-disposition"]);
    const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.ms-excel" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};
