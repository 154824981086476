import React, { SyntheticEvent } from "react";
import { Input } from "antd";

import { Label } from "@components/input";
import { Button } from "@components/button";

import { AddNotesFormProps } from "./types";

const { TextArea } = Input;

const noteLabelId = "Note";

export const AddNoteForm: React.FC<AddNotesFormProps> = ({
  toggle,
  addComment,
  value,
  onChange,
  isLoading,
}) => {
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    addComment();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Label htmlFor={noteLabelId}>{noteLabelId}</Label>
      <TextArea id={noteLabelId} value={value} onChange={onChange} rows={5} cols={50} />
      <div style={{ marginTop: 20 }}>
        <Button size="small" htmlType="button" onClick={toggle}>
          Cancel
        </Button>
        <Button size="small" htmlType="submit" loading={isLoading} type="primary">
          Add note
        </Button>
      </div>
    </form>
  );
};
